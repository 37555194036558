import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import withMobileDialog, {
  InjectedProps,
} from '@material-ui/core/withMobileDialog';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import RotateLeft from '@material-ui/icons/RotateLeft';
import RotateRight from '@material-ui/icons/RotateRight';
import ZoomIn from '@material-ui/icons/ZoomIn';
import ZoomOut from '@material-ui/icons/ZoomOut';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
import { css } from 'emotion';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import Dropzone from 'react-dropzone';
import styled from 'react-emotion';
import { FormattedMessage } from 'react-intl';

import Dialog from '~/components/dialogs/Dialog';
import DialogActions from '~/components/dialogs/DialogActions';
import DialogContent from '~/components/dialogs/DialogContent';
import DialogTitle from '~/components/dialogs/DialogTitle';
import i18n from '~/locales/i18n';
import stores from '~/stores';
import theme from '~/styles/theme';
import canvasHasTransparency from '~/utils/canvasHasTransparency';
import getFileName from '~/utils/getFileName';
import toLink from '~/utils/toLink';
import withStore, { WithStoreProps } from '~/utils/withStore';

const CropperImage = css`
  max-height: 500px;
  max-width: 100%;
`;

const EditorWrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const DropzoneDiv = styled.div`
  &:hover {
    border-color: ${(p) => p.theme.palette.primary.main}!important;
  }
`;

export interface ImageImportResult {
  name: string;
  url: string;
  blob?: Blob;
  id?: number;
  link?: string;
}

interface Props extends InjectedProps {
  onSubmit: (result: ImageImportResult) => void;
  onClose?: () => void;
  outputOptions?: Cropper.GetCroppedCanvasOptions;
  options?: Cropper.Options;
  index: number;
  withLink?: boolean;
  link?: string;
  image?: File;
  imageUrl?: string | null;
  recommendedSize?: string;
}

@observer
class ImageImport extends React.Component<Props & WithStoreProps> {
  public cropperImageRef: React.RefObject<HTMLImageElement>;
  public cropperInstance: Cropper;

  @observable
  public url: string = '';

  @observable
  public error: boolean = false;

  @observable
  public link: string = '';

  @observable
  public linkOpen: boolean = false;

  @observable
  public name: string = '';

  constructor(props: Props & WithStoreProps) {
    super(props);
    this.cropperImageRef = React.createRef();
    this.link = this.props.link || '';
    this.linkOpen = !!this.props.link;
  }

  public init = () => {
    const options: Cropper.Options = {
      aspectRatio: 16 / 9,
      autoCropArea: 1,
      autoCrop: true,
      rotatable: true,
      checkOrientation: false,
      ...this.props.options,
    };
    if (this.cropperImageRef.current) {
      this.cropperInstance = new Cropper(this.cropperImageRef.current, options);
      if (this.props.image) {
        const url = URL.createObjectURL(this.props.image);
        this.cropperInstance.replace(url);
        this.url = url;
        this.name = this.props.image.name;
      }
      if (this.props.imageUrl) {
        this.cropperInstance.replace(this.props.imageUrl);
        this.url = this.props.imageUrl;
        this.name = getFileName(this.props.imageUrl);
      }
    }
  };

  public clean() {
    if (this.cropperInstance) {
      this.cropperInstance.destroy();
    }
  }

  @action.bound
  public onError = () => {
    this.error = true;
  };

  @action.bound
  public onLoad = () => {
    this.error = false;
  };

  @action.bound
  public onDrop(files: File[]) {
    if (files && files.length === 1) {
      const file = files[0];
      const url = URL.createObjectURL(file);
      if (this.props.store.uiStore.isMobile) {
        this.name = file.name;
        const nameParts = this.name.split('.');
        let name = nameParts[0];

        if (nameParts.length >= 2) {
          name = `${nameParts[0]}.${nameParts[nameParts.length - 1]}`;
        }

        this.props.onSubmit({
          url,
          name: name.replace('.svg', '.png'),
          blob: file,
          link: toLink(this.link),
        });
        return;
      }

      this.cropperInstance.replace(url);
      this.url = url;
      this.name = file.name;
    }
  }

  public handleSubmit = () => {
    const options: Cropper.GetCroppedCanvasOptions = {
      ...this.props.outputOptions,
    };
    options.maxWidth = Math.min(options.maxWidth || 2000, 2000);
    options.maxHeight = Math.min(options.maxHeight || 2000, 2000);

    const canvas = this.cropperInstance.getCroppedCanvas(options);

    let type = 'image/png';

    if (!canvasHasTransparency(canvas)) {
      type = 'image/jpeg';
    }

    canvas.toBlob((blob: Blob | null) => {
      if (blob) {
        const nameParts = this.name.split('.');
        let name = nameParts[0];
        const extension = type.split('/')[1];

        if (nameParts.length >= 2) {
          name = `${nameParts[0]}.${extension}`;
        }

        this.props.onSubmit({
          blob,
          name,
          url: URL.createObjectURL(blob),
          link: toLink(this.link),
        });
      }
    }, type);
  };

  public render() {
    const { fullScreen, index, onClose } = this.props;
    /* tslint:disable:jsx-no-lambda */
    return (
      <Dialog
        open
        fullScreen={fullScreen}
        disableEscapeKeyDown
        index={index}
        onEntered={this.init}
        onExit={this.clean}
        onClose={onClose}
      >
        <DialogTitle onClose={onClose}>
          <FormattedMessage id="dialogs.import_image.title" />
        </DialogTitle>
        <DialogContent>
          <Dropzone accept="image/*" onDrop={this.onDrop}>
            {({
              getRootProps,
              isDragActive,
              isDragAccept,
              getInputProps,
              open,
            }) => {
              return (
                <DropzoneDiv
                  onClick={open}
                  style={{
                    cursor: 'pointer',
                    width: '100%',
                    height: '100px',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px',
                    justifyContent: 'center',
                    color:
                      isDragActive || isDragAccept
                        ? theme.current.palette.primary.main
                        : 'grey',
                    border:
                      isDragActive || isDragAccept
                        ? `2px dashed ${theme.current.palette.primary.main}`
                        : '2px dashed grey',
                    borderRadius: '5px',
                  }}
                  {...getRootProps()}
                >
                  <input {...getInputProps()} />
                  <FormattedMessage
                    id={
                      this.url.length
                        ? 'upload_picture.drag_drop.description_already_uploaded'
                        : 'upload_picture.drag_drop.description'
                    }
                  />
                </DropzoneDiv>
              );
            }}
          </Dropzone>
          {this.url ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
              }}
            >
              <IconButton onClick={() => this.cropperInstance.zoom(0.1)}>
                <ZoomIn />
              </IconButton>
              <IconButton onClick={() => this.cropperInstance.zoom(-0.1)}>
                <ZoomOut />
              </IconButton>
              <IconButton onClick={() => this.cropperInstance.rotate(-45)}>
                <RotateLeft />
              </IconButton>
              <IconButton onClick={() => this.cropperInstance.rotate(45)}>
                <RotateRight />
              </IconButton>
              <IconButton onClick={() => this.cropperInstance.move(0, -10)}>
                <KeyboardArrowDown />
              </IconButton>
              <IconButton onClick={() => this.cropperInstance.move(0, 10)}>
                <KeyboardArrowUp />
              </IconButton>
              <IconButton onClick={() => this.cropperInstance.move(10, 0)}>
                <KeyboardArrowLeft />
              </IconButton>
              <IconButton onClick={() => this.cropperInstance.move(-10, 0)}>
                <KeyboardArrowRight />
              </IconButton>
            </div>
          ) : null}
          <EditorWrapper style={{ display: this.error ? 'none' : undefined }}>
            <img
              crossOrigin="use-credentials"
              className={CropperImage}
              onError={this.onError}
              onLoad={this.onLoad}
              ref={this.cropperImageRef}
            />
          </EditorWrapper>
          {this.error && (
            <Typography color="error">
              <FormattedMessage id="dialogs.import_image.error" />
            </Typography>
          )}
          {this.props.withLink && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.linkOpen}
                    color="primary"
                    onChange={() =>
                      runInAction(() => {
                        this.linkOpen = !this.linkOpen;
                      })
                    }
                  />
                }
                label={i18n.formatMessage('image.upload.link')}
              />
              {this.linkOpen && (
                <TextField
                  variant="outlined"
                  fullWidth
                  onChange={(e) =>
                    runInAction(() => {
                      this.link = e.target.value;
                    })
                  }
                  value={this.link}
                />
              )}
            </>
          )}
          {this.props.recommendedSize && (
            <Typography variant="caption" align="center">
              <FormattedMessage id="dialogs.import_image.recommended_size" />:{' '}
              {this.props.recommendedSize}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="flat" color="primary" onClick={onClose}>
            <FormattedMessage id="commons.back" />
          </Button>
          <Button
            disabled={this.error || !this.url || this.url.length === 0}
            variant="contained"
            onClick={this.handleSubmit}
            color="primary"
          >
            <FormattedMessage id="commons.send" />
          </Button>
        </DialogActions>
      </Dialog>
    );
    /* tslint:enable:jsx-no-lambda */
  }
}

const ImageImportDialog = withMobileDialog<Props>()(withStore(ImageImport));

const imageImport = (
  options?: Cropper.Options,
  outputOptions?: Cropper.GetCroppedCanvasOptions,
  withLink?: boolean,
  image?: File,
  recommendedSize?: string,
) =>
  new Promise<ImageImportResult>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = () => stores.uiStore.closeDialog(index);
    const onSubmit = (result: ImageImportResult) => {
      resolve(result);
      onClose();
    };

    stores.uiStore.openDialog(
      <ImageImportDialog
        index={index}
        onClose={onClose}
        options={options}
        outputOptions={outputOptions}
        onSubmit={onSubmit}
        withLink={withLink}
        recommendedSize={recommendedSize}
        image={image}
      />,
    );
  });

export const importImage = (params: {
  options?: Cropper.Options;
  outputOptions?: Cropper.GetCroppedCanvasOptions;
  withLink?: boolean;
  link?: string;
  image?: File;
  imageUrl?: string | null;
  recommendedSize?: string;
}) =>
  new Promise<ImageImportResult>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = () => stores.uiStore.closeDialog(index);
    const onSubmit = (result: ImageImportResult) => {
      resolve(result);
      onClose();
    };

    stores.uiStore.openDialog(
      <ImageImportDialog
        index={index}
        onClose={onClose}
        options={params.options}
        outputOptions={params.outputOptions}
        onSubmit={onSubmit}
        withLink={params.withLink}
        link={params.link}
        image={params.image}
        recommendedSize={params.recommendedSize}
        imageUrl={params.imageUrl}
      />,
    );
  });

export default imageImport;
