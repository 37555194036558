import isPropValid from '@emotion/is-prop-valid';
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage, FormattedRelative } from 'react-intl';
import { Link } from 'react-router-dom';

import Avatar from '~/components/Avatar';
import { BOT_ICON, BOT_NAME } from '~/constants/bot';
import { conversationPath } from '~/constants/routes';
import i18n from '~/locales/i18n';
import { Conversation } from '~/models/chat';
import styled from '~/styles';
import theme from '~/styles/theme';
import withStore, { WithStoreProps } from '~/utils/withStore';
import UserIcon from '../UserDisplay/UserIcon';
import { MENTION_EXTRACT } from '../utils/EnhancedContent';
import ChatAvatar from './ChatAvatar';

interface WrapperProps {
  active?: boolean;
  disableBorder?: boolean;
}

const Wrapper = styled(ListItem, { shouldForwardProp: isPropValid })<
  WrapperProps
>(
  (p) => `
  && {
    padding: 16px;
    cursor: pointer;
    box-sizing: border-box;
    border-left: ${p.disableBorder ? '0px' : '5px'} solid;
    border-color: ${
      p.active ? p.theme.palette.secondary.main : p.theme.palette.grey[200]
    };
    background-color: ${p.active ? p.theme.palette.grey[200] : 'transparent'};
    &:hover {
      border-color: ${p.theme.palette.secondary.main};
    }
  }

  strong {
    font-weight: 800;
  }
`,
);

const Preview = styled(ListItemText)`
  white-space: nowrap;
  overflow: hidden;
  pointer-events: none;
  img {
    vertical-align: middle;
  }
`;

const ConvLink = styled(Link)`
  text-decoration: none;
`;

const Circle = styled.div`
  position: absolute;
  height: 14px;
  width: 14px;
  background-color: ${(p) => p.theme.palette.secondary.main};
  border: 4px solid white;
  z-index: 999;
  border-radius: 50%;
  left: 45px;
  margin-top: -15px;
`;

const ConvHeader = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
`;

const Icon = styled.img`
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
`;

const BotIcon = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 3px;
`;

const MENTION_REMOVE = new RegExp(MENTION_EXTRACT, 'g');

const getMessage = (value: string) => {
  return value.replace(MENTION_REMOVE, '@$2');
};

interface Props {
  conversation: Conversation;
  active: boolean;
  disableBorder?: boolean;
}

@observer
class ConversationItem extends React.Component<Props & WithStoreProps> {
  @computed
  get toUser() {
    const { store, conversation } = this.props;
    return conversation.one_to_one
      ? conversation.members.filter((c) => c.id !== store.authStore.user.id)[0]
      : null;
  }

  @computed
  get isNew() {
    const { conversation, store } = this.props;
    return (
      conversation.last_message &&
      !conversation.last_message.read &&
      conversation.last_message.user !== store.authStore.user.id
    );
  }

  @computed
  get title() {
    const { conversation } = this.props;

    if (conversation.name && conversation.name.length) {
      return conversation.name;
    }

    if (conversation.isBot) {
      return (
        <div>
          {BOT_NAME}
          <span style={{ fontSize: '14px', marginLeft: '3px', color: '#aaa' }}>
            (bot)
          </span>
        </div>
      );
    }

    if (conversation.one_to_one) {
      return this.toUser ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {this.toUser.fullname}
          <UserIcon user={this.toUser} size={15} />
        </div>
      ) : (
        'Error'
      );
    }

    if (conversation.isGroup) {
      return conversation.members
        .filter((c) => c.id !== this.props.store.authStore.user.id)
        .map((c) => c.fullname)
        .join(', ');
    }

    if (conversation.data && conversation.data.type === 'project') {
      return `${conversation.data.name}`;
    }

    if (conversation.data && conversation.data.type === 'partnership') {
      return `${conversation.data.name}`;
    }

    if (conversation.data && conversation.data.type === 'intra_folder') {
      const messageId = `intra.folders.${conversation.data.folder_id}`;
      return `[${i18n.formatMessage(messageId)}] ${conversation.data.name}`;
    }
    if (conversation.data && conversation.data.type === 'openinno_project') {
      return `${conversation.data.name}`;
    }
    if (conversation.data && conversation.data.type === 'community') {
      return `${conversation.data.name}`;
    }
    if (conversation.data && conversation.data.type === 'labprojectopic') {
      if (conversation.data.name) {
        return `${conversation.data.name} - ${conversation.data.project_name}`;
      }

      return conversation.data.project_name;
    }
    return 'Error';
  }

  @computed
  get lastMessage() {
    const { conversation, store } = this.props;
    if (conversation.isBot) {
      if (
        conversation.last_message &&
        conversation.last_message.user !== store.authStore.user.id &&
        !conversation.last_message.read
      ) {
        return <FormattedMessage id="chatbot.new_message" />;
      }
      return null;
    }
    if (conversation.last_message) {
      if (conversation.last_message.user === store.authStore.user.id) {
        if (!conversation.last_message.content) {
          return i18n.formatMessage('chat.sent_file');
        }
        return `${i18n.formatMessage('chat.you')}: ${getMessage(
          conversation.last_message.content,
        )}`;
      }
      if (!conversation.last_message.content) {
        return i18n.formatMessage('chat.received_file');
      }
      return getMessage(conversation.last_message.content);
    }
    return i18n.formatMessage('chat.new_conversation');
  }

  @computed
  get avatar() {
    const { conversation } = this.props;

    if (conversation.isBot) {
      return <BotIcon src={BOT_ICON} />;
    }

    if (conversation.icon) {
      return <Icon src={conversation.icon} />;
    }

    if (conversation.one_to_one && this.toUser) {
      return <Avatar size={40} user={this.toUser} />;
    }

    return <ChatAvatar conversation={conversation} />;
  }

  public render() {
    const { active, conversation } = this.props;
    return (
      <ConvLink to={conversationPath(conversation.id)}>
        <Wrapper active={active} disableBorder={this.props.disableBorder}>
          {this.isNew && (
            <Circle
              className="circle"
              style={{
                borderColor: active
                  ? theme.current.palette.grey[200]
                  : undefined,
              }}
            />
          )}
          <ListItemAvatar>{this.avatar}</ListItemAvatar>
          <Preview
            primary={
              <ConvHeader>
                {this.isNew ? (
                  <strong>{this.title}</strong>
                ) : (
                  <span>{this.title}</span>
                )}
                {conversation.last_message && (
                  <Typography
                    component="span"
                    variant="caption"
                    style={{
                      marginRight: '-10px',
                      backgroundColor: active
                        ? theme.current.palette.grey[200]
                        : 'white',
                      position: 'absolute',
                      zIndex: 100,
                      right: '-7px',
                      top: '3px',
                      padding: '0 5px',
                    }}
                  >
                    <FormattedRelative
                      value={conversation.last_message.date_created.toJSDate()}
                      updateInterval={0}
                    />
                  </Typography>
                )}
              </ConvHeader>
            }
            secondary={this.lastMessage}
          />
        </Wrapper>
      </ConvLink>
    );
  }
}

export default withStore(ConversationItem);
