import { computed } from 'mobx';
import { observer } from 'mobx-react';
import React, { Suspense } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';

import {
  academyDetailsPath,
  academyPath,
  accountCreationsPath,
  accountNewsPath,
  accountPath,
  accountSettingsPath,
  challengesAdminPath,
  challengesDetailsPath,
  challengesPath,
  changeLogPath,
  chatPath,
  communitiesDetailsPath,
  communitiesPath,
  contestDetailsPath,
  contestPath,
  conversationPath,
  dashboardPath,
  detailsRedirectPath,
  homePath,
  ideaboxDetailsPath,
  ideaboxPath,
  intraDetailsPath,
  intraPath,
  joinPath,
  labPath,
  labProjectDetailsPath,
  loginPath,
  myProfilePath,
  oiContestAdminDetailsPath,
  oiContestDetailsPath,
  oiContestPath,
  openInnoChallengeAdminPath,
  openInnoChallengePath,
  openInnoChallengesPath,
  openInnoJoinPath,
  openInnoPartnerDetailsPath,
  openInnoPartnerRedirectPath,
  openInnoPartnershipsPath,
  openInnoPath,
  openInnoProjectJoinPath,
  openInnoProjectsDetailsPath,
  openInnoPublicPageAdminPath,
  openInnoPublicPagePath,
  profilePath,
  projectsDetailsPath,
  projectsPath,
  registerPath,
  resetPasswordConfirmPath,
  resetPasswordPath,
  searchPath,
  settingsPath,
  startConversationPath,
  startRegistrationPath,
  usersPath,
} from '~/constants/routes';

import {
  largePageWidth,
  noMaxWidth,
  veryLargePageWidth,
  xLargePageWidth,
} from '~/components/Page';
import LayoutRoute from '~/components/Router/LayoutRoute';
import history from '~/history';
import lazyRetry from '~/utils/lazyRetry';
import withStore, { WithStoreProps } from '~/utils/withStore';
import Progress from '../Progress';
import FragmentSupportingSwitch from './FragmentSwitch';

const getRedirectSearch = () => {
  if (window.location.search.length > 0) {
    if (window.location.search.indexOf('next=') !== -1) {
      return window.location.search;
    }

    return `${window.location.search}&next=${window.location.pathname}${window.location.hash}`;
  }
  return `?next=${window.location.pathname}${window.location.hash}`;
};

const HomePage = lazyRetry(() => import('~/pages/Home'));
const ProfilePage = lazyRetry(() => import('~/pages/Profile'));
const ChatPage = lazyRetry(() => import('~/pages/Chat'));
const ProjectDetailsPage = lazyRetry(() => import('~/pages/Projects/Details'));
const OpenInnoProjectDetailsPage = lazyRetry(() =>
  import('~/modules/openinno.projects/Details'),
);
const IntraDetailsPage = lazyRetry(() => import('~/pages/Intra/Details'));
const SettingsPage = lazyRetry(() => import('~/pages/Settings'));
const DashboardPage = lazyRetry(() => import('~/pages/Dashboard'));
const SearchPage = lazyRetry(() => import('~/pages/Search'));

@observer
class CampusRouter extends React.Component<WithStoreProps> {
  @computed
  get routes() {
    const organization = this.props.store.organizationStore.organization;

    if (this.props.store.authStore.authenticated) {
      return (
        <Switch>
          <LayoutRoute
            exact
            path={homePath()}
            width={largePageWidth}
            component={HomePage}
            pageProps={{ tab: 0 }}
          />
          <LayoutRoute
            exact
            path={homePath('news')}
            width={largePageWidth}
            component={HomePage}
            pageProps={{ tab: 0 }}
          />
          <LayoutRoute
            exact
            path={homePath('my_news')}
            width={largePageWidth}
            component={HomePage}
            pageProps={{ tab: 1 }}
          />
          <LayoutRoute
            exact
            path={homePath('popular')}
            width={largePageWidth}
            component={HomePage}
            pageProps={{ tab: 2 }}
          />
          <LayoutRoute
            exact
            path={homePath('topusers')}
            width={largePageWidth}
            component={HomePage}
            pageProps={{ tab: 3 }}
          />
          <LayoutRoute
            exact
            path={ideaboxPath}
            component={lazyRetry(() => import('~/pages/Ideabox'))}
            feature="ideabox"
          />
          <LayoutRoute
            path={ideaboxDetailsPath()}
            component={lazyRetry(() => import('~/pages/Ideabox/Details'))}
            feature="ideabox"
            width={noMaxWidth}
            padding={0}
          />

          <LayoutRoute
            path={usersPath}
            component={lazyRetry(() => import('~/pages/Users'))}
          />
          <LayoutRoute
            exact
            path={myProfilePath}
            width={veryLargePageWidth}
            component={ProfilePage}
            pageProps={{ tab: 0 }}
          />
          <LayoutRoute
            exact
            path={accountPath}
            component={ProfilePage}
            externalOk
            pageProps={{ tab: 1 }}
          />
          <LayoutRoute
            exact
            path={accountSettingsPath}
            component={ProfilePage}
            externalOk
            pageProps={{ tab: 2 }}
          />
          <LayoutRoute
            exact
            path={accountNewsPath}
            component={ProfilePage}
            pageProps={{ tab: 3 }}
          />
          <LayoutRoute
            exact
            path={accountCreationsPath}
            component={ProfilePage}
            pageProps={{ tab: 4 }}
          />
          <LayoutRoute
            width={veryLargePageWidth}
            path={profilePath()}
            component={lazyRetry(() => import('~/pages/Profile/Public'))}
          />
          <LayoutRoute
            exact
            path={chatPath}
            component={ChatPage}
            width={noMaxWidth}
            padding={0}
            externalOk
          />
          <LayoutRoute
            exact
            path={startConversationPath()}
            component={lazyRetry(() => import('~/pages/Chat/Create'))}
            width={noMaxWidth}
            padding={0}
          />
          <LayoutRoute
            path={conversationPath()}
            component={ChatPage}
            width={noMaxWidth}
            externalOk
            padding={0}
          />
          <LayoutRoute
            exact
            path={challengesPath}
            component={lazyRetry(() => import('~/pages/Challenges'))}
            feature="challenges"
          />
          <LayoutRoute
            exact
            path={challengesDetailsPath()}
            width={noMaxWidth}
            padding={0}
            component={lazyRetry(() => import('~/pages/Challenges/Details'))}
            feature="challenges"
          />
          <LayoutRoute
            exact
            path={challengesAdminPath()}
            width={noMaxWidth}
            padding={0}
            component={lazyRetry(() => import('~/pages/Challenges/Admin'))}
            feature="challenges"
          />
          <LayoutRoute
            exact
            path={communitiesPath}
            component={lazyRetry(() => import('~/pages/Communities'))}
            feature="communities"
          />
          <LayoutRoute
            exact
            path={communitiesDetailsPath(':slug')}
            component={lazyRetry(() => import('~/pages/Communities/Details'))}
            feature="communities"
            width={noMaxWidth}
            padding={0}
          />
          <LayoutRoute
            exact
            path={projectsPath}
            component={lazyRetry(() => import('~/pages/Projects'))}
            feature="projects"
          />
          <LayoutRoute
            exact
            path={projectsDetailsPath(':slug', 'dashboard')}
            component={ProjectDetailsPage}
            feature="projects"
            width={noMaxWidth}
            padding={0}
            pageProps={{ tab: 0 }}
          />
          <LayoutRoute
            exact
            path={projectsDetailsPath(':slug', 'team')}
            component={ProjectDetailsPage}
            feature="projects"
            width={noMaxWidth}
            padding={0}
            pageProps={{ tab: 1 }}
          />
          <LayoutRoute
            exact
            path={projectsDetailsPath(':slug', 'chat')}
            component={ProjectDetailsPage}
            feature="projects"
            width={noMaxWidth}
            padding={0}
            pageProps={{ tab: 2 }}
          />
          <LayoutRoute
            exact
            path={projectsDetailsPath(':slug', 'files')}
            component={ProjectDetailsPage}
            feature="projects"
            width={noMaxWidth}
            padding={0}
            pageProps={{ tab: 3 }}
          />
          <LayoutRoute
            exact
            path={projectsDetailsPath(':slug', 'study')}
            component={ProjectDetailsPage}
            feature="projects"
            width={noMaxWidth}
            padding={0}
            pageProps={{ tab: 4 }}
          />
          <LayoutRoute
            exact
            path={projectsDetailsPath(':slug', 'notes')}
            component={ProjectDetailsPage}
            feature="projects"
            width={noMaxWidth}
            padding={0}
            pageProps={{ tab: 5 }}
          />
          <LayoutRoute
            exact
            path={intraPath}
            component={lazyRetry(() => import('~/pages/Intra'))}
            feature="intra"
          />
          <LayoutRoute
            exact
            path={intraDetailsPath(':slug', '')}
            component={IntraDetailsPage}
            feature="intra"
            pageProps={{ tab: 0, isHome: true }}
            width={noMaxWidth}
            padding={0}
          />
          <LayoutRoute
            exact
            path={intraDetailsPath(':slug', 'project')}
            component={IntraDetailsPage}
            feature="intra"
            pageProps={{ tab: 5, isHome: true }}
            width={noMaxWidth}
            padding={0}
          />
          <LayoutRoute
            exact
            path={intraDetailsPath(':slug', 'team')}
            component={IntraDetailsPage}
            feature="intra"
            pageProps={{ tab: 6, isHome: true }}
            width={noMaxWidth}
            padding={0}
          />
          <LayoutRoute
            exact
            path={intraDetailsPath(':slug', ':folder_id')}
            component={IntraDetailsPage}
            feature="intra"
            pageProps={{ tab: 1 }}
            width={noMaxWidth}
            padding={0}
          />
          <LayoutRoute
            exact
            path={intraDetailsPath(':slug', ':folder_id', 'chat')}
            component={IntraDetailsPage}
            feature="intra"
            width={noMaxWidth}
            pageProps={{ tab: 2 }}
            padding={0}
          />
          <LayoutRoute
            exact
            path={intraDetailsPath(':slug', ':folder_id', 'files')}
            component={IntraDetailsPage}
            feature="intra"
            width={noMaxWidth}
            pageProps={{ tab: 3 }}
            padding={0}
          />
          <LayoutRoute
            exact
            path={intraDetailsPath(':slug', ':folder_id', 'notes')}
            component={IntraDetailsPage}
            feature="intra"
            width={noMaxWidth}
            pageProps={{ tab: 4 }}
            padding={0}
          />
          <LayoutRoute
            exact
            path={settingsPath()}
            feature="organization"
            component={SettingsPage}
            width={largePageWidth}
            pageProps={{ tab: 6 }}
          />
          <LayoutRoute
            exact
            path={dashboardPath('general')}
            userType="moderator"
            component={DashboardPage}
            width={xLargePageWidth}
            pageProps={{ tab: 0 }}
          />
          <LayoutRoute
            exact
            path={dashboardPath('communities')}
            userType="moderator"
            component={DashboardPage}
            width={xLargePageWidth}
            pageProps={{ tab: 0 }}
          />
          <LayoutRoute
            exact
            path={dashboardPath('search')}
            userType="moderator"
            component={DashboardPage}
            width={largePageWidth}
            pageProps={{ tab: 1 }}
          />
          <LayoutRoute
            exact
            path={dashboardPath('ideabox')}
            userType="moderator"
            component={DashboardPage}
            width={largePageWidth}
            pageProps={{ tab: 2 }}
          />

          <LayoutRoute
            exact
            path={dashboardPath('challenges')}
            userType="moderator"
            component={DashboardPage}
            width={largePageWidth}
            pageProps={{ tab: 4 }}
          />
          <LayoutRoute
            exact
            path={dashboardPath('projects')}
            userType="moderator"
            component={DashboardPage}
            width={largePageWidth}
            pageProps={{ tab: 5 }}
          />
          <LayoutRoute
            exact
            path={dashboardPath('intra')}
            userType="moderator"
            component={DashboardPage}
            width={largePageWidth}
            pageProps={{ tab: 6 }}
          />
          <LayoutRoute
            exact
            path={dashboardPath('openinno.partnerships')}
            userType="moderator"
            component={DashboardPage}
            width={largePageWidth}
            pageProps={{ tab: 7 }}
          />
          <LayoutRoute
            exact
            path={dashboardPath('openinno.challenges')}
            userType="moderator"
            component={DashboardPage}
            width={largePageWidth}
            pageProps={{ tab: 8 }}
          />
          <LayoutRoute
            exact
            path={dashboardPath('oi_contest')}
            userType="moderator"
            component={DashboardPage}
            width={largePageWidth}
            pageProps={{ tab: 9 }}
          />
          <LayoutRoute
            exact
            path={changeLogPath}
            component={lazyRetry(() => import('~/pages/ChangeLog'))}
            width={largePageWidth}
          />
          {this.props.store.organizationStore.labFeatures.map(
            (feature, index) => (
              <LayoutRoute
                exact
                path={dashboardPath('lab', feature)}
                userType="moderator"
                component={DashboardPage}
                width={largePageWidth}
                pageProps={{ feature, tab: 100 + index }}
              />
            ),
          )}
          {this.props.store.organizationStore.contestFeatures.map(
            (feature, index) => (
              <LayoutRoute
                exact
                path={dashboardPath('contest', feature)}
                userType="moderator"
                component={DashboardPage}
                width={largePageWidth}
                pageProps={{ feature, tab: 200 + index }}
              />
            ),
          )}
          {this.props.store.organizationStore.academyFeatures.map(
            (feature, index) => (
              <LayoutRoute
                exact
                path={dashboardPath('academy', feature)}
                userType="moderator"
                component={DashboardPage}
                width={largePageWidth}
                pageProps={{ feature, tab: 300 + index }}
              />
            ),
          )}
          <LayoutRoute
            exact
            path={searchPath('contents')}
            component={SearchPage}
            pageProps={{ tab: 0 }}
            width={largePageWidth}
          />
          <LayoutRoute
            exact
            path={searchPath('users')}
            component={SearchPage}
            pageProps={{ tab: 1 }}
            width={largePageWidth}
          />
          <LayoutRoute
            exact
            path={searchPath('comments')}
            component={SearchPage}
            pageProps={{ tab: 2 }}
            width={largePageWidth}
          />
          <LayoutRoute
            exact
            path={searchPath('contributions')}
            component={SearchPage}
            pageProps={{ tab: 3 }}
            width={largePageWidth}
          />
          <Redirect exact from={openInnoPath} to={openInnoChallengesPath} />
          <LayoutRoute
            exact
            path={openInnoChallengesPath}
            component={lazyRetry(() => import('~/pages/OpenInno/Challenges'))}
            feature="openinno.challenges"
          />
          <LayoutRoute
            exact
            path={openInnoPartnershipsPath}
            component={lazyRetry(() => import('~/pages/OpenInno/Home'))}
            feature="openinno.partnerships"
          />
          <LayoutRoute
            exact
            path={openInnoPartnerDetailsPath()}
            component={lazyRetry(() =>
              import('~/pages/OpenInno/PartnerDetails'),
            )}
            padding={0}
            width={noMaxWidth}
            feature="openinno.partnerships"
          />
          <LayoutRoute
            exact
            path={openInnoPartnerRedirectPath()}
            component={lazyRetry(() =>
              import('~/pages/OpenInno/PartnerRedirect'),
            )}
            feature="openinno.partnerships"
          />
          <LayoutRoute
            exact
            path={openInnoProjectsDetailsPath(':slug', 'dashboard')}
            component={OpenInnoProjectDetailsPage}
            feature="openinno.partnerships"
            width={noMaxWidth}
            padding={0}
            externalOk
            pageProps={{ tab: 0 }}
          />
          <LayoutRoute
            exact
            path={openInnoProjectsDetailsPath(':slug', 'team')}
            component={OpenInnoProjectDetailsPage}
            feature="openinno.partnerships"
            width={noMaxWidth}
            padding={0}
            externalOk
            pageProps={{ tab: 1 }}
          />
          <LayoutRoute
            exact
            path={openInnoProjectsDetailsPath(':slug', 'chat')}
            component={OpenInnoProjectDetailsPage}
            feature="openinno.partnerships"
            width={noMaxWidth}
            padding={0}
            externalOk
            pageProps={{ tab: 2 }}
          />
          <LayoutRoute
            exact
            path={openInnoProjectsDetailsPath(':slug', 'files')}
            component={OpenInnoProjectDetailsPage}
            feature="openinno.partnerships"
            width={noMaxWidth}
            padding={0}
            externalOk
            pageProps={{ tab: 3 }}
          />
          <LayoutRoute
            exact
            path={openInnoProjectsDetailsPath(':slug', 'study')}
            component={OpenInnoProjectDetailsPage}
            feature="openinno.partnerships"
            width={noMaxWidth}
            padding={0}
            externalOk
            pageProps={{ tab: 4 }}
          />
          <LayoutRoute
            exact
            path={openInnoProjectsDetailsPath(':slug', 'notes')}
            component={OpenInnoProjectDetailsPage}
            feature="openinno.partnerships"
            width={noMaxWidth}
            padding={0}
            externalOk
            pageProps={{ tab: 5 }}
          />
          <LayoutRoute
            exact
            path={openInnoChallengeAdminPath()}
            component={lazyRetry(() =>
              import('~/pages/OpenInno/ChallengeAdmin'),
            )}
            adminOnly
            width={noMaxWidth}
            padding={0}
            feature="oi_contest"
          />
          <LayoutRoute
            exact
            path={openInnoPublicPageAdminPath()}
            component={lazyRetry(() =>
              import('~/pages/OpenInno/PublicPageAdmin'),
            )}
            adminOnly
            width={noMaxWidth}
            padding={0}
            feature="oi_contest"
          />
          {this.props.store.organizationStore.labFeatures.map((feature) => (
            <LayoutRoute
              exact
              path={labPath(feature)}
              pageProps={{
                feature,
              }}
              component={lazyRetry(() => import('~/pages/Lab/List'))}
            />
          ))}
          {this.props.store.organizationStore.labFeatures.map((feature) => (
            <LayoutRoute
              exact
              path={labProjectDetailsPath(':slug', feature)}
              width={noMaxWidth}
              padding={0}
              pageProps={{
                feature,
              }}
              component={lazyRetry(() => import('~/pages/Lab/Details'))}
            />
          ))}
          {this.props.store.organizationStore.contestFeatures.map((feature) => (
            <LayoutRoute
              exact
              path={contestPath(feature)}
              pageProps={{
                feature,
              }}
              component={lazyRetry(() => import('~/pages/Contest/List'))}
            />
          ))}
          {this.props.store.organizationStore.contestFeatures.map((feature) => (
            <LayoutRoute
              exact
              path={contestDetailsPath(':slug', feature)}
              width={noMaxWidth}
              padding={0}
              pageProps={{
                feature,
              }}
              component={lazyRetry(() => import('~/pages/Contest/Details'))}
            />
          ))}
          {this.props.store.organizationStore.academyFeatures.map((feature) => (
            <LayoutRoute
              exact
              path={academyPath(feature)}
              pageProps={{
                feature,
              }}
              component={lazyRetry(() => import('~/pages/Academy'))}
            />
          ))}
          {this.props.store.organizationStore.academyFeatures.map((feature) => (
            <LayoutRoute
              path={academyDetailsPath(':slug', feature)}
              component={lazyRetry(() => import('~/pages/Academy/Details'))}
              feature="academy"
              padding={0}
              pageProps={{ feature }}
              width={noMaxWidth}
            />
          ))}
          <LayoutRoute
            exact
            path={oiContestPath}
            component={lazyRetry(() => import('~/pages/OIContest/List'))}
          />
          <LayoutRoute
            exact
            path={oiContestAdminDetailsPath(':slug')}
            width={noMaxWidth}
            padding={0}
            component={lazyRetry(() => import('~/pages/OIContest/Details'))}
          />
          <Route
            exact
            path={oiContestDetailsPath(':slug')}
            component={lazyRetry(() =>
              import('~/pages/OIContest/PublicDetails'),
            )}
          />
          <Redirect
            to={
              this.props.store.authStore.isExternal &&
              this.props.store.authStore.myProjects.length >= 1
                ? openInnoProjectsDetailsPath(
                    this.props.store.authStore.myProjects[0].slug,
                  )
                : homePath()
            }
          />
        </Switch>
      );
    }
    return (
      <FragmentSupportingSwitch>
        <Route
          exact
          path={loginPath}
          component={lazyRetry(() => import('~/pages/Auth/Login'))}
        />

        <Route
          exact
          path={oiContestDetailsPath(':slug')}
          component={lazyRetry(() => import('~/pages/OIContest/PublicDetails'))}
        />

        {!this.props.store.organizationStore.passwordDisabled && (
          <>
            <Route
              exact
              path={resetPasswordPath}
              component={lazyRetry(() => import('~/pages/Auth/ResetPassword'))}
            />
            <Route
              exact
              path={resetPasswordConfirmPath}
              component={lazyRetry(() =>
                import('~/pages/Auth/ResetPasswordConfirm'),
              )}
            />
            <Route
              exact
              path={joinPath}
              component={lazyRetry(() => import('~/pages/Auth/Join'))}
            />
            <Route
              exact
              path={registerPath}
              component={lazyRetry(() => import('~/pages/Auth/Register'))}
            />
          </>
        )}

        {organization.config.allow_registration && (
          <>
            <Route
              exact
              path={startRegistrationPath}
              component={lazyRetry(() =>
                import('~/pages/Auth/StartRegistration'),
              )}
            />
          </>
        )}

        <Redirect to={{ pathname: '/login', search: getRedirectSearch() }} />
      </FragmentSupportingSwitch>
    );
  }

  public render() {
    const { store } = this.props;
    if (store.authStore.loading) return null;
    return (
      <Suspense fallback={Progress}>
        <Router history={history}>
          <Switch>
            <Route
              path={openInnoJoinPath()}
              exact
              component={lazyRetry(() => import('~/pages/OpenInno/Join'))}
            />
            <Route
              path={openInnoProjectJoinPath()}
              exact
              component={lazyRetry(() =>
                import('~/modules/openinno.projects/Join'),
              )}
            />
            <Route
              path={openInnoPublicPagePath()}
              exact
              component={lazyRetry(() => import('~/pages/OpenInno/PublicPage'))}
            />
            <Route
              path={openInnoChallengePath()}
              exact
              component={lazyRetry(() => import('~/pages/OpenInno/Challenge'))}
            />
            <Route
              path={detailsRedirectPath}
              exact
              component={lazyRetry(() => import('~/pages/DetailsRedirect'))}
            />
            <Route
              path="/editor"
              exact
              component={lazyRetry(() => import('~/pages/Editor'))}
            />

            {this.routes}
          </Switch>
        </Router>
      </Suspense>
    );
  }
}

export default withStore(CampusRouter);
