import { DateTime } from 'luxon';
import { action, computed, observable } from 'mobx';
import OpenInnoApi from '~/api/openinno';
import { randomColor } from '~/constants/colors';
import { MiniUser } from '~/models/user';
import { Socialable, UploadedFile, UploadedImage } from '~/models/utils';
import stores from '~/stores';
import { forceDownload } from '~/utils/downloadFile';
import isEmptyHTML from '~/utils/isEmptyHTML';
import { Question } from './polls';

export interface PartnershipUser extends MiniUser {
  email: string;
  phone: string;
  reinvited: boolean;
}

export class Partnership {
  public id: string;
  public name: string;
  public slug: string;
  public owner: MiniUser;

  @observable
  public partner: MiniUser;

  public content: string;
  public conversation: number;
  public partner_name: string;

  @observable
  public partner_logo: string;

  @observable
  public members: PartnershipUser[];

  constructor(data: any) {
    Object.assign(this, data);
  }

  public update(data: any) {
    Object.assign(this, data);
  }

  @computed
  get isOwner() {
    return this.owner.id === stores.authStore.user.id;
  }

  @computed
  get externalMembers() {
    return this.members.filter(
      (c) => c.user_type === 'external' || c.user_type === 'external_tmp',
    );
  }

  @computed
  get orgMembers() {
    return this.members.filter(
      (c) => c.user_type !== 'external' && c.user_type !== 'external_tmp',
    );
  }
}

export interface Rating {
  owner: MiniUser;
  comment: string;
  rating: number;
  files?: UploadedFile[];
}

export class ChallengeContribution extends Socialable {
  public username: string;
  public content: string;
  public images: UploadedImage[];
  public files: UploadedFile[];
  public personal_data_admin: any;
  public personal_data?: any;
  @observable
  public ratings: Rating[];
  @observable
  public selected: boolean;

  constructor(data: any) {
    super('openinnochallengecontribution', data);
    Object.assign(this, data);
  }

  @action.bound
  public toggleLike() {
    if (this.liked) {
      this.like_count -= 1;
      OpenInnoApi.unlike(this.object_type, this.id);
    } else {
      this.like_count += 1;
      OpenInnoApi.like(this.object_type, this.id);
    }
    this.liked = !this.liked;
  }

  @action.bound
  public share() {
    if (!this.shared) {
      this.shared = true;
      this.share_count += 1;
      OpenInnoApi.share(this.object_type, this.id);
    }
  }

  @computed
  get rating() {
    if (this.ratings.length === 0) return 0;
    return this.ratings.reduce((a, b) => a + b.rating, 0) / this.ratings.length;
  }

  @computed
  get rated() {
    return this.ratings.some((c) => c.owner.id === stores.authStore.user.id);
  }
}

export interface ChallengeOptions {
  displayNumber: boolean;
  canComment: boolean;
  canLike: boolean;
  canShare: boolean;
  hasEndDate: boolean;
  displayContributions: boolean;
  canLikeCommentContributions: boolean;
  needValidation?: boolean;
  canContributeMultipleTimes?: boolean;
  hideRatingsToParticipants?: boolean;
  hideRatingsToJury?: boolean;
}

export interface SectionFile {
  id: number;
  date_created: DateTime;
  section: string;
  file: string;
}

export class Challenge extends Socialable {
  public slug: string;

  @observable
  public title: string;

  @observable
  public brief: string;

  @observable
  public rules: string;

  @observable
  public jury: string;

  @observable
  public rewards: string;

  @observable
  public partners: string;

  @observable
  public resources: string;

  @observable
  public agenda: string;

  @observable
  public contribution_count: number;

  @observable
  public date_end: DateTime;

  @observable
  public sections: string[] = [];

  @observable
  public personal_data: string[] = [];

  @observable
  public language: 'fr' | 'en';

  @observable
  public options: ChallengeOptions;

  @observable
  public type: 'files' | 'poll';

  @observable
  public banner: string;

  @observable
  public published: boolean;

  @observable
  public ended: boolean;

  @observable
  public published_results: boolean;

  @observable
  public show_contributions: boolean;

  @observable
  public end_message: string;

  @observable
  public data: Question[] = [];

  @observable
  public result_files: UploadedFile[] = [];

  @observable
  public section_files: SectionFile[] = [];

  @observable
  public contributed: boolean;

  @observable
  public jury_members: MiniUser[] = [];

  @observable
  public public_page: number | null = null;

  constructor(data: any) {
    super('openinnochallenge', data);
    Object.assign(this, data);
  }

  public update(data: any) {
    Object.assign(this, data);
  }

  public hasSection(section: string) {
    return this.sections.indexOf(section) !== -1;
  }

  get dateEnded() {
    if (!this.date_end || !this.options.hasEndDate) return false;
    return DateTime.local() > this.date_end;
  }

  @action.bound
  public toggleLike() {
    if (this.liked) {
      this.like_count -= 1;
      OpenInnoApi.unlike(this.object_type, this.id);
    } else {
      this.like_count += 1;
      OpenInnoApi.like(this.object_type, this.id);
    }
    this.liked = !this.liked;
  }

  @action.bound
  public share() {
    if (!this.shared) {
      this.shared = true;
      this.share_count += 1;
      OpenInnoApi.share(this.object_type, this.id);
    }
  }

  @computed
  get isPoll() {
    return this.type === 'poll';
  }

  @computed
  get isOwner() {
    return stores.authStore.isFeatureAdmin('openinno.challenges');
  }

  @computed
  get hasJury() {
    return this.hasSection('jury') && this.jury_members.length !== 0;
  }

  @computed
  get isJuryMember() {
    if (stores.authStore.user) {
      return this.jury_members.some((m) => m.id === stores.authStore.user.id);
    }
    return false;
  }

  public downloadCSV = async () => {
    const csv = await OpenInnoApi.getPersonalDataCSV(this);
    const content = `data:text/csv;charset=utf-8,\uFEFF${csv}`;

    const data = encodeURI(content);

    forceDownload(data, `${this.title}-participants.csv`);
  };

  public downloadCSVResults = async () => {
    const csv = await OpenInnoApi.getCSVResult(this);
    const content = `data:text/csv;charset=utf-8,\uFEFF${csv}`;

    const data = encodeURI(content);

    forceDownload(data, `${this.title}-results.csv`);
  };

  @computed
  get thumbnail() {
    return this.banner;
  }
}

export class Comment {
  public id: number;
  public content: string;
  public username: string;
  public date_created: DateTime;
  public parent?: number;

  @observable
  public liked: boolean;

  @observable
  public like_count: number;

  constructor(data: any) {
    Object.assign(this, data);
  }

  @action.bound
  public toggleLike() {
    if (this.liked) {
      this.like_count -= 1;
      OpenInnoApi.unlike('comment', this.id);
    } else {
      this.like_count += 1;
      OpenInnoApi.like('comment', this.id);
    }
    this.liked = !this.liked;
  }
}

export interface PartnershipUploadedFile {
  id: number;
  file: string;
  date_created: DateTime;
  owner: number;
}

export class Partner extends Socialable {
  @observable
  public name: string;

  @observable
  public slug: string;

  @observable
  public owner: MiniUser;

  @observable
  public note: string;

  @observable
  public description: string;

  @observable
  public logo: string;

  @observable
  public data: any;

  @observable
  public published: boolean;

  public hit_count: number;

  @observable
  public hit: boolean;

  @observable
  public color: string;

  constructor(data: any) {
    super('partner', data);
    Object.assign(this, data);
    this.color = randomColor();
  }

  public update(data: any) {
    Object.assign(this, data);
  }

  @computed
  get hasDescription() {
    return !isEmptyHTML(this.description);
  }

  @computed
  get isOwner() {
    return stores.authStore.user.id === this.owner.id;
  }

  @computed
  get isOwnerOrAdmin() {
    return (
      this.isOwner || stores.authStore.isFeatureAdmin('openinno.partnerships')
    );
  }
}

export interface MiniPartner {
  name: string;
  slug: string;
  logo: string;
  data: any;
  id: number;
  date_created: DateTime;
}

export class PublicPage {
  public id: number;

  @observable
  public title: string;

  @observable
  public content: string;

  @observable
  public banner: string;

  @observable
  public data: any;

  @observable
  public slug: string;

  constructor(data: any) {
    Object.assign(this, data);
  }

  public update(data: any) {
    Object.assign(this, data);
  }
}
