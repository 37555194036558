import CommentApi from '~/api/comment';
import { CommentFile } from '~/models/comment';
import downloadFile from '~/utils/downloadFile';

class CommentStore {
  public async loadComments(type: string, id: number) {
    const comments = await CommentApi.getComments(type, id);
    return comments;
  }

  public async downloadHistory(type: string, id: number) {
    const data = await CommentApi.getHistory(type, id);
    downloadFile(
      URL.createObjectURL(
        new Blob([data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      ),
      `comments_${type}_${id}.xlsx`,
    );
  }

  public async postComment(
    type: string,
    id: number,
    content: string,
    file: CommentFile,
    parent?: number,
  ) {
    const comment = await CommentApi.postComment(
      type,
      id,
      content,
      file,
      parent,
    );
    return comment;
  }
}

export default CommentStore;
