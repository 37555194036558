import { computed, observable, runInAction } from 'mobx';
import { Partner } from '~/models/openinno';
import OpenInnoProjectsApi from './api';
import { OpenInnoProject } from './models';

class OpenInnoProjectsStore {
  public projects = observable.map<string, OpenInnoProject>();

  public async loadProject(slug: string) {
    const found = this.projects.get(slug);
    if (found) return found;
    const project = await OpenInnoProjectsApi.getProject(slug);

    if (project) {
      runInAction(() => {
        this.projects.set(project.slug, project);
      });
    }

    return project;
  }

  public async loadProjects() {
    const newItems = await OpenInnoProjectsApi.getProjects();
    runInAction(() => {
      for (const project of newItems.results) {
        this.projects.set(project.slug, project);
      }
    });

    return this.projects;
  }

  public async loadPartnerProjects(partner: Partner) {
    const newItems = await OpenInnoProjectsApi.getPartnerProjects(partner);
    runInAction(() => {
      for (const project of newItems) {
        this.projects.set(project.slug, project);
      }
    });

    return newItems;
  }

  public async deleteProject(project: OpenInnoProject) {
    await OpenInnoProjectsApi.deleteProject(project);
    this.projects.delete(project.slug);
  }

  @computed
  get projectList() {
    return [...this.projects.values()];
  }
}

export default OpenInnoProjectsStore;
