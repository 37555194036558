import React, { ComponentType, Suspense } from 'react';
import Progress from '~/components/Progress';
import retry from './retry';

export default function lazyRetry<Props>(
  factory: () => Promise<{ default: ComponentType<Props> }>,
): React.ComponentType<Props> {
  const Component = React.lazy(() => retry(factory));
  return (props: Props) => (
    <Suspense fallback={Progress}>
      <Component {...props} />
    </Suspense>
  );
}
