import { DateTime } from 'luxon';
import { action, computed, observable, runInAction } from 'mobx';
import { MiniUser } from '~/models/user';
import { FileImportResult } from '~/models/utils';
import stores from '~/stores';
import DriveApi from './api';

export interface DriveFile {
  id: number;
  file: string;
  owner: MiniUser;
  date_created: DateTime;
}

export class Drive {
  public id: number;

  @observable
  public files: DriveFile[] = [];

  @observable
  public count: number;

  @observable
  public loading: boolean = false;

  @observable
  public uploadLoading: boolean = false;

  constructor(data: any) {
    Object.assign(this, data);
  }

  @action.bound
  public async loadFiles() {
    this.loading = true;
    const files = await DriveApi.getDriveFiles(this);
    runInAction(() => {
      this.files = files;
      this.loading = false;
    });
  }

  @action.bound
  public async deleteFile(file: DriveFile) {
    await DriveApi.deleteFile(this, file.id);
    runInAction(() => {
      this.files = this.files.filter((f) => f.id !== file.id);
    });
  }

  @action.bound
  public async uploadFile(file: FileImportResult) {
    this.uploadLoading = true;
    try {
      const newFile = await DriveApi.uploadFile(this, file);
      runInAction(() => {
        this.uploadLoading = false;
        this.files.unshift(newFile);
      });
    } catch (e) {
      runInAction(() => {
        this.uploadLoading = false;
      });
    }
  }

  @computed
  get hasNew() {
    const status = stores.authStore.storage.get('drive_status', {});
    const count = status[this.id] || 0;
    return count < this.count;
  }

  public updateStatus() {
    const status = stores.authStore.storage.get('drive_status', {});
    status[this.id] = this.count;
    stores.authStore.storage.set('drive_status', status);
  }
}
