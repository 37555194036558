import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  withMobileDialog,
} from '@material-ui/core';
import { InjectedProps } from '@material-ui/core/withMobileDialog';
import { computed, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import SwipeableViews from 'react-swipeable-views';
import stores from '~/stores';
import styled from '~/styles';
import Dots from '../utils/Dots';

const Description = styled(Typography)`
  margin-bottom: 15px;
  padding: 20px;
  text-align: center;
  color: ${(p) => p.theme.palette.grey[500]};
`;

const Actions = styled(DialogActions)`
  padding: 10px;
  padding-top: 0px;
  justify-content: center;
  align-items: center;
  > * {
    flex-grow: 1;
  }
`;

const Wrapper = styled(Dialog)`
  > div > div {
    border-radius: 20px;
    ${(p) => p.theme.breakpoints.down('sm')} {
      max-width: calc(100vw - 40px);
      max-height: calc(100vh - 40px);
      margin: 20px;
    }
  }
`;

const Title = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
`;

const steps = [
  {
    image: 'https://campus-defaults.s3.eu-west-3.amazonaws.com/project.1.jpg',
    title: 'projects.on_boarding.slide.1.title',
    description: 'projects.on_boarding.slide.1.description',
  },
  {
    image: 'https://campus-defaults.s3.eu-west-3.amazonaws.com/project.2.jpg',
    title: 'projects.on_boarding.slide.2.title',
    description: 'projects.on_boarding.slide.2.description',
    onlyOwner: true,
  },
  {
    image: 'https://campus-defaults.s3.eu-west-3.amazonaws.com/project.3.jpg',
    title: 'projects.on_boarding.slide.3.title',
    description: 'projects.on_boarding.slide.3.description',
  },
  {
    image: 'https://campus-defaults.s3.eu-west-3.amazonaws.com/project.4.jpg',
    title: 'projects.on_boarding.slide.4.title',
    description: 'projects.on_boarding.slide.4.description',
  },
];

interface Props extends InjectedProps {
  open?: boolean;
  isOwner: boolean;
  onClose: () => void;
}

@observer
class OnBoarding extends React.Component<Props> {
  @observable
  public stepIndex: number = 0;

  public close = () => {
    this.props.onClose();
  }

  @computed
  get steps() {
    if (this.props.isOwner) return steps;
    return steps.filter((s) => !s.onlyOwner);
  }

  public render() {
    const { fullScreen } = this.props;

    return (
      <Wrapper
        fullScreen={fullScreen}
        disableEscapeKeyDown
        open
        onClose={
          this.stepIndex === this.steps.length - 1
            ? this.props.onClose
            : () =>
                runInAction(() => {
                  this.stepIndex += 1;
                })
        }
      >
        <DialogContent>
          <SwipeableViews
            index={this.stepIndex}
            onChangeIndex={(index) =>
              runInAction(() => {
                this.stepIndex = index;
              })
            }
          >
            {this.steps.map((step) => (
              <div style={{ fontSize: '15px' }}>
                <img src={step.image} style={{ maxWidth: '100%' }} />
                <Title align="center" color="primary">
                  <FormattedHTMLMessage id={step.title} />
                </Title>
                <Description>
                  <FormattedHTMLMessage id={step.description} />
                </Description>
              </div>
            ))}
          </SwipeableViews>
          <Dots
            value={this.stepIndex}
            count={this.steps.length}
            onChange={(value) =>
              runInAction(() => {
                this.stepIndex = value;
              })
            }
          />
        </DialogContent>
        <Actions>
          <Button
            onClick={() =>
              runInAction(() => {
                this.stepIndex -= 1;
              })
            }
            variant="text"
            color="primary"
            disabled={this.stepIndex === 0}
          >
            <FormattedMessage id="commons.previous" />
          </Button>
          <Button
            onClick={
              this.stepIndex === this.steps.length - 1
                ? this.props.onClose
                : () =>
                    runInAction(() => {
                      this.stepIndex += 1;
                    })
            }
            variant="text"
            color="primary"
          >
            <FormattedMessage id="commons.next" />
          </Button>
        </Actions>
      </Wrapper>
    );
  }
}

const OnBoardingDialog = withMobileDialog<Props>()(OnBoarding);

const projectOnBoarding = (isOwner: boolean) =>
  new Promise<boolean>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = () => {
      resolve();
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <OnBoardingDialog isOwner={isOwner} onClose={onClose} />,
    );
  });

export default projectOnBoarding;
