import { AcademyFormation } from '~/models/academy';
import { Challenge } from '~/models/challenges';
import { IdeaboxItem } from '~/models/ideabox';
import { IntraItem } from '~/models/intra';
import { Project } from '~/models/projects';
import api from './api';

async function loadGeneralDashboard() {
  const { data } = await api.get('/dashboard/general');
  return data;
}

async function loadGlobalDashboard() {
  const { data } = await api.get('/dashboard/global/stats');
  return data;
}

async function loadIdeaboxDashboard() {
  const { data } = await api.get('/dashboard/ideabox/activity');
  return data;
}

async function loadAcademyDashboard(feature: string) {
  const { data } = await api.get('/dashboard/academy/activity', {
    params: {
      feature,
    },
  });
  return data;
}

async function loadChallengesDashboard() {
  const { data } = await api.get('/dashboard/challenges/activity');
  return data;
}

async function loadProjectsDashboard() {
  const { data } = await api.get('/dashboard/projects/activity');
  return data;
}

async function loadLabDashboard(feature: string) {
  const { data } = await api.get('/dashboard/lab/activity', {
    params: { feature },
  });
  return data;
}

async function loadChallengeDashboard(challenge_id: number) {
  const { data } = await api.get('/dashboard/challenges/challenge_activity', {
    params: { id: challenge_id },
  });
  return data;
}

async function loadContestDashboard(contest_id: number) {
  const { data } = await api.get('/dashboard/contest/contest_activity', {
    params: { id: contest_id },
  });
  return data;
}

async function loadOIContestDashboard(contest_id: number) {
  const { data } = await api.get('/dashboard/oi_contest/contest_activity', {
    params: { id: contest_id },
  });
  return data;
}

async function loadOpenInnoDashboard() {
  const { data } = await api.get('/dashboard/openinno/activity');
  return data;
}

async function loadOpenInnoChallengeDashboard(challenge_id: number) {
  const { data } = await api.get('/dashboard/openinno/challenge_activity', {
    params: { id: challenge_id },
  });
  return data;
}

async function loadIntraDashboard() {
  const { data } = await api.get('/dashboard/intra/activity');
  return data;
}

async function loadContestsDashboard(feature: string) {
  const { data } = await api.get('/dashboard/contest/activity', {
    params: {
      feature,
    },
  });
  return data;
}

async function loadOIContestsDashboard() {
  const { data } = await api.get('/dashboard/oi_contest/activity');
  return data;
}

async function getMostActiveChallenges(time: string): Promise<Challenge[]> {
  try {
    const { data } = await api.get('/dashboard/challenges_most_popular', {
      params: { time },
    });
    return data.map((i: any) => new Challenge(i));
  } catch (e) {
    return [];
  }
}

async function getLessActiveChallenges(): Promise<Challenge[]> {
  try {
    const { data } = await api.get('/dashboard/challenges_less_popular');
    return data.map((i: any) => new Challenge(i));
  } catch (e) {
    return [];
  }
}

async function getMostActiveProjects(time: string): Promise<Project[]> {
  try {
    const { data } = await api.get('/dashboard/projects_most_popular', {
      params: { time },
    });
    return data.map((i: any) => new Project(i));
  } catch (e) {
    return [];
  }
}

async function getLessActiveProjects(): Promise<Project[]> {
  try {
    const { data } = await api.get('/dashboard/projects_less_popular');
    return data.map((i: any) => new Project(i));
  } catch (e) {
    return [];
  }
}

async function getLessActiveIntra(): Promise<IntraItem[]> {
  try {
    const { data } = await api.get('/dashboard/intra_less_popular');
    return data;
  } catch (e) {
    return [];
  }
}

async function search(
  q: string,
  tags: string[],
  buildings: string[],
  ordering: string,
) {
  const { data } = await api.get('/dashboard/search', {
    params: { q, buildings, tags, ordering },
  });
  return {
    items: data.items.map((i: any) => new IdeaboxItem(i)),
    formations: data.formations.map((f: any) => new AcademyFormation(f)),
    challenges: data.challenges.map((f: any) => new Challenge(f)),
    projects: data.projects.map((f: any) => new Project(f)),
  };
}

export default {
  loadGeneralDashboard,
  loadIdeaboxDashboard,
  loadAcademyDashboard,
  loadChallengesDashboard,
  loadProjectsDashboard,
  loadIntraDashboard,
  loadGlobalDashboard,
  getMostActiveChallenges,
  getLessActiveChallenges,
  getMostActiveProjects,
  getLessActiveProjects,
  getLessActiveIntra,
  search,
  loadChallengeDashboard,
  loadOpenInnoChallengeDashboard,
  loadOpenInnoDashboard,
  loadLabDashboard,
  loadContestDashboard,
  loadContestsDashboard,
  loadOIContestDashboard,
  loadOIContestsDashboard,
};
