import { Typography } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from '~/styles';

const Wrapper = styled(Typography)<{ selected?: boolean }>`
  display: block;
  margin: 5px 13px;
  display: flex;
  align-items: center;
  color: white;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-size: 16px;
  border-radius: 6px;
  padding: 7px 11px;
  padding-left: 12px;
  user-select: none;
  white-space: nowrap;
  transition: background-color 0.1s;
  overflow: hidden;
  min-height: 40px;
  box-sizing: border-box;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }

  ${(p) =>
    p.selected &&
    `
      background-color: rgba(255, 255, 255, 0.2);

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    `}

  svg, div {
    height: 22px;
    width: 22px;
    min-height: 22px;
    min-width: 22px;
    margin-right: 26px;
    vertical-align: middle;
  }

  span {
    vertical-align: middle;
    margin-bottom: 1px;
  }
`;

interface SidebarItemProps {
  label: React.ReactElement | string;
  icon: React.ReactElement;
  to: string;
  matchPath?: string | string[];
  exact?: boolean;
  onClick?: () => void;
}

function SidebarItem(props: SidebarItemProps) {
  let selected = false;
  const matchPath = props.matchPath || props.to;

  if (matchPath) {
    const pathName = `${location.pathname}/`; // ensure both pathname ends with a /
    if (Array.isArray(matchPath)) {
      selected = matchPath.some((p) =>
        props.exact
          ? p === pathName
          : pathName.startsWith(p.endsWith('/') ? p : `${p}/`),
      );
    } else {
      selected = props.exact
        ? matchPath === pathName
        : pathName.startsWith(
            matchPath.endsWith('/') ? matchPath : `${matchPath}/`,
          );
    }
  }

  return (
    <Wrapper
      component={(p: any) => <Link {...p} to={props.to} />}
      onClick={props.onClick}
      selected={selected}
    >
      {props.icon}
      <span>{props.label}</span>
    </Wrapper>
  );
}

export default SidebarItem;
