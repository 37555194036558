import { AcademyFormation, MiniAcademyFormation } from '~/models/academy';
import {
  LimitOffsetParams,
  OrderParams,
  PaginatedResult,
  TagsParams,
} from '~/models/api';

import { runInAction } from 'mobx';
import api from '~/api/api';
import { ImageImportResult } from '~/components/ImageImport';
import { FileImportResult } from '~/models/utils';
import stores from '~/stores';
import toImageName from '~/utils/toImageName';

export interface GetFormationsParams
  extends LimitOffsetParams,
    TagsParams,
    OrderParams {}

async function getFormations(
  url: string | null,
  params: GetFormationsParams,
): Promise<PaginatedResult<AcademyFormation>> {
  try {
    const { data } = await api.get(url || '/academy/search', { params });
    return {
      ...data,
      results: data.results.map((i: any) => new AcademyFormation(i)),
    };
  } catch (e) {
    return { next: null, previous: null, results: [] };
  }
}

async function getNotPublished(): Promise<AcademyFormation[]> {
  try {
    const { data } = await api.get('/academy/not_published');
    return data.map((i: any) => new AcademyFormation(i));
  } catch (e) {
    return [];
  }
}

async function getPopular(time: string): Promise<MiniAcademyFormation[]> {
  try {
    const { data } = await api.get('/academy/popular', { params: { time } });
    return data;
  } catch (e) {
    return [];
  }
}

async function getSimilar(tags: string[]): Promise<AcademyFormation[]> {
  try {
    const { data } = await api.get('/academy/similar', { params: { tags } });
    return data.map((d: any) => new AcademyFormation(d));
  } catch (e) {
    return [];
  }
}

async function getFormation(slug: string): Promise<AcademyFormation | null> {
  try {
    const { data } = await api.get(`/academy/${slug}`);
    return new AcademyFormation(data);
  } catch (e) {
    return null;
  }
}

async function createFormation(
  feature: string,
  title: string,
  abstract: string,
  tags: string[],
  thumbnail: ImageImportResult | null,
  content: string,
  published: boolean,
  files: FileImportResult[],
  type: string,
): Promise<AcademyFormation | null> {
  const formData = new FormData();
  formData.append('feature', feature);
  formData.append('title', title);
  formData.append('abstract', abstract);
  formData.append('tags', JSON.stringify(tags));
  formData.append('content', content);

  if (thumbnail) {
    formData.append('thumbnail', thumbnail.blob!, toImageName(thumbnail.name));
  }
  formData.append('published', published ? 'True' : 'False');
  formData.append('type', type);

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  try {
    const { data } = await api.post('/academy/', formData);
    return new AcademyFormation(data);
  } catch (e) {
    return null;
  }
}

async function updateFormation(
  formation: AcademyFormation,
  title: string,
  abstract: string,
  tags: string[],
  thumbnail: ImageImportResult | null,
  content: string,
  published: boolean,
  files: FileImportResult[],
  filesToDelete: number[],
  type?: string,
): Promise<AcademyFormation | null> {
  const formData = new FormData();
  if (formation.title !== title) formData.append('title', title);
  if (formation.abstract !== abstract) {
    formData.append('abstract', abstract);
  }
  if (JSON.stringify(formation.tags) !== JSON.stringify(tags)) {
    formData.append('tags', JSON.stringify(tags));
  }
  if (formation.content !== content) {
    formData.append('content', content);
  }
  if (formation.published !== published) {
    formData.append('published', published ? 'True' : 'False');
  }
  if (type && formation.type !== type) {
    formData.append('type', type);
  }

  filesToDelete.forEach((file) => {
    formData.append('to_delete_files', file.toString());
  });

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  if (thumbnail) {
    formData.append('thumbnail', thumbnail.blob!, toImageName(thumbnail.name));
  }

  try {
    const { data } = await api.patch(`/academy/${formation.slug}`, formData);
    return new AcademyFormation(data);
  } catch (e) {
    return null;
  }
}

async function deleteFormation(formation: AcademyFormation) {
  await api.delete(`/academy/${formation.slug}`);
}

async function updateExternalFormations(formations: any) {
  await api.post('/academy/update_external_formations', {
    formations,
  });
  runInAction(() => {
    stores.organizationStore.organization.config = {
      ...stores.organizationStore.organization.config,
      external_formations: formations,
    };
  });
}

async function getExternal() {
  const { data } = await api.get('/academy/external_formations');
  return data as MiniAcademyFormation[];
}

export default {
  createFormation,
  deleteFormation,
  getFormation,
  getFormations,
  getNotPublished,
  getPopular,
  updateFormation,
  getSimilar,
  updateExternalFormations,
  getExternal,
};
