import { DateTime } from 'luxon';
import { computed, observable } from 'mobx';
import buildingOnBoarding from '~/components/OnBoarding/dialogs';
import projectOnBoarding from '~/components/Projects/OnBoarding';
import { MiniUser } from '~/models/user';
import { Socialable, UploadedFile, UploadedImage } from '~/models/utils';
import stores from '~/stores';
import { MiniBoard } from './notes';
import { PollResults, Question } from './polls';

export type StepType = 'poll' | 'files' | 'text';

export interface MiniProject {
  owner: MiniUser;
  name: string;
  slug: string;
  comment_count?: number;
  contribution_count?: number;
  like_count?: number;
}

export class ProjectContribution extends Socialable {
  public owner: MiniUser;
  public content: string;

  @observable
  public selected: boolean;

  public data: any;
  public images: UploadedImage[];
  public files: UploadedFile[];

  constructor(data: any) {
    super('projectcontribution', data);
    Object.assign(this, data);
  }
}

export class ProjectPost extends Socialable {
  @observable
  public date_created: DateTime;

  @observable
  public title: string;

  @observable
  public content: string;

  @observable
  public pinned: boolean;

  @observable
  public images: UploadedImage[];

  @observable
  public files: UploadedFile[];

  constructor(data: any) {
    super('projectpost', data);
    Object.assign(this, data);
  }

  public update(data: any) {
    Object.assign(this, data);
  }
}

export class ProjectStep extends Socialable {
  public id: number;
  public date_created: DateTime;
  public type: StepType;

  @observable
  public target_groups: string[] = [];

  @observable
  public title: string;

  @observable
  public description: string;

  @observable
  public result_message: string;

  @observable
  public ended: boolean;

  public publish: boolean;

  public data: Question[];

  @observable
  public anonymous: boolean;

  public images: UploadedImage[];

  @observable
  public files: UploadedFile[];

  @observable
  public result_images: UploadedImage[];

  @observable
  public result_files: UploadedFile[];

  @observable
  public result_data: PollResults;

  @observable
  public contributor_count: number;

  @observable
  public contribution_count: number;

  @observable
  public contributed: boolean;

  @observable
  public multiple: boolean;

  @observable
  public always_on_results: boolean;

  constructor(data: any) {
    super('projectstep', data);
    Object.assign(this, data);
  }

  public update(data: any) {
    Object.assign(this, data);
  }

  @computed
  get isInTargetGroups() {
    if (this.target_groups.length === 0) return true;
    return this.target_groups.some(
      (g) => stores.authStore.user.campus_groups.indexOf(g) !== -1,
    );
  }
}

export type ProjectStatus = 'useless' | 'intra' | 'implemented';

export class Project extends Socialable {
  public name: string;
  public slug: string;
  public description: string;
  public hit_count: number;
  public number?: number;

  @observable
  public hit: boolean;
  public images: UploadedImage[];
  public files: UploadedFile[];
  public tags: string[];

  @observable
  public owner: MiniUser;
  public ideabox_item: {
    name: string;
    slug: string;
    status_moderation_message: string;
  } | null;
  public intra_item: { name: string; slug: string; published: boolean } | null;

  @observable
  public date_published: DateTime;

  @observable
  public need_change: boolean;

  @observable
  public published: boolean;

  @observable
  public requested: boolean;

  @observable
  public invited: boolean;

  @observable
  public member_count: number;

  @observable
  public members: MiniUser[];

  public conversation: number;
  public board: MiniBoard;

  @observable
  public contribution_count?: number;

  @observable
  public status?: ProjectStatus;

  @observable
  public status_message?: string;

  public challenge_slug: string;

  public file_count: number;

  @observable
  public members_can_create?: boolean;

  constructor(data: any) {
    super('project', data);
    Object.assign(this, data);
  }

  public update(data: Partial<Project>) {
    Object.assign(this, data);
  }

  get thumbnail() {
    return this.images.length === 0 ? '' : this.images[0].image;
  }

  @computed
  get isMember() {
    return (
      this.isOwner ||
      this.members.find((u) => u.id === stores.authStore.user.id)
    );
  }

  @computed
  get isMemberAndMembersCanCreate() {
    return this.isMember && this.members_can_create;
  }

  @computed
  get isOwner() {
    return this.owner.id === stores.authStore.user.id;
  }

  public checkOnBoarding() {
    if (!this.isOwner && !this.isMember) {
      buildingOnBoarding('lab');
      return;
    }
    if (!stores.authStore.storage.contains('project-onboarding', this.id)) {
      projectOnBoarding(this.isOwner);
      stores.authStore.storage.append('project-onboarding', this.id);
    } else {
      buildingOnBoarding('lab');
    }
  }

  @computed
  get newChatCount() {
    if (!this.isMember) return 0;
    const conv = stores.chatStore.conversations.find(
      (c) => c.id === this.conversation,
    );
    if (!conv) return 0;
    if (!conv.last_message) return 0;
    if (conv.last_message.user === stores.authStore.user.id) return 0;
    if (conv.last_message.read) return 0;
    return 1;
  }

  @computed
  get newNoteCount() {
    if (!this.isMember) return 0;
    const oldValue = stores.globalStore.boardStatus[this.board.id];
    if (!oldValue) return this.board.count;
    if (oldValue < this.board.count) return this.board.count - oldValue;
    return 0;
  }

  @computed
  get newFileCount() {
    if (!this.isMember) return 0;
    const oldValue = stores.projectsStore.fileStatus[this.id];
    if (!oldValue) return this.file_count;
    if (oldValue < this.file_count) return this.file_count - oldValue;
    return 0;
  }
}

export interface ProjectUploadedFile {
  id: number;
  file: string;
  date_created: DateTime;
  owner: MiniUser;
}
