import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import App from '~/components/App';
import '~/styles';
import { askNotificationPermission } from './utils/pushNotifications';

if (process.env.SENTRY === 'true') {
  Sentry.init({
    dsn: 'https://52f8a9360dbe48e98c7e064166d433d6@campus-api.lfai.co/1374552',
    ignoreErrors: [
      "Cannot read property 'data' of undefined",
      'Network Error',
      'r.node.contains is not a function',
      'r.node.isEqualNode is not a function',
      'Request failed with status code 404',
      'Failed to register a ServiceWorker: ServiceWorker script evaluation failed',
      'Failed to register a ServiceWorker: An SSL certificate error occurred when fetching the script.',
      '"PromiseRejectionEvent"',
      'Connection to Indexed Database server lost. Refresh the page to try again',
      "Object doesn't support property or method 'contains'",
      'The operation is insecure.',
      'Failed to register a ServiceWorker',
    ],
  });
}

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);
askNotificationPermission();

window.addEventListener('load', () => {
  if (!('serviceWorker' in navigator)) {
    // service workers not supported 😣
    return;
  }

  navigator.serviceWorker.register('/sw.js');
});

clearTimeout((window as any).errorTimeoutId);
const tmp = document.getElementById('browser-error');
if (tmp) {
  tmp.style.display = 'none';
}
