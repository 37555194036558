import api from '~/api/api';
import { Activity } from '~/models/activity';
import { PaginatedResult } from '~/models/api';
import { ContentResult } from '~/models/search';

async function getActivities(
  url: string | null,
  myFeed: boolean = false,
): Promise<PaginatedResult<Activity>> {
  try {
    const { data } = await api.get(
      url || (myFeed ? '/activities/my_feed' : '/activities/news_feed'),
    );
    return data;
  } catch (e) {
    return { next: null, previous: null, results: [] };
  }
}

async function getUserActivities(
  url: string | null,
  userId?: number,
): Promise<PaginatedResult<Activity>> {
  try {
    const { data } = await api.get(url || '/activities/user_feed', {
      params: {
        id: userId,
      },
    });
    return data;
  } catch (e) {
    return { next: null, previous: null, results: [] };
  }
}

async function getUserCreations(userId: number): Promise<ContentResult[]> {
  try {
    const { data } = await api.get('/activities/creations', {
      params: {
        id: userId,
      },
    });
    return data;
  } catch (e) {
    return [];
  }
}

async function togglePin(activity: Activity) {
  const { data } = await api.post(`/activities/${activity.id}/toggle_pin`);
  return data.pinned as boolean;
}

export default {
  getActivities,
  getUserActivities,
  getUserCreations,
  togglePin,
};
