import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import React from 'react';
import i18n from '~/locales/i18n';
import withStore, { WithStoreProps } from '~/utils/withStore';

@observer
class Notifier extends React.Component<WithStoreProps & WithSnackbarProps> {
  public displayed: number[] = [];
  public connectionNotificationKey: any;

  public storeDisplayed = (id: number) => {
    this.displayed.push(id);
  }

  public componentDidMount() {
    window.addEventListener('online', this.handleConnection);
    window.addEventListener('offline', this.handleConnection);

    autorun(() => {
      const notifications = this.props.store.uiStore.notifications;

      notifications.forEach((notification) => {
        if (this.displayed.includes(notification.key)) return;
        this.props.enqueueSnackbar(notification.message, {
          ...notification.options,
          action: notification.options.action
            ? notification.options.action((key: any) => {
              this.props.closeSnackbar(key);
            })
            : undefined,
        });
        this.displayed.push(notification.key);
        this.props.store.uiStore.removeNotification(notification.key);
      });
    });
  }

  public handleConnection = () => {
    if (navigator.onLine) {
      this.props.store.uiStore.addNotification(
        i18n.formatMessage('notifications.online'),
        'success',
      );
      if (this.connectionNotificationKey) {
        this.props.closeSnackbar(this.connectionNotificationKey);
      }
    } else {
      const key = this.props.enqueueSnackbar(
        i18n.formatMessage('notifications.offline'),
        {
          persist: true,
          variant: 'warning',
        },
      );
      this.connectionNotificationKey = key;
    }
  }

  public render() {
    return null;
  }
}

export default withSnackbar(withStore(Notifier));
