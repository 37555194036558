import { Tooltip, Typography } from '@material-ui/core';
import {
  Edit,
  Forum,
  KeyboardArrowDown,
  KeyboardArrowUp,
  OpenInNew,
} from '@material-ui/icons';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { chatPath } from '~/constants/routes';
import history from '~/history';
import i18n from '~/locales/i18n';
import { StoreContext } from '~/stores';
import styled from '~/styles';
import ConversationItem from './ConversationItem';
import { createChat } from './dialogs';

const Wrapper = styled(Typography)`
  position: fixed;
  bottom: 0;
  right: 45px;
  width: 300px;
  z-index: 1002;
  transition: bottom 0.3s;
  box-sizing: border-box;

  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  box-shadow: 0px 2px 13.95px 1.05px rgba(0, 0, 0, 0.35);

  overflow: hidden;

  ${(p) => p.theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const Header = styled.div`
  background-color: ${(p) => p.theme.palette.primary.main};
  padding: 8px 20px;
  padding-right: 0px;
  font-weight: 500;
  font-size: 17px;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    vertical-align: middle;
    margin-right: 0.5em;
  }

  div {
    display: flex;
    align-items: center;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 420px;
  overflow: auto;
  background-color: white;

  li {
    border-left: 3px solid transparent !important;
  }

  > *:hover {
    li,
    span {
      background-color: ${(p) => p.theme.palette.grey[200]}!important;
    }

    .circle {
      border-color: ${(p) => p.theme.palette.grey[200]}!important;
    }

    li {
      border-left: 3px solid ${(p) => p.theme.palette.secondary.main}!important;
    }
  }
`;

const Notif = styled(Typography)`
  height: 22px;
  width: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 50%;
  margin-left: 10px;
  color: white;
  background-color: ${(p) => p.theme.palette.secondary.main};
`;

function ChatPopup() {
  const [open, setOpen] = useState(false);
  const { chatStore } = useContext(StoreContext);

  const create = useCallback((e: React.MouseEvent<any>) => {
    e.preventDefault();
    e.stopPropagation();
    createChat();
  }, []);

  if (chatStore.conversations.length === 0) return null;

  return (
    <Wrapper style={{ bottom: open ? undefined : '-420px' }}>
      <Header onClick={() => setOpen(!open)}>
        <div>
          <Forum height="20" width="20" style={{ marginRight: '12px' }} />
          <FormattedMessage id="buildings.chat" />
          {chatStore.hasNew && !open && <Notif>+1</Notif>}
        </div>
        <div>
          <Tooltip title={i18n.formatMessage('chat.open')}>
            <OpenInNew
              style={{ fontSize: '18px' }}
              onClick={() => history.push(chatPath)}
            />
          </Tooltip>
          <Tooltip title={i18n.formatMessage('chat.create_conv')}>
            <Edit
              style={{ fontSize: '18px', marginLeft: '7px' }}
              onClick={create}
            />
          </Tooltip>
          {open ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
        </div>
      </Header>
      <Content onClick={() => setOpen(false)}>
        {chatStore.sortedConversations.map((c) => (
          <ConversationItem conversation={c} active={false} disableBorder />
        ))}
      </Content>
    </Wrapper>
  );
}

export default observer(ChatPopup);
