import styled from '~/styles';
import theme from '~/styles/theme';
import { isUndefined } from '~/utils/isUndefined';

interface Props {
  sidebarCollapsed?: boolean;
  padding?: number;
  disableSidebar?: boolean;
}

const Content = styled.div(
  (props: Props) => `
  padding: ${!isUndefined(props.padding) ? props.padding : 20}px;
  min-height: calc(100vh - 56px);
  position: relative;
  will-change: margin-left;
  flex-grow: 1;

  ${theme.current.breakpoints.down('sm')} {
    margin-left: 0;
    padding: ${(!isUndefined(props.padding) ? props.padding : 20) - 5}px;
    margin-top: 56px;
  }

  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
`,
);

export default Content;
