import { computed, observable, runInAction } from 'mobx';

import DashboardApi from '~/api/dashboard';
import OrganizationApi from '~/api/organization';
import { Building } from '~/models/global';
import { Organization, OrganizationConfig } from '~/models/organization';
import { MiniUser } from '~/models/user';
import stores, { RootStore } from '~/stores';
import theme from '~/styles/theme';
import { forceDownload } from '~/utils/downloadFile';

class OrganizationStore {
  @observable
  public loading: boolean = true;

  @observable
  public organization: Organization;

  constructor(private rootStore: RootStore) {
    this.loadOrganization();
  }

  public async loadOrganization() {
    const organization = await OrganizationApi.getCurrentOrganization();
    if (organization) {
      theme.initTheme(organization.theme);
      runInAction(() => {
        this.organization = organization;
        this.loading = false;
      });
    }
  }

  public async join(email: string, token: string, groups: string) {
    await OrganizationApi.join(email, token, groups);
  }

  public async invite(email: string, restrictedContents: any) {
    return await OrganizationApi.invite(email, restrictedContents);
  }

  public async updateSettings(newData: Partial<Organization>) {
    const oldColor = this.organization.theme.primary;
    const organization = await OrganizationApi.updateSettings(
      newData,
      this.organization,
    );
    if (organization) {
      if (organization.theme.primary !== oldColor) {
        theme.initTheme(organization.theme);
      }

      runInAction(() => {
        this.loading = false;
      });
    }
  }

  public updateConfig(config: Partial<OrganizationConfig>) {
    this.updateSettings({
      config: {
        ...this.organization.config,
        ...config,
      },
    });
  }

  public hasFeature(fullFeature?: string, params?: boolean, unauth?: boolean) {
    if (!fullFeature) return true;
    if (!this.organization) return false;
    const feature = fullFeature.split('.')[0];

    if (this.organization.features.indexOf(feature) === -1) return false;

    if (!params && this.rootStore.authStore.user) {
      if (
        this.organization.config.hidden_buildings_all.indexOf(feature) !== -1 ||
        this.organization.config.hidden_buildings_all.indexOf(fullFeature) !==
          -1
      ) {
        return false;
      }
      if (
        (stores.authStore.user.user_type === 'member' || unauth) &&
        (this.organization.config.hidden_buildings.indexOf(feature) !== -1 ||
          this.organization.config.hidden_buildings.indexOf(fullFeature) !== -1)
      ) {
        return false;
      }
    }

    return true;
  }

  public isHidden(feature: string, admin?: boolean) {
    if (this.organization.config.hidden_buildings_all.indexOf(feature) !== -1) {
      return true;
    }

    if (
      stores.authStore.user.user_type === 'member' &&
      !admin &&
      this.organization.config.hidden_buildings.indexOf(feature) !== -1
    ) {
      return true;
    }

    return false;
  }

  @computed
  get hasAllBuildings() {
    return (
      this.hasFeature('ideabox') &&
      this.hasFeature('projects') &&
      this.hasFeature('intra') &&
      this.hasFeature('challenges') &&
      this.hasFeature('academy') &&
      this.hasFeature('openinno')
    );
  }

  public async loadGeneralDashboard() {
    const data = await DashboardApi.loadGeneralDashboard();
    return data;
  }

  public async loadIdeaboxDashboard() {
    const data = await DashboardApi.loadIdeaboxDashboard();
    return data;
  }

  public async loadChallengesDashboard() {
    const data = await DashboardApi.loadChallengesDashboard();
    return data;
  }

  public async loadOpenInnoDashboard() {
    const data = await DashboardApi.loadOpenInnoDashboard();
    return data;
  }

  public async loadProjectsDashboard() {
    const data = await DashboardApi.loadProjectsDashboard();
    return data;
  }

  public async loadIntraDashboard() {
    const data = await DashboardApi.loadIntraDashboard();
    return data;
  }

  public async search(
    q: string,
    tags: string[],
    buildings: string[],
    ordering: string,
  ) {
    const data = await DashboardApi.search(q, tags, buildings, ordering);
    return data;
  }

  public async setRole(user: MiniUser, role: string) {
    const updatedUser = await OrganizationApi.setRole(
      user,
      role,
      user.roles || [],
    );
    runInAction(() => {
      this.rootStore.userStore.users.set(updatedUser.id, updatedUser);
    });
    return updatedUser;
  }

  public async deleteUser(user: MiniUser) {
    await OrganizationApi.deleteUser(user);
    runInAction(() => {
      this.rootStore.userStore.users.set(user.id, {
        ...user,
        user_type: 'member',
        admin_deleted: true,
        deleted: true,
      });
    });
    return { ...user, admin_deleted: true, deleted: true };
  }

  public async deleteInvitation(id: number) {
    await OrganizationApi.deleteInvitation(id);
  }

  @computed
  get tags() {
    if (!this.organization) return [];
    return [
      ...new Set([
        ...this.organization.config.intra_tags,
        ...this.organization.config.ideabox_tags,
        ...this.organization.config.projects_tags,
        ...this.organization.config.challenges_tags,
      ]),
    ];
  }

  @computed
  get isOnlyOpenInno() {
    if (!this.organization) return false;
    return !(
      this.hasFeature('ideabox') ||
      this.hasFeature('projects') ||
      this.hasFeature('intra') ||
      this.hasFeature('challenges') ||
      this.hasFeature('academy')
    );
  }

  public downloadUsersCSV = async () => {
    const csv = await OrganizationApi.getUsersCSV();
    const content = `data:text/csv;charset=utf-8,${csv}`;

    const data = encodeURI(content);

    forceDownload(data, 'users.csv');
  };

  public getLabConfig(feature: string) {
    return this.organization.config.lab_configs[feature];
  }

  @computed
  get labFeatures() {
    return Object.keys(this.organization.config.lab_configs);
  }

  public getContestConfig(feature: string) {
    return this.organization.config.contest_configs[feature];
  }

  @computed
  get contestFeatures() {
    return Object.keys(this.organization.config.contest_configs);
  }

  public getAcademyConfig(feature: string) {
    return this.organization.config.academy_configs[feature];
  }

  @computed
  get academyFeatures() {
    return Object.keys(this.organization.config.academy_configs);
  }

  public getModuleConfig(building: Building, feature: string) {
    if (building === 'lab') return this.getLabConfig(feature);
    if (building === 'contest') return this.getContestConfig(feature);
    if (building === 'academy') return this.getAcademyConfig(feature);

    return null;
  }

  @computed
  get passwordDisabled() {
    return this.ssoConfig && !this.ssoConfig.enable_password;
  }

  @computed
  get ssoConfig() {
    if (this.organization.auth_backend === 'adfs') {
      return this.organization.active_directory;
    }
    if (this.organization.auth_backend === 'saml') {
      return this.organization.saml;
    }
    return null;
  }

  @computed
  get ssoEnabled() {
    return !!this.ssoConfig;
  }
}

export default OrganizationStore;
