import { action, computed, observable, runInAction } from 'mobx';
import SearchApi from '~/api/search';
import { SearchResult } from '~/models/search';

const TABS = ['contents', 'users', 'comments', 'contributions'];

interface Counts {
  contents: 0;
  users: 0;
  comments: 0;
  contributions: 0;
}

class SearchStore {
  @observable
  public q?: string = '';

  @observable
  public tab: number = 0;

  @observable
  public next: string | null = null;

  @observable
  public results: SearchResult[] = [];

  @observable
  public loading: boolean = false;

  @observable
  public counts: Counts | null = null;

  @observable
  public filters: any = {};

  @action.bound
  public async updateCounts(force?: boolean) {
    if (!this.counts || force) {
      this.counts = null;
      const data = await SearchApi.counts(this.q);
      runInAction(() => {
        this.counts = data;
      });
    }
  }

  @action.bound
  public async search(tab?: number, params?: any) {
    this.loading = true;
    this.results = [];
    this.next = null;
    this.tab = tab || 0;
    const data = await SearchApi.search(
      null,
      this.q,
      TABS[tab || 0],
      this.finalFilters,
      params,
    );
    runInAction(() => {
      this.loading = false;
      this.results = data.results;
      this.next = data.next;
    });
  }

  @action.bound
  public async searchMore() {
    this.loading = true;
    const data = await SearchApi.search(this.next);
    runInAction(() => {
      this.loading = false;
      this.results.push(...data.results);
      this.next = data.next;
    });
  }

  @computed
  get hasNext() {
    return this.next && this.next.length;
  }

  @computed
  get finalFilters() {
    let ff = this.filters;
    if (ff.roles && ff.roles.indexOf('ambassador') !== -1) {
      ff = {
        ...ff,
        roles: ff.roles.filter((r: any) => r !== 'ambassador'),
        user_type: 'ambassador',
      };
    }

    if (ff.buildings && ff.buildings.indexOf('openinno') !== -1) {
      ff = {
        ...ff,
        buildings: [
          ...ff.buildings.filter((c: any) => c !== 'openinno'),
          'openinno.projects',
          'openinno.partnerships',
          'openinno.challenges',
        ],
      };
    }

    if (ff.buildings && ff.buildings.indexOf('openinno.partnerships') !== -1) {
      ff = {
        ...ff,
        buildings: [...ff.buildings, 'openinno.projects'],
      };
    }

    return ff;
  }
}

export default SearchStore;
