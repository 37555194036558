import api from '~/api/api';
import { ImageImportResult } from '~/components/ImageImport';
import { PaginatedResult } from '~/models/api';
import { MiniUser } from '~/models/user';
import { LabProcess, LabProject, LabProjectTopic } from './models';

class LabApi {
  public async getProjects(
    url: string | null,
    feature: string,
    params?: any,
  ): Promise<PaginatedResult<LabProject>> {
    const { data } = await api.get(url || '/lab/projects/search', {
      params: { feature, ps: 12, ...params },
    });
    return data;
  }

  public async getNotPublishedProjects(feature: string): Promise<any[]> {
    const { data } = await api.get('/lab/projects/not_published', {
      params: { feature },
    });
    return data;
  }

  public async getProject(slug: string, feature?: string) {
    const { data } = await api.get(`/lab/projects/${slug}`, {
      params: { feature },
    });
    return data;
  }

  public async updateProject(
    project: LabProject,
    newData: Partial<LabProject>,
  ) {
    const { data } = await api.patch(`/lab/projects/${project.slug}`, newData, {
      params: { feature: project.feature },
    });
    return data;
  }

  public async createProject(projectData: any) {
    const { data } = await api.post('/lab/projects/', projectData);
    return data;
  }

  public async deleteProject(project: LabProject) {
    await api.delete(`/lab/projects/${project.slug}`, {
      params: { feature: project.feature },
    });
  }

  public async updateThumbnail(
    project: LabProject,
    thumbnail: ImageImportResult,
  ) {
    const formData = new FormData();
    formData.append('thumbnail', thumbnail.blob!, thumbnail.name);

    const { data } = await api.patch(
      `/lab/projects/${project.slug}`,
      formData,
      {
        params: { feature: project.feature },
      },
    );
    return data;
  }

  public async getProcesses() {
    const { data } = await api.get('/lab/processes');
    return data.results;
  }

  public async getFormations(process: LabProcess, topicName: string) {
    const { data } = await api.get(`/lab/processes/${process.id}/formations`, {
      params: {
        topic: topicName,
      },
    });
    return data;
  }

  public async updateTopic(topic: LabProjectTopic) {
    const { data } = await api.post(
      `/lab/projects/${topic.project.slug}/update_topic`,
      {
        data: topic.data,
      },
      {
        params: {
          feature: topic.project.feature,
          topic_id: topic.id,
        },
      },
    );
    return data;
  }

  public async deleteTopic(topic: LabProjectTopic) {
    await api.delete(`/lab/projects/${topic.project.slug}/delete_topic`, {
      params: {
        topic_id: topic.id,
        feature: topic.project.feature,
      },
    });
  }

  public async deleteProcess(process: LabProcess) {
    await api.delete(`/lab/processes/${process.id}`);
  }

  public async updateProcess(
    process: LabProcess,
    newData: Partial<LabProcess>,
    applyDiffs?: boolean,
  ) {
    const { data } = await api.patch(`/lab/processes/${process.id}`, newData, {
      params: {
        apply_diffs: applyDiffs,
      },
    });
    return data;
  }

  public async createProcess(newData: any) {
    const { data } = await api.post('/lab/processes', newData);
    return data;
  }

  public async setStatus(
    project: LabProject,
    status: string | null,
    message: string,
    members: MiniUser[],
  ) {
    const { data } = await api.post(
      `/lab/projects/${project.slug}/set_status`,
      {
        status,
        status_message: message,
        status_users: members.map((u) => u.id),
      },
    );
    return data;
  }
}

export default new LabApi();
