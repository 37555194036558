import { runInAction } from 'mobx';
import api from '~/api/api';
import { ImageImportResult } from '~/components/ImageImport';
import { AcademyFormation } from '~/models/academy';
import {
  LimitOffsetParams,
  OrderParams,
  PaginatedResult,
  TagsParams,
} from '~/models/api';
import {
  Folder,
  FolderModelFile,
  FolderUploadedFile,
  IntraItem,
  IntraPost,
  IntraStatus,
} from '~/models/intra';
import { MiniUser } from '~/models/user';
import { FileImportResult } from '~/models/utils';
import stores from '~/stores';
import toImageName from '~/utils/toImageName';

export interface GetIntraItemsParams
  extends LimitOffsetParams,
    TagsParams,
    OrderParams {}

async function getItems(
  url: string | null,
  params: GetIntraItemsParams,
): Promise<PaginatedResult<IntraItem>> {
  try {
    const { data } = await api.get(url || '/intra/search', { params });
    return {
      ...data,
      results: data.results.map((i: any) => new IntraItem(i)),
    };
  } catch (e) {
    return { next: null, previous: null, results: [] };
  }
}

async function getNotPublished(): Promise<IntraItem[]> {
  try {
    const { data } = await api.get('/intra/not_published');
    return data.map((i: any) => new IntraItem(i));
  } catch (e) {
    return [];
  }
}

async function getPopular(time: string): Promise<IntraItem[]> {
  try {
    const { data } = await api.get('/intra/popular', { params: { time } });
    return data.map((i: any) => new IntraItem(i));
  } catch (e) {
    return [];
  }
}

async function getIntraItem(slug: string): Promise<IntraItem | null> {
  try {
    const { data } = await api.get(`/intra/${slug}`);
    return new IntraItem(data);
  } catch (e) {
    return null;
  }
}

async function createIntraItem(
  name: string,
  description: string,
  tags: string[],
  project: { name: string; slug: string } | null,
  images: ImageImportResult[],
  files: FileImportResult[],
  saveOnly: boolean,
): Promise<IntraItem | null> {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('description', description);
  formData.append('tags', JSON.stringify(tags));
  formData.append('save_only', saveOnly ? 'True' : 'False');

  if (project) {
    formData.append('new_project', project.slug);
  } else {
    formData.append('new_project', 'null');
  }

  images.forEach((image) => {
    formData.append('new_images', image.blob!, toImageName(image.name));
  });

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  try {
    const { data } = await api.post('/intra/', formData);
    return new IntraItem(data);
  } catch (e) {
    return null;
  }
}

async function updateIntraItem(
  item: IntraItem,
  name: string,
  description: string,
  tags: string[],
  project: { name: string; slug: string } | null,
  images: ImageImportResult[],
  files: FileImportResult[],
  imagesToDelete: number[],
  filesToDelete: number[],
  saveOnly: boolean,
): Promise<IntraItem | null> {
  const formData = new FormData();
  if (item.name !== name) formData.append('name', name);
  formData.append('description', description);
  formData.append('save_only', saveOnly ? 'True' : 'False');

  if (project) {
    formData.append('new_project', project.slug);
  } else {
    formData.append('new_project', 'null');
  }

  if (JSON.stringify(item.tags) !== JSON.stringify(tags)) {
    formData.append('tags', JSON.stringify(tags));
  }

  imagesToDelete.forEach((image) => {
    formData.append('to_delete_images', image.toString());
  });

  filesToDelete.forEach((file) => {
    formData.append('to_delete_files', file.toString());
  });

  images.forEach((image) => {
    formData.append('new_images', image.blob!, toImageName(image.name));
  });

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  try {
    const { data } = await api.patch(`/intra/${item.slug}`, formData);
    return new IntraItem(data);
  } catch (e) {
    return null;
  }
}

async function askChanges(item: IntraItem, message: string): Promise<number> {
  const formData = new FormData();
  formData.append('message', message);
  const { data } = await api.post(`/intra/${item.slug}/ask_changes`, formData);
  return data.conv_id;
}

async function deleteIntraItem(item: IntraItem) {
  await api.delete(`/intra/${item.slug}`);
}

async function getInvitations(item: IntraItem): Promise<MiniUser[]> {
  try {
    const { data } = await api.get(`/intra/${item.slug}/invitations`);
    return data.map((c: any) => c.user);
  } catch (e) {
    return [];
  }
}

async function removeMember(item: IntraItem, user: MiniUser): Promise<void> {
  await api.post(`/intra/${item.slug}/remove_member`, {
    user: user.id,
  });
  runInAction(() => {
    item.members = item.members.filter((c) => c.id !== user.id);
    item.member_count -= 1;
  });
}

async function getRequests(item: IntraItem): Promise<MiniUser[]> {
  try {
    const { data } = await api.get(`/intra/${item.slug}/invitation_requests`);
    return data.map((c: any) => c.user);
  } catch (e) {
    return [];
  }
}

async function isInvited(item: IntraItem): Promise<boolean> {
  try {
    const { data } = await api.get(`/intra/${item.slug}/invited`);
    return data;
  } catch (e) {
    return false;
  }
}

async function hasRequested(item: IntraItem): Promise<boolean> {
  try {
    const { data } = await api.get(`/intra/${item.slug}/requested`);
    return data;
  } catch (e) {
    return false;
  }
}

async function invite(
  item: IntraItem,
  user: MiniUser,
  message?: string,
): Promise<void> {
  const formData = new FormData();
  formData.append('user', user.id.toString());
  if (message && message.length) {
    formData.append('message', message);
  }
  await api.post(`/intra/${item.slug}/invite`, formData);
}

async function deleteRequest(item: IntraItem, user: MiniUser): Promise<void> {
  const formData = new FormData();
  formData.append('user', user.id.toString());
  await api.post(`/intra/${item.slug}/delete_request`, formData);
}

async function deleteInvitation(
  item: IntraItem,
  user: MiniUser,
): Promise<void> {
  const formData = new FormData();
  formData.append('user', user.id.toString());
  await api.post(`/intra/${item.slug}/delete_invitation`, formData);
}

async function resendInvitation(
  item: IntraItem,
  user: MiniUser,
): Promise<void> {
  const formData = new FormData();
  formData.append('user', user.id.toString());
  await api.post(`/intra/${item.slug}/resend_invitation`, formData);
}

async function acceptRequest(item: IntraItem, user: MiniUser): Promise<void> {
  const formData = new FormData();
  formData.append('user', user.id.toString());
  await api.post(`/intra/${item.slug}/accept_request`, formData);
}

async function requestInvite(item: IntraItem, message?: string): Promise<void> {
  const formData = new FormData();
  if (message && message.length) {
    formData.append('message', message);
  }
  await api.post(`/intra/${item.slug}/request_invitation`, formData);
}

async function join(item: IntraItem): Promise<void> {
  await api.post(`/intra/${item.slug}/join`);
  runInAction(() => {
    item.member_count += 1;
    item.members.push(stores.authStore.user);
  });
  item.checkOnBoarding();
}

async function getFiles(
  item: IntraItem,
  folder_id: string,
): Promise<FolderUploadedFile[]> {
  try {
    const { data } = await api.get(`/intra/${item.slug}/files`, {
      params: {
        folder_id,
      },
    });
    return data;
  } catch (e) {
    return [];
  }
}

async function getFolders(item_slug: string): Promise<Folder[]> {
  try {
    const { data } = await api.get(`/intra/${item_slug}/folders`);
    return data.map((c: any) => new Folder(c));
  } catch (e) {
    return [];
  }
}

async function getFormations(
  item: IntraItem,
  folder_id: string,
): Promise<AcademyFormation[]> {
  try {
    const { data } = await api.get(`/intra/${item.slug}/formations`, {
      params: {
        folder_id,
      },
    });
    return data.map((c: any) => new AcademyFormation(c));
  } catch (e) {
    return [];
  }
}

async function getModelFiles(
  item: IntraItem,
  folder_id: string,
): Promise<FolderModelFile[]> {
  try {
    const { data } = await api.get(`/intra/${item.slug}/model_files`, {
      params: {
        folder_id,
      },
    });
    return data;
  } catch (e) {
    return [];
  }
}

async function uploadFile(
  item: IntraItem,
  folder_id: string,
  file: FileImportResult,
): Promise<FolderUploadedFile | null> {
  try {
    const formData = new FormData();
    formData.append('file', file.blob!, file.name);
    formData.append('folder_id', folder_id);
    const { data } = await api.post(
      `/intra/${item.slug}/upload_file`,
      formData,
    );
    return data;
  } catch (e) {
    return null;
  }
}

async function deleteFile(item: IntraItem, file: number): Promise<void> {
  const formData = new FormData();
  formData.append('file', file.toString());
  await api.post(`/intra/${item.slug}/delete_file`, formData);
}

async function publish(item: IntraItem): Promise<void> {
  const { data } = await api.patch(`/intra/${item.slug}/`);
  item.update(data);
}

async function setStatus(
  item: IntraItem,
  status: IntraStatus | null,
  message: string,
  moderationMessage?: string,
) {
  const { data } = await api.post(`/intra/${item.slug}/set_status`, {
    status,
    status_moderation_message: moderationMessage,
    status_message: message,
  });
  item.update(data);
}

async function reject(item: IntraItem, message: string) {
  const { data } = await api.post(`/intra/${item.slug}/reject`, {
    message,
  });
  return data.conv_id as number;
}

async function createPost(
  item: IntraItem,
  title: string,
  content: string,
  images: ImageImportResult[],
  files: FileImportResult[],
): Promise<IntraPost | null> {
  const formData = new FormData();
  formData.append('title', title);
  formData.append('content', content);

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  images.forEach((image) => {
    formData.append('new_images', image.blob!, toImageName(image.name));
  });

  try {
    const { data } = await api.post(
      `/intra/${item.slug}/create_post`,
      formData,
    );
    return new IntraPost(data);
  } catch (e) {
    return null;
  }
}

async function updatePost(
  item: IntraItem,
  post: IntraPost,
  title: string,
  content: string,
  images: ImageImportResult[],
  files: FileImportResult[],
  imagesToDelete: number[],
  filesToDelete: number[],
): Promise<IntraPost | null> {
  const formData = new FormData();
  formData.append('title', title);
  formData.append('content', content);

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  images.forEach((image) => {
    formData.append('new_images', image.blob!, toImageName(image.name));
  });

  filesToDelete.forEach((file) => {
    formData.append('to_delete_files', file.toString());
  });

  imagesToDelete.forEach((image) => {
    formData.append('to_delete_images', image.toString());
  });

  try {
    const { data } = await api.post(
      `/intra/${item.slug}/update_post`,
      formData,
      {
        params: {
          post_id: post.id,
        },
      },
    );
    post.update(data);
    return data;
  } catch (e) {
    return null;
  }
}

async function deletePost(item: IntraItem, post: IntraPost) {
  runInAction(() => {
    stores.intraStore.deletedPosts.push(post.id);
  });
  await api.post(`/intra/${item.slug}/delete_post`, null, {
    params: {
      post_id: post.id,
    },
  });
}

async function getPosts(item: IntraItem): Promise<IntraPost[]> {
  try {
    const { data } = await api.get(`/intra/${item.slug}/posts`);
    return data.map((s: any) => new IntraPost(s));
  } catch (e) {
    return [];
  }
}

async function updateOwner(item: IntraItem, newOwner: MiniUser) {
  try {
    await api.post(`/intra/${item.slug}/update_owner`, {
      owner: newOwner.id,
    });
    runInAction(() => {
      item.owner = newOwner;
    });
  } catch (e) {}
}

async function togglePostPin(item: IntraItem, post: IntraPost) {
  const { data } = await api.post(`/intra/${item.slug}/toggle_post_pin`, {
    id: post.id,
  });
  runInAction(() => {
    post.pinned = data.pinned;
  });
}

export default {
  togglePostPin,
  getIntraItem,
  getItems,
  getNotPublished,
  createIntraItem,
  updateIntraItem,
  deleteIntraItem,
  getPopular,
  askChanges,
  getInvitations,
  getRequests,
  deleteRequest,
  requestInvite,
  acceptRequest,
  invite,
  isInvited,
  join,
  getFiles,
  uploadFile,
  deleteFile,
  hasRequested,
  getFolders,
  getFormations,
  deleteInvitation,
  getModelFiles,
  publish,
  setStatus,
  reject,
  createPost,
  updatePost,
  deletePost,
  getPosts,
  resendInvitation,
  removeMember,
  updateOwner,
};
