import { Dialog } from '@material-ui/core';
import React from 'react';
import styled, { css } from '~/styles';
import withStore, { WithStoreProps } from '~/utils/withStore';

import AccountStep from './Account';

const OnBoardingWrapper = styled.div`
  padding: 20px;
`;

const PaperClass = css`
  width: 800px;
  max-width: 100%;
  margin: 10px;
`;

class OnBoarding extends React.Component<WithStoreProps> {
  public render() {
    return (
      <Dialog open classes={{ paper: PaperClass }}>
        <OnBoardingWrapper>
          <AccountStep />
        </OnBoardingWrapper>
      </Dialog>
    );
  }
}

export default withStore(OnBoarding);
