import { action, computed, observable, runInAction } from 'mobx';
import qs from 'query-string';
import OpenInnoApi from '~/api/openinno';
import i18n from '~/locales/i18n';
import { Challenge, Partner, Partnership, PublicPage } from '~/models/openinno';
import { RootStore } from '~/stores';

class OpenInnoStore {
  public challenges = observable.map<string, Challenge>();
  public partnerships = observable.map<string, Partnership>();
  public partners = observable.map<string, Partner>();
  public publicPages = observable.map<string, PublicPage>();

  @observable
  public getTokenError: boolean = false;

  @observable
  public fileStatus: any = {};

  @observable
  public projectFileStatus: any = {};

  @observable
  public deletedPosts: number[] = [];

  constructor(private rootStore: RootStore) {
    const { openinno_join_code } = qs.parse(window.location.search);

    if (openinno_join_code && openinno_join_code.length) {
      this.tryLogin(openinno_join_code as string);
    }
  }

  public init() {
    this.fileStatus = this.rootStore.authStore.storage.get(
      'openinno_files_status',
      {},
    );
    this.projectFileStatus = this.rootStore.authStore.storage.get(
      'openinno_projects_files_status',
      {},
    );
  }

  public async tryLogin(join_code: string) {
    try {
      const data = await OpenInnoApi.getToken(join_code);
      this.rootStore.authStore.internalLogin(data);
    } catch (e) {
      runInAction(() => {
        this.getTokenError = true;
      });
    }
  }

  @action
  public updateFileStatus(partnership: Partnership, count: number) {
    this.fileStatus[partnership.id] = count;
    this.rootStore.authStore.storage.set(
      'openinno_files_status',
      this.fileStatus,
    );
  }

  public async loadChallenges() {
    const newChallenges = await OpenInnoApi.getChallenges(null);
    runInAction(() => {
      for (const challenge of newChallenges.results) {
        this.challenges.set(challenge.slug, challenge);
      }
    });

    return this.challenges;
  }

  public async loadChallenge(slug: string) {
    const found = this.challenges.get(slug);
    if (found) return found;
    const challenge = await OpenInnoApi.getChallenge(slug);
    runInAction(() => {
      this.challenges.set(challenge.slug, challenge);
    });

    return challenge;
  }

  public async loadPartnerships() {
    const newItems = await OpenInnoApi.getPartnerships();
    runInAction(() => {
      for (const partnership of newItems.results) {
        this.partnerships.set(partnership.slug, partnership);
      }
    });

    return this.challenges;
  }

  public async loadPartnership(slug: string) {
    const found = this.partnerships.get(slug);
    if (found) return found;
    const partnership = await OpenInnoApi.getPartnership(slug);

    if (partnership) {
      runInAction(() => {
        this.partnerships.set(partnership.slug, partnership);
      });
    }

    return partnership;
  }

  public async loadPartnerById(id: number) {
    const partner = await OpenInnoApi.getPartnerById(id);
    if (partner) {
      runInAction(() => {
        this.partners.set(partner.slug, partner);
      });
    }
    return partner;
  }

  public async loadPartner(slug: string) {
    const found = this.partners.get(slug);
    if (found) return found;
    const partner = await OpenInnoApi.getPartner(slug);

    if (partner) {
      runInAction(() => {
        this.partners.set(partner.slug, partner);
      });
    }

    return partner;
  }

  public async loadPublicPage(slug: string) {
    const found = this.publicPages.get(slug);
    if (found) return found;
    const page = await OpenInnoApi.getPublicPage(slug);

    if (page) {
      runInAction(() => {
        this.publicPages.set(page.slug, page);
      });
    }

    return page;
  }

  public async loadPublicPages() {
    const newItems = await OpenInnoApi.getPublicPages();
    this.publicPages.clear();
    runInAction(() => {
      for (const page of newItems) {
        this.publicPages.set(page.slug, page);
      }
    });

    return this.publicPages;
  }

  public deletePublicPage(page: PublicPage) {
    OpenInnoApi.deletePublicPage(page);
    this.publicPages.delete(page.slug);
  }

  public async createPublicPage() {
    const page = await OpenInnoApi.createPublicPage(
      i18n.formatMessage('openinno.public_pages.default.title'),
    );
    this.publicPages.set(page.slug, page);
    return page;
  }

  public async loadPartners() {
    const newItems = await OpenInnoApi.getPartners();
    runInAction(() => {
      for (const partner of newItems.results) {
        this.partners.set(partner.slug, partner);
      }
    });

    return this.partners;
  }

  public async deletePartner(partner: Partner) {
    await OpenInnoApi.deletePartner(partner);
    this.partners.delete(partner.slug);
  }

  @computed
  get challengeList() {
    return [...this.challenges.values()];
  }

  @computed
  get partnershipList() {
    return [...this.partnerships.values()];
  }

  @computed
  get partnersList() {
    return [...this.partners.values()];
  }

  @computed
  get publicPageList() {
    return [...this.publicPages.values()];
  }
}

export default OpenInnoStore;
