import { action, computed, observable, runInAction } from 'mobx';

import AcademyApi from '~/api/academy';
import { ImageImportResult } from '~/components/ImageImport';
import { AcademyFormation } from '~/models/academy';
import { FileImportResult } from '~/models/utils';
import { RootStore } from '~/stores';

class AcademyStore {
  public formations = observable.map<string, AcademyFormation>();

  @observable
  public results: string[] = [];

  @observable
  public search: string = '';

  @observable
  public tags: string[] = [];

  @observable
  public type: 'external' | 'internal' | 'both' = 'both';

  @observable
  public sort: string = '-date_created';

  @observable
  public next: string | null = null;
  public previous: string | null = null;
  public currentUrl: string | null;

  constructor(private rootStore: RootStore) {}

  public async loadFormations(force?: boolean) {
    if (force) this.resetUrl();

    if (!force && this.resultItems.length) return this.resultItems;
    const newFormations = await AcademyApi.getFormations(
      null,
      this.queryParams,
    );
    runInAction(() => {
      for (const formation of newFormations.results) {
        this.formations.set(formation.slug, formation);
      }
      this.results = newFormations.results.map((formation) => formation.slug);
      this.next = newFormations.next;
      this.previous = newFormations.previous;
    });

    return this.resultItems;
  }

  public async loadSimilar(formation: AcademyFormation) {
    const newFormations = await AcademyApi.getSimilar(formation.tags);
    runInAction(() => {
      for (const f of newFormations) {
        if (!this.formations.has(f.slug)) {
          this.formations.set(f.slug, f);
        }
      }
    });

    return newFormations;
  }

  public async loadNotPublished() {
    const notPublished = await AcademyApi.getNotPublished();
    runInAction(() => {
      for (const formation of notPublished) {
        this.formations.set(formation.slug, formation);
      }
    });

    return notPublished;
  }

  public async loadPopular(time: string) {
    const newIdeaboxItems = await AcademyApi.getPopular(time);
    return newIdeaboxItems;
  }

  public async loadFormation(slug: string, force: boolean = false) {
    if (force || !this.formations.has(slug)) {
      const item = await AcademyApi.getFormation(slug);
      if (item) {
        this.formations.set(item.slug, item);
      }
      return item;
    }
    return this.formations.get(slug);
  }

  public async createFormation(
    feature: string,
    title: string,
    abstract: string,
    tags: string[],
    thumbnail: ImageImportResult | null,
    content: string,
    published: boolean,
    files: FileImportResult[],
    type: string,
  ) {
    const formation = await AcademyApi.createFormation(
      feature,
      title,
      abstract,
      tags,
      thumbnail,
      content,
      published,
      files,
      type,
    );
    if (formation) {
      if (formation.published) {
        this.rootStore.authStore.addRawXp(
          this.rootStore.organizationStore.getAcademyConfig(feature).create_xp,
        );
      }
      this.formations.set(formation.slug, formation);
    }
    return formation;
  }

  public async updateFormation(
    formation: AcademyFormation,
    title: string,
    abstract: string,
    tags: string[],
    thumbnail: ImageImportResult | null,
    content: string,
    published: boolean,
    files: FileImportResult[],
    filesToDelete: number[],
    type?: string,
  ) {
    const oldPublished = formation.published;
    const updatedFormation = await AcademyApi.updateFormation(
      formation,
      title,
      abstract,
      tags,
      thumbnail,
      content,
      published,
      files,
      filesToDelete,
      type,
    );
    if (updatedFormation) {
      this.formations.set(updatedFormation.slug, updatedFormation);
      if (
        !oldPublished &&
        updatedFormation.published &&
        updatedFormation.owner.id === this.rootStore.authStore.user.id
      ) {
        this.rootStore.authStore.addRawXp(
          this.rootStore.organizationStore.getAcademyConfig(formation.feature)
            .create_xp,
        );
      }
    }
    return updatedFormation;
  }

  @action
  public async deleteFormation(formation: AcademyFormation) {
    this.results = this.results.filter((c) => c !== formation.slug);
    await AcademyApi.deleteFormation(formation);
  }

  public async loadMore() {
    const newFormations = await AcademyApi.getFormations(
      this.currentUrl,
      this.queryParams,
    );
    runInAction(() => {
      for (const item of newFormations.results) {
        this.formations.set(item.slug, item);
      }
      this.results.push(...newFormations.results.map((item) => item.slug));
      this.next = newFormations.next;
      this.previous = newFormations.previous;
    });

    return this.resultItems;
  }

  @action.bound
  public async loadNextFormations() {
    this.currentUrl = this.next;
    return this.loadMore();
  }

  @computed
  get queryParams() {
    return {
      tags__name: this.tags.join(','),
      q: this.search,
      hit: this.sort === 'not_hit' ? false : undefined,
      ordering: this.sort !== 'not_hit' ? this.sort : undefined,
      type: this.type,
    };
  }

  @computed
  get resultItems() {
    return this.results
      .map((slug) => this.formations.get(slug)!)
      .filter((item) => !!item);
  }

  @action
  public setSearch = (search: string) => {
    this.search = search;
    this.loadFormations(true);
  };

  @action
  public setTags = (tags: string[]) => {
    this.tags = tags;
    this.loadFormations(true);
  };

  @action
  public setSort = (sort: string) => {
    this.sort = sort;
    this.loadFormations(true);
  };

  @action
  public setType = (type: 'external' | 'internal' | 'both') => {
    this.type = type;
    this.loadFormations(true);
  };

  @computed
  get hasNext() {
    return this.next && this.next.length !== 0;
  }

  @action.bound
  public resetUrl() {
    this.next = null;
    this.previous = null;
    this.currentUrl = null;
  }

  @action.bound
  public reset(withLoad: boolean = true) {
    this.tags = [];
    this.type = 'both';
    this.sort = '-date_created';
    this.search = '';
    this.results = [];
    if (withLoad) {
      this.loadFormations(true);
    }
  }
}

export default AcademyStore;
