import React from 'react';
import styled from '~/styles';

import { ListItem, ListItemIcon, WithTheme } from '@material-ui/core';

import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import { withTheme } from 'emotion-theming';

const CollapseListItem = styled(ListItem)`
  svg {
    margin-right: 0px;
  }
`;

interface Props {
  collapsed: boolean;
  onClick: () => void;
}

class CollapseButton extends React.PureComponent<Props & WithTheme> {
  public render() {
    const { collapsed, onClick, theme } = this.props;
    const color = theme.palette.common.white;

    return (
      <CollapseListItem
        button
        onClick={onClick}
        style={{
          justifyContent: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
        }}
      >
        <ListItemIcon style={{ color, margin: 0 }}>
          {collapsed ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </ListItemIcon>
      </CollapseListItem>
    );
  }
}

export default withTheme<Props>(CollapseButton);
