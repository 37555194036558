import {
  Button,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Add, Camera, Delete, Edit } from '@material-ui/icons';
import { action, computed, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

import Avatar from '~/components/Avatar';
import withStore, { WithStoreProps } from '~/utils/withStore';
import { ImageImportResult, importImage } from '../ImageImport';
import { Title } from './styles';

import { FormattedMessage } from 'react-intl';
import JobIcon from '~/assets/images/icons/job.svg';
import SkillIcon from '~/assets/images/icons/skills.svg';
import TeamIcon from '~/assets/images/icons/team.svg';
import i18n from '~/locales/i18n';
import styled from '~/styles';
import theme from '~/styles/theme';
import { buildingInfos } from '../dialogs';

const Tags = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const Tag = styled(Typography)`
  border: 2px solid ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.primary.main};
  border-radius: 4px;
  padding: 2px 8px;
  margin-right: 5px;
  margin-bottom: 5px;

  .remove {
    cursor: pointer;
    margin-left: 7px;
  }
`;

const SubTitle = styled(Typography)`
  padding-bottom: 5px;
  margin-top: 30px;
  color: ${(p) => p.theme.palette.grey[800]};
  width: 100%;
  font-size: 17px;
  border-bottom: 2px solid ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.primary.main};

  svg {
    vertical-align: middle;
    margin-right: 5px;
    margin-top: -2px;
  }
`;

@observer
class AccountStep extends React.Component<WithStoreProps> {
  @observable
  public newAvatar: ImageImportResult | null = null;

  @observable
  public deleteAvatar: boolean = false;

  @observable
  public job: string = '';

  @observable
  public competences: string[] = [];

  @observable
  public groups: string[] = [];

  @observable
  public groupsOpen: boolean = false;

  @observable
  public competenceOpen: boolean = false;

  @observable
  public anchor: any = null;

  constructor(props: any) {
    super(props);
    this.competences = this.props.store.authStore.user.competences;
    this.groups = this.props.store.authStore.user.campus_groups;
    this.job = this.props.store.authStore.user.job;
  }

  @computed
  get unusedCompetences() {
    return this.props.store.organizationStore.organization.competences.filter(
      (c) => this.competences.indexOf(c) === -1,
    );
  }

  @computed
  get unusedGroups() {
    return this.props.store.organizationStore.organization.groups.filter(
      (c) => this.groups.indexOf(c) === -1,
    );
  }

  @action.bound
  public delete() {
    this.deleteAvatar = true;
    this.newAvatar = null;
  }

  @action.bound
  public async edit() {
    const data = await importImage({
      options: {
        aspectRatio: 1,
      },
      outputOptions: {
        height: 200,
        width: 200,
        maxWidth: 200,
        maxHeight: 200,
        minHeight: 200,
        minWidth: 200,
      },
      imageUrl: this.newAvatar?.url || this.props.store.authStore.user.avatar,
    });
    runInAction(() => {
      this.newAvatar = data;
      this.deleteAvatar = false;
    });
  }

  @action
  public handleJobUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.job = e.target.value;
  };

  @action
  public toggleCompetences = (e?: React.SyntheticEvent<any>) => {
    if (e && e.currentTarget) {
      this.anchor = e.currentTarget;
    }
    this.competenceOpen = !this.competenceOpen;
  };

  @action
  public toggleGroups = (e?: React.SyntheticEvent<any>) => {
    if (e && e.currentTarget) {
      this.anchor = e.currentTarget;
    }
    this.groupsOpen = !this.groupsOpen;
  };

  @action
  public submit = async () => {
    const { store } = this.props;
    await store.authStore.updateCurrentUser(
      {
        job: this.job,
        campus_groups: this.groups,
        competences: this.competences,
        on_boarding_version: 2,
      },
      this.deleteAvatar ? null : this.newAvatar ? this.newAvatar : undefined,
    );
    if (
      store.organizationStore.organization.config.enabled_infos.indexOf(
        'general',
      ) !== -1
    ) {
      buildingInfos('general');
    }
  };

  @computed
  get valid() {
    if (!this.job || this.job.length === 0) return false;
    if (this.groups.length === 0) return false;
    if (this.competences.length === 0) return false;
    return true;
  }

  public render() {
    const { store } = this.props;
    const user = store.authStore.user;
    return (
      <div>
        <Title align="center">
          <FormattedMessage id="onboarding.title" />
        </Title>
        <div
          style={{
            margin: '0 auto',
            padding: '5px 0px',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '400px',
            maxWidth: '100%',
          }}
        >
          <Typography>
            <FormattedMessage id="onboarding.subtitle" />
          </Typography>
          <TextField
            style={{ marginTop: '20px' }}
            value={this.job}
            variant="outlined"
            onChange={this.handleJobUpdate}
            placeholder={i18n.formatMessage('onboarding.job')}
            fullWidth
            margin="normal"
            inputProps={{
              style: {
                paddingTop: '12px',
                paddingBottom: '12px',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <JobIcon height="20" width="20" />
                </InputAdornment>
              ),
            }}
          />
          <SubTitle>
            <SkillIcon height="20" width="20" />
            <FormattedMessage id="onboarding.define_skills" />
          </SubTitle>
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <Menu
              anchorEl={this.anchor}
              open={this.competenceOpen}
              onClose={this.toggleCompetences}
            >
              {this.unusedCompetences.map((c) => {
                const add = () =>
                  runInAction(() => {
                    if (this.competences.indexOf(c) === -1) {
                      this.competences = [...this.competences, c];
                    }
                    this.competenceOpen = false;
                  });

                return (
                  <MenuItem key={c} onClick={add}>
                    {c}
                  </MenuItem>
                );
              })}
            </Menu>
            <Button
              variant="text"
              onClick={this.toggleCompetences}
              color="primary"
            >
              <Add />
              <FormattedMessage id="onboarding.add_skill" />
            </Button>
          </div>
          <Tags>
            {this.competences.map((competence) => {
              const remove = () =>
                runInAction(() => {
                  this.competences = this.competences.filter(
                    (i) => i !== competence,
                  );
                });
              return (
                <Tag key={competence}>
                  {competence}
                  <span className="remove" onClick={remove}>
                    <i className="fas fa-times" />
                  </span>
                </Tag>
              );
            })}
          </Tags>
          <SubTitle>
            <TeamIcon height="20" width="20" />
            <FormattedMessage id="onboarding.choose_groups" />
          </SubTitle>
          <div style={{ textAlign: 'center', marginTop: '10px' }}>
            <Menu
              anchorEl={this.anchor}
              open={this.groupsOpen}
              onClose={this.toggleGroups}
            >
              {this.unusedGroups.map((c) => {
                const add = () =>
                  runInAction(() => {
                    if (this.groups.indexOf(c) === -1) {
                      this.groups = [...this.groups, c];
                    }
                    this.groupsOpen = false;
                  });

                return (
                  <MenuItem key={c} onClick={add}>
                    {c}
                  </MenuItem>
                );
              })}
            </Menu>
            <Button variant="text" onClick={this.toggleGroups} color="primary">
              <Add />
              <FormattedMessage id="onboarding.add_group" />
            </Button>
          </div>
          <Tags>
            {this.groups.map((group) => {
              const remove = () =>
                runInAction(() => {
                  this.groups = this.groups.filter((i) => i !== group);
                });
              return (
                <Tag key={group}>
                  {group}
                  <span className="remove" onClick={remove}>
                    <i className="fas fa-times" />
                  </span>
                </Tag>
              );
            })}
          </Tags>
          <SubTitle>
            <Camera />
            <FormattedMessage id="onboarding.add_photo" />
          </SubTitle>
          <div
            style={{
              padding: '20px 0px',
              paddingBottom: '10px',
              boxSizing: 'border-box',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxWidth: '100%',
            }}
          >
            <Avatar
              user={
                this.newAvatar
                  ? { ...user, avatar: this.newAvatar.url }
                  : this.deleteAvatar
                  ? { ...user, avatar: null }
                  : user
              }
              size={100}
            />
            <div style={{ marginTop: '5px' }}>
              <IconButton onClick={this.edit}>
                <Edit />
              </IconButton>
              <IconButton onClick={this.delete}>
                <Delete />
              </IconButton>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '10px',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <Typography
              style={{
                color: theme.current.palette.grey[400],
                fontSize: '14px',
                marginBottom: '15px',
              }}
              align="center"
            >
              <FormattedMessage id="onboarding.later" />
            </Typography>{' '}
            <Tooltip
              title={
                this.valid
                  ? ''
                  : i18n.formatMessage('onboarding.button.tooltip')
              }
            >
              <div>
                <Button
                  variant="contained"
                  size="large"
                  color="primary"
                  onClick={this.submit}
                  disabled={!this.valid}
                >
                  <FormattedMessage id="onboarding.start" />
                </Button>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}

export default withStore(AccountStep);
