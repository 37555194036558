import { pure } from 'recompose';
import styled from '~/styles';

export const pageWidth = 900;
export const noMaxWidth = 100000;
export const largePageWidth = 1200;
export const xLargePageWidth = 1400;
export const veryLargePageWidth = 1500;

interface Props {
  width?: number;
}

const Page = styled.div(
  (props: Props) => `
  max-width: ${props.width || pageWidth}px;
  width: 100%;
`,
);

export default pure(Page);
