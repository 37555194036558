import { AppBar, IconButton, Toolbar, Typography } from '@material-ui/core';
import { Close, KeyboardArrowRight, Menu, Search } from '@material-ui/icons';
import { observer } from 'mobx-react';
import React from 'react';
import { Link } from 'react-router-dom';

import FlexDivider from '~/components/utils/FlexDivider';
import { homePath, myProfilePath, searchPath } from '~/constants/routes';
import styled from '~/styles';
import theme from '~/styles/theme';
import withStore, { WithStoreProps } from '~/utils/withStore';
import Avatar from '../Avatar';
import BuildingIcon from '../BuildingIcon';

export const topbarHeight = theme.current.spacing.unit * 7;

const CampusToolbar = styled(Toolbar)`
  &&& {
    height: ${topbarHeight}px;
    min-height: ${topbarHeight}px;
  }

  svg {
    color: white !important;
  }
`;

const CustomLink = styled(Link)`
  color: white;
  text-decoration: none;
  vertical-align: center;
  margin-top: 2px;
  margin-right: 8px;

  svg {
    vertical-align: middle;
  }
`;

const NewAvatarBubble = styled.div`
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: ${(p) => p.theme.palette.secondary.main};
  z-index: 10000;
  border-radius: 50%;
  right: 65px;
  margin-top: -37px;
`;

@observer
class Topbar extends React.Component<WithStoreProps> {
  public render() {
    const { store } = this.props;
    const { to, subTo, label, subLabel, icon } = store.uiStore.appBarData;
    return (
      <AppBar position="fixed">
        <CampusToolbar>
          {to !== homePath() && (
            <>
              <Link
                style={{
                  textDecoration: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  color: 'white',
                }}
                to={homePath()}
              >
                <BuildingIcon building="home" size={25} />
              </Link>
              <KeyboardArrowRight style={{ color: 'white', margin: '0 3px' }} />
            </>
          )}
          <Link
            style={{
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
            to={subTo || to || homePath()}
          >
            {icon}

            <Typography
              style={{
                marginLeft: '5px',
                color: theme.current.palette.common.white,
                fontSize: '19px',
              }}
            >
              {subLabel || label}
            </Typography>
          </Link>
          <FlexDivider />
          <CustomLink
            to={searchPath('contents', true)}
            style={{
              marginRight: '14px',
            }}
          >
            <Search />
          </CustomLink>
          <CustomLink to={myProfilePath} onClick={store.uiStore.clickAvatar}>
            <Avatar size={35} user={store.authStore.user} />
            {!store.uiStore.avatarBubble && <NewAvatarBubble />}
          </CustomLink>
          <IconButton onClick={store.uiStore.toggleSidebar} color="inherit">
            {store.uiStore.sidebarCollapsed ? <Menu /> : <Close />}
          </IconButton>
        </CampusToolbar>
      </AppBar>
    );
  }
}

export default withStore(Topbar);
