import axios from 'axios';
import { TOKEN_KEY } from '~/constants/storage';
import storage from '~/utils/storage';

const realtimeApi = axios.create({
  baseURL: `${window.location.protocol}//${process.env.REALTIME_URL}`,
});

const unsubscribe = async (subscriptionId: string) => {
  await realtimeApi.post('/unsubscribe/', {
    subscriptionId,
    token: storage.get(TOKEN_KEY),
  });
};

const subscribe = async (subscription: any) => {
  await realtimeApi.post('/subscribe/', {
    ...subscription,
    token: storage.get(TOKEN_KEY),
  });
};

export default { subscribe, unsubscribe };
