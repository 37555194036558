import { Drawer, List, Typography } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { computed } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import MediaQuery from 'react-responsive';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import Avatar from '~/components/Avatar';
import CollapseButton from '~/components/Sidebar/CollapseButton';
import SidebarItem from '~/components/Sidebar/SidebarItem';
import FlexDivider from '~/components/utils/FlexDivider';
import {
  academyPath,
  accountPath,
  challengesPath,
  chatPath,
  communitiesPath,
  dashboardPath,
  homePath,
  ideaboxPath,
  intraPath,
  labPath,
  myProfilePath,
  openInnoPartnershipsPath,
  openInnoProjectsDetailsPath,
  projectsPath,
  searchPath,
  settingsPath,
} from '~/constants/routes';
import styled, { css } from '~/styles';
import theme from '~/styles/theme';
import toMediaQuery from '~/utils/toMediaQuery';
import withStore, { WithStoreProps } from '~/utils/withStore';

import AcademyIcon from '~/assets/images/icons/academy.svg';
import CampusIcon from '~/assets/images/icons/campus.svg';
import ChallengeIcon from '~/assets/images/icons/challenge.svg';
import CommunitiesIcon from '~/assets/images/icons/communities.svg';
import DashboardIcon from '~/assets/images/icons/dashboard.svg';
import IdeaboxIcon from '~/assets/images/icons/ideabox.svg';
import IntraIcon from '~/assets/images/icons/intra.svg';
import ChatIcon from '~/assets/images/icons/messagerie.svg';
import PartnershipIcon from '~/assets/images/icons/partnership.svg';
import ProjectsIcon from '~/assets/images/icons/projets.svg';
import SettingsIcon from '~/assets/images/icons/settings.svg';

import { FormattedMessage } from 'react-intl';
import i18n from '~/locales/i18n';

export const sidebarWidth = 250;
export const collapsedSidebarWidth = theme.current.spacing.unit * 9;

const Header = styled.div`
  position: relative;
  &&&&& {
    margin-top: 5px;
    margin-bottom: -8px;
    h6 {
      margin-top: 2px;
      font-weight: 500;
      font-size: 25px;
    }
  }
`;

const SearchLink = styled(Link)`
  color: white;
  text-decoration: none;
  vertical-align: center;
  margin-top: 6px;
  position: absolute;
  right: 15px;
  top: 5px;
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.1s;

  svg {
    vertical-align: middle;
  }

  &.selected {
    color: white;
    background-color: rgba(255, 255, 255, 0.2);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const NewAvatarBubble = styled.div`
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: ${(p) => p.theme.palette.secondary.main};
  z-index: 10000;
  border-radius: 50%;
  left: 30px;
  margin-top: -30px;
`;

const Notif = styled(Typography)`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 50%;
  margin-left: 7px;
  color: white;
  background-color: ${(p) => p.theme.palette.secondary.main};
  position: fixed;
  top: 15px;
  left: 10px;
  z-index: 100;
`;

interface Props extends WithStoreProps, RouteComponentProps<any> {}

@observer
class Sidebar extends React.Component<Props> {
  @computed
  get paperClass() {
    return css`
      box-shadow: 0 0px 3px rgba(0, 0, 0, 0.16), 0 0px 3px rgba(0, 0, 0, 0.23) !important;

      will-change: width;
      white-space: nowrap;
      width: ${sidebarWidth}px;
      transition: ${theme.current.transitions.create('width', {
        easing: theme.current.transitions.easing.sharp,
        duration: theme.current.transitions.duration.enteringScreen,
      })};

      && {
        overflow-x: hidden;
        border: none;
        box-shadow: 1px 0 6px 0px rgba(0, 0, 0, 0.3);
        background-color: ${theme.current.palette.primary.main};
        ${theme.current.breakpoints.down('xs')} {
          width: calc(100vw - 70px);
        }
      }
    `;
  }

  @computed
  get paperClassCollapsed() {
    return css`
      ${this.paperClass};
      width: ${collapsedSidebarWidth}px;

      &&& {
        ${theme.current.breakpoints.down('sm')} {
          width: 0;
        }
      }
    `;
  }

  public toggleSidebar = () => {
    const { store } = this.props;
    store.uiStore.toggleSidebar();
  };

  public resetHome = () => {
    this.props.store.activityStore.reset();
  };

  @computed
  get buildings() {
    const store = this.props.store;
    const buildingOrder =
      store.organizationStore.organization.config.building_order;
    const items = [
      [
        store.organizationStore.hasFeature('ideabox') && (
          <SidebarItem
            // tslint:disable-next-line: campus-use-i18n
            label="buildings.ideabox"
            feature="ideabox"
            icon={<IdeaboxIcon height="22" width="22" />}
            to={ideaboxPath}
            onClick={store.ideaboxStore.reset}
          />
        ),
        buildingOrder.indexOf('ideabox'),
      ],
      [
        store.organizationStore.hasFeature('projects') && (
          <SidebarItem
            feature="projects"
            // tslint:disable-next-line: campus-use-i18n
            label="buildings.projects"
            icon={<ProjectsIcon height="22" width="22" />}
            to={projectsPath}
            onClick={store.projectsStore.reset}
          />
        ),
        buildingOrder.indexOf('projects'),
      ],
      [
        store.organizationStore.hasFeature('lab') && (
          <SidebarItem
            feature="lab"
            // tslint:disable-next-line: campus-use-i18n
            label="buildings.lab"
            icon={<IntraIcon height="22" width="22" />}
            to={labPath()}
            onClick={store.projectsStore.reset}
          />
        ),
        buildingOrder.indexOf('lab'),
      ],
      [
        store.organizationStore.hasFeature('intra') && (
          <SidebarItem
            feature="intra"
            // tslint:disable-next-line: campus-use-i18n
            label="buildings.intra"
            icon={<IntraIcon height="22" width="22" />}
            to={intraPath}
            onClick={store.intraStore.reset}
          />
        ),
        buildingOrder.indexOf('intra'),
      ],
      [
        store.organizationStore.hasFeature('academy') && (
          <SidebarItem
            // tslint:disable-next-line: campus-use-i18n
            label="buildings.academy"
            feature="academy"
            icon={<AcademyIcon height="22" width="22" />}
            to={academyPath()}
            onClick={store.academyStore.reset}
          />
        ),
        buildingOrder.indexOf('academy'),
      ],
      [
        store.organizationStore.hasFeature('challenges') && (
          <SidebarItem
            feature="challenges"
            // tslint:disable-next-line: campus-use-i18n
            label="buildings.challenges"
            icon={<ChallengeIcon height="22" width="22" />}
            to={challengesPath}
            onClick={store.challengesStore.reset}
          />
        ),
        buildingOrder.indexOf('challenges'),
      ],
      [
        store.organizationStore.hasFeature('openinno') &&
          !store.organizationStore.isHidden('openinno.partnerships') && (
            <>
              {!store.uiStore.sidebarCollapsed && (
                <Typography
                  style={{
                    color: 'white',
                    paddingLeft: '20px',
                    marginTop: '30px',
                    fontWeight: 400,
                    fontSize: '11px',
                    textTransform: 'uppercase',
                    letterSpacing: '2px',
                  }}
                >
                  <FormattedMessage id="buildings.openinno" />
                </Typography>
              )}
              {!store.organizationStore.isHidden('openinno.partnerships') && (
                <SidebarItem
                  feature="openinno"
                  // tslint:disable-next-line: campus-use-i18n
                  label="buildings.openinno.partnerships"
                  icon={<PartnershipIcon height="22" width="22" />}
                  to={openInnoPartnershipsPath}
                  matchPath="/openinno/pa"
                />
              )}
            </>
          ),
        buildingOrder.indexOf('openinno'),
      ],
    ];

    return items
      .filter(([c, _]) => !!c)
      .sort((a: any, b: any) => a[1] - b[1])
      .map(([c, _]) => c);
  }

  public render() {
    const { store } = this.props;
    const count =
      store.activityStore.newActivityCount[0] +
      store.activityStore.newActivityCount[1];
    return (
      <Drawer
        variant="permanent"
        open
        classes={{
          paper: store.uiStore.sidebarCollapsed
            ? this.paperClassCollapsed
            : this.paperClass,
        }}
      >
        {count > 0 && !store.authStore.isExternal && <Notif>+1</Notif>}

        <Header
          style={{
            pointerEvents: store.authStore.isExternal ? 'none' : undefined,
          }}
        >
          <SidebarItem
            exact
            // tslint:disable-next-line: campus-use-i18n
            disableLabelTranslation
            label={
              <div style={{ fontSize: '20px' }}>
                {store.organizationStore.organization.config.campus_name ||
                  i18n.formatMessage('commons.campus').toUpperCase()}
              </div>
            }
            icon={
              <CampusIcon
                height="27"
                width="27"
                style={{ marginLeft: '-3px' }}
              />
            }
            to={homePath()}
            forceColor
            onClick={this.resetHome}
          />
          {!store.uiStore.sidebarCollapsed &&
            !(
              store.organizationStore.organization.features.length === 2 &&
              store.organizationStore.hasFeature('openinno')
            ) &&
            !store.authStore.isExternal && (
              <SearchLink
                className={`desktop-only ${location.pathname.startsWith(
                  '/search',
                ) && 'selected'}`}
                to={searchPath('contents', true)}
              >
                <Search />
              </SearchLink>
            )}
        </Header>
        {!store.authStore.isExternal && (
          <List component="nav" style={{ paddingTop: 0, marginTop: '0px' }}>
            {store.uiStore.sidebarCollapsed && (
              <SidebarItem
                label=""
                icon={<Search />}
                to={searchPath('contents', true)}
              />
            )}
            {this.buildings}
          </List>
        )}
        {store.authStore.isExternal && (
          <List>
            {store.authStore.myProjects.map((p) => (
              <SidebarItem
                label={p.name}
                disableLabelTranslation
                icon={<PartnershipIcon height="22" width="22" />}
                matchPath={openInnoProjectsDetailsPath(p.slug, '')}
                to={openInnoProjectsDetailsPath(p.slug)}
              />
            ))}
          </List>
        )}
        <FlexDivider />
        <List>
          {!store.authStore.isExternal && (
            <>
              {store.authStore.isModerator && (
                <SidebarItem
                  // tslint:disable-next-line: campus-use-i18n
                  label="buildings.dashboard"
                  icon={<DashboardIcon height="22" width="22" />}
                  matchPath={dashboardPath('')}
                  to={dashboardPath('general')}
                />
              )}
            </>
          )}
          {store.authStore.isAdmin && (
            <SidebarItem
              // tslint:disable-next-line: campus-use-i18n
              label="buildings.admin"
              icon={<SettingsIcon height="22" width="22" />}
              matchPath={settingsPath('')}
              to={settingsPath('general')}
            />
          )}
          {store.organizationStore.hasFeature('communities') &&
            !store.authStore.isExternal && (
              <SidebarItem
                // tslint:disable-next-line: campus-use-i18n
                label="buildings.communities"
                icon={<CommunitiesIcon height="22" width="22" />}
                matchPath={communitiesPath}
                to={communitiesPath}
              />
            )}
          <SidebarItem
            // tslint:disable-next-line: campus-use-i18n
            label="buildings.chat"
            icon={<ChatIcon height="22" width="22" />}
            to={chatPath}
            matchPath={chatPath}
            onClick={store.uiStore.clickAvatar}
          />
          <SidebarItem
            label={
              <div style={{ marginTop: '-1px' }}>
                {store.authStore.user.fullname}
              </div>
            }
            disableLabelTranslation
            icon={
              <div style={{ marginLeft: '-3px', marginRight: '-3px' }}>
                <Avatar size={27} user={store.authStore.user} />
                {!store.uiStore.avatarBubble && !store.authStore.isExternal && (
                  <NewAvatarBubble />
                )}
              </div>
            }
            to={store.authStore.isExternal ? accountPath : myProfilePath}
            matchPath={store.authStore.isExternal ? accountPath : myProfilePath}
            onClick={store.uiStore.clickAvatar}
          />
          <MediaQuery query={toMediaQuery(theme.current.breakpoints.up('md'))}>
            <CollapseButton
              onClick={this.toggleSidebar}
              collapsed={store.uiStore.sidebarCollapsed}
            />
          </MediaQuery>
        </List>
      </Drawer>
    );
  }
}

export default withStore(withRouter(Sidebar));
