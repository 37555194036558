import { IconButton, Tooltip, Typography } from '@material-ui/core';
import {
  Forum,
  Info,
  KeyboardArrowRight,
  Notifications,
  Settings,
} from '@material-ui/icons';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import AdminIcon from '~/assets/images/icons/admin_icon.svg';
import AnalyticsIcon from '~/assets/images/icons/analytics.svg';
import GiftIcon from '~/assets/images/icons/gift.svg';
import Avatar from '~/components/Avatar';
import {
  changeLogPath,
  chatPath,
  homePath,
  myProfilePath,
  searchPath,
  settingsPath,
} from '~/constants/routes';
import history from '~/history';

import { darken } from 'polished';
import { buildingInfos, showBuildingInfos } from '~/components/dialogs';
import UserIcon from '~/components/UserDisplay/UserIcon';
import i18n from '~/locales/i18n';
import { useStore } from '~/stores';
import styled from '~/styles';
import SearchInput from './SearchInput';

const Wrapper = styled.div<{ overlayOpen?: boolean }>`
  position: sticky;
  top: 0;
  z-index: ${(p) => (p.overlayOpen ? 0 : 1000)};
  background-color: ${(p) => p.theme.palette.primary.main};
  box-shadow: 4px 2px 3px rgba(0, 0, 0, 0.1);
  ${(p) => p.theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const InnerWrapper = styled.div`
  box-shadow: -4px 0 4px rgba(0, 0, 0, 0.2);

  background-color: white;
  color: ${(p) => p.theme.palette.primary.main};
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-top-left-radius: 25px;
  height: 56px;
`;

const Brand = styled(Typography)`
  display: flex;
  color: inherit;
  align-items: center;
  font-size: 20px;
  text-decoration: none;

  svg {
    vertical-align: middle;
    margin-right: 8px;
  }

  ${(p) => p.theme.breakpoints.down('lg')} {
    font-size: 19px;
  }
  ${(p) => p.theme.breakpoints.down('md')} {
    font-size: 17px;
  }
`;

const AppBarButton = styled(IconButton)`
  padding: 7px;
  color: ${(p) => p.theme.palette.grey[500]};
  transition: color 0.5s;

  &:not(:last-of-type) {
    margin-right: 5px;
  }

  svg {
    font-size: 22px;
  }

  &:hover {
    color: ${(p) => p.theme.palette.grey[700]};
  }
`;

const UserLink = styled(Link)`
  text-decoration: none;
  color: ${(p) => p.theme.palette.secondary.main};
  transition: color 0.3s;
  display: flex;
  align-items: center;

  p {
    font-weight: 500;
    font-size: 16px;
    margin-top: -2px;
    margin-left: 8px;
    color: inherit;

    ${(p) => p.theme.breakpoints.down('md')} {
      display: none;
    }
  }

  &:hover {
    color: ${(p) => darken(0.1, p.theme.palette.secondary.main)};
  }
`;

const Separator = styled.div`
  height: 32px;
  width: 2px;
  background-color: ${(p) => p.theme.palette.grey[300]};
  margin: 0 15px;
`;

const Notif = styled(Typography)`
  height: 10px;
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  border-radius: 50%;
  margin-left: 7px;
  margin-top: -5px;
  background-color: ${(p) => p.theme.palette.secondary.main};
  position: absolute;
`;

const BrandSplitter = styled(KeyboardArrowRight)``;

function AppBar() {
  const {
    uiStore,
    authStore,
    chatStore,
    activityStore,
    organizationStore,
  } = useStore();

  const {
    label,
    icon,
    subTo,
    subLabel,
    to,
    settingsTo,
    dashboardTo,
    disableDashboard,
    infoKey,
  } = uiStore.appBarData;

  useEffect(() => {
    if (uiStore.appBarData && uiStore.appBarData.infoKey) {
      showBuildingInfos(uiStore.appBarData.infoKey, label);
    }
  }, [uiStore.appBarData.infoKey]);

  function onSearch() {
    history.push(searchPath('contents', true));
  }

  return (
    <Wrapper overlayOpen={uiStore.overlayOpen}>
      <InnerWrapper>
        <Brand component={to ? (p: any) => <Link {...p} to={to} /> : undefined}>
          {icon}
          {label || i18n.formatMessage('commons.loading')}
        </Brand>
        {subLabel && (
          <>
            <BrandSplitter />
            <Brand
              component={
                subTo ? (p: any) => <Link {...p} to={subTo} /> : undefined
              }
            >
              {subLabel || i18n.formatMessage('commons.loading')}
            </Brand>
          </>
        )}
        <div style={{ marginLeft: '10px' }}>
          {infoKey &&
            organizationStore.organization.config.enabled_infos.indexOf(
              infoKey,
            ) !== -1 && (
              <Tooltip title={i18n.formatMessage('commons.infos')}>
                <AppBarButton onClick={() => buildingInfos(infoKey, label)}>
                  <Info />
                </AppBarButton>
              </Tooltip>
            )}
          {settingsTo && (
            <Tooltip title={i18n.formatMessage('buildings.admin')}>
              <AppBarButton onClick={() => history.push(settingsTo!)}>
                {settingsTo === settingsPath('general') ? (
                  <AdminIcon height="19" width="19" />
                ) : (
                  <Settings />
                )}
              </AppBarButton>
            </Tooltip>
          )}
          {dashboardTo && (
            <Tooltip title={i18n.formatMessage('buildings.dashboard')}>
              <AppBarButton
                onClick={() => history.push(dashboardTo!)}
                disabled={disableDashboard}
              >
                <AnalyticsIcon height="19" width="19" />
              </AppBarButton>
            </Tooltip>
          )}
        </div>

        <div style={{ flexGrow: 1 }} />
        {!location.pathname.startsWith('/search') && (
          <SearchInput onClick={onSearch} />
        )}
        <Separator />
        {/* {authStore.isModerator && (
          <Tooltip title={i18n.formatMessage('dashboard.changelog')}>
            <AppBarButton onClick={() => history.push(changeLogPath)}>
              <GiftIcon height="19" width="19" />
            </AppBarButton>
          </Tooltip>
        )} */}
        <Tooltip title={i18n.formatMessage('home.my_news')}>
          <AppBarButton
            onClick={() => {
              activityStore.setTab(1)();
              history.push(`${homePath()}?tab=1`);
            }}
          >
            <Notifications />
            {activityStore.newActivityCount[1] > 0 && <Notif />}
          </AppBarButton>
        </Tooltip>
        <Tooltip title={i18n.formatMessage('buildings.chat')}>
          <AppBarButton onClick={() => history.push(chatPath)}>
            <Forum />
            {chatStore.hasNew && <Notif />}
          </AppBarButton>
        </Tooltip>
        <Separator />
        <Tooltip title={i18n.formatMessage('titles.profil')}>
          <UserLink to={myProfilePath}>
            <Avatar size={27} user={authStore.user} />
            <Typography>{authStore.user.fullname}</Typography>
            <UserIcon user={authStore.user} size={15} />
          </UserLink>
        </Tooltip>
      </InnerWrapper>
    </Wrapper>
  );
}

export default observer(AppBar);
