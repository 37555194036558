import { action, computed, observable, runInAction } from 'mobx';

import ActivityApi from '~/api/activity';
import UsersApi from '~/api/users';
import { Activity } from '~/models/activity';
import { MiniUser } from '~/models/user';

class UserStore {
  public users = observable.map<number, MiniUser>();

  @observable
  public results: number[] = [];

  @observable
  public search: string = '';

  @observable
  public interests: string[] = [];

  @observable
  public competences: string[] = [];

  @observable
  public groups: string[] = [];

  @observable
  public roles: string[] = [];

  @observable
  public sort: string = '-date_created';

  @observable
  public next: string | null = null;
  public previous: string | null = null;
  public currentUrl: string | null;

  @observable
  public activities: Activity[] = [];

  @observable
  public nextActivities: string | null = null;
  public previousActivities: string | null = null;
  public currentUrlActivities: string | null;

  @observable
  public loadingActivities: boolean = true;

  public async loadUsers(force?: boolean) {
    if (!force && this.resultItems.length) return this.resultItems;
    const newUsers = await UsersApi.getUsers(this.currentUrl, this.queryParams);
    runInAction(() => {
      for (const user of newUsers.results) {
        this.users.set(user.id, user);
      }
      this.next = newUsers.next;
      this.previous = newUsers.previous;
      this.results = newUsers.results.map((user) => user.id);
    });

    return this.resultItems;
  }

  @action.bound
  public async loadMore() {
    const newUsers = await UsersApi.getUsers(this.next, this.queryParams);
    runInAction(() => {
      for (const user of newUsers.results) {
        this.users.set(user.id, user);
      }
      this.results.push(...newUsers.results.map((user) => user.id));
      this.next = newUsers.next;
      this.previous = newUsers.previous;
    });

    return this.resultItems;
  }

  @action.bound
  public async loadNextUsers() {
    this.currentUrl = this.next;
    return this.loadUsers(true);
  }

  @action.bound
  public async loadPreviousUsers() {
    this.currentUrl = this.previous;
    return this.loadUsers(true);
  }

  @action.bound
  public async loadNextItems() {
    this.currentUrl = this.next;
    return this.loadMore();
  }

  public async loadUser(id: number) {
    try {
      const user = await UsersApi.getUser(id);
      this.users.set(user.id, user);
      return user;
    } catch (e) {
      return null;
    }
  }

  @computed
  get hasNext() {
    return this.next && this.next.length !== 0;
  }

  public async loadTopUsers(time: string) {
    try {
      const users = await UsersApi.getTopUsers(time);
      runInAction(() => {
        for (const user of users) {
          this.users.set(user.id, user);
        }
      });
      return users;
    } catch (e) {
      return [];
    }
  }

  @computed
  get queryParams() {
    return {
      q: this.search,
      ordering: this.sort,
      competences: this.competences.join(','),
      interests: this.interests.join(','),
      campus_groups: this.groups.join(','),
      roles: this.roles.filter((c) => c !== 'ambassador').join(','),
      user_type:
        this.roles.indexOf('ambassador') !== -1 ? 'ambassador' : undefined,
    };
  }

  @action
  public setSearch = (search: string) => {
    this.search = search;
    this.loadUsers(true);
  }

  @action
  public setSort = (sort: string) => {
    this.sort = sort;
    this.loadUsers(true);
  }

  @action
  public setCompetences = (competences: string[]) => {
    this.competences = competences;
    this.loadUsers(true);
  }

  @action
  public setGroups = (groups: string[]) => {
    this.groups = groups;
    this.loadUsers(true);
  }

  @action
  public setInterests = (interests: string[]) => {
    this.interests = interests;
    this.loadUsers(true);
  }

  @action
  public setRoles = (roles: string[]) => {
    this.roles = roles;
    this.loadUsers(true);
  }

  @computed
  get resultItems() {
    return this.results
      .map((id) => this.users.get(id)!)
      .filter((user) => !!user);
  }

  @action.bound
  public reset(noLoad?: boolean) {
    this.results = [];
    this.next = null;
    this.previous = null;
    this.currentUrl = null;
    if (!noLoad) {
      this.loadUsers(true);
    }
  }

  @action
  public async loadActivities(userId: number) {
    if (this.activities.length) {
      return this.activities;
    }
    this.loadingActivities = true;
    const activities = await ActivityApi.getUserActivities(
      this.currentUrlActivities,
      userId,
    );
    runInAction(() => {
      this.loadingActivities = false;
      this.activities = activities.results;
      this.nextActivities = activities.next;
      this.previousActivities = activities.previous;
    });
    return activities.results;
  }

  @action
  public async loadMoreActivities() {
    this.loadingActivities = true;
    const activities = await ActivityApi.getUserActivities(
      this.currentUrlActivities,
    );
    runInAction(() => {
      this.activities.push(...activities.results);
      this.nextActivities = activities.next;
      this.previousActivities = activities.previous;
      this.loadingActivities = false;
    });

    return activities.results;
  }

  @action.bound
  public async loadNextActivities() {
    this.currentUrlActivities = this.nextActivities;
    return this.loadMoreActivities();
  }

  @action.bound
  public async resetActivities() {
    this.nextActivities = null;
    this.previousActivities = null;
    this.currentUrlActivities = null;
    this.activities = [];
  }

  @computed
  get hasNextActivities() {
    return this.nextActivities && this.nextActivities.length !== 0;
  }

  @action
  public updateUser = (user: MiniUser, newUser: MiniUser) => {
    this.users.set(user.id, newUser);
  }
}

export default UserStore;
