import api from '~/api/api';
import { ImageImportResult } from '~/components/ImageImport';
import { PaginatedResult } from '~/models/api';
import {
  Contest,
  ContestChallenge,
  ContestContribution,
  ContestRating,
  ContestSection,
} from './models';

class ContestApi {
  public async getContests(
    url: string | null,
    params?: any,
  ): Promise<PaginatedResult<Contest>> {
    const { data } = await api.get(url || '/oi_contest/search', {
      params: { ps: 12, ...params },
    });
    return data;
  }

  public async getContest(slug: string) {
    const { data } = await api.get(`/oi_contest/${slug}`);
    return data;
  }

  public async updateContest(contest: Contest, newData: any) {
    const { data } = await api.patch(`/oi_contest/${contest.slug}`, newData);
    return data;
  }

  public async createContest(contestData: any) {
    const { data } = await api.post('/oi_contest/', contestData);
    return data;
  }

  public async deleteContest(contest: Contest) {
    await api.delete(`/oi_contest/${contest.slug}`);
  }

  public async updateBanner(contest: Contest, banner: ImageImportResult) {
    const formData = new FormData();
    formData.append('banner', banner.blob!, banner.name);

    const { data } = await api.patch(`/oi_contest/${contest.slug}`, formData);
    return data;
  }

  public async addSection(contest: Contest, section: Partial<ContestSection>) {
    const { data } = await api.post(
      `/oi_contest/${contest.slug}/add_section`,
      section,
    );
    return data;
  }

  public async updateSection(
    contest: Contest,
    section: Partial<ContestSection>,
  ) {
    const { data } = await api.post(
      `/oi_contest/${contest.slug}/update_section`,
      {
        section: section.slug,
        ...section,
      },
    );
    return data;
  }

  public async deleteSection(contest: Contest, section: ContestSection) {
    await api.post(`/oi_contest/${contest.slug}/delete_section`, {
      section: section.slug,
    });
  }

  public async getSections(contest: Contest) {
    const { data } = await api.get(`/oi_contest/${contest.slug}/sections`);
    return data;
  }

  public async addChallenge(
    contest: Contest,
    challenge: Partial<ContestChallenge>,
  ) {
    const { data } = await api.post(
      `/oi_contest/${contest.slug}/add_challenge`,
      challenge,
    );
    return data;
  }

  public async updateChallenge(
    contest: Contest,
    challenge: Partial<ContestChallenge>,
  ) {
    const { data } = await api.post(
      `/oi_contest/${contest.slug}/update_challenge`,
      {
        challenge: challenge.slug,
        ...challenge,
      },
    );
    return data;
  }

  public async deleteChallenge(contest: Contest, challenge: ContestChallenge) {
    await api.post(`/oi_contest/${contest.slug}/delete_challenge`, {
      challenge: challenge.slug,
    });
  }

  public async getChallenges(contest: Contest) {
    const { data } = await api.get(`/oi_contest/${contest.slug}/challenges`);
    return data;
  }

  public async createResults(
    challenge: ContestChallenge,
    name: string = 'id',
    noFiles?: boolean,
  ) {
    const { data } = await api.post(
      `/oi_contest/challenges/${challenge.id}/create_results`,
      {
        folder_name: name,
        no_files: noFiles,
      },
    );
    return data;
  }

  public async addContribution(
    challenge: ContestChallenge,
    contributionData: any,
  ) {
    const { data } = await api.post(
      `/oi_contest/challenges/${challenge.id}/add_contribution`,
      contributionData,
    );
    return data;
  }

  public async updateContribution(
    challenge: ContestChallenge,
    contribution: ContestContribution,
    contributionData: any,
  ) {
    const { data } = await api.post(
      `/oi_contest/challenges/${challenge.id}/update_contribution`,
      {
        contribution: contribution.id,
        ...contributionData,
      },
    );
    return data;
  }

  public async deleteContribution(
    challenge: ContestChallenge,
    contribution: ContestContribution,
  ) {
    await api.post(
      `/oi_contest/challenges/${challenge.id}/delete_contribution`,
      {
        contribution: contribution.id,
      },
    );
  }

  public async getContributions(challenge: ContestChallenge) {
    const { data } = await api.get(
      `/oi_contest/challenges/${challenge.id}/contributions`,
    );
    return data;
  }

  public async getPollResults(challenge: ContestChallenge) {
    const { data } = await api.get(
      `/oi_contest/challenges/${challenge.id}/poll_results`,
    );
    return data;
  }

  public async addRating(contribution: ContestContribution, ratingData: any) {
    const { data } = await api.post(
      `/oi_contest/contributions/${contribution.id}/add_rating`,
      ratingData,
    );
    return data;
  }

  public async updateRating(
    contribution: ContestContribution,
    rating: ContestRating,
    ratingData: any,
  ) {
    const { data } = await api.post(
      `/oi_contest/contributions/${contribution.id}/update_rating`,
      {
        rating: rating.id,
        ...ratingData,
      },
    );
    return data;
  }

  public async deleteRating(
    contribution: ContestContribution,
    rating: ContestRating,
  ) {
    await api.post(
      `/oi_contest/contributions/${contribution.id}/delete_rating`,
      {
        rating: rating.id,
      },
    );
  }

  public async getRatings(contribution: ContestContribution) {
    const { data } = await api.get(
      `/oi_contest/contributions/${contribution.id}/ratings`,
    );
    return data;
  }

  public async getRatingResults(contribution: ContestContribution) {
    const { data } = await api.get(
      `/oi_contest/contributions/${contribution.id}/rating_results`,
    );
    return data;
  }
}

export default new ContestApi();
