import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';

export const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  insertionPoint: 'insertion-point-jss',
});

export default jss;
