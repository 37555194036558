import { DialogActions as MuiDialogActions } from '@material-ui/core';
import styled from '~/styles';

const DialogActions = styled(MuiDialogActions)`
  padding: 10px 20px;
  padding-top: 10px;
  padding-bottom: 2px;
  flex-wrap: wrap;
  background-color: white;
`;

export default DialogActions;
