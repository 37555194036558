import { action, computed, observable, runInAction } from 'mobx';
import React from 'react';

import { Chat, Settings } from '@material-ui/icons';
import { isUndefined } from 'util';
import AnalyticsIcon from '~/assets/images/icons/analytics.svg';
import BuildingIcon from '~/components/BuildingIcon';
import { dashboardPath, homePath, settingsPath } from '~/constants/routes';
import { AVATAR_BUBBLE, SIDEBAR_COLLAPSED } from '~/constants/storage';
import history from '~/history';
import i18n from '~/locales/i18n';
import { Building } from '~/models/global';
import theme from '~/styles/theme';
import storage from '~/utils/storage';
import toMediaQuery from '~/utils/toMediaQuery';
import { RootStore } from '.';

const mobileMediaQuery = window.matchMedia(
  toMediaQuery(theme.current.breakpoints.down('sm')),
);

export type Section = 'home' | 'general' | 'chat' | 'dashboard' | 'settings';

const SECTION_ICONS = {
  home: <BuildingIcon building="home" size={20} />,
  chat: <Chat style={{ fontSize: '25px' }} />,
  dashboard: <AnalyticsIcon height="19" width="19" />,
  settings: <Settings style={{ fontSize: '25px' }} />,
  general: <BuildingIcon building="home" size={20} />,
};

const SECTION_NAMES = {
  home: 'titles.home',
  chat: 'buildings.chat',
  dashboard: 'buildings.dashboard',
  settings: 'buildings.admin',
  general: 'commons.campus',
};

export interface AppBarConfig {
  building?: Building;
  section?: Section;
  disableDashboard?: boolean;
  raw?: {
    icon?: React.ReactElement;
    label?: string;
    settingsPath?: string | null;
    dashboardPath?: string | null;
    infoKey?: Building | string | null;
    to?: string | null;
  };
}

class UiStore {
  @observable
  public sidebarCollapsed: boolean = storage.get(SIDEBAR_COLLAPSED, true);

  @observable
  public avatarBubble: boolean;

  @observable
  public isMobile: boolean = mobileMediaQuery.matches;

  @observable
  public dialogStack: React.ReactNode[] = [];

  @observable
  public dialogKeys: string[] = [];

  @observable
  public notifications: any[] = [];

  @observable
  public appBarConfig: AppBarConfig | null = null;

  public dialogId: number = 0;

  @observable
  public overlayOpen: boolean = false;

  constructor(private rootStore: RootStore) {
    mobileMediaQuery.addListener((e: MediaQueryListEvent) => {
      runInAction(() => {
        this.isMobile = e.matches;
      });
    });

    history.listen(() => {
      if (this.isMobile) {
        runInAction(() => {
          this.sidebarCollapsed = true;
        });
      }
    });
  }

  public init() {
    this.avatarBubble = this.rootStore.authStore.storage.get(AVATAR_BUBBLE);
  }

  @action.bound
  public setSidebarCollapsed = (collapsed: boolean) => {
    this.sidebarCollapsed = collapsed;
    storage.set(SIDEBAR_COLLAPSED, collapsed, true);
  };

  @action.bound
  public toggleSidebar = () => {
    this.setSidebarCollapsed(!this.sidebarCollapsed);
  };

  @action.bound
  public clickAvatar = () => {
    this.avatarBubble = true;
    this.rootStore.authStore.storage.set(AVATAR_BUBBLE, true);
  };

  @action.bound
  public openDialog = (component: React.ReactNode, key?: string) => {
    if (key && key.length) {
      if (this.dialogKeys.indexOf(key) !== -1) return;
      this.dialogKeys.push(key);
    }

    this.dialogStack.push(component);
  };

  @action.bound
  public closeDialog = (index: number, key?: string) => {
    this.dialogKeys = this.dialogKeys.filter((c) => c !== key);
    this.dialogStack.splice(index, 1);
  };

  @action.bound
  public addNotification = (
    message: string,
    variant: string = 'info',
    options?: any,
  ) => {
    const notif = {
      message,
      key: new Date().getTime() + Math.random(),
      options: {
        variant,
        ...options,
      },
    };
    this.notifications.push(notif);
    return notif;
  };

  @action.bound
  public removeNotification = (key: any) => {
    this.notifications = this.notifications.filter((n) => n.key !== key);
  };

  @action.bound
  public setAppBarConfig(config: AppBarConfig) {
    this.appBarConfig = config;
  }

  @computed
  get appBarData() {
    const config = this.appBarConfig;
    let label = '';
    let icon: React.ReactElement = <div />;
    let settingsTo: string | null = null;
    let dashboardTo: string | null = null;
    let infoKey: string | null = null;
    let to: string | null = null;

    if (config) {
      if (config.building) {
        const buildingParts = config.building ? config.building.split('.') : [];

        label = i18n.formatMessage(`buildings.${buildingParts[0]}`);
        icon = (
          <BuildingIcon
            building={config.building}
            color={theme.current.palette.primary.main}
          />
        );
        settingsTo = this.rootStore.authStore.isFeatureAdmin(config.building)
          ? settingsPath('params', config.building)
          : null;
        dashboardTo = this.rootStore.authStore.isFeatureAdmin(config.building)
          ? dashboardPath(config.building)
          : null;
        infoKey = config.building;
        to = `/${buildingParts[0]}`;

        if (buildingParts.length > 1) {
          label = i18n.formatMessage(`buildings.${config.building}`);
          to = `/${config.building.replace('.', '/')}`;
        }
      }
      if (config.section) {
        label = i18n.formatMessage(
          SECTION_NAMES[config.section] || SECTION_NAMES.general,
        );
        icon = SECTION_ICONS[config.section] || SECTION_ICONS.general;

        if (config.section === 'home') {
          label = this.isMobile
            ? i18n.formatMessage(SECTION_NAMES.home)
            : this.rootStore.organizationStore.organization.config
                .welcome_message || i18n.formatMessage(SECTION_NAMES.home);
          to = homePath();
          infoKey = 'general';
        }
      }

      if (config.raw) {
        if (!isUndefined(config.raw.label)) {
          label = config.raw.label;
        }
        if (!isUndefined(config.raw.icon)) {
          icon = config.raw.icon;
        }
        if (!isUndefined(config.raw.settingsPath)) {
          settingsTo = config.raw.settingsPath;
        }
        if (!isUndefined(config.raw.dashboardPath)) {
          dashboardTo = config.raw.dashboardPath;
        }
        if (!isUndefined(config.raw.infoKey)) {
          infoKey = config.raw.infoKey;
        }
        if (!isUndefined(config.raw.to)) {
          to = config.raw.to;
        }
      }

      if (
        !location.pathname.startsWith(settingsPath('')) &&
        !settingsTo &&
        isUndefined(config.raw?.settingsPath)
      ) {
        settingsTo = this.rootStore.authStore.isAdmin
          ? settingsPath('general')
          : null;
      }

      if (
        !location.pathname.startsWith(dashboardPath('')) &&
        !dashboardTo &&
        isUndefined(config.raw?.dashboardPath)
      ) {
        dashboardTo = this.rootStore.authStore.isModerator
          ? dashboardPath('general')
          : null;
      }
    }
    return {
      label,
      icon,
      settingsTo,
      dashboardTo,
      infoKey,
      subLabel: null,
      subTo: null,
      to: to || homePath(),
      disableDashboard: config?.disableDashboard,
    };
  }
}

export default UiStore;
