import { DateTime } from 'luxon';
import { action, computed, observable, runInAction } from 'mobx';
import { ImageImportResult } from '~/components/ImageImport';
import { randomColor } from '~/constants/colors';
import { MiniBoard } from '~/models/notes';
import { Question } from '~/models/polls';
import { MiniUser } from '~/models/user';
import { Socialable } from '~/models/utils';
import stores from '~/stores';
import { Drive } from '../drive/models';
import { Feed } from '../feed/models';
import { Team } from '../teams/models';
import { WorkForm } from '../workforms/models';
import LabApi from './api';

interface LabFormation {
  slug: string;
  title: string;
  tags: string[];
  owner: MiniUser;
  thumbnail: string;
}

export interface TopicTemplateData {
  id: string;
  goal: string;
  name: string;
  data: Question[];
  open_on_create?: boolean;
  formation_ids?: number[];
  order?: number;
}

export interface StatusConfig {
  delay?: number;
  moderator_only?: boolean;
  disabled?: boolean;
  status: {
    name: string;
    description?: string;
    lab_feature?: string;
    users?: boolean;
  }[];
}

interface LabProcessData {
  topics: TopicTemplateData[];
  disabled?: boolean;
  deleted?: boolean;
  statusConfig?: StatusConfig;
}

export class LabProcess {
  public id: number;

  @observable
  public name: string;

  public feature: string;

  @observable
  public data: LabProcessData;

  constructor(data: any) {
    Object.assign(this, data);
  }

  public async update(data: any, applyDiffs?: boolean) {
    const newData = await LabApi.updateProcess(this, data, applyDiffs);
    Object.assign(this, newData);
  }

  public setData(data: Partial<LabProcessData>) {
    this.update({ data: { ...this.data, ...data } });
  }

  @computed
  get questionCount() {
    let x = 0;
    this.data.topics.forEach((topic) => {
      x += topic.data.length;
    });
    return x;
  }
}

interface TopicData {
  hidden?: boolean;
  needModeration?: boolean;
  validated?: boolean;
  order: number;
}

export class LabProjectTopic {
  public id: number;

  @observable
  public name: string;

  public slug: string;

  @observable
  public data: TopicData;

  public conversation: number;
  public board: MiniBoard;
  public drive: Drive;
  public workform: WorkForm;
  public project: LabProject;

  @observable
  public date_updated: DateTime;

  @observable
  public formations: LabFormation[] = [];

  constructor({ drive, workform, ...data }: any, project: LabProject) {
    Object.assign(this, data);
    this.drive = new Drive(drive);
    this.workform = new WorkForm(workform);
    this.project = project;
  }

  public async loadFormations() {
    if (!this.project.process) return;
    const formations = await LabApi.getFormations(
      this.project.process,
      this.name,
    );
    runInAction(() => {
      this.formations = formations;
    });
  }

  @action
  public async setData(data: Partial<TopicData>) {
    this.data = { ...this.data, ...data };
    await LabApi.updateTopic(this);
  }

  @computed
  get infos() {
    if (!this.project.process) return null;
    return this.project.process.data.topics.find((t) => t.name === this.name);
  }

  @computed
  get order() {
    return this.data.order;
  }

  @computed
  get completed() {
    return this.workform.progress === 1;
  }

  @computed
  get isTopicInit() {
    return this.name === 'topic_init';
  }

  @computed
  get hasChatNotif() {
    const conv = stores.chatStore.conversations.find(
      (c) => c.id === this.conversation,
    );
    if (!conv) return false;
    if (!conv.last_message) return false;
    if (conv.last_message.user === stores.authStore.user.id) return false;
    if (conv.last_message.read) return false;
    return true;
  }

  @computed
  get hasNotesNotif() {
    const oldValue = stores.globalStore.boardStatus[this.board.id];
    if (!oldValue) return this.board.count > 0;
    if (oldValue < this.board.count) return this.board.count - oldValue > 0;
    return false;
  }

  @computed
  get hasDriveNotif() {
    return this.drive.hasNew;
  }

  @computed
  get hasNotif() {
    return this.hasChatNotif || this.hasDriveNotif || this.hasNotesNotif;
  }
}

export class LabProject extends Socialable {
  public id: number;

  @observable
  public name: string;

  @observable
  public slug: string;

  @observable
  public feed: Feed;

  @observable
  public process: LabProcess | null;

  @observable
  public feature: string;

  @observable
  public tags: string[];

  @observable
  public thumbnail: string;

  @observable
  public topics: LabProjectTopic[];

  @observable
  public team: Team;

  @observable
  public published: boolean | null;

  @observable
  public hackathon: boolean;

  @observable
  public status?: string;

  @observable
  public status_message?: string;

  @observable
  public status_users: MiniUser[];

  public hit_count: number;
  public hit: boolean;

  public color: string;

  constructor({ process, feed, topics, team, ...data }: any) {
    super('labproject', data);
    Object.assign(this, data);
    this.topics = topics.map((c: any) => new LabProjectTopic(c, this));
    this.updateTopicOrder();

    this.feed = new Feed(feed);
    this.team = new Team(team);
    this.process = process ? new LabProcess(process) : null;

    this.color = randomColor();
    stores.io.on('labprojecttopic', this.onTopicUpdate);
  }

  public async update(newData: any) {
    const data = await LabApi.updateProject(this, newData);
    this._update(data);
  }

  public async updateThumbnail(thumbnail: ImageImportResult) {
    const data = await LabApi.updateThumbnail(this, thumbnail);
    this._update(data);
  }

  public updateTopicOrder() {
    this.topics = this.topics.sort((a, b) => a.order - b.order);
  }

  @computed
  get progress() {
    let x = 0;
    this.topics
      .filter((c) => !c.data.hidden)
      .forEach((topic) => {
        x += topic.workform.progress / this.topics.length;
      });
    return x;
  }

  @action
  public deleteTopic(topic: LabProjectTopic) {
    LabApi.deleteTopic(topic);
    this.topics = this.topics.filter((c) => c.id !== topic.id);
  }

  @action
  public async setStatus(
    status: string | null,
    message: string,
    members: MiniUser[],
  ) {
    const data = await LabApi.setStatus(this, status, message, members);
    this._update(data);
  }

  @action
  public _update({ process, feed, topics, team, ...data }: any) {
    Object.assign(this, data);
    this.process = process ? new LabProcess(process) : null;
    this.topics = topics.map((c: any) => new LabProjectTopic(c, this));
    this.updateTopicOrder();
  }

  @computed
  get needModeration() {
    return this.published === null;
  }

  @computed
  get labFeature() {
    return stores.organizationStore.getLabConfig(this.feature);
  }

  @computed
  get statusConfig() {
    const data = this.process
      ? this.process?.data.statusConfig
      : this.labFeature.disable_empty_process
      ? undefined
      : this.labFeature.empty_process_status_config;
    if (!data || !data.status || data.status.length === 0 || data.disabled) {
      return null;
    }
    return data;
  }

  @action.bound
  public onTopicUpdate(data: any) {
    const topic = this.topics.find((c) => c.id === data.id);
    if (topic) {
      topic.data = data.data;
    }
  }
}
