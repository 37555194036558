class Storage {
  public get(key: string, asJson: boolean = false) {
    const val = localStorage.getItem(key);
    if (asJson && val) {
      try {
        return JSON.parse(val);
      } catch (error) {
        return val;
      }
    }
    return val;
  }

  public has(key: string) {
    return !!localStorage.getItem(key);
  }

  public set(key: string, value: any | null, asJson: boolean = false) {
    try {
      if (!value) {
        localStorage.removeItem(key);
      } else {
        const savedValue = asJson ? JSON.stringify(value) : value;
        localStorage.setItem(key, savedValue);
      }
    } catch (e) {}
  }
}

export default new Storage();
