import { DateTime } from 'luxon';
import { action, computed, observable } from 'mobx';
import ChallengeApi from '~/api/challenges';
import { MiniUser } from '~/models/user';
import { Socialable, UploadedFile, UploadedImage } from '~/models/utils';
import { Team } from '~/modules/teams/models';
import stores from '~/stores';
import { forceDownload } from '~/utils/downloadFile';
import sanitize from '~/utils/sanitize';
import { Question } from './polls';

export type ChallengeType = 'poll' | 'files' | 'hackathon';

export interface MiniChallenge {
  owner: MiniUser;
  title: string;
  slug: string;
  contribution_count?: number;
  comment_count?: number;
  like_count?: number;
  type: ChallengeType;
}

export interface Rating {
  owner: MiniUser;
  comment: string;
  rating: number;
  files?: UploadedFile[];
}

export class ChallengeContribution extends Socialable {
  public owner: MiniUser;

  public number?: number;

  @observable
  public title: string;

  @observable
  public content: string;

  public images: UploadedImage[];
  public files: UploadedFile[];

  @observable
  public ratings: Rating[];

  @observable
  public final_file: string | null;

  @observable
  public team?: Team;

  @observable
  public lab_project: string | null;

  @observable
  public selected: boolean;

  constructor({ team, ...data }: any) {
    super('challengecontribution', data);
    Object.assign(this, data);
    if (team) {
      this.team = new Team(team);
    }
  }

  public update({ team, ...data }: any) {
    Object.assign(this, data);
    if (team) {
      this.team = new Team(team);
    }
  }

  @computed
  get rated() {
    return this.ratings.some((c) => c.owner.id === stores.authStore.user.id);
  }

  @computed
  get rating() {
    if (this.ratings.length === 0) return 0;
    return this.ratings.reduce((a, b) => a + b.rating, 0) / this.ratings.length;
  }

  @computed
  get isTeamMember() {
    return this.team?.isMember;
  }

  @computed
  get isOwner() {
    return this.team?.isOwner;
  }
}

export interface ChallengeOptions {
  displayNumber: boolean;
  canComment: boolean;
  canLike: boolean;
  canShare: boolean;
  hasEndDate: boolean;
  displayContributions: boolean;
  canLikeCommentContributions: boolean;
  canContributeMultipleTimes?: boolean;
  hideRatingsToParticipants?: boolean;
  hideRatingsToJury?: boolean;
  hideJury?: boolean;
  section_order?: string[];
  labFeature?: string;
  labProcessId?: number;
}

export const DEFAULT_SECTION_ORDER = [
  'brief',
  'jury',
  'rules',
  'rewards',
  'resources',
  'partners',
  'agenda',
  'results',
  'contributions',
];

export interface SectionFile {
  id: number;
  date_created: DateTime;
  section: string;
  file: string;
}

export class Challenge extends Socialable {
  public owner: MiniUser;

  @observable
  public slug: string;

  @observable
  public title: string;

  @observable
  public abstract: string;

  @observable
  public date_end: DateTime | null;

  @observable
  public date_final_file: DateTime | null;

  @observable
  public need_moderation: boolean;

  @observable
  public contribution_count: number;

  @observable
  public contributor_count: number;

  @observable
  public availability_count: number;

  public hit_count: number;

  @observable
  public hit: boolean;

  @observable
  public type: ChallengeType;

  @observable
  public need_change: boolean;

  public images: UploadedImage[];
  public tags: string[];
  public time_like_count?: number;
  public time_comment_count?: number;
  public time_contribution_count?: number;

  @observable
  public final_file: string | null;

  @observable
  public published: boolean;

  @observable
  public ended: boolean;

  @observable
  public end_message: string;

  @observable
  public rules: string;

  @observable
  public description: string;

  @observable
  public jury: string;

  @observable
  public rewards: string;

  @observable
  public partners: string;

  @observable
  public resources: string;

  @observable
  public agenda: string;

  @observable
  public sections: string[] = [];

  @observable
  public options: ChallengeOptions;

  @observable
  public banner: string;

  @observable
  public published_results: boolean;

  @observable
  public show_contributions: boolean;

  @observable
  public data: Question[] = [];

  @observable
  public result_files: UploadedFile[] = [];

  @observable
  public section_files: SectionFile[] = [];

  @observable
  public jury_members: MiniUser[] = [];

  @observable
  public contributed: boolean;

  @observable
  public target_groups: string[];

  constructor(data: any) {
    super('challenge', data);
    Object.assign(this, data);
    this.end_message = sanitize(data.end_message || '');
    this.rules = sanitize(this.rules);
    this.description = sanitize(this.description);
    this.jury = sanitize(this.jury);
    this.partners = sanitize(this.partners);
    this.resources = sanitize(this.resources);
    this.agenda = sanitize(this.agenda);
  }

  public update(data: any) {
    Object.assign(this, data);
  }

  get dateEnded() {
    if (!this.date_end || !this.options.hasEndDate) return false;
    return DateTime.local() > this.date_end;
  }

  @computed
  get isPoll() {
    return this.type === 'poll';
  }

  @computed
  get isHackathon() {
    return this.type === 'hackathon';
  }

  @computed
  get finalFileError() {
    // return error message based on what's wrong
    if (this.date_final_file && DateTime.local() < this.date_final_file) {
      return 'challenges.hackathon.submit_file.tooltips.not_yet';
    }

    if (this.dateEnded) {
      return 'challenges.hackathon.submit_file.tooltips.too_late';
    }

    return null;
  }

  @computed
  get isInTargetGroups() {
    if (this.target_groups.length === 0) return true;
    return this.target_groups.some(
      (g) => stores.authStore.user.campus_groups.indexOf(g) !== -1,
    );
  }

  public hasSection(section: string) {
    return this.sections.indexOf(section) !== -1;
  }

  @computed
  get hasJury() {
    return this.hasSection('jury') && this.jury_members.length !== 0;
  }

  get thumbnail() {
    return this.banner;
  }

  @computed
  get isOwner() {
    return this.owner.id === stores.authStore.user.id;
  }

  @computed
  get isJuryMember() {
    return this.jury_members.some((m) => m.id === stores.authStore.user.id);
  }

  public downloadResults = async () => {
    const csv = await ChallengeApi.getCSVResult(this);
    const content = `data:text/csv;charset=utf-8,\uFEFF${csv}`;

    const data = encodeURI(content);

    forceDownload(data, `${this.title}-results.csv`);
  };
}

export class Availability {
  @observable
  public user: MiniUser;

  @observable
  public content: string;

  @observable
  public deleted: boolean = false;

  constructor(data: any) {
    Object.assign(this, data);
  }

  @action
  public delete() {
    this.deleted = true;
  }

  public update(data: any) {
    Object.assign(this, data);
  }
}
