import { Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import i18n from '~/locales/i18n';
import { StoreContext } from '~/stores';
import styled from '~/styles';
import confirm from '../dialogs/Confirm';

const Wrapper = styled(Typography)`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${(p) => p.theme.palette.primary.main};
  color: white;
  box-shadow: 0 -4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  z-index: 1005;
  text-align: center;
  box-sizing: border-box;
  font-size: 17px;
  font-weight: 600;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    color: white;
    font-weight: bold;
    margin-left: 5px;
  }

  @media (display-mode: standalone) {
    display: none;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    display: none;
  }
`;

const Closer = styled.div`
  position: fixed;
  right: 20px;
  bottom: 17px;
  z-index: 1010;
  color: white;

  @media (display-mode: standalone) {
    display: none;
  }

  ${(p) => p.theme.breakpoints.up('md')} {
    display: none;
  }
`;

function InstallPrompt() {
  const { globalStore } = useContext(StoreContext);
  // tslint:disable: campus-use-i18n
  return (
    <div>
      <Wrapper onClick={globalStore.openPrompt}>
        <FormattedMessage id="profile.parameters.notifications.application.setup" />{' '}
      </Wrapper>

      <Closer
        onClick={async () => {
          const value = await confirm(
            i18n.formatMessage('commons.confirm'),
            i18n.formatMessage('dialogs.setup_app.description'),
            i18n.formatMessage('dialogs.setup_app.next_time'),
            i18n.formatMessage('commons.continue'),
          );
          globalStore.closeInstallPrompt(value);
        }}
      >
        <i className="fas fa-times" />
      </Closer>
    </div>
  );
  // tslint:enable: campus-use-i18n
}

export default InstallPrompt;
