import { computed, observable, runInAction } from 'mobx';
import { ImageImportResult } from '~/components/ImageImport';
import { MiniUser } from '~/models/user';
import CommunitiesApi from './api';
import { Community } from './models';

class CommunitiesStore {
  public communities = observable.map<string, Community>();

  public deletedCommunities: number[] = [];

  public async loadCommunities() {
    const communities = await CommunitiesApi.getCommunities();
    runInAction(() => {
      for (const i of communities.results) {
        if (!this.communities.has(i.slug)) {
          this.communities.set(i.slug, i);
        }
      }
    });
  }

  public async loadCommunity(slug: string) {
    if (this.communities.has(slug)) return this.communities.get(slug)!;
    const community = await CommunitiesApi.getCommunity(slug);
    this.communities.set(community.slug, community);
    return community;
  }

  public async createCommunity(
    name: string,
    description: string,
    image: ImageImportResult | null,
    tags: string[],
    private_: boolean,
    initialMembers: MiniUser[],
  ) {
    const community = await CommunitiesApi.createCommunity(
      name,
      description,
      image,
      tags,
      private_,
      initialMembers,
    );
    this.communities.set(community.slug, community);
    return community;
  }

  public async deleteCommunity(community: Community) {
    CommunitiesApi.deleteCommunity(community);
    this.communities.delete(community.slug);
    this.deletedCommunities.push(community.id);
  }

  @computed
  get communityList() {
    return [...this.communities.values()].filter(
      (c) => this.deletedCommunities.indexOf(c.id) === -1,
    );
  }
}

export default CommunitiesStore;
