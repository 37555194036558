import { action, computed, observable, runInAction } from 'mobx';

import ChallengesApi from '~/api/challenges';
import { Challenge } from '~/models/challenges';

class ChallengesStore {
  public challenges = observable.map<string, Challenge>();

  @observable
  public results: string[] = [];

  @observable
  public search: string = '';

  @observable
  public tags: string[] = [];

  @observable
  public sort: string = 'date_end';

  @observable
  public deletedChallenges: string[] = [];

  @observable
  public next: string | null = null;
  public previous: string | null = null;
  public currentUrl: string | null;

  public async loadChallenges(force?: boolean) {
    if (force) this.resetUrl();

    if (!force && this.resultItems.length) return this.resultItems;
    const newChallenges = await ChallengesApi.getChallenges(
      null,
      this.queryParams,
    );
    runInAction(() => {
      for (const challenge of newChallenges.results) {
        this.challenges.set(challenge.slug, challenge);
      }
      this.results = newChallenges.results.map((challenge) => challenge.slug);
      this.next = newChallenges.next;
      this.previous = newChallenges.previous;
    });

    return this.resultItems;
  }

  public async loadNotPublished() {
    const notPublished = await ChallengesApi.getNotPublished();
    runInAction(() => {
      for (const challenge of notPublished) {
        this.challenges.set(challenge.slug, challenge);
      }
    });

    return notPublished;
  }

  public async loadPopular(time: string) {
    const newIdeaboxItems = await ChallengesApi.getPopular(time);
    return newIdeaboxItems;
  }

  public async loadChallenge(slug: string, force: boolean = false) {
    if (force || !this.challenges.has(slug)) {
      const item = await ChallengesApi.getChallenge(slug);
      if (item) {
        this.challenges.set(item.slug, item);
      }
      return item;
    }
    return this.challenges.get(slug);
  }

  @action
  public async deleteChallenge(challenge: Challenge) {
    this.results = this.results.filter((c) => c !== challenge.slug);
    this.deletedChallenges.push(challenge.slug);
    await ChallengesApi.deleteChallenge(challenge);
  }

  public async loadMore() {
    const newChallenges = await ChallengesApi.getChallenges(
      this.currentUrl,
      this.queryParams,
    );
    runInAction(() => {
      for (const item of newChallenges.results) {
        this.challenges.set(item.slug, item);
      }
      this.results.push(...newChallenges.results.map((item) => item.slug));
      this.next = newChallenges.next;
      this.previous = newChallenges.previous;
    });

    return this.resultItems;
  }

  @action.bound
  public async loadNextChallenges() {
    this.currentUrl = this.next;
    return this.loadMore();
  }

  @computed
  get queryParams() {
    return {
      tags__name: this.tags.join(','),
      q: this.search,
      hit: this.sort === 'not_hit' ? false : undefined,
      ordering: this.sort !== 'not_hit' ? this.sort : undefined,
    };
  }

  @computed
  get resultItems() {
    return this.results
      .map((slug) => this.challenges.get(slug)!)
      .filter((item) => !!item);
  }

  @action
  public setSearch = (search: string) => {
    this.search = search;
    this.loadChallenges(true);
  }

  @action
  public setTags = (tags: string[]) => {
    this.tags = tags;
    this.loadChallenges(true);
  }

  @action
  public setSort = (sort: string) => {
    this.sort = sort;
    this.loadChallenges(true);
  }

  @computed
  get hasNext() {
    return this.next && this.next.length !== 0;
  }

  @action.bound
  public resetUrl() {
    this.next = null;
    this.previous = null;
    this.currentUrl = null;
  }

  @action.bound
  public reset() {
    this.tags = [];
    this.sort = 'date_end';
    this.search = '';
    this.results = [];
    this.loadChallenges(true);
  }

  @computed
  get challengeList() {
    return [...this.challenges.values()].filter(
      (c) => this.deletedChallenges.indexOf(c.slug) === -1,
    );
  }
}

export default ChallengesStore;
