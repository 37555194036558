import RealtimeApi from '~/api/realtime';

export function askNotificationPermission() {
  if (typeof Notification === 'undefined') return;
  if (Notification.permission !== 'granted') {
    Notification.requestPermission();
  }
}

function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  // tslint:disable-next-line:no-increment-decrement
  for (let i = 0; i < rawData.length; i++) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

function loadVersionBrowser(userAgent: string) {
  const ua = userAgent;
  let tem;
  let M =
    ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) ||
    [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: tem[1] || '' };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) {
      return { name: 'Opera', version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  tem = ua.match(/version\/(\d+)/i);
  if (tem != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: M[1],
  };
}

const applicationServerKey: string =
  // tslint:disable-next-line:max-line-length
  'BODlcRBT_J3DyAVjB45w3fO9iQGTA1Mr1SB6qMbXnsh7JKAfvaEH1-xcgAXV2AFESBos12CLwsEl1InVKjvdy5o';

export const unsubscribe = async () => {
  try {
    const reg = await navigator.serviceWorker.getRegistration();
    if (!reg) {
      return;
    }

    const sub = await reg.pushManager.getSubscription();
    if (!sub) return;

    await sub.unsubscribe();
    const endpointParts = sub.endpoint.split('/');
    const subId = endpointParts[endpointParts.length - 1];
    await RealtimeApi.unsubscribe(subId);
    // tslint:disable-next-line: no-empty
  } catch (e) {}
};

export const subscribe = async () => {
  try {
    const reg = await navigator.serviceWorker.getRegistration();
    if (!reg) {
      return;
    }
    const options = {
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(applicationServerKey),
    };
    const browser = loadVersionBrowser(navigator.userAgent);
    const sub = await reg.pushManager.subscribe(options);
    const endpointParts = sub.endpoint.split('/');
    const registrationId = endpointParts[endpointParts.length - 1];
    const data = {
      registrationId,
      endpoint: sub.endpoint,
      browser: browser.name.toUpperCase(),
      keys: {
        p256dh: btoa(
          String.fromCharCode.apply(
            null,
            new Uint8Array(sub.getKey('p256dh')!),
          ),
        ),
        auth: btoa(
          String.fromCharCode.apply(null, new Uint8Array(sub.getKey('auth')!)),
        ),
      },
      name: browser.name.toUpperCase(),
      active: true,
      cloud_message_type: 'FCM',
    };
    await RealtimeApi.subscribe(data);
    // tslint:disable-next-line: no-empty
  } catch (e) {}
};
