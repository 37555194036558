import api from '~/api/api';
import { Answer } from '~/models/polls';
import { Poll } from './models';

class PollsApi {
  public async contribute(poll: Poll, data: Answer[]) {
    await api.post(`/polls/${poll.id}/contribute`, {
      data,
    });
  }

  public async getResults(poll: Poll) {
    const { data } = await api.get(`/polls/${poll.id}/results`);
    return data;
  }

  public async getCSVResult(poll: Poll) {
    const { data } = await api.get(`/polls/${poll.id}/csv_results`);
    return data.csv as string;
  }
}

export default new PollsApi();
