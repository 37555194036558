import Axios from 'axios';
import api from '~/api/api';
import { showProgress } from '~/components/dialogs';
import { FileImportResult } from '~/models/utils';
import stores from '~/stores';
import getFileSize from '~/utils/getFileSize';
import { Drive, DriveFile } from './models';

class DriveApi {
  public async getDriveFiles(drive: Drive) {
    const { data } = await api.get(`/drive/${drive.id}/files`);
    return data as DriveFile[];
  }

  public async deleteFile(drive: Drive, fileId: number) {
    await api.delete(`/drive/${drive.id}/delete_file`, {
      params: {
        file_id: fileId,
      },
    });
  }

  public async uploadFile(drive: Drive, file: FileImportResult) {
    const formData = new FormData();
    formData.append('file', file.blob!, file.name);
    const isLarge = getFileSize(file.blob!) > 1;
    let data;
    if (isLarge) {
      data = await this.uploadLargeFile(drive, file);
    } else {
      data = (await api.post(`/drive/${drive.id}/upload_file`, formData)).data;
    }

    return data as DriveFile;
  }

  private async uploadLargeFile(drive: Drive, file: FileImportResult) {
    if (!file.blob) return;
    const source = Axios.CancelToken.source();
    const upload = stores.progressStore.startUpload(file.name, source.cancel);
    showProgress(upload);

    const { data } = await api.post(`/drive/${drive.id}/upload_large`, {
      filename: file.name,
      content_type: file.blob.type,
      size: file.blob.size,
    });

    const formData = new FormData();

    Object.keys(data.s3.fields).forEach((key) => {
      formData.append(key, data.s3.fields[key]);
    });

    formData.append('file', file.blob);

    try {
      await Axios.post(data.s3.url, formData, {
        headers: {
          Authorization: '',
        },
        onUploadProgress: (event) => {
          upload.updateProgress(event.loaded / event.total);
        },
        cancelToken: source ? source.token : undefined,
      });
    } finally {
      stores.progressStore.endUpload(upload);
    }

    return data.file;
  }
}

export default new DriveApi();
