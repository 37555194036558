const env = {
  API_URL: process.env.API_URL,
};

export default (key: string): string => {
  const val = env[key];
  if (val) {
    return val;
  }
  throw new Error(`${key} not found in env !`);
};
