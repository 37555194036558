import { Button } from '@material-ui/core';
import { action, computed, observable, runInAction } from 'mobx';
import React from 'react';
import ChatApi from '~/api/chat';
import { chatPath } from '~/constants/routes';
import history from '~/history';
import i18n from '~/locales/i18n';
import { ChatFile, Conversation, Message } from '~/models/chat';
import { MiniUser } from '~/models/user';
import { FileImportResult } from '~/models/utils';
import { RootStore } from '~/stores';

class ChatStore {
  @observable
  public conversations: Conversation[] = [];

  public messages = observable.map<number, Message[]>();

  constructor(
    private rootStore: RootStore,
    private io: SocketIOClient.Socket,
  ) {}

  public init() {
    this.loadConversations().then(() => {
      if (this.hasNew && window.location.pathname.indexOf(chatPath) === -1) {
        this.displayNotif();
      }
    });

    this.io.on('message', (id: number) => {
      this.loadMessage(id);
    });

    this.io.on('conversation', async (conversationId: number) => {
      this.loadConversation(conversationId);
    });
  }

  @action
  public async loadConversations() {
    const convs = await ChatApi.getConversations();
    runInAction(() => {
      this.conversations = convs;
    });
  }

  @action
  public async loadConversation(id: number, force = false) {
    const found = this.conversations.find((i) => i.id === id);
    if (!force && found) return;
    const conv = await ChatApi.getConversation(id);
    runInAction(() => {
      if (this.conversations.find((c) => c.id === conv.id)) {
        this.conversations = this.conversations.map((c) =>
          c.id === conv.id ? conv : c,
        );
      } else {
        this.conversations.push(conv);
      }
    });
  }

  @action
  public async loadMessages(conversation: Conversation) {
    const messages = await ChatApi.getMessages(conversation.id);
    runInAction(() => {
      this.messages.set(conversation.id, messages);
    });
  }

  @action
  public async loadMessage(id: number) {
    const message = await ChatApi.getMessage(id);
    if (message) {
      this.handleNewMessage(message);
    }
  }

  @action
  public handleNewMessage(message: Message) {
    this.messages.set(message.conversation, [
      ...(this.messages.get(message.conversation) || []),
      message,
    ]);
    const conv = this.conversations.find((c) => c.id === message.conversation);
    if (conv) {
      conv.last_message = message;
    }
  }

  public displayNotif = () => {
    if (this.rootStore.authStore.user.user_type.startsWith('external')) return;
    this.rootStore.uiStore.addNotification(
      i18n.formatMessage('notifications.new_messages'),
      'info',
      {
        action: (hide: any) => (
          <Button
            color="inherit"
            size="small"
            onClick={() => {
              history.push(chatPath);
              hide();
            }}
          >
            {i18n.formatMessage('notifications.new_messages.action')}
          </Button>
        ),
      },
    );
  };

  @action
  public async sendMessage(
    conv: Conversation,
    content: string,
    file?: ChatFile,
  ) {
    const message = await ChatApi.sendMessage(conv.id, content, file);
    this.handleNewMessage(message);
  }

  @action
  public async setRead(conversation: number) {
    const messages = this.messages.get(conversation);
    if (messages && messages.length !== 0) {
      const conv = this.conversations.find((c) => c.id === conversation);
      if (conv) {
        const lastMessage = conv.last_message;
        if (!lastMessage || lastMessage.read) return;
        await ChatApi.setRead(lastMessage.id);
        runInAction(() => {
          if (conv.last_message) {
            conv.last_message = { ...conv.last_message, read: true };
          }
        });
      }
    }
  }

  @action
  public async startConversation(user: number) {
    const conv = await ChatApi.startConversation(user);
    if (this.conversations.find((c) => c.id === conv.id)) {
      this.conversations = this.conversations.map((c) =>
        c.id === conv.id ? conv : c,
      );
    } else {
      this.conversations.push(conv);
    }
    return conv;
  }

  @action
  public async startGroupConversation(
    name: string,
    icon: FileImportResult | null,
    users: MiniUser[],
  ) {
    const conv = await ChatApi.startGroupConversation(name, icon, users);
    runInAction(() => {
      this.conversations.push(conv);
    });
    return conv;
  }

  @action
  public async updateConversation(
    conversation: Conversation,
    name: string | null,
    icon: FileImportResult | null,
    users: MiniUser[] | null,
  ) {
    const conv = await ChatApi.updateConversation(
      conversation,
      name,
      icon,
      users,
    );
    return conv;
  }

  @action
  public async leaveConversation(conversation: Conversation) {
    const conv = await ChatApi.leaveConversation(conversation);
    runInAction(() => {
      this.conversations = this.conversations.filter(
        (c) => c.id !== conversation.id,
      );
    });
    return conv;
  }

  @computed
  get hasNew() {
    return this.conversations.some((c) => c.hasUnreadMessages);
  }

  @computed
  get sortedConversations() {
    return this.conversations
      .slice()
      .sort((c1, c2) => {
        const c1Date = c1.last_message
          ? c1.last_message.date_created
          : c1.date_created;
        const c2Date = c2.last_message
          ? c2.last_message.date_created
          : c2.date_created;
        return c1Date > c2Date ? -1 : 1;
      })
      .filter(
        (c) =>
          c.one_to_one ||
          (!!c.last_message && Object.keys(c.data).length !== 0) ||
          c.isGroup,
      );
  }
}

export default ChatStore;
