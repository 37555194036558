import React, { Fragment } from 'react';
import { Switch } from 'react-router';

function flatten(target: any, children: any) {
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === Fragment) {
        flatten(target, (child.props as any).children);
      } else {
        target.push(child);
      }
    }
  });
}

export default function FragmentSupportingSwitch({ children }: any) {
  const flattenedChildren: any = [];
  flatten(flattenedChildren, children);
  return React.createElement.apply(
    React,
    [Switch, null].concat(flattenedChildren),
  );
}
