import { CircularProgress } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/browser';
import { ThemeProvider } from 'emotion-theming';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import LuxonUtils from 'material-ui-pickers/utils/luxon-utils';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { ErrorInfo } from 'react';
import { Helmet } from 'react-helmet';
import { IntlProvider } from 'react-intl';
import JssProvider from 'react-jss/lib/JssProvider';

import Router from '~/components/Router';

import store from '~/stores';

import { SnackbarProvider } from 'notistack';
import CrashImg from '~/assets/images/crash.png';
import MaintenanceImg from '~/assets/images/maintenance.png';
import i18n from '~/locales/i18n';
import styled from '~/styles';
import jss, { generateClassName } from '~/styles/jss';
import theme from '~/styles/theme';
import getOrganizationSlug from '~/utils/getOrganizationSlug';
import Notifier from './utils/Notifier';

(window as any).store = store;

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10000;
  background-color: white;
`;

@observer
class App extends React.Component {
  @observable
  public errorMessage: string = '';

  @action
  public componentDidCatch(error: Error, errInfo: ErrorInfo) {
    // tslint:disable-next-line:no-console
    console.error(error);
    // tslint:disable-next-line:no-console
    console.error(errInfo);
    this.errorMessage = errInfo.componentStack.toString();

    try {
      Sentry.withScope((scope) => {
        scope.setTag('campus', getOrganizationSlug());
        scope.setTag('crash-ui', 'true');
        Object.keys(errInfo).forEach((key) => {
          scope.setExtra(key, errInfo[key]);
        });
        Sentry.captureException(error);
      });
    } catch (e) {}
  }

  @computed
  get hasError() {
    return this.errorMessage && this.errorMessage.length !== 0;
  }

  public render() {
    if (this.hasError) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '30px',
            boxSizing: 'border-box',
            backgroundColor: 'white',
          }}
        >
          <img src={CrashImg} style={{ width: '100%', maxWidth: '700px' }} />
        </div>
      );
    }

    if (store.organizationStore.loading || store.authStore.loading) {
      return (
        <Loader>
          <CircularProgress />
        </Loader>
      );
    }

    if (store.organizationStore.organization.maintenance) {
      return (
        <div
          style={{
            height: '100%',
            width: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '30px',
            boxSizing: 'border-box',
            backgroundColor: 'white',
          }}
        >
          <img
            src={MaintenanceImg}
            style={{ width: '100%', maxWidth: '700px' }}
          />
        </div>
      );
    }

    return (
      <IntlProvider locale={i18n.locale} messages={i18n.messages}>
        <>
          <Helmet
            titleTemplate={`%s | ${store.organizationStore.organization.name} | Campus`}
          >
            {store.organizationStore.organization.favicon && [
              <link
                key="icon-1"
                rel="icon"
                type="image/png"
                href={store.organizationStore.organization.favicon}
              />,
              <link
                key="icon-2"
                rel="apple-touch-icon"
                sizes="60x60"
                href={store.organizationStore.organization.favicon}
              />,
              <link
                key="icon-3"
                rel="apple-touch-icon"
                sizes="57x57"
                href={store.organizationStore.organization.favicon}
              />,
              <link
                key="icon-4"
                rel="apple-touch-icon"
                sizes="72x72"
                href={store.organizationStore.organization.favicon}
              />,
              <link
                key="icon-5"
                rel="apple-touch-icon"
                sizes="76x76"
                href={store.organizationStore.organization.favicon}
              />,
              <link
                key="icon-6"
                rel="apple-touch-icon"
                sizes="114x114"
                href={store.organizationStore.organization.favicon}
              />,
              <link
                key="icon-7"
                rel="apple-touch-icon"
                sizes="120x120"
                href={store.organizationStore.organization.favicon}
              />,
              <link
                key="icon-8"
                rel="apple-touch-icon"
                sizes="144x144"
                href={store.organizationStore.organization.favicon}
              />,
              <link
                key="icon-9"
                rel="apple-touch-icon"
                sizes="152x152"
                href={store.organizationStore.organization.favicon}
              />,
              <link
                key="icon-10"
                rel="apple-touch-icon"
                sizes="180x180"
                href={store.organizationStore.organization.favicon}
              />,
              <link
                key="icon-11"
                rel="icon"
                type="image/png"
                sizes="192x192"
                href={store.organizationStore.organization.favicon}
              />,
              <link
                key="icon-12"
                rel="icon"
                type="image/png"
                sizes="32x32"
                href={store.organizationStore.organization.favicon}
              />,
              <link
                key="icon-13"
                rel="icon"
                type="image/png"
                sizes="96x96"
                href={store.organizationStore.organization.favicon}
              />,
              <link
                key="icon-14"
                rel="icon"
                type="image/png"
                sizes="16x16"
                href={store.organizationStore.organization.favicon}
              />,
            ]}
          </Helmet>
          <JssProvider jss={jss} generateClassName={generateClassName}>
            <MuiThemeProvider theme={theme.current}>
              <ThemeProvider theme={theme.current}>
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                  <SnackbarProvider
                    preventDuplicate
                    style={{ marginTop: '56px' }}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <>
                      <Notifier />
                      <Router />
                    </>
                  </SnackbarProvider>
                </MuiPickersUtilsProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </JssProvider>
        </>
      </IntlProvider>
    );
  }
}

export default App;
