import React from 'react';
import styled from '~/styles';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const Dot = styled.div<{ selected: boolean }>`
  margin: 5px;
  background-color: ${(p) =>
    p.selected ? p.theme.palette.primary.main : p.theme.palette.grey[300]};
  height: 10px;
  width: 10px;
  border-radius: 50%;
  transition: background-color 0.3s;
  cursor: pointer;
`;

interface Props {
  count: number;
  value: number;
  onChange: (value: number) => void;
}

function Dots(props: Props) {
  return (
    <Wrapper>
      {Array(props.count)
        .fill(0)
        .map((_, i) => (
          <Dot selected={props.value === i} onClick={() => props.onChange(i)} />
        ))}
    </Wrapper>
  );
}

export default Dots;
