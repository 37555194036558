import { CircularProgress } from '@material-ui/core';
import React from 'react';
import styled from '~/styles';

const Wrapper = styled.div`
  margin-top: 30px;
  text-align: center;
`;

class Loader extends React.Component {
  public render() {
    return (
      <Wrapper>
        <CircularProgress color="primary" />
      </Wrapper>
    );
  }
}

export default Loader;
