function randomID() {
  return (
    // tslint:disable-next-line: prefer-template
    '_' +
    Math.random()
      .toString(36)
      .substr(2, 9)
  );
}

export default randomID;
