import React from 'react';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import styled from '~/styles';

const Wrapper = styled(MuiDialogTitle)`
  padding: 20px;
  padding-bottom: 15px;

  h6 {
    font-size: 22px;
    line-height: unset;
    font-weight: 600;
    margin-top: -3px;
    color: ${(p) => p.theme.palette.primary.main};
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    h6 {
      font-weight: 500;
      font-size: 19px;
    }
  }
`;

interface Props {
  onClose?: () => void;
}

class DialogTitle extends React.Component<Props> {
  public render() {
    return (
      <Wrapper>
        {this.props.children}
        {this.props.onClose ? (
          <CloseIcon
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={this.props.onClose}
          />
        ) : null}
      </Wrapper>
    );
  }
}

export default DialogTitle;
