import { DateTime } from 'luxon';
import { computed, observable } from 'mobx';
import ChatApi from '~/api/chat';
import { ImageImportResult } from '~/components/ImageImport';
import { MiniUser } from '~/models/user';
import { FileImportResult } from '~/models/utils';
import { Team } from '~/modules/teams/models';
import stores from '~/stores';
import downloadFile from '~/utils/downloadFile';

export interface Message {
  id: number;
  conversation: number;
  user: number | null;
  read: boolean;
  date_created: DateTime;
  content?: string;
  file?: string;
}

export class Conversation {
  public id: number;

  @observable
  public _members: MiniUser[];

  @observable
  public last_message?: Message;

  public date_created: DateTime;
  public one_to_one: boolean;

  @observable
  public name: string;

  @observable
  public icon: string;

  @observable
  public data?: any;

  @observable
  public team: Team;

  constructor({ team, members, ...data }: any) {
    Object.assign(this, data);
    if (team) {
      this.team = new Team(team);
    }
    this._members = members;
  }

  public update({ team, members, ...data }: any) {
    Object.assign(this, data);
    if (team) {
      this.team = new Team(team);
    }
    this._members = members;
  }

  @computed
  get members() {
    if (this.team) return this.team.members;
    return this._members;
  }

  @computed
  get isBot() {
    return this.data && this.data.type === 'bot';
  }

  @computed
  get isGroup() {
    return this.data && this.data.type === 'group';
  }

  @computed
  get hasUnreadMessages() {
    return (
      this.last_message &&
      this.last_message.user !== stores.authStore.user.id &&
      !this.last_message.read
    );
  }

  public downloadHistory = async () => {
    const data = await ChatApi.getHistory(this.id);
    downloadFile(
      URL.createObjectURL(
        new Blob([data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      ),
      `conversation_${(this.data.project_name
        ? `${this.data.name}-${this.data.project_name}`
        : this.data.name) || this.id}.xlsx`,
    );
  };
}

export type ChatFile = FileImportResult | ImageImportResult | null;
