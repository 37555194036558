import { computed, observable } from 'mobx';
import { randomColor } from '~/constants/colors';
import { MiniUser } from '~/models/user';
import { Socialable, UploadedFile } from '~/models/utils';
import stores from '~/stores';
import sanitize from '~/utils/sanitize';

export interface MiniAcademyFormation {
  owner: MiniUser;
  title: string;
  slug: string;
  id: number;
  like_count?: number;
  feature: string;
}

export class AcademyFormation extends Socialable {
  public owner: MiniUser;
  public slug: string;
  public title: string;
  public abstract: string;
  public content: string;
  public thumbnail: string;
  public tags: string[];
  public published: boolean;
  public files: UploadedFile[];
  public organization: number;
  public external: boolean;
  public hit_count: number;
  public type: string;
  public feature: string;

  public color: string;

  @observable
  public hit: boolean;

  @observable
  public time_like_count?: number;

  constructor(data: any) {
    super('academyformation', data);
    Object.assign(this, data);
    this.color = randomColor();
    this.content = sanitize(this.content);
  }

  @computed
  get isOwner() {
    return stores.authStore.user.id === this.owner.id;
  }

  @computed
  get academyConfig() {
    return stores.organizationStore.getAcademyConfig(this.feature);
  }
}
