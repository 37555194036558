import api from '~/api/api';
import { PaginatedResult } from '~/models/api';
import { CurrentUser, MiniUser } from '~/models/user';

async function getUsers(
  url: string | null,
  params: any,
): Promise<PaginatedResult<MiniUser>> {
  try {
    const { data } = await api.get(url || '/users/search', { params });
    return data;
  } catch (e) {
    return { next: '', previous: '', results: [] };
  }
}

async function getTopUsers(time: string): Promise<MiniUser[]> {
  const { data } = await api.get('/users/top', { params: { time } });
  return data;
}

async function getUser(id: number): Promise<CurrentUser> {
  const { data: user } = await api.get(`/users/${id}`);
  return user;
}

export default {
  getUsers,
  getUser,
  getTopUsers,
};
