import { runInAction } from 'mobx';
import api from '~/api/api';
import { ImageImportResult } from '~/components/ImageImport';
import { PaginatedResult } from '~/models/api';
import { MiniUser } from '~/models/user';
import { Community } from './models';

class CommunitiesApi {
  public async getCommunities(): Promise<PaginatedResult<Community>> {
    const { data } = await api.get('/communities', {
      params: { ps: 10000 },
    });
    return { ...data, results: data.results.map((c: any) => new Community(c)) };
  }

  public async getCommunity(slug: string): Promise<Community> {
    const { data } = await api.get(`/communities/${slug}`);
    return new Community(data);
  }

  public async createCommunity(
    name: string,
    description: string,
    image: ImageImportResult | null,
    tags: string[],
    private_: boolean,
    initialMembers: MiniUser[],
  ): Promise<Community> {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('private', private_ ? 'true' : 'false');
    if (image) {
      formData.append('image', image.blob!, image.name);
    }
    if (tags.length > 0) {
      tags.forEach((tag) => formData.append('allowed_tags', tag));
    }

    initialMembers.forEach((m) => {
      formData.append('initial_members', m.id.toString());
    });

    const { data } = await api.post('/communities', formData);

    return new Community(data);
  }

  public async updateCommunity(
    community: Community,
    name: string,
    description: string,
    tags: string[],
    private_: boolean,
    groups: string[],
    image: ImageImportResult | null,
  ): Promise<Community> {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('private', private_ ? 'true' : 'false');

    if (image) {
      formData.append('image', image.blob!, image.name);
    }

    if (tags.length > 0) {
      tags.forEach((tag) => formData.append('allowed_tags', tag));
    }

    if (groups.length > 0) {
      groups.forEach((group) => formData.append('groups', group));
    }

    const { data } = await api.patch(
      `/communities/${community.slug}`,
      formData,
    );

    community.update(data);
    return community;
  }

  public async requestInvite(community: Community) {
    await api.post(`/communities/${community.slug}/request_invite`);
    runInAction(() => {
      community.requested = true;
    });
  }
  public async deleteInvitationRequest(community: Community, user: MiniUser) {
    await api.post(`/communities/${community.slug}/delete_invitation_request`, {
      user: user.id,
    });
  }

  public async getInvitationRequests(
    community: Community,
  ): Promise<MiniUser[]> {
    const { data } = await api.get(
      `/communities/${community.slug}/invitation_requests`,
    );
    return data.map((c: any) => c.user);
  }

  public async deleteCommunity(community: Community) {
    await api.delete(`/communities/${community.slug}`);
  }

  public async addGroupMembers(
    community: Community,
    groups: string[],
  ): Promise<MiniUser[]> {
    const { data } = await api.post(
      `/communities/${community.slug}/add_group_members`,
      {
        groups,
      },
    );
    data.forEach((c: MiniUser) => {
      community.team.members.push(c);
    });
    return data;
  }
}

export default new CommunitiesApi();
