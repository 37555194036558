export const getSsoLogoutUrl = (slug: string, authBackend: string) => {
  return `${process.env.API_URL!.replace(
    '{slug}',
    slug,
  )}/${authBackend}/logout`;
};

export const getSsoLoginUrl = (slug: string, authBackend: string) => {
  return `${process.env.API_URL!.replace('{slug}', slug)}/${authBackend}/login`;
};
