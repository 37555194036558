import React from 'react';
import { BUILDING_ONBOARDING } from '~/constants/storage';
import stores from '~/stores';
import lazyRetry from '~/utils/lazyRetry';

type ONBOARDING_KEY =
  | 'aca'
  | 'bai'
  | 'challenge'
  | 'lab'
  | 'intra'
  | 'hackathon'
  | 'oi_challenge'
  | 'oi_partnership'
  | 'new_lab';

const ONBOARDING_COUNTS = {
  aca: 4,
  bai: 3,
  challenge: 3,
  lab: 4,
  intra: 3,
  hackathon: 3,
  oi_challenge: 2,
  oi_partnership: 3,
  new_lab: 4,
};

const OnBoardingDialog = lazyRetry(() => import('./BuildingOnBoarding'));

const buildingOnBoarding = (onBoardingKey: ONBOARDING_KEY, force = false) => {
  // disable building on boardings for now
  return;

  let onBoardingToCheck = onBoardingKey; // TODO Remove this and use proper switch

  if (onBoardingKey === 'new_lab') {
    onBoardingToCheck = 'lab';
  }

  if (stores.authStore.isExternal) return;
  if (!force) {
    const disabled =
      stores.organizationStore.organization.config.disabled_onboardings;
    if ((disabled || []).indexOf(onBoardingToCheck) !== -1) return;
    if (stores.authStore.storage.contains(BUILDING_ONBOARDING, onBoardingKey)) {
      return;
    }
  }
  return new Promise((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = () => {
      resolve();
      if (!force) {
        stores.authStore.storage.append(BUILDING_ONBOARDING, onBoardingKey);
      }
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <OnBoardingDialog
        onBoardingKey={onBoardingKey}
        count={ONBOARDING_COUNTS[onBoardingKey]}
        onClose={onClose}
      />,
    );
  });
};

export default buildingOnBoarding;
