import { DateTime } from 'luxon';
import { action, computed, observable, runInAction } from 'mobx';
import api from '~/api/api';
import PlaceholderImage from '~/assets/images/placeholder_challenge.jpg';
import PlaceholderImageEn from '~/assets/images/placeholder_challenge_en.jpg';
import { startLoading } from '~/components/dialogs';
import { ImageImportResult } from '~/components/ImageImport';
import { randomColor } from '~/constants/colors';
import { contestDetailsPath } from '~/constants/routes';
import history from '~/history';
import i18n from '~/locales/i18n';
import {
  Answer,
  PollDisplayConfig,
  PollResults,
  Question,
} from '~/models/polls';
import { MiniUser } from '~/models/user';
import { Socialable } from '~/models/utils';
import stores from '~/stores';
import { forceDownload } from '~/utils/downloadFile';
import { Team } from '../teams/models';
import ContestApi from './api';
import { getResultsConfig } from './dialogs';

export interface ContestOptions {
  multiple?: boolean;
  team?: boolean;
  enable_contest_social?: boolean;
  anonymous?: boolean;
  social?: boolean;
  lab_feature?: string;
  tabs_order?: string[];
  display_contributions?: boolean;
  hide_owner?: boolean;
  hide_date_end?: boolean;
  jury_challenge?: number; // index of the challenge that must be rated by the jury
  jury_hide_avatars?: boolean; // hide jury avatars on the results/jury pages
  jury_hide?: boolean; // hide contributions ratings to the jury before the results get published
  jury_hide_all?: boolean; // hide contributions ratings to every one even after publishing the results

  contribute_header?: string; // Header at the top of the "ChallengeSelector" Dialog
  contribute_footer?: string; // Footer at the bottom of the "ChallengeSelector" Dialog

  // wording
  wording_contribute?: string;
  wording_view_contributions?: string;
  wording_challenge_tab?: string;
  wording_view_results?: string;
  wording_filters?: Record<number, string>;
}

export interface JuryForm {
  questions: Question[];
  header?: string;
  footer?: string;
}

export interface ContestResult {
  content: string;
  displayResults?: boolean;
}

export class Contest extends Socialable {
  @observable
  public feature: string;

  @observable
  public name: string;

  @observable
  public slug: string;

  @observable
  public owner: MiniUser;

  @observable
  public banner: string;

  @observable
  public published: boolean;

  @observable
  public need_moderation: boolean;

  @observable
  public date_end: DateTime | null;

  @observable
  public tags: string[];

  @observable
  public is_poll: boolean;

  @observable
  public target_groups: string[];

  @observable
  public view_groups: string[];

  @observable
  public jury: MiniUser[];

  @observable
  public jury_form: JuryForm;

  @observable
  public jury_text: string;

  @observable
  public options: ContestOptions;

  @observable
  public challenges: ContestChallenge[] = [];

  @observable
  public sections: ContestSection[] = [];

  @observable
  public results: ContestResult | null = null;

  @observable
  public contribution_count: number;

  public hit_count: number;
  public hit: boolean;

  public color: string;

  constructor({ ...data }: any) {
    super('contest', data);
    Object.assign(this, data);
    this.color = randomColor();
  }

  public async update({ jury, date_end, ...newData }: Partial<Contest>) {
    const oldSlug = this.slug;
    const data = await ContestApi.updateContest(this, {
      ...newData,
      new_jury: jury?.map((c) => c.id),
      date_end: date_end ? date_end.toISO() : date_end,
    });
    this._update(data);

    if (this.slug !== oldSlug) {
      history.replace(
        `${contestDetailsPath(this.slug, this.feature)}${location.search}`,
      );
    }
  }

  public async updateThumbnail(thumbnail: ImageImportResult) {
    const data = await ContestApi.updateBanner(this, thumbnail);
    this._update(data);
  }

  @action
  public _update({ ...data }: any) {
    Object.assign(this, data);
  }

  public async loadChallenges() {
    if (this.challenges.length !== 0) return;
    const challenges = await ContestApi.getChallenges(this);
    runInAction(() => {
      this.challenges = challenges.map((c: any) => new ContestChallenge(c));
    });
  }

  public async loadSections() {
    if (this.sections.length !== 0) return;
    const sections = await ContestApi.getSections(this);
    runInAction(() => {
      this.sections = sections.map((c: any) => new ContestSection(c));
    });
  }

  public getSection(slug: string) {
    return this.sections.find((s) => s.slug === slug);
  }

  public getChallenge(slug: string) {
    return this.challenges.find((s) => s.slug === slug);
  }

  public async addSection(name: string) {
    const section = await ContestApi.addSection(this, { name });
    const newSection = new ContestSection(section);
    this.update({
      options: {
        ...this.options,
        tabs_order: [...this.tabsOrder, `section://${newSection.slug}`],
      },
    });
    runInAction(() => {
      this.sections.push(newSection);
    });
    return newSection;
  }

  public async updateSection(
    section: ContestSection,
    d: Partial<ContestSection>,
  ) {
    const data = await ContestApi.updateSection(this, {
      slug: section.slug,
      ...d,
    });
    section.update(data);
  }

  public async deleteSection(section: ContestSection) {
    await ContestApi.deleteSection(this, section);
    runInAction(() => {
      this.sections = this.sections.filter((c) => c.slug !== section.slug);
    });
  }

  public async addChallenge(data: Partial<ContestChallenge>) {
    const challenge = await ContestApi.addChallenge(this, data);
    const newChallenge = new ContestChallenge(challenge);
    runInAction(() => {
      this.challenges.push(newChallenge);
    });
    return newChallenge;
  }

  public async updateChallenge(
    challenge: ContestChallenge,
    d: Partial<ContestChallenge>,
  ) {
    const data = await ContestApi.updateChallenge(this, {
      slug: challenge.slug,
      ...d,
    });
    challenge.update(data);
  }

  public async deleteChallenge(challenge: ContestChallenge) {
    await ContestApi.deleteChallenge(this, challenge);
    runInAction(() => {
      this.challenges = this.challenges.filter(
        (c) => c.slug !== challenge.slug,
      );
    });
  }

  @computed
  get isJuryMember() {
    return this.jury.find((c) => c.id === stores.authStore.user.id);
  }

  @computed
  get isOwner() {
    return this.owner.id === stores.authStore.user.id;
  }

  @computed
  get isOwnerOrAdmin() {
    return this.isOwner || stores.authStore.isContestAdmin(this.feature);
  }

  @computed
  get challengeTab() {
    return (
      this.options.wording_challenge_tab ||
      i18n.formatMessage('contest.tabs.challenge')
    );
  }

  @computed
  get bannerOrDefault() {
    return (
      this.banner ||
      (i18n.locale === 'en' ? PlaceholderImageEn : PlaceholderImage)
    );
  }

  @computed
  get isInTargetGroup() {
    return (
      this.target_groups.length === 0 ||
      this.target_groups.some(
        (c) => stores.authStore.user.campus_groups.indexOf(c) !== -1,
      )
    );
  }

  @computed
  get isInViewGroup() {
    return (
      this.view_groups.length === 0 ||
      this.view_groups.some(
        (c) => stores.authStore.user.campus_groups.indexOf(c) !== -1,
      )
    );
  }

  @computed
  get mainChallenge() {
    if (this.challenges.length === 0) return null;
    return this.options.jury_challenge
      ? this.challenges[this.options.jury_challenge]
      : this.challenges[0];
  }

  @computed
  get isDateEnded() {
    return !!(this.date_end && DateTime.local() > this.date_end);
  }

  @computed
  get availableChallenges() {
    if (this.options.multiple) {
      return this.challenges;
    }

    return this.challenges.filter((c) => !c.contributed);
  }

  @computed
  get canContribute() {
    return (
      this.isInTargetGroup &&
      !this.isDateEnded &&
      this.availableChallenges.length > 0
    );
  }

  @computed
  get canSeeContributions() {
    return (
      this.options.display_contributions ||
      this.isJuryMember ||
      this.isOwnerOrAdmin
    );
  }

  @computed
  get contestFeature() {
    return stores.organizationStore.getContestConfig(this.feature);
  }

  @computed
  get defaultTab() {
    if (this.challenges.length >= 1 && this.sections.length === 0) {
      return 'challenge://';
    }

    if (this.sections.length === 1 && this.challenges.length === 0) {
      return `section://${this.sections[0]}`;
    }

    if (this.tabsOrder && this.tabsOrder.length > 0) {
      if (!this.options.display_contributions) {
        const valid = this.tabsOrder.filter(
          (c) => !c.startsWith('challenge://'),
        );

        if (valid.length === 0) return '';

        return valid[0];
      }
      return this.tabsOrder[0];
    }

    if (this.sections.length !== 0) {
      return `section://${this.sections[0].slug}`;
    }

    return '';
  }

  @computed
  get mainChallengeSlug() {
    if (!this.mainChallenge) return '';
    return this.mainChallenge.slug;
  }

  @computed
  get tabsOrder() {
    const order = this.options.tabs_order || [];
    const seen: [string, number][] = [];

    this.sections.forEach((section, i) => {
      const key = `section://${section.slug}`;
      const index = order.indexOf(key);
      seen.push([key, index === -1 ? 1000 - i : index]);
    });

    seen.push(['challenge://', order.indexOf('challenge://')]);

    if (this.publishedResults) {
      const key = 'results://';
      seen.push([key, order.indexOf(key)]);
    }
    if (this.hasJury) {
      const key = 'jury://';
      seen.push([key, order.indexOf(key)]);
    }

    seen.sort(([_i, i], [_j, j]) => i - j);

    return seen.map(([a, _]) => a);
  }

  @computed
  get hasJury() {
    return this.jury.length !== 0;
  }

  @computed
  get hasJuryRatingQuestions() {
    return (
      this.hasJury &&
      this.jury_form.questions.filter((c) => c.type === 'rating').length > 0
    );
  }

  @computed
  get canSeeJury() {
    if (!this.hasJury) return false;
    if (this.isOwnerOrAdmin) return true;
    if (this.isDateEnded) return !this.options.jury_hide_all;
    if (this.isJuryMember) return !this.options.jury_hide;
    return false;
  }

  @computed
  get canRateJury() {
    if (!this.hasJury) return false;
    if (this.isOwnerOrAdmin) return true;
    if (this.isJuryMember) return true;
    return false;
  }

  @computed
  get publishedResults() {
    return this.isDateEnded && !!this.results;
  }
}

export class ContestChallenge {
  @observable
  public id: number;

  @observable
  public name: string;

  @observable
  public tab_name?: string;

  @observable
  public contribute_name?: string;

  @observable
  public slug: string;

  @observable
  public data: Question[];

  @observable
  public display_config: PollDisplayConfig;

  @observable
  public header: string;

  @observable
  public footer: string;

  @observable
  public contributed: boolean;

  @observable
  public contribution_count: number;

  @observable
  public contributions: ContestContribution[] = [];

  @observable
  public pollResults: PollResults = [];

  constructor({ ...data }: any) {
    Object.assign(this, data);
  }

  @action
  public update({ ...data }: any) {
    Object.assign(this, data);
  }

  public async loadContributions() {
    const data = await ContestApi.getContributions(this);
    runInAction(() => {
      this.contributions = data.map(
        (cont: any) => new ContestContribution(cont),
      );
    });
  }

  public async addContribution(contribution: Partial<ContestContribution>) {
    const data = await ContestApi.addContribution(this, contribution);
    const newContribution = new ContestContribution(data);
    runInAction(() => {
      this.contributions.push(newContribution);
    });

    return newContribution;
  }

  public async updateContribution(
    contribution: ContestContribution,
    d: Partial<ContestContribution>,
  ) {
    const data = await ContestApi.updateContribution(this, contribution, d);
    contribution.update(data);
  }

  public async deleteContribution(contribution: ContestContribution) {
    await ContestApi.deleteContribution(this, contribution);
    runInAction(() => {
      this.contributions = this.contributions.filter(
        (c) => c.id !== contribution.id,
      );
    });
  }

  @computed
  get displayConfig() {
    if (
      this.display_config &&
      this.display_config.length === this.data.length
    ) {
      return this.display_config;
    }
    return this.data.map(() => [false, true]);
  }

  public async loadPollResults() {
    const results = await ContestApi.getPollResults(this);
    runInAction(() => {
      this.pollResults = results;
    });
    return this.pollResults;
  }

  @computed
  get tabName() {
    return this.tab_name || this.name;
  }

  @computed
  get contributeName() {
    return this.contribute_name || this.name;
  }

  public async openResults(contest: Contest) {
    let name = 'fullname';
    let noFiles = true;

    if (this.data.some((c) => c.type === 'file')) {
      const config = await getResultsConfig(this);
      if (config === null) {
        return;
      }
      name = config.name;
      noFiles = config.noFiles;
    }

    const stopLoading = startLoading(
      i18n.formatMessage('contest.get_results.loading'),
    );

    const { url, body } = await ContestApi.createResults(this, name, noFiles);

    let resultsUrl = '';
    if (body) {
      const { data } = await api.post(url, body);
      resultsUrl = data.output;
    } else {
      resultsUrl = url;
    }

    stopLoading();

    const parts = resultsUrl.split('.');
    const ext = parts[(parts.length = 1)];
    forceDownload(resultsUrl, `${contest.name}.${ext}`);
  }

  @computed
  get filteredPollResults() {
    return this.pollResults.filter((_, i) => this.displayConfig[i][1]);
  }

  @computed
  get filteredData() {
    return this.data.filter((_, i) => this.displayConfig[i][1]);
  }
}

export class ContestSection {
  @observable
  public id: number;

  @observable
  public name: string;

  @observable
  public slug: string;

  @observable
  public content: string;

  @observable
  public hidden: boolean;

  constructor({ ...data }: any) {
    Object.assign(this, data);
  }

  @action
  public update({ ...data }: any) {
    Object.assign(this, data);
  }
}

export class ContestContribution extends Socialable {
  public team: Team;

  public number?: number;

  @observable
  public data: Answer[];

  @observable
  public selected: boolean;

  @observable
  public anonymous: boolean;

  @observable
  public lab_project: string | null;

  @observable
  public ratings: ContestRating[] = [];

  @observable
  public ratingResults: PollResults = [];

  @observable
  public date_created: DateTime;

  constructor({ team, ...data }: any) {
    super('contestcontribution', data);
    Object.assign(this, data);
    this.team = new Team(team);
  }

  @action
  public update({ team, ...data }: any) {
    Object.assign(this, data);
  }

  public async loadRatings() {
    if (this.ratings.length) return this.ratings;
    const ratings = await ContestApi.getRatings(this);
    runInAction(() => {
      this.ratings = ratings.map((r: any) => new ContestRating(r));
    });
    return this.ratings;
  }

  public async loadRatingResults() {
    if (this.ratingResults.length) return this.ratingResults;
    const results = await ContestApi.getRatingResults(this);
    runInAction(() => {
      this.ratingResults = results;
    });
    return this.ratingResults;
  }

  @computed
  get rated() {
    return this.ratings.some((r) => r.owner.id === stores.authStore.user.id);
  }

  public async addRating(data: Partial<ContestRating>) {
    const rating = await ContestApi.addRating(this, data);
    const newRating = new ContestRating(rating);
    runInAction(() => {
      this.ratings.push(newRating);
    });
    return newRating;
  }

  public async updateRating(rating: ContestRating, d: Partial<ContestRating>) {
    const data = await ContestApi.updateRating(this, rating, {
      id: rating.id,
      ...d,
    });
    rating.update(data);
  }

  public async deleteRating(rating: ContestRating) {
    await ContestApi.deleteRating(this, rating);
    runInAction(() => {
      this.ratings = this.ratings.filter((c) => c.id !== rating.id);
    });
  }

  @computed
  get averageRating() {
    if (this.ratings.length === 0) return 0;
    let total = 0;
    this.ratings.forEach((rating) => {
      total += rating.rating;
    });
    return total / this.ratings.length;
  }
}

export class ContestRating {
  public id: number;

  public owner: MiniUser;

  @observable
  public rating: number;

  @observable
  public data: Answer[];

  constructor(data: any) {
    Object.assign(this, data);
  }

  @action
  public update({ ...data }: any) {
    Object.assign(this, data);
  }

  @computed
  get isOwner() {
    return this.owner.id === stores.authStore.user.id;
  }
}
