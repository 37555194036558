import api from '~/api/api';

async function share(type: string, id: number): Promise<Comment> {
  const { data } = await api.post('/shares/share', {
    content_type: type,
    object_id: id,
  });
  return data;
}

export default {
  share,
};
