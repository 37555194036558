import api from '~/api/api';
import { ChatFile, Conversation, Message } from '~/models/chat';
import { MiniUser } from '~/models/user';
import { FileImportResult } from '~/models/utils';

async function getConversations(): Promise<Conversation[]> {
  const { data } = await api.get('/chat/conversations');
  return data.map((c: any) => new Conversation(c));
}

async function getConversation(id: number): Promise<Conversation> {
  const { data } = await api.get('/chat/conversation', { params: { id } });
  return new Conversation(data);
}

async function getMessages(conversation: number): Promise<Message[]> {
  const { data } = await api.get('/chat/messages', {
    params: { conversation },
  });
  return data;
}

async function getMessage(id: number): Promise<Message> {
  const { data } = await api.get('/chat/message', { params: { id } });
  return data;
}

async function getHistory(id: number) {
  const { data } = await api.get(`/chat/history`, {
    params: { id },
    responseType: 'blob',
  });
  return data;
}

async function sendMessage(
  conversation: number,
  content: string,
  file?: ChatFile,
): Promise<Message> {
  const formData = new FormData();
  formData.append('conversation', conversation.toString());
  formData.append('content', content);
  if (file) {
    formData.append('file', file.blob!, file.name);
  }

  const { data } = await api.post('/chat/send', formData);
  return data;
}

async function startConversation(user: number): Promise<Conversation> {
  const { data } = await api.post('/chat/start', {
    to: user,
  });
  return new Conversation(data);
}

async function startGroupConversation(
  name: string,
  icon: FileImportResult | null,
  users: MiniUser[],
): Promise<Conversation> {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('users', JSON.stringify(users.map((u) => u.id)));

  if (icon) {
    formData.append('icon', icon.blob!, icon.name);
  }

  const { data } = await api.post('/chat/start_group', formData);
  return new Conversation(data);
}

async function setRead(message: number): Promise<void> {
  await api.post('/chat/set_read', {
    message,
  });
}

async function updateConversation(
  conversation: Conversation,
  name: string | null,
  icon: FileImportResult | null,
  users: MiniUser[] | null,
): Promise<Conversation> {
  const formData = new FormData();

  if (name) {
    formData.append('name', name);
  }

  if (users) {
    formData.append('users', JSON.stringify(users.map((u) => u.id)));
  }

  if (icon) {
    formData.append('icon', icon.blob!, icon.name);
  }

  formData.append('id', conversation.id.toString());

  const { data } = await api.post('/chat/update_conv', formData);
  conversation.update(data);
  return data;
}

async function leaveConversation(conversation: Conversation): Promise<void> {
  await api.post('/chat/leave_conv', {
    id: conversation.id,
  });
}

export default {
  getConversations,
  getConversation,
  getMessages,
  getMessage,
  sendMessage,
  startConversation,
  setRead,
  startGroupConversation,
  leaveConversation,
  updateConversation,
  getHistory,
};
