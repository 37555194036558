import api from '~/api/api';
import { ImageImportResult } from '~/components/ImageImport';
import { EditableTagsDiff } from '~/components/Tag/EditableTagSelector';
import { Invitation, Organization } from '~/models/organization';
import { MiniUser } from '~/models/user';
import { FileImportResult, UploadedFile } from '~/models/utils';
import getOrganizationSlug from '~/utils/getOrganizationSlug';
import toImageName from '~/utils/toImageName';

async function getCurrentOrganization(): Promise<Organization | null> {
  try {
    const { data } = await api.get('/organizations/current');
    return new Organization(data);
  } catch (error) {
    if (
      error.response &&
      error.response.status === 404 &&
      error.config.url!.endsWith('/organizations/current/')
    ) {
      location.href = `https://innovative-campus.com/login?campus=${getOrganizationSlug()}&invalid=1`;
    }
    return null;
  }
}

async function join(
  email: string,
  token: string,
  groups: string,
): Promise<void> {
  await api.post('/organizations/join', {
    email,
    token,
    groups: groups && groups.length ? groups : undefined,
  });
}

async function invite(
  email: string,
  restrictedContents: any,
): Promise<Invitation> {
  const { data } = await api.post('/organizations/invite', {
    email,
    restricted_contents: restrictedContents,
  });
  return data;
}

async function getInvitations(): Promise<Invitation[]> {
  const { data } = await api.get('/organizations/invitations');
  return data;
}

async function updateSettings(
  newData: Partial<Organization>,
  organization: Organization,
): Promise<Organization> {
  const { data } = await api.post('/organizations/update_settings', {
    ...newData,
  });
  organization.update(data);
  return data;
}

async function updateLogo(
  logo: ImageImportResult,
  organization: Organization,
): Promise<Organization> {
  const formData = new FormData();
  formData.append('logo', logo.blob!, toImageName(logo.name));
  const { data } = await api.post('/organizations/update_settings', formData);
  organization.update(data);
  return data;
}

async function updateCampusImage(
  image: ImageImportResult,
  organization: Organization,
): Promise<Organization> {
  const formData = new FormData();
  formData.append('campus_image', image.blob!, toImageName(image.name));
  const { data } = await api.post('/organizations/update_settings', formData);
  organization.update(data);
  return data;
}

async function setRole(
  user: MiniUser,
  role: string,
  roles: string[],
  restrictedContents?: any,
): Promise<MiniUser> {
  const { data } = await api.post('/organizations/set_role', {
    roles,
    user_id: user.id,
    user_type: role,
    restricted_contents: restrictedContents || null,
  });
  return data;
}

async function getContents(
  building: string,
  feature?: string,
): Promise<{ id: number; name: string }[]> {
  const { data } = await api.get('/organizations/contents', {
    params: {
      feature,
      building,
    },
  });
  return data;
}

async function deleteUser(user: MiniUser): Promise<void> {
  await api.post('/organizations/delete_user', {
    user_id: user.id,
  });
}

async function deleteInvitation(id: number): Promise<void> {
  await api.post('/organizations/delete_invitation', {
    id,
  });
}

async function feedback(message: string): Promise<void> {
  await api.post('/organizations/feedback', {
    message,
  });
}

async function updateInfos(building: string, content: string): Promise<void> {
  await api.post('/organizations/update_infos', {
    building,
    content,
  });
}

async function getInfos(building: string): Promise<string> {
  try {
    const { data } = await api.get('/organizations/infos', {
      params: { building },
    });
    return data.infos;
  } catch (_) {
    return '';
  }
}

async function getDefaultFiles(
  type: 'project' | 'intra',
): Promise<UploadedFile[]> {
  const { data } = await api.get('/organizations/default_files', {
    params: { type },
  });
  return data;
}

async function addDefaultFiles(
  type: 'project' | 'intra',
  files: FileImportResult[],
): Promise<string> {
  const formData = new FormData();
  formData.append('type', type);
  files.forEach((f) => {
    formData.append('files', f.blob!, f.name);
  });

  const { data } = await api.post('/organizations/add_default_files', formData);
  return data.infos;
}

async function removeDefaultFiles(
  type: 'project' | 'intra',
  files: number[],
): Promise<string> {
  const formData = new FormData();
  formData.append('type', type);

  files.forEach((f) => {
    formData.append('files', f.toString());
  });

  const { data } = await api.post(
    '/organizations/remove_default_files',
    formData,
  );
  return data.infos;
}

async function getUsersCSV() {
  const { data } = await api.get('/organizations/users_csv');
  return data.csv;
}

async function applyDiffs(
  building: string,
  diffs: Record<string, EditableTagsDiff[]>,
  feature?: string,
) {
  await api.post('/organizations/apply_diffs', {
    diffs,
    feature,
    building,
  });
}

export default {
  applyDiffs,
  join,
  getCurrentOrganization,
  invite,
  updateSettings,
  updateCampusImage,
  setRole,
  getInfos,
  updateInfos,
  deleteUser,
  getInvitations,
  deleteInvitation,
  updateLogo,
  feedback,
  getDefaultFiles,
  addDefaultFiles,
  removeDefaultFiles,
  getUsersCSV,
  getContents,
};
