import dompurify from 'dompurify';

function sanitize(source: string) {
  return dompurify.sanitize(source, {
    ADD_TAGS: ['iframe', 'embed'],
    ADD_ATTR: ['allowfullscreen', 'target', 'frameborder'],
  });
}

export default sanitize;
