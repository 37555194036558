import { Button, withMobileDialog } from '@material-ui/core';
import { InjectedProps } from '@material-ui/core/withMobileDialog';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Dialog from '~/components/dialogs/Dialog';
import DialogActions from '~/components/dialogs/DialogActions';
import DialogContent from '~/components/dialogs/DialogContent';
import DialogTitle from '~/components/dialogs/DialogTitle';
import stores from '~/stores';

interface Props extends InjectedProps {
  open?: boolean;
  onClose: (value: boolean) => void;
  index: number;
  title: string;
  action: string;
  description: string;
}

class Info extends React.Component<Props> {
  public closeFalse = () => {
    this.props.onClose(false);
  }

  public closeTrue = () => {
    this.props.onClose(true);
  }

  public render() {
    const { fullScreen, index, title, description } = this.props;
    return (
      <Dialog
        index={index}
        fullScreen={fullScreen}
        disableEscapeKeyDown
        open
        onClose={this.closeFalse}
      >
        <DialogTitle onClose={this.closeFalse}>
          {description.length !== 0 ? (
            title
          ) : (
            <FormattedMessage id="commons.info" />
          )}
        </DialogTitle>
        <DialogContent>
          {description.length !== 0 ? description : title}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeTrue} variant="contained" color="primary">
            <FormattedMessage id={this.props.action} />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const InfoDialog = withMobileDialog<Props>()(Info);

const info = (
  title: string,
  description: string = '',
  action: string = 'commons.continue',
) =>
  new Promise<boolean>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = (value: boolean) => {
      resolve(value);
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <InfoDialog
        index={index}
        onClose={onClose}
        title={title}
        description={description}
        action={action}
      />,
    );
  });

export default info;
