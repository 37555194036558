import api from '~/api/api';
import { ImageImportResult } from '~/components/ImageImport';
import { PaginatedResult } from '~/models/api';
import { MiniPartner, Partner } from '~/models/openinno';
import { MiniUser } from '~/models/user';
import { OpenInnoProject } from './models';

class OpenInnoProjectsApi {
  public async getProject(slug: string): Promise<OpenInnoProject | null> {
    try {
      const { data } = await api.get(`/openinno/projects/${slug}`);
      return new OpenInnoProject(data);
    } catch (_) {
      return null;
    }
  }

  public async createProject(
    name: string,
    status: string,
    tags: string[],
    partners: MiniPartner[],
    goal: string,
    thumbnail: ImageImportResult | null,
  ) {
    const formData = new FormData();

    formData.append('name', name);
    formData.append('status', status);
    formData.append('goal', goal);
    formData.append('tags', JSON.stringify(tags));

    partners.forEach((c) => {
      formData.append('new_partners', c.id.toString());
    });

    if (thumbnail) {
      formData.append('thumbnail', thumbnail.blob!, thumbnail.name);
    }

    const { data } = await api.post('/openinno/projects', formData);
    return new OpenInnoProject(data);
  }

  public async editProject(
    project: OpenInnoProject,
    name: string,
    status: string,
    tags: string[],
    partners: MiniPartner[],
    thumbnail: ImageImportResult | null,
  ) {
    const formData = new FormData();

    formData.append('name', name);
    formData.append('status', status);
    formData.append('tags', JSON.stringify(tags));

    partners.forEach((c) => {
      formData.append('new_partners', c.id.toString());
    });

    if (thumbnail) {
      formData.append('thumbnail', thumbnail.blob!, thumbnail.name);
    }

    const { data } = await api.patch(
      `/openinno/projects/${project.slug}`,
      formData,
    );
    return project.update(data);
  }

  public async deleteProject(project: OpenInnoProject) {
    await api.delete(`/openinno/projects/${project.slug}`);
  }

  public async getProjects(): Promise<PaginatedResult<OpenInnoProject>> {
    const { data } = await api.get('/openinno/projects', {
      params: { ps: 10000 },
    });
    return {
      ...data,
      results: data.results.map((c: any) => new OpenInnoProject(c)),
    };
  }

  public async inviteProjectExternalUser(
    project: OpenInnoProject,
    partnerEmail: string,
    partnerFullname: string,
    partnerId: number,
  ) {
    const { data } = await api.post(
      `/openinno/projects/${project.slug}/add_external`,
      {
        partner_email: partnerEmail,
        partner_fullname: partnerFullname,
        partner_id: partnerId,
      },
    );

    return data as MiniUser;
  }

  public async inviteProjectUser(project: OpenInnoProject, user: MiniUser) {
    await api.post(`/openinno/projects/${project.slug}/add_member`, {
      id: user.id,
    });
  }

  public async getMyProjects() {
    const { data } = await api.get('/openinno/projects/my_projects');
    return data.map((c: any) => new OpenInnoProject(c));
  }

  public async resendProjectInvitation(
    project: OpenInnoProject,
    user: MiniUser,
  ) {
    await api.post(`/openinno/projects/${project.slug}/resend_invitation`, {
      user: user.id,
    });
  }

  public async getPartnerProjects(partner: Partner) {
    const { data } = await api.get(
      `/openinno/partners/${partner.slug}/projects`,
    );
    return data.map((c: any) => new OpenInnoProject(c));
  }
}

export default new OpenInnoProjectsApi();
