import React from 'react';
import { Redirect, Route, RouteComponentProps, RouteProps } from 'react-router';
import Layout from '~/components/Layout';
import { chatPath, homePath } from '~/constants/routes';
import withStore, { WithStoreProps } from '~/utils/withStore';

interface Props {
  width?: number;
  padding?: number;
  feature?: string;
  adminOnly?: boolean;
  role?: string;
  pageProps?: any;
  externalOk?: boolean;
  userType?: string;
}

class LayoutRoute extends React.Component<Props & RouteProps & WithStoreProps> {
  public customRender = (props: RouteComponentProps<any>) => {
    const {
      component: Component,
      width,
      padding,
      feature,
      store,
      adminOnly,
      pageProps,
      role,
      externalOk,
      userType,
    } = this.props;

    if (!externalOk && store.authStore.user.user_type.startsWith('external')) {
      return <Redirect to={chatPath} />;
    }

    if (
      adminOnly &&
      (feature
        ? !store.authStore.isFeatureAdmin(feature as any)
        : !store.authStore.isAdmin)
    ) {
      return <Redirect to={homePath()} />;
    }

    if (role && !store.authStore.hasRole(role)) {
      return <Redirect to={homePath()} />;
    }

    if (
      userType &&
      store.authStore.user.user_type !== userType &&
      store.authStore.user.user_type !== 'admin'
    ) {
      return <Redirect to={homePath()} />;
    }

    if (feature && !store.organizationStore.hasFeature(feature)) {
      return <Redirect to={homePath()} />;
    }

    if (!Component) return null;
    return (
      <Layout width={width} padding={padding}>
        <Component {...props} {...pageProps} />
      </Layout>
    );
  };

  public render() {
    const { component, ...props } = this.props;
    return <Route {...props} render={this.customRender} />;
  }
}

export default withStore(LayoutRoute);
