import api from '~/api/api';
import { WorkForm, WorkFormItem } from './models';

class WorkFormsApi {
  public async getItems(form: WorkForm) {
    const { data } = await api.get(`/workforms/${form.id}/items`);
    return data;
  }

  public async updateItem(form: WorkForm, item: WorkFormItem, newData: any) {
    const { data } = await api.post(
      `/workforms/${form.id}/update_item`,
      {
        data: newData.data,
        confirmed: newData.confirmed,
      },
      {
        params: {
          item_id: item.id,
        },
      },
    );
    return data;
  }

  public async updateItemConfig(
    form: WorkForm,
    item: WorkFormItem,
    config: any,
  ) {
    const { data } = await api.post(
      `/workforms/${form.id}/update_item`,
      {
        config,
      },
      {
        params: {
          item_id: item.id,
        },
      },
    );
    return data;
  }

  public async createItem(form: WorkForm, config: any) {
    const { data } = await api.post(`/workforms/${form.id}/create_item`, {
      config,
    });
    return data;
  }

  public async deleteItem(form: WorkForm, item: WorkFormItem) {
    const { data } = await api.delete(`/workforms/${form.id}/delete_item`, {
      params: {
        item_id: item.id,
      },
    });
    return data;
  }
}

export default new WorkFormsApi();
