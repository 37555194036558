import { observer } from 'mobx-react';
import React from 'react';
import withStore, { WithStoreProps } from '~/utils/withStore';

@observer
class DialogStack extends React.Component<WithStoreProps> {
  public render() {
    const { store } = this.props;
    return store.uiStore.dialogStack.map((dialog, i) => {
      return <div key={`dialog-${i}`}>{dialog}</div>;
    });
  }
}

export default withStore(DialogStack);
