import React, { useContext } from 'react';
import io from 'socket.io-client';

import i18n from '~/locales/i18n';
import CommunitiesStore from '~/modules/communities/store';
import ContestStore from '~/modules/contest/store';
import LabStore from '~/modules/lab/store';
import OIContestStore from '~/modules/oi_contest/store';
import OpenInnoProjectsStore from '~/modules/openinno.projects/store';
import AcademyStore from '~/stores/academy';
import ActivityStore from '~/stores/activities';
import ChatStore from '~/stores/chat';
import CommentStore from '~/stores/comment';
import IdeaboxStore from '~/stores/ideabox';
import OrganizationStore from '~/stores/organization';
import UiStore from '~/stores/ui';
import UserStore from '~/stores/user';
import AuthStore from './auth';
import ChallengesStore from './challenges';
import GlobalStore from './global';
import IntraStore from './intra';
import OpenInnoStore from './openinno';
import { ProgressStore } from './progress';
import ProjectsStore from './projects';
import SearchStore from './search';

export class RootStore {
  public authStore: AuthStore;
  public ideaboxStore: IdeaboxStore;
  public uiStore: UiStore;
  public organizationStore: OrganizationStore;
  public academyStore: AcademyStore;
  public chatStore: ChatStore;
  public userStore: UserStore;
  public activityStore: ActivityStore;
  public commentStore: CommentStore;
  public globalStore: GlobalStore;
  public challengesStore: ChallengesStore;
  public projectsStore: ProjectsStore;
  public intraStore: IntraStore;
  public searchStore: SearchStore;
  public openInnoStore: OpenInnoStore;
  public communitiesStore: CommunitiesStore;
  public labStore: LabStore;
  public progressStore: ProgressStore;
  public openInnoProjectsStore: OpenInnoProjectsStore;
  public contestStore: ContestStore;
  public oiContestStore: OIContestStore;

  public io: SocketIOClient.Socket;

  constructor() {
    this.io = io(process.env.REALTIME_URL!, {
      transports: ['websocket'],
    });

    this.io.on('reconnect_attempt', () => {
      this.io.io.opts.transports = ['websocket'];
    });

    this.authStore = new AuthStore(this);
    this.organizationStore = new OrganizationStore(this);
    this.ideaboxStore = new IdeaboxStore(this);
    this.challengesStore = new ChallengesStore();
    this.projectsStore = new ProjectsStore(this);
    this.intraStore = new IntraStore(this);
    this.uiStore = new UiStore(this);
    this.academyStore = new AcademyStore(this);
    this.userStore = new UserStore();
    this.commentStore = new CommentStore();
    this.globalStore = new GlobalStore(this);
    this.searchStore = new SearchStore();
    this.activityStore = new ActivityStore(this, this.io);
    this.chatStore = new ChatStore(this, this.io);
    this.openInnoStore = new OpenInnoStore(this);
    this.communitiesStore = new CommunitiesStore();
    this.openInnoProjectsStore = new OpenInnoProjectsStore();

    // modules
    this.labStore = new LabStore();
    this.progressStore = new ProgressStore();
    this.contestStore = new ContestStore();
    this.oiContestStore = new OIContestStore();

    i18n.init(this);
  }

  public init(token: string) {
    this.io.emit('login', { token });

    this.chatStore.init();
    this.activityStore.init();
    this.globalStore.init();
    this.uiStore.init();
    this.intraStore.init();
    this.openInnoStore.init();
    this.projectsStore.init();
  }

  public reset() {
    try {
      this.io.disconnect();
    } catch (_) {}
    this.io = io(process.env.REALTIME_URL!, {
      transports: ['websocket'],
    });

    this.io.on('reconnect_attempt', () => {
      this.io.io.opts.transports = ['websocket'];
    });

    this.organizationStore = new OrganizationStore(this);
    this.authStore = new AuthStore(this);
    this.ideaboxStore = new IdeaboxStore(this);
    this.challengesStore = new ChallengesStore();
    this.projectsStore = new ProjectsStore(this);
    this.intraStore = new IntraStore(this);
    this.uiStore = new UiStore(this);
    this.academyStore = new AcademyStore(this);
    this.userStore = new UserStore();
    this.commentStore = new CommentStore();
    this.globalStore = new GlobalStore(this);
    this.searchStore = new SearchStore();
    this.activityStore = new ActivityStore(this, this.io);
    this.chatStore = new ChatStore(this, this.io);
    this.openInnoStore = new OpenInnoStore(this);
    this.communitiesStore = new CommunitiesStore();
    this.openInnoProjectsStore = new OpenInnoProjectsStore();

    // modules
    this.labStore = new LabStore();
    this.progressStore = new ProgressStore();
    this.contestStore = new ContestStore();
    this.oiContestStore = new OIContestStore();
  }
}

const stores = new RootStore();

export const StoreContext = React.createContext(stores);

export function useStore() {
  return useContext(StoreContext);
}

export default stores;
