import api from '~/api/api';
import { MiniUser } from '~/models/user';
import { PollData } from '../polls/models';
import { DisplayTo, Feed, FeedItem } from './models';

class FeedApi {
  public async getFeedItems(feed: Feed) {
    const { data } = await api.get(`/feed/${feed.id}/items`);
    return data;
  }

  public async createItem(
    feed: Feed,
    content: string,
    poll?: PollData,
    noActivity?: boolean,
    tags?: string[],
    displayTo?: DisplayTo,
    owner?: MiniUser,
  ) {
    const { data } = await api.post(
      `/feed/${feed.id}/create_item`,
      {
        content,
        tags,
        poll_data: poll,
        display_to: displayTo,
        initial_owner: owner ? owner.id : undefined,
      },
      {
        params: {
          no_activity: noActivity ? 1 : undefined,
        },
      },
    );
    return data;
  }

  public async toggleItemPin(feed: Feed, item: FeedItem) {
    await api.post(`/feed/${feed.id}/toggle_item_pin`, { item_id: item.id });
  }

  public async deleteItem(feed: Feed, item: FeedItem) {
    await api.delete(`/feed/${feed.id}/delete_item`, {
      params: { item_id: item.id },
    });
  }

  public async getHistory(feed: Feed) {
    const { data } = await api.get(`/feed/${feed.id}/history`, {
      responseType: 'blob',
    });
    return data;
  }

  public async updateItem(
    feed: Feed,
    item: FeedItem,
    content: string,
    poll?: PollData,
    tags?: string[],
    displayTo?: DisplayTo,
  ) {
    const { data } = await api.post(
      `/feed/${feed.id}/update_item`,
      {
        content,
        tags,
        poll_data: poll,
        display_to: displayTo,
      },
      {
        params: { item_id: item.id },
      },
    );
    return data;
  }
}

export default new FeedApi();
