import { AcademyFormation } from '~/models/academy';
import { PaginatedResult } from '~/models/api';
import { Challenge } from '~/models/challenges';
import { IdeaboxItem } from '~/models/ideabox';
import { IntraItem } from '~/models/intra';
import { Challenge as OpenInnoChallenge } from '~/models/openinno';
import { Project } from '~/models/projects';
import { ContentResult, SearchResult } from '~/models/search';
import api from './api';

const TYPES = {
  ideabox: IdeaboxItem,
  projects: Project,
  intra: IntraItem,
  challenges: Challenge,
  academy: AcademyFormation,
  'openinno.challenges': OpenInnoChallenge,
};

async function search(
  url: string | null,
  q?: string,
  type?: string,
  filters?: any,
  params?: any,
): Promise<PaginatedResult<SearchResult>> {
  const { data } = await api.get(url || `/search/${type}`, {
    params: {
      q,
      ...filters,
      ...params,
    },
  });

  if (type === 'contents' || (url && url.indexOf('contents') !== -1)) {
    return {
      next: data.next,
      previous: null,
      results: data.results.map((c: any) => {
        if (TYPES[c.type]) {
          return { type: c.type, data: new TYPES[c.type](c.data) };
        }
        return c;
      }),
    };
  }

  return data;
}

async function similar(q?: string): Promise<ContentResult[]> {
  const { data } = await api.get('/search/contents', {
    params: {
      q,
      mini: true,
      buildings: ['ideabox', 'academy', 'projects', 'openinno.projects'],
    },
  });

  return data.results;
}

async function counts(q?: string): Promise<any> {
  const { data } = await api.get('/search/counts', { params: { q } });
  return data;
}

export default {
  search,
  counts,
  similar,
};
