import { action, computed, observable, runInAction } from 'mobx';
import IdeaboxApi from '~/api/ideabox';
import { ImageImportResult } from '~/components/ImageImport';
import { IdeaboxItem, IdeaboxType } from '~/models/ideabox';
import { MiniUser } from '~/models/user';
import { RootStore } from '~/stores';

class IdeaboxStore {
  public items = observable.map<string, IdeaboxItem>();

  @observable
  public results: string[] = [];

  @observable
  public search: string = '';

  @observable
  public types: IdeaboxType[] = [];

  @observable
  public tags: string[] = [];

  @observable
  public status: string[] = [];

  @observable
  public sort: string = '-date_created';

  @observable
  public next: string | null = null;
  public previous: string | null = null;
  public currentUrl: string | null;

  constructor(private rootStore: RootStore) {}

  public async loadItems(force?: boolean) {
    if (force) this.resetUrl();
    if (!force && this.resultItems.length) return this.resultItems;
    const newItems = await IdeaboxApi.getItems(null, this.queryParams);
    runInAction(() => {
      for (const item of newItems.results) {
        this.items.set(item.slug, item);
      }
      this.results = newItems.results.map((item) => item.slug);
      this.next = newItems.next;
      this.previous = newItems.previous;
    });

    return this.resultItems;
  }

  public async loadSimilar(item: IdeaboxItem) {
    const newIdeaboxItems = await IdeaboxApi.getSimilar(item.tags);
    runInAction(() => {
      for (const i of newIdeaboxItems) {
        if (!this.items.has(i.slug)) {
          this.items.set(i.slug, i);
        }
      }
    });

    return newIdeaboxItems;
  }

  public async loadPopular(time: string, type?: string) {
    const newIdeaboxItems = await IdeaboxApi.getPopular(time, type);
    return newIdeaboxItems;
  }

  public async loadItem(slug: string, force: boolean = false) {
    if (force || !this.items.has(slug)) {
      const item = await IdeaboxApi.getItem(slug);
      if (item) {
        this.items.set(item.slug, item);
      }
      return item;
    }
    return this.items.get(slug);
  }

  public async createItem(
    type: IdeaboxType,
    name: string,
    description: string,
    tags: string[],
    url: string,
    anonymous: boolean,
    team: MiniUser[],
  ) {
    const item = await IdeaboxApi.createItem(
      type,
      name,
      description,
      tags,
      url,
      anonymous,
      team,
    );
    if (item) {
      this.items.set(item.slug, item);
      this.rootStore.authStore.addXp(`idea_create_xp`);
    }
    return item;
  }

  public async updateItem(
    item: IdeaboxItem,
    type: IdeaboxType,
    name: string,
    description: string,
    tags: string[],
    url: string,
    anonymous: boolean,
    thumbnail: ImageImportResult | null,
    team: MiniUser[],
  ) {
    const updatedItem = await IdeaboxApi.updateItem(
      item,
      type,
      name,
      description,
      tags,
      url,
      anonymous,
      thumbnail,
      team,
    );
    if (updatedItem) {
      this.items.set(updatedItem.slug, updatedItem);
    }
    return updatedItem;
  }

  @action
  public async deleteItem(item: IdeaboxItem) {
    this.results = this.results.filter((c) => c !== item.slug);
    await IdeaboxApi.deleteItem(item);
  }

  public async loadMore() {
    const newItems = await IdeaboxApi.getItems(
      this.currentUrl,
      this.queryParams,
    );
    runInAction(() => {
      for (const item of newItems.results) {
        this.items.set(item.slug, item);
      }
      this.results.push(...newItems.results.map((item) => item.slug));
      this.next = newItems.next;
      this.previous = newItems.previous;
    });

    return this.resultItems;
  }

  @action.bound
  public async loadNextItems() {
    this.currentUrl = this.next;
    return this.loadMore();
  }

  @computed
  get queryParams() {
    return {
      type: this.types.length === 0 ? ['idea', 'problem', 'watch'] : this.types,
      tags__name: this.tags.join(','),
      q: this.search,
      status: this.status,
      hit: this.sort === 'not_hit' ? false : undefined,
      ordering: this.sort !== 'not_hit' ? this.sort : undefined,
    };
  }

  @computed
  get resultItems() {
    return this.results
      .map((slug) => this.items.get(slug)!)
      .filter((item) => !!item);
  }

  @action
  public setSearch = (search: string) => {
    this.search = search;
    this.loadItems(true);
  };

  @action
  public setTags = (tags: string[]) => {
    this.tags = tags;
    this.loadItems(true);
  };

  @action
  public setStatus = (status: string[]) => {
    this.status = status;
    this.loadItems(true);
  };

  @action
  public setSort = (sort: string) => {
    this.sort = sort;
    this.loadItems(true);
  };

  @action
  public setTypes = (types: IdeaboxType[]) => {
    this.types = types;
    this.loadItems(true);
  };

  @computed
  get hasNext() {
    return this.next && this.next.length !== 0;
  }

  @action.bound
  public resetUrl() {
    this.next = null;
    this.previous = null;
    this.currentUrl = null;
  }

  @action.bound
  public reset(withLoad: boolean = true) {
    this.types = ['idea', 'problem', 'watch'];
    this.tags = [];
    this.sort = '-date_created';
    this.search = '';
    this.status = [];
    this.results = [];
    if (withLoad) {
      this.loadItems(true);
    }
  }
}

export default IdeaboxStore;
