import { computed, observable, runInAction } from 'mobx';
import LabApi from './api';
import { LabProcess, LabProject } from './models';

class LabStore {
  public projectsBySlug = observable.map<string, LabProject>();

  @observable
  public processList: LabProcess[] = [];

  public async loadProject(slug: string, feature: string) {
    const p = this.projectsBySlug.get(slug);
    if (p) return p;

    const data = await LabApi.getProject(slug, feature);
    const project = new LabProject(data);
    runInAction(() => {
      this.projectsBySlug.set(project.slug, project);
    });
    return project;
  }

  public async loadProjects(feature: string = 'lab') {
    const data = await LabApi.getProjects(null, feature);
    data.results.forEach((d: any) => {
      runInAction(() => {
        const p = this.projectsBySlug.get(d.slug);
        if (p) {
          p._update(d);
        } else {
          const project = new LabProject(d);
          this.projectsBySlug.set(project.slug, project);
        }
      });
    });
  }

  public async loadProcesses() {
    if (this.processList.length !== 0) return;
    const data = await LabApi.getProcesses();
    runInAction(() => {
      this.processList = data
        .map((p: any) => new LabProcess(p))
        .filter((p: LabProcess) => !p.data.deleted);
    });
  }

  public async createProject(projectData: any) {
    const data = await LabApi.createProject(projectData);
    const project = new LabProject(data);
    runInAction(() => {
      this.projectsBySlug.set(project.slug, project);
    });
    return project;
  }

  public async createProcess(processData: any) {
    const data = await LabApi.createProcess(processData);
    const process = new LabProcess(data);
    runInAction(() => {
      this.processList.unshift(process);
    });
    return process;
  }

  public async deleteProject(project: LabProject) {
    await LabApi.deleteProject(project);
    runInAction(() => {
      this.projectsBySlug.delete(project.slug);
    });
  }

  public async deleteProcess(process: LabProcess) {
    process.setData({ deleted: true });
    runInAction(() => {
      this.processList = this.processList.filter((p) => p.id !== process.id);
    });
  }

  @computed
  get projects() {
    return Array.from(this.projectsBySlug.values());
  }

  public getProcessList(feature: string) {
    return this.processList.filter((c) => c.feature === feature);
  }
}

export default LabStore;
