import React from 'react';

import AcademyIcon from '~/assets/images/icons/academy.svg';
import Interrogation from '~/assets/images/icons/askcom.svg';
import Astro from '~/assets/images/icons/astronaut.svg';
import ChallengeIcon from '~/assets/images/icons/challenge.svg';
import Cloud from '~/assets/images/icons/cloud.svg';
import CommunitiesIcon from '~/assets/images/icons/communities.svg';
import EcosystemIcon from '~/assets/images/icons/ecosystem.svg';
import Gear from '~/assets/images/icons/gear.svg';
import HomeIcon from '~/assets/images/icons/home.svg';
import IdeaboxIcon from '~/assets/images/icons/ideabox.svg';
import IntraIcon from '~/assets/images/icons/intra.svg';
import Lab2Icon from '~/assets/images/icons/lab2.svg';
import Lab1Icon from '~/assets/images/icons/lab3.svg';
import MedCross from '~/assets/images/icons/med_cross.svg';
import OpenInnoChallengeIcon from '~/assets/images/icons/openinno_challenges.svg';
import ProjectIcon from '~/assets/images/icons/projets.svg';

import { BusinessCenterRounded } from '@material-ui/icons';
import {
  blue,
  communitiesColor,
  green,
  openInnoColor,
  pink,
  red,
  yellow,
} from '~/constants/colors';
import { Building } from '~/models/global';

type Icon =
  | 'home'
  | 'lab3'
  | 'lab2'
  | 'med_cross'
  | 'astro'
  | 'gear'
  | 'cloud'
  | 'interrogation';

interface Props {
  building: Building | Icon;
  size?: number;
  color?: string;
  disableDefaultColor?: boolean;
}

export default (props: Props) => {
  if (props.building === 'home') {
    return (
      <HomeIcon
        style={{
          color: props.color || 'inherit',
          minHeight: `${props.size || 22}px`,
          minWidth: `${props.size || 22}px`,
        }}
        height={props.size || 22}
        width={props.size || 22}
      />
    );
  }
  if (props.building === 'gear') {
    return (
      <Gear
        style={{
          color: props.color || 'inherit',
          minHeight: `${props.size || 22}px`,
          minWidth: `${props.size || 22}px`,
        }}
        height={props.size || 22}
        width={props.size || 22}
      />
    );
  }
  if (props.building === 'interrogation') {
    return (
      <Interrogation
        style={{
          color: props.color || 'inherit',
          minHeight: `${props.size || 22}px`,
          minWidth: `${props.size || 22}px`,
        }}
        height={props.size || 22}
        width={props.size || 22}
      />
    );
  }
  if (props.building === 'cloud') {
    return (
      <Cloud
        style={{
          color: props.color || 'inherit',
          minHeight: `${props.size || 22}px`,
          minWidth: `${props.size || 22}px`,
        }}
        height={props.size || 22}
        width={props.size || 22}
      />
    );
  }
  if (props.building === 'lab3') {
    return (
      <Lab1Icon
        style={{
          color: props.color || (props.disableDefaultColor ? undefined : pink),
          minHeight: `${props.size || 22}px`,
          minWidth: `${props.size || 22}px`,
        }}
        height={props.size || 22}
        width={props.size || 22}
      />
    );
  }
  if (props.building === 'med_cross') {
    return (
      <MedCross
        style={{
          color: props.color || (props.disableDefaultColor ? undefined : pink),
          minHeight: `${props.size || 22}px`,
          minWidth: `${props.size || 22}px`,
        }}
        height={props.size || 22}
        width={props.size || 22}
      />
    );
  }
  if (props.building === 'astro') {
    return (
      <Astro
        style={{
          color: props.color || (props.disableDefaultColor ? undefined : pink),
          minHeight: `${props.size || 22}px`,
          minWidth: `${props.size || 22}px`,
        }}
        height={props.size || 22}
        width={props.size || 22}
      />
    );
  }
  if (props.building === 'lab2') {
    return (
      <Lab2Icon
        style={{
          color: props.color || (props.disableDefaultColor ? undefined : pink),
          minHeight: `${props.size || 22}px`,
          minWidth: `${props.size || 22}px`,
        }}
        height={props.size || 22}
        width={props.size || 22}
      />
    );
  }
  if (props.building === 'intra' || props.building === 'lab') {
    return (
      <IntraIcon
        style={{
          color: props.color || (props.disableDefaultColor ? undefined : pink),
          minHeight: `${props.size || 22}px`,
          minWidth: `${props.size || 22}px`,
        }}
        height={props.size || 22}
        width={props.size || 22}
      />
    );
  }
  if (props.building === 'projects') {
    return (
      <ProjectIcon
        style={{
          color: props.color || (props.disableDefaultColor ? undefined : green),
          minHeight: `${props.size || 22}px`,
          minWidth: `${props.size || 22}px`,
        }}
        height={props.size || 22}
        width={props.size || 22}
      />
    );
  }
  if (props.building === 'academy') {
    return (
      <AcademyIcon
        style={{
          color: props.color || (props.disableDefaultColor ? undefined : blue),
          minHeight: `${props.size || 22}px`,
          minWidth: `${props.size || 22}px`,
        }}
        height={props.size || 22}
        width={props.size || 22}
      />
    );
  }
  if (props.building === 'ideabox') {
    return (
      <IdeaboxIcon
        style={{
          color:
            props.color || (props.disableDefaultColor ? undefined : yellow),
          minHeight: `${props.size || 24}px`,
          minWidth: `${props.size || 24}px`,
        }}
        height={props.size || 24}
        width={props.size || 24}
      />
    );
  }
  if (props.building === 'challenges') {
    return (
      <ChallengeIcon
        style={{
          color: props.color || (props.disableDefaultColor ? undefined : red),
          minHeight: `${props.size || 24}px`,
          minWidth: `${props.size || 24}px`,
        }}
        height={props.size || 24}
        width={props.size || 24}
      />
    );
  }
  if (props.building === 'openinno') {
    return (
      <EcosystemIcon
        style={{
          color:
            props.color ||
            (props.disableDefaultColor ? undefined : openInnoColor),
          minHeight: `${props.size || 24}px`,
          minWidth: `${props.size || 24}px`,
        }}
        height={props.size || 24}
        width={props.size || 24}
      />
    );
  }
  if (
    props.building === 'openinno.challenges' ||
    props.building === 'oi_contest'
  ) {
    return (
      <OpenInnoChallengeIcon
        style={{
          color:
            props.color ||
            (props.disableDefaultColor ? undefined : openInnoColor),
          minHeight: `${props.size || 24}px`,
          minWidth: `${props.size || 24}px`,
        }}
        height={props.size || 24}
        width={props.size || 24}
      />
    );
  }

  if (props.building === 'openinno.projects') {
    return (
      <BusinessCenterRounded
        style={{
          color:
            props.color ||
            (props.disableDefaultColor ? undefined : openInnoColor),
          minHeight: `${props.size || 24}px`,
          minWidth: `${props.size || 24}px`,
        }}
        height={props.size || 24}
        width={props.size || 24}
      />
    );
  }
  if (props.building === 'openinno.partnerships') {
    return (
      <EcosystemIcon
        style={{
          color:
            props.color ||
            (props.disableDefaultColor ? undefined : openInnoColor),
          minHeight: `${props.size || 24}px`,
          minWidth: `${props.size || 24}px`,
        }}
        height={props.size || 24}
        width={props.size || 24}
      />
    );
  }
  if (props.building === 'communities') {
    return (
      <CommunitiesIcon
        style={{
          color:
            props.color ||
            (props.disableDefaultColor ? undefined : communitiesColor),
          minHeight: `${props.size || 24}px`,
          minWidth: `${props.size || 24}px`,
        }}
        height={props.size || 24}
        width={props.size || 24}
      />
    );
  }
  return null;
};
