import { Tooltip } from '@material-ui/core';
import React from 'react';
import AmbassadorIcon from '~/assets/images/icons/award_star.svg';
import ModeratorIcon from '~/assets/images/icons/shield_star.svg';
import i18n from '~/locales/i18n';
import { Building } from '~/models/global';
import { MiniUser } from '~/models/user';
import stores from '~/stores';
import { css } from '~/styles';
import theme from '~/styles/theme';

interface Props {
  user: MiniUser;
  size?: number;
}

const getModeratorTitle = (user: MiniUser) => {
  if (
    user.user_type === 'admin' ||
    (user.roles && user.roles.find((c) => c === 'organization:admin'))
  ) {
    return i18n.formatMessage('tooltips.is_admin');
  }
  if (user.roles && user.roles.length) {
    let res = `${i18n.formatMessage('tooltips.moderator')} `;
    let prefix = '';
    user.roles.forEach((role) => {
      if (role.indexOf(':admin') !== -1) {
        const building = role.split(':admin')[0];
        if (building === 'dashboard') return;

        const parts = building.split('://');
        res += prefix;

        if (parts.length === 2) {
          const config = stores.organizationStore.getModuleConfig(
            parts[0] as Building,
            parts[1],
          );
          res += config?.name;
        } else {
          res += i18n.formatMessage(`buildings.${building}`);
        }

        prefix = ', ';
      }
    });
    return res;
  }
  return '';
};

class UserIcon extends React.PureComponent<Props> {
  public render() {
    const { user } = this.props;

    return (
      <>
        {user.user_type === 'ambassador' && (
          <Tooltip
            title={i18n.formatMessage('tooltips.ambassador')}
            classes={{
              tooltip: css`
                text-align: left !important;
              `,
            }}
          >
            <div
              style={{
                marginLeft: '5px',
                color: theme.current.palette.secondary.main,
                marginBottom: '-3px',
              }}
            >
              <AmbassadorIcon
                width={this.props.size || 20}
                height={this.props.size || 20}
              />
            </div>
          </Tooltip>
        )}
        {(user.user_type === 'admin' || user.user_type === 'moderator') && (
          <Tooltip
            title={getModeratorTitle(user)}
            classes={{
              tooltip: css`
                text-align: left !important;
              `,
            }}
          >
            <div
              style={{
                marginLeft: '5px',
                color: theme.current.palette.primary.main,
                marginBottom: '-3px',
              }}
            >
              <ModeratorIcon
                width={this.props.size || 20}
                height={this.props.size || 20}
              />
            </div>
          </Tooltip>
        )}
      </>
    );
  }
}

export default UserIcon;
