export default function retry<T>(
  // tslint:disable-next-line: ban-types
  fn: Function,
  retriesLeft = 5,
  interval = 500,
) {
  return new Promise<T>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error: any) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}
