import { DateTime } from 'luxon';
import { computed, observable } from 'mobx';
import intraOnBoarding from '~/components/Intra/OnBoarding';
import buildingOnBoarding from '~/components/OnBoarding/dialogs';
import { FolderId } from '~/constants/intra';
import { MiniUser } from '~/models/user';
import { Socialable, UploadedFile, UploadedImage } from '~/models/utils';
import stores from '~/stores';
import { MiniBoard } from './notes';

export type IntraStatus = 'useless' | 'implemented';

export class IntraItem extends Socialable {
  public id: number;
  public name: string;
  public slug: string;
  public description: string;
  public images: UploadedImage[];
  public files: UploadedFile[];
  public tags: string[];

  @observable
  public owner: MiniUser;
  public date_created: DateTime;

  public hit_count: number;

  @observable
  public hit: boolean;

  @observable
  public need_change: boolean;

  @observable
  public published: boolean;

  @observable
  public date_published: DateTime;

  @observable
  public requested: boolean;

  @observable
  public invited: boolean;

  @observable
  public member_count: number;

  @observable
  public members: MiniUser[];

  @observable
  public status?: IntraStatus;

  @observable
  public status_message?: string;

  @observable
  public project: {
    name: string;
    slug: string;
    status_moderation_message: string;
    status_file: string;
  } | null;

  constructor(data: any) {
    super('intraitem', data);
    Object.assign(this, data);
  }

  public update(data: any) {
    Object.assign(this, data);
  }

  get thumbnail() {
    return this.images.length === 0 ? '' : this.images[0].image;
  }

  @computed
  get isMember() {
    return (
      this.isOwner ||
      this.members.find((u) => u.id === stores.authStore.user.id)
    );
  }

  @computed
  get isOwner() {
    return this.owner.id === stores.authStore.user.id;
  }

  @computed
  get isOwnerOrAdmin() {
    return (
      this.owner.id === stores.authStore.user.id ||
      stores.authStore.isFeatureAdmin('intra')
    );
  }

  public checkOnBoarding() {
    if (!this.isOwner && !this.isMember) {
      buildingOnBoarding('intra');
      return;
    }
    if (!stores.authStore.storage.contains('intra-onboarding', this.id)) {
      intraOnBoarding(this.isOwner);
      stores.authStore.storage.append('intra-onboarding', this.id);
    } else {
      buildingOnBoarding('intra');
    }
  }
}

export class Folder {
  public folder_id: FolderId;
  public id: number;

  @observable
  public conversation: number;

  @observable
  public board: MiniBoard;

  @observable
  public file_count: number;

  constructor(data: any) {
    this.folder_id = data.folder_id;
    this.id = data.id || 0;
    this.conversation = data.conversation || 0;
    this.board = data.board || { id: 0, count: 0 };
    this.file_count = data.file_count || 0;
  }

  @computed
  get newChatCount() {
    const conv = stores.chatStore.conversations.find(
      (c) => c.id === this.conversation,
    );
    if (!conv) return 0;
    if (!conv.last_message) return 0;
    if (conv.last_message.user === stores.authStore.user.id) return 0;
    if (conv.last_message.read) return 0;
    return 1;
  }

  @computed
  get newFileCount() {
    const oldValue = stores.intraStore.fileStatus[this.id];
    if (!oldValue) return this.file_count;
    if (oldValue < this.file_count) return this.file_count - oldValue;
    return 0;
  }

  @computed
  get newNoteCount() {
    const oldValue = stores.globalStore.boardStatus[this.board.id];
    if (!oldValue) return this.board.count;
    if (oldValue < this.board.count) return this.board.count - oldValue;
    return 0;
  }
}

export interface FolderUploadedFile {
  id: number;
  file: string;
  date_created: DateTime;
  owner: number;
}

export interface FolderModelFile {
  id: number;
  file: string;
  date_created: DateTime;
  name: string;
  description: string;
}

export class IntraPost extends Socialable {
  @observable
  public date_created: DateTime;

  @observable
  public title: string;

  @observable
  public content: string;

  @observable
  public pinned: boolean;

  @observable
  public images: UploadedImage[];

  @observable
  public files: UploadedFile[];

  constructor(data: any) {
    super('intrapost', data);
    Object.assign(this, data);
  }

  public update(data: any) {
    Object.assign(this, data);
  }
}
