import MuiDialog, { DialogProps } from '@material-ui/core/Dialog';
import { css } from 'emotion';
import { observer } from 'mobx-react';
import React from 'react';

import theme from '~/styles/theme';
import withStore, { WithStoreProps } from '~/utils/withStore';
import { mobilePWA } from '../../utils/responsive';

const DialogPaperClass = css`
  &&& {
    width: 100%;
    max-width: 600px;
    overflow: hidden;
    border-radius: 7px;

    ${theme.current.breakpoints.down('sm')} {
      margin: 10px;
      height: calc(100vh - 76px);
      margin-top: 56px;
      max-width: unset;
    }

    ${mobilePWA} {
      margin-top: 10px;
      height: calc(100vh - 20px);
    }
  }
`;

const SmallDialogPaperClass = css`
  &&& {
    width: 100%;
    max-width: 600px;
    margin: 10px;
    overflow: hidden;
    border-radius: 3px;

    ${theme.current.breakpoints.down('sm')} {
      max-width: unset;
    }
  }
`;

const LargeDialogPaperClass = css`
  ${DialogPaperClass};
  &&& {
    max-width: 1100px;
  }
`;

const XLargeDialogPaperClass = css`
  ${DialogPaperClass};
  &&& {
    max-width: 1400px;
  }
`;

const MediumDialogPaperClass = css`
  ${DialogPaperClass};
  &&& {
    max-width: 700px;
  }
`;

interface Props {
  index?: number;
  large?: boolean;
  small?: boolean;
  xLarge?: boolean;
  medium?: boolean;
}

@observer
class Dialog extends React.Component<Props & DialogProps & WithStoreProps> {
  private id: number;

  public componentDidMount() {
    this.id = new Date().getTime();
    if (!document.body.classList.contains('has-dialog')) {
      document.body.classList.add('has-dialog');
    }
    document.body.classList.add(`dialog-${this.id}`);
  }

  public componentWillUnmount() {
    document.body.classList.remove(`dialog-${this.id}`);
    const classes = Array.from(document.body.classList);
    const dialogClasses = classes.filter((c) => c.startsWith('dialog-'));
    if (dialogClasses.length === 0) {
      document.body.classList.remove('has-dialog');
    }
  }

  public render() {
    const { large, small, xLarge, medium } = this.props;
    return (
      <MuiDialog
        disableBackdropClick
        classes={{
          paper: xLarge
            ? XLargeDialogPaperClass
            : large
            ? LargeDialogPaperClass
            : small
            ? SmallDialogPaperClass
            : medium
            ? MediumDialogPaperClass
            : DialogPaperClass,
        }}
        {...this.props}
      />
    );
  }
}

export default withStore(Dialog);
