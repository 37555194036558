import { DateTime } from 'luxon';
import { observable } from 'mobx';
import { StatusConfig } from '~/modules/lab/models';
import { Building } from './global';
import { IdeaboxType } from './ideabox';

export interface Theme {
  primary: string;
  secondary?: string;
}

export interface SSOConfig {
  sso_logout: boolean;
  enable_password?: boolean;
}

export interface PartnerConfig {
  disableLogo?: boolean;
  disableWebsite?: boolean;
  disableDescription?: boolean;
  disableLocation?: boolean;
  disableNote?: boolean;
  sections?: {
    disabled: boolean;
    name: string;
    values: string[];
  }[];
}

export interface CustomLikesConfig {
  name: string;
  icon: string;
  user_types: string[];
}

export interface IdeaboxStatusData {
  name: string;
  description?: string;
  users?: boolean;
  lab_feature?: string;
}

export interface OrganizationConfig {
  goal: string;
  profile_xp: number | null;
  app_xp: number | null;

  ideabox_goal: string;
  ideabox_types: IdeaboxType[];
  ideabox_status: {
    [type: string]: {
      delay?: number;
      moderator_only?: boolean;
      disabled?: boolean;
      status: IdeaboxStatusData[];
    };
  };
  ideabox_allow_team: boolean;
  ideabox_allow_anonymous: boolean;
  ideabox_hide_similar: boolean;
  ideabox_tags: string[];
  idea_create_xp: number | null;
  problem_create_xp: number | null;
  watch_create_xp: number | null;
  solve_problem_xp: number | null;

  challenges_goal: string;
  challenges_create_anyone: boolean;
  challenges_need_moderation: boolean;
  challenges_tags: string[];
  challenges_create_xp: number | null;
  challenges_contribute_xp: number | null;
  challenges_winner_xp: number | null;

  projects_goal: string;
  projects_create_anyone: boolean;
  projects_tags: string[];
  projects_create_xp: number | null;
  projects_member_xp: number | null;
  projects_contribute_xp: number | null;
  projects_winner_xp: number | null;

  intra_goal: string;
  intra_create_anyone: boolean;
  intra_tags: string[];
  intra_create_xp: number | null;
  intra_need_file: boolean;

  openinno_challenges_goal: string;
  openinno_challenges_create_anyone: boolean;
  openinno_partnerships_goal: string;
  openinno_partnerships_create_anyone: boolean;
  openinno_partnerships_member_xp: number | null;
  openinno_projects_create_xp: number | null;
  openinno_partners_create_xp: number | null;
  openinno_projects_tags: string[];

  openinno_partners_create_anyone: boolean;
  openinno_projects_create_anyone: boolean;
  openinno_partners_config: PartnerConfig;
  openinno_projects_status: string[];

  terms_enabled: boolean;
  terms_version: number;

  onboarding: boolean;
  welcome_message: string;
  hidden_buildings: string[];
  hidden_buildings_all: string[];
  hidden_separators: string[];
  building_names: any;
  building_order: string[];
  external_formations: any;
  disable_general_infos: boolean;

  newsletter_email_subject: string;
  newsletter_email_from: string;

  allow_registration: boolean;
  restrict_emails: boolean;
  email_domain_whitelist: string[];
  disabled_onboardings: string[];

  lab_configs: Record<
    string,
    {
      tags: string[];
      disable_empty_process?: boolean;
      empty_process_status_config?: StatusConfig;
      icon: Building;
      name: string;
      create_xp: number | null;
      poll_xp: number | null;
      member_xp: number | null;
      disable_moderation?: boolean;
      only_moderators?: boolean;
    }
  >;

  contest_configs: Record<
    string,
    {
      tags: string[];
      icon: Building;
      name: string;
      create_xp: number | null;
      contribute_xp: number | null;
      disable_moderation?: boolean;
      only_moderators?: boolean;
      direct_link?: boolean;
    }
  >;

  academy_configs: Record<
    string,
    {
      tags: string[];
      types: string[];
      icon: Building;
      name: string;
      create_xp: number | null;
      disable_moderation?: boolean;
      only_moderators?: boolean;
      hide_similar?: boolean;
    }
  >;

  campus_name?: string;
  sidebar_config: string[];
  enabled_infos: string[];
  info_settings: Record<string, 'info' | 'force' | 'once'>;
  xp_enabled: boolean;
  oi_contest_tags: string[];
  communities_create_anyone: boolean;

  custom_likes: Record<string, CustomLikesConfig | null>;
}

export class Organization {
  public id: number;
  public slug: string;

  @observable
  public logo: string;
  public origin: string;
  public name: string;

  @observable
  public theme: Theme;

  @observable
  public features: string[];

  @observable
  public interests: string[];

  @observable
  public competences: string[];

  @observable
  public tags: string[];

  @observable
  public config: OrganizationConfig;

  @observable
  public groups: string[];

  @observable
  public campus_image?: string;

  @observable
  public favicon?: string;

  public maintenance: boolean;

  @observable
  public join_token?: string;

  public bloomup_conversation?: number;

  public date_created: DateTime;
  public owner_id?: number;

  public saml?: SSOConfig;
  public active_directory?: SSOConfig;
  public auth_backend: 'password' | 'adfs' | 'saml';

  @observable
  public language: 'fr' | 'en';

  constructor(data: any) {
    Object.assign(this, data);
    if (!this.config.lab_configs) this.config.lab_configs = {};
    if (!this.config.contest_configs) this.config.contest_configs = {};
  }

  public update(data: any) {
    Object.assign(this, data);
    if (!this.config.lab_configs) this.config.lab_configs = {};
    if (!this.config.contest_configs) this.config.contest_configs = {};
  }
}

export interface Invitation {
  email: string;
  id: number;
}
