import { observer } from 'mobx-react';
import React from 'react';
import MediaQuery from 'react-responsive';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import Swipeable from 'react-swipeable';
import Layout from './Layout';

import { injectGlobal } from 'emotion';
import { runInAction } from 'mobx';
import AppBar from '~/components/Design/AppBar';
import Sidebar from '~/components/Design/Sidebar';
import Content from '~/components/Layout/Content';
import DialogStack from '~/components/Layout/DialogStack';
import Page from '~/components/Page';
import Topbar from '~/components/Topbar';
import { backgroundColor } from '~/constants/colors';
import styled from '~/styles';
import theme from '~/styles/theme';
import toMediaQuery from '~/utils/toMediaQuery';
import withStore, { WithStoreProps } from '~/utils/withStore';
import ChatPopup from '../Chat/ChatPopup';
import OnBoarding from '../OnBoarding';
import { collapsedSidebarWidth, sidebarWidth } from '../Sidebar';
import InstallPrompt from './InstallPrompt';
import Terms from './Terms';

const Overlay = styled.div`
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1012;
  height: 100%;
  width: 100%;
`;

const ContentSide = styled.div<{ sidebarCollapsed?: boolean }>`
  flex-grow: 1;
  background-color: ${backgroundColor};
  position: relative;
  z-index: 1000;
  transition: max-width 0.3s;
  max-width: calc(
    100% -
      ${(p) => (p.sidebarCollapsed ? collapsedSidebarWidth : sidebarWidth)}px
  );

  ${(p) => p.theme.breakpoints.down('sm')} {
    max-width: 100%;
  }
`;

const Shadow = styled.div<{ external?: boolean }>`
  position: fixed;
  height: calc(100% - 56px);
  box-shadow: -4px 0 4px rgba(0, 0, 0, 0.2);
  width: 10px;
  margin-top: 56px;

  ${(p) =>
    p.external &&
    `
    margin-top: 0;
    height: 100%;
  `}
`;

interface Props {
  width?: number;
  padding?: number;
}

@observer
class CampusLayout extends React.Component<
  Props & WithStoreProps & RouteComponentProps<any>
> {
  public handleSwipeLeft = () => {
    this.props.store.uiStore.setSidebarCollapsed(true);
  };

  public componentDidMount() {
    // tslint:disable-next-line:no-unused-expression
    injectGlobal`
      ${theme.current.breakpoints.down('sm')} {
        #nprogress .bar, #nprogress .spinner-icon {
          margin-top: 56px;
        }
      }
    `;
    const { store } = this.props;
    if (store.authStore.next && store.authStore.next.length) {
      window.location.href = `${store.authStore.next}${window.location.hash}`;
    }
  }

  public componentWillUnmount() {
    // tslint:disable-next-line:no-unused-expression
    injectGlobal`
      ${theme.current.breakpoints.down('sm')} {
        #nprogress .bar, #nprogress .spinner-icon {
          margin-top: unset;
        }
      }
    `;
  }

  public render() {
    const { children, store, width, padding } = this.props;

    if (store.authStore.next && store.authStore.next.startsWith('/')) {
      const res = <Redirect to={store.authStore.next} />;
      runInAction(() => {
        store.authStore.next = null;
      });
      return res;
    }

    return (
      <Swipeable onSwipedLeft={this.handleSwipeLeft} delta={90}>
        <Layout>
          {!this.props.location.pathname.startsWith('/chat') &&
            store.authStore.authenticated && <ChatPopup />}

          {!store.authStore.isExternal &&
            store.organizationStore.organization.config.onboarding &&
            store.authStore.user.on_boarding_version === 0 && <OnBoarding />}

          {!store.authStore.isExternal &&
            store.organizationStore.organization.config.terms_enabled &&
            store.organizationStore.organization.config.terms_version &&
            store.authStore.user.terms_version !==
              store.organizationStore.organization.config.terms_version && (
              <Terms />
            )}

          <MediaQuery
            query={toMediaQuery(theme.current.breakpoints.down('sm'))}
          >
            <Topbar />
            {!store.uiStore.sidebarCollapsed && (
              <Overlay onClick={store.uiStore.toggleSidebar} />
            )}
          </MediaQuery>
          <Sidebar />

          <ContentSide sidebarCollapsed={store.uiStore.sidebarCollapsed}>
            <Shadow external={store.authStore.isExternal} />
            {!store.authStore.isExternal && <AppBar />}
            <Content
              padding={padding}
              sidebarCollapsed={store.uiStore.sidebarCollapsed}
            >
              <Page width={width}>{children}</Page>
            </Content>
          </ContentSide>
          <DialogStack />

          {store.globalStore.displayInstallPromp && <InstallPrompt />}
        </Layout>
      </Swipeable>
    );
  }
}

export default withStore(withRouter(CampusLayout));
