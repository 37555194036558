export const forceDownload = (blobURL: string, filename: string) => {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.download = filename;
  a.href = blobURL;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const getBlob = (url: string) => {
  return fetch(url, {
    headers: new Headers({
      Origin: location.origin,
    }),
    credentials: 'include',
    mode: 'cors',
  }).then((response) => response.blob());
};

const downloadFile = (url: string, filename: string) => {
  return fetch(url, {
    headers: new Headers({
      Origin: location.origin,
    }),
    credentials: 'include',
    mode: 'cors',
  })
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);

      forceDownload(blobUrl, filename);
    });
};

export default downloadFile;
