import DialogContent, {
  DialogContentProps,
} from '@material-ui/core/DialogContent';
import React from 'react';

import { Typography } from '@material-ui/core';
import styled from '~/styles';
import Loader from '../Page/Loader';

const Content = styled(DialogContent)``;

interface CustomDialogProps extends DialogContentProps {
  loading?: boolean;
}

export default ({ children, loading, ...props }: CustomDialogProps) => (
  <Content {...props} className="custom-scrollbar">
    {loading ? <Loader /> : <Typography component="div">{children}</Typography>}
  </Content>
);
