import { computed, observable, runInAction } from 'mobx';
import { MiniUser } from '~/models/user';
import stores from '~/stores';
import TeamsApi from './api';

export class Team {
  public id: number;

  @observable
  public members: MiniUser[];

  @observable
  public owners: MiniUser[];

  @observable
  public owner: number;

  public feature: string;

  @observable
  public requests: MiniUser[] = [];

  constructor(data: any) {
    Object.assign(this, data);
  }

  public update(data: any) {
    Object.assign(this, data);
  }

  @computed
  get isMember() {
    return !!this.members.find((u) => u.id === stores.authStore.user.id);
  }

  @computed
  get isMemberOrTeamAdmin() {
    return this.isMember || this.isTeamAdmin;
  }

  @computed
  get isOwner() {
    return this.owners.some((u) => u.id === stores.authStore.user.id);
  }

  public isUserOwner(user: MiniUser) {
    return this.owners.some((u) => u.id === user.id);
  }

  public isUserMember(user: MiniUser) {
    return this.members.some((u) => u.id === user.id);
  }

  @computed
  get isFeatureModerator() {
    if (this.feature) {
      return stores.authStore.isFeatureAdmin(this.feature as any);
    }
    return stores.authStore.isModerator;
  }

  @computed
  get isTeamAdmin() {
    return this.isOwner || this.isFeatureModerator;
  }

  @computed
  get memberCount() {
    return this.members.length - this.owners.length;
  }

  @computed
  get fullMemberCount() {
    return this.members.length;
  }

  public async addMembers(users: MiniUser[]) {
    const data = await TeamsApi.addMembers(this, users);
    this.update(data);
  }

  public async removeMembers(users: MiniUser[]) {
    const data = await TeamsApi.removeMembers(this, users);
    this.update(data);
  }

  public async addOwners(users: MiniUser[]) {
    const data = await TeamsApi.addOwners(this, users);
    this.update(data);
  }

  public async removeOwners(users: MiniUser[]) {
    const data = await TeamsApi.removeOwners(this, users);
    this.update(data);
  }

  public async updateOwner(user: MiniUser) {
    const data = await TeamsApi.updateOwner(this, user);
    this.update(data);
  }

  public async hasRequested() {
    return await TeamsApi.requested(this);
  }

  public async request() {
    await TeamsApi.request(this);
  }

  public async loadRequests() {
    this.requests = await TeamsApi.getJoinRequests(this);
  }

  public async deleteRequest(user: MiniUser) {
    await TeamsApi.deleteRequest(this, user);
    runInAction(() => {
      this.requests = this.requests.filter((c) => c.id !== user.id);
    });
  }

  public async acceptRequest(user: MiniUser) {
    this.addMembers([user]);
    runInAction(() => {
      this.requests = this.requests.filter((c) => c.id !== user.id);
    });
  }
}
