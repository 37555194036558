import { Banner, ChangeLogEntry } from '~/models/global';
import api from './api';

async function getBanner(): Promise<Banner> {
  const { data } = await api.get('/global/banner');
  return data;
}

async function getChangeLog(): Promise<ChangeLogEntry[]> {
  const { data } = await api.get('/global/changelog');
  return data;
}

export default {
  getBanner,
  getChangeLog,
};
