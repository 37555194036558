import { Avatar as MuiAvatar } from '@material-ui/core';
import { css } from 'emotion';
import { observer } from 'mobx-react';
import React from 'react';
import { SECONDARY_COLORS } from '~/constants/colors';
import getFullUrl from '~/utils/getFullUrl';
import getUrlWithoutParams from '~/utils/getUrlWithoutParams';

const AvatarClass = css`
  border: 1px solid rgba(0, 0, 0, 0.1);
`;

const BotAvatarClass = css`
  border-radius: 3px;
`;

const COLORS = [...Object.keys(SECONDARY_COLORS)];

const getColor = (username: string) => {
  let r = 0;
  // tslint:disable-next-line:no-increment-decrement
  for (let i = 0; i < username.length; i++) {
    r += username.charCodeAt(i);
  }
  return COLORS[r % COLORS.length];
};

interface Props {
  user: { fullname: string; username?: string; avatar: string | null };
  size?: number;
  anonymous?: boolean;
  isBot?: boolean;
}

@observer
class Avatar extends React.Component<Props> {
  public render() {
    const { user, size, anonymous } = this.props;
    const withSizeProps = {
      style: {
        width: `${size}px`,
        height: `${size}px`,
        backgroundColor:
          !user.avatar || user.avatar.length === 0
            ? getColor(user.username || user.fullname)
            : undefined,
      },
    };

    const withoutSizeProps = {
      style: {
        backgroundColor:
          !user.avatar || user.avatar.length === 0
            ? getColor(user.fullname)
            : undefined,
      },
    };

    const customProps = size ? withSizeProps : withoutSizeProps;

    if (anonymous) {
      // tslint:disable: campus-use-i18n
      return (
        <MuiAvatar classes={{ root: AvatarClass }} {...customProps}>
          C
        </MuiAvatar>
      );
      // tslint:enable: campus-use-i18n
    }
    if (user.avatar && user.avatar.length) {
      return (
        <MuiAvatar
          classes={{ root: this.props.isBot ? BotAvatarClass : AvatarClass }}
          src={
            this.props.isBot
              ? user.avatar
              : getFullUrl(getUrlWithoutParams(user.avatar))
          }
          {...customProps}
        />
      );
    }
    return (
      <MuiAvatar classes={{ root: AvatarClass }} {...customProps}>
        {user.fullname[0].toUpperCase()}
      </MuiAvatar>
    );
  }
}

export default Avatar;
