import { keyframes } from 'emotion';
import React from 'react';
import styled from '~/styles';

const loaderAnimation1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

const loaderAnimation2 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const loaderAnimation3 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
`;

const Loader = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;

  div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  div:nth-child(1) {
    left: 6px;
    animation: ${loaderAnimation1} 0.6s infinite;
  }
  div:nth-child(2) {
    left: 6px;
    animation: ${loaderAnimation2} 0.6s infinite;
  }
  div:nth-child(3) {
    left: 26px;
    animation: ${loaderAnimation2} 0.6s infinite;
  }
  div:nth-child(4) {
    left: 45px;
    animation: ${loaderAnimation3} 0.6s infinite;
  }
`;

export default () => (
  <Loader>
    <div />
    <div />
    <div />
    <div />
  </Loader>
);
