const VALID_EXTENSIONS = ['jpeg', 'jpg', 'png'];

const toImageName = (name: string) => {
  const parts = name.split('.');
  const extension = parts[parts.length - 1];
  if (VALID_EXTENSIONS.indexOf(extension) !== -1) return name;
  return `${parts.slice(0, -1).join('.')}.png`;
};

export default toImageName;
