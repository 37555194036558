import { injectGlobal } from 'emotion';
import { action, observable } from 'mobx';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

class Progress {
  @observable
  public currentCount: number = 0;

  @action
  public start() {
    this.currentCount += 1;
    NProgress.start();
  }

  @action
  public done() {
    this.currentCount -= 1;
    if (this.currentCount === 0) {
      NProgress.done();
    }
  }

  public update(val: number) {
    NProgress.inc(val);
  }

  public setColor(color: string) {
    // tslint:disable-next-line:no-unused-expression
    injectGlobal`
      #nprogress .bar {
        background-color: ${color}!important;
      }

      #nprogress .spinner-icon {
        border-top-color: ${color}!important;
        border-left-color: ${color}!important;
      }
    `;
  }
}

export default new Progress();
