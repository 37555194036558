import { Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from '~/styles';
import contentify from '~/utils/contentify';
import mediumZoom from '~/utils/mediumZoom';
import randomID from '~/utils/randomID';
import sanitize from '~/utils/sanitize';
import { getContentStyle } from '../TextEditor/newStyles';

const Content = styled(Typography)<{ smallTitle?: boolean }>`
  ${(p) => getContentStyle(p)}
`;

function ContentWrapper(props: { content: string; smallTitle?: boolean }) {
  const [id] = useState(randomID());

  useEffect(() => {
    mediumZoom(`#${id}.content-zoomable img`);
  }, []);

  return (
    <Content
      smallTitle={props.smallTitle}
      id={id}
      className="dont-break-out content-zoomable"
      dangerouslySetInnerHTML={{
        __html: sanitize(contentify(props.content || '')),
      }}
    />
  );
}

export default ContentWrapper;
