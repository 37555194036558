import { Button } from '@material-ui/core';
import withMobileDialog, {
  InjectedProps,
} from '@material-ui/core/withMobileDialog';
import { observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import OrganizationApi from '~/api/organization';
import Dialog from '~/components/dialogs/Dialog';
import DialogActions from '~/components/dialogs/DialogActions';
import DialogContent from '~/components/dialogs/DialogContent';
import DialogTitle from '~/components/dialogs/DialogTitle';
import withStore, { WithStoreProps } from '~/utils/withStore';
import ContentWrapper from '../utils/Content';
import Loader from '../utils/Loader';

@observer
class Terms extends React.Component<InjectedProps & WithStoreProps> {
  @observable
  public content: string = '';

  @observable
  public loading: boolean = true;

  public onSubmit = () => {
    this.props.store.authStore.updateCurrentUser({
      terms_version: this.props.store.organizationStore.organization.config
        .terms_version,
    });
  }

  public async componentDidMount() {
    try {
      const infos = await OrganizationApi.getInfos('terms');
      runInAction(() => {
        this.loading = false;
        this.content = infos;
      });
    } catch (_) {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  public render() {
    const props = this.props;

    return (
      <Dialog fullScreen={props.fullScreen} disableEscapeKeyDown large open>
        <DialogTitle>
          <FormattedMessage id="dialogs.cgu.title" />
        </DialogTitle>
        <DialogContent>
          {this.loading ? (
            <Loader />
          ) : (
            <ContentWrapper content={this.content} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onSubmit} variant="contained" color="primary">
            <FormattedMessage id="dialogs.cgu.button" />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMobileDialog<{}>()(withStore(Terms));
