import { action, computed, observable } from 'mobx';

export class Upload {
  @observable
  public progress: number;
  public filename: string;
  public onCancel: () => void;

  @observable
  public finished: boolean = false;

  constructor(filename: string, onCancel: () => void) {
    this.onCancel = onCancel;
    this.filename = filename;
    this.progress = 0;
  }

  @observable
  public end() {
    this.finished = true;
  }

  @action.bound
  public updateProgress(progress: number) {
    this.progress = progress;
  }
}

export class ProgressStore {
  @observable
  public uploads: Upload[] = [];

  @action
  public startUpload(filename: string, onCancel: () => void) {
    const u = new Upload(filename, onCancel);
    this.uploads.push(u);
    return u;
  }

  @action
  public endUpload(upload: Upload) {
    upload.end();
    this.uploads = this.uploads.filter((u) => u !== upload);
  }

  @action
  public cancelAllUploads = () => {
    this.uploads.forEach((u) => {
      u.onCancel();
    });
    this.uploads = [];
  };

  @computed
  get uploadProgress() {
    let x = 0;
    this.uploads.forEach((u) => {
      x += u.progress / this.uploads.length;
    });
    return x;
  }
}
