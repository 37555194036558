import styled from '~/styles';

const Layout = styled.div`
  min-height: 100vh;
  display: flex;
  background-color: ${(p) => p.theme.palette.primary.main};

  &&&& {
    .image-gallery-left-nav,
    .image-gallery-right-nav {
      font-size: 2.4em;
      padding: 0 15px;
    }

    .image-gallery-fullscreen-button:hover::before,
    .image-gallery-play-button:hover::before,
    .image-gallery-left-nav:hover::before,
    .image-gallery-right-nav:hover::before {
      color: ${(p) => p.theme.palette.primary.main};
    }

    .image-gallery-fullscreen-button::before,
    .image-gallery-play-button::before,
    .image-gallery-left-nav::before,
    .image-gallery-right-nav::before {
      text-shadow: 0 1x 1px rgb(0, 0, 0, 0.7);
      color: white;
    }

    .image-gallery-bullet {
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
      transition: background-color 0.2s;

      &.active {
        background-color: ${(p) => p.theme.palette.primary.main};
      }
    }
  }
`;

export default Layout;
