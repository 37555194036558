import { isUndefined } from 'util';
import api from '~/api/api';
import { ImageImportResult } from '~/components/ImageImport';
import { Token } from '~/models/auth';
import { CurrentUser } from '~/models/user';

async function login(email: string, password: string) {
  const { data: userAndToken } = await api.post('/auth/login', {
    email,
    password,
  });
  return userAndToken;
}

async function register(
  fullname: string,
  password: string,
  code: string,
  groups: string,
) {
  const { data: userAndToken } = await api.post('/auth/register', {
    fullname,
    password,
    code,
    groups: groups && groups.length ? groups : undefined,
  });
  return userAndToken;
}

async function logout() {
  await api.post('/auth/logout');
}

async function resetPassword(email: string) {
  await api.post('/auth/password_reset', {
    email,
  });
}

async function startRegistration(email: string) {
  await api.post('/auth/start_registration', {
    email,
  });
}

async function resetPasswordConfirm(
  uid: string,
  token: string,
  password: string,
  close: boolean,
) {
  await api.post('/auth/password_reset_confirm', {
    uid,
    token,
    password,
    close,
  });
}

async function getCurrentUser(): Promise<CurrentUser> {
  if (api.defaults.headers.common.Authorization) {
    const { data: user } = await api.get('/auth/current_user');
    return user;
  }
  throw new Error();
}

async function updateCurrentUser(
  data: Partial<CurrentUser>,
  avatar?: ImageImportResult | null,
): Promise<CurrentUser> {
  if (!avatar) {
    const { data: u } = await api.post('/auth/current_user', {
      ...data,
      avatar,
    });
    return u;
  }

  const formData = new FormData();

  Object.keys(data).forEach((k) => {
    if (isUndefined(data[k])) return;
    if (Array.isArray(data[k])) {
      (data[k] as any[]).forEach((v) => {
        formData.append(k, v);
      });
    } else if (typeof data[k] !== 'string') {
      formData.append(k, JSON.stringify(data[k]));
    } else {
      formData.append(k, data[k]);
    }
  });

  formData.append('avatar', avatar.blob!, avatar.name);

  const { data: user } = await api.post('/auth/current_user', formData);
  return user;
}

async function changePassword(old: string, newP: string) {
  await api.post('/auth/update_password', {
    old_password: old,
    new_password: newP,
  });
}

async function getTokens(): Promise<Token[]> {
  const { data } = await api.get('/auth/tokens');
  return data;
}

async function deleteToken(key: string) {
  await api.delete('/auth/tokens', { data: { token: key } });
}

async function deleteAccount() {
  await api.delete('/auth/current_user');
}

export default {
  login,
  logout,
  getCurrentUser,
  register,
  updateCurrentUser,
  changePassword,
  resetPassword,
  resetPasswordConfirm,
  getTokens,
  deleteToken,
  startRegistration,
  deleteAccount,
};
