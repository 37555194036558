import { observable } from 'mobx';
import { Feed } from '../feed/models';
import { Team } from '../teams/models';

export class Community {
  public id: number;

  @observable
  public name: string;

  public slug: string;

  @observable
  public description: string;

  public conversation: number;

  @observable
  public image: string;

  @observable
  public requested: boolean;

  @observable
  public team: Team;

  @observable
  public feed: Feed;

  @observable
  public allowed_tags: string[];

  @observable
  public private: boolean;

  @observable
  public groups: string[];

  constructor({ team, feed, ...data }: any) {
    Object.assign(this, data);
    this.team = new Team(team);
    this.feed = new Feed(feed);
  }

  public update({ team, feed, ...data }: any) {
    Object.assign(this, data);
    this.team = new Team(team);
  }
}
