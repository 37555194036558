import { DateTime } from 'luxon';
import { computed, observable } from 'mobx';
import { randomColor } from '~/constants/colors';
import { MiniBoard } from '~/models/notes';
import { MiniPartner } from '~/models/openinno';
import { Socialable } from '~/models/utils';
import stores from '~/stores';
import { Drive } from '../drive/models';
import { Feed } from '../feed/models';
import { Team } from '../teams/models';

export class OpenInnoProject extends Socialable {
  @observable
  public name: string;

  @observable
  public slug: string;

  @observable
  public partners: MiniPartner[];

  @observable
  public tags: string[];

  @observable
  public status: string;

  @observable
  public thumbnail: string;

  @observable
  public published: boolean;

  public hit_count: number;

  @observable
  public hit: boolean;

  public date_updated: DateTime;

  @observable
  public color: string;

  public board: MiniBoard;
  public feed: Feed;
  public team: Team;
  public drive: Drive;
  public conversation: number;

  constructor({ team, drive, feed, ...data }: any) {
    super('openinnoproject', data);
    Object.assign(this, data);
    this.color = randomColor();
    this.feed = new Feed(feed);
    this.team = new Team(team);
    this.drive = new Drive(drive);
  }

  public update(data: any) {
    Object.assign(this, data);
  }

  @computed
  get hasChatNotif() {
    if (!this.team.isMemberOrTeamAdmin) return false;
    const conv = stores.chatStore.conversations.find(
      (c) => c.id === this.conversation,
    );
    if (!conv) return false;
    if (!conv.last_message) return false;
    if (conv.last_message.user === stores.authStore.user.id) return false;
    if (conv.last_message.read) return false;
    return true;
  }

  @computed
  get hasNotesNotif() {
    if (!this.team.isMemberOrTeamAdmin) return false;
    const oldValue = stores.globalStore.boardStatus[this.board.id];
    if (!oldValue) return this.board.count > 0;
    if (oldValue < this.board.count) return this.board.count - oldValue > 0;
    return false;
  }

  @computed
  get hasDriveNotif() {
    if (!this.team.isMemberOrTeamAdmin) return false;
    return this.drive.hasNew;
  }

  @computed
  get hasNotif() {
    if (!this.team.isMemberOrTeamAdmin) return false;
    return this.hasChatNotif || this.hasDriveNotif || this.hasNotesNotif;
  }
}
