import Cookies from 'js-cookie';
import { DateTime } from 'luxon';
import { action, computed, observable, runInAction, when } from 'mobx';
import qs from 'query-string';
import { setToken } from '~/api/api';
import AuthApi from '~/api/auth';
import { ImageImportResult } from '~/components/ImageImport';
import {
  chatPath,
  homePath,
  openInnoProjectsDetailsPath,
} from '~/constants/routes';
import { TOKEN_KEY } from '~/constants/storage';
import history from '~/history';
import i18n from '~/locales/i18n';
import { Feature } from '~/models/global';
import { CurrentUser } from '~/models/user';
import OpenInnoProjectsApi from '~/modules/openinno.projects/api';
import { OpenInnoProject } from '~/modules/openinno.projects/models';
import { RootStore } from '~/stores';
import { getSsoLogoutUrl } from '~/utils/adfs';
import getOrganizationSlug from '~/utils/getOrganizationSlug';
import { subscribe, unsubscribe } from '~/utils/pushNotifications';
import storage from '~/utils/storage';

const organizationSlug = getOrganizationSlug();
const cookieDomain = process.env.COOKIE_DOMAIN || '.lfai.co';

const setCookie = (token: string) => {
  Cookies.set('file_token', token, {
    domain: cookieDomain,
    path: `/files/${organizationSlug}`,
    expires: 0.5,
    sameSite: 'none',
    secure: true,
  });
};

const delCookie = () => {
  Cookies.remove('file_token', {
    domain: cookieDomain,
    path: `/files/${organizationSlug}`,
    expires: 0.5,
    sameSite: 'none',
    secure: true,
  });
};

class RemoteStorage {
  private store: AuthStore;

  constructor(store: AuthStore) {
    this.store = store;
  }

  public get(key: string, def?: any) {
    const val = this.store.user.storage[key];
    return val || def;
  }

  public addUnique(key: string, value: any) {
    const tmp = this.get(key, []);
    const s = new Set(tmp);
    if (s.has(value)) return;
    s.add(value);
    this.set(key, [...s]);
  }

  public has(key: string) {
    return !!this.store.user.storage[key];
  }

  public contains(key: string, value: any) {
    return (this.get(key) || []).indexOf(value) !== -1;
  }

  public append(key: string, value: any) {
    this.set(key, [...(this.get(key) || []), value]);
  }

  public set(key: string, value: any | null) {
    try {
      if (!value) {
        delete this.store.user.storage[key];
      } else {
        this.store.user.storage[key] = value;
      }
    } catch (e) {}
    this.store.updateCurrentUser({
      storage: this.store.user.storage,
    });
  }
}

class AuthStore {
  @observable
  public authenticated: boolean = false;

  @observable
  public loading: boolean = true;

  @observable
  public next: string | null;

  @observable
  public user: CurrentUser;

  @observable
  public myProjects: OpenInnoProject[] = [];

  @observable
  public defaultLanguage: 'fr' | 'en' | null = null;

  public storage: RemoteStorage;

  constructor(private rootStore: RootStore) {
    const { auth_token: token, file_token } = qs.parse(window.location.search);
    this.storage = new RemoteStorage(this);

    if (token) {
      if (file_token) {
        setCookie(file_token as string);
      }
      storage.set('token', token);
      setToken(token as string);
      if (file_token) {
        setCookie(file_token as string);
      }
      history.push('/');
      storage.set('login_date', DateTime.local().toISO());
    } else {
      setToken(storage.get(TOKEN_KEY));

      const date = DateTime.fromISO(storage.get('login_date'));
      if (date.diffNow().as('hours') < -12 && !token) {
        this.updateToken(null);
      }
    }

    when(
      () => this.authenticated,
      () => {
        this.rootStore.init(storage.get(TOKEN_KEY));
      },
    );

    this.updateAuthStatus();
  }

  public updateToken(token: string | null) {
    setToken(token);
    storage.set(TOKEN_KEY, token);
  }

  public internalLogin({ token, file_token }: any) {
    if (token) {
      if (file_token) {
        setCookie(file_token as string);
      }
      storage.set('token', token);
      setToken(token as string);
      this.updateAuthStatus();
    }
  }

  @computed
  get isAdmin() {
    return this.user && this.isFeatureAdmin('organization');
  }

  public isFeatureAdmin(feature: Feature) {
    const mainFeature = feature.split('.')[0];

    return (
      this.user &&
      (this.user.roles.indexOf(`${mainFeature}:admin`) !== -1 ||
        this.user.roles.indexOf(`${feature}:admin`) !== -1)
    );
  }

  public isLabAdmin(feature: string) {
    return this.isFeatureAdmin(`lab://${feature}`);
  }

  public isContestAdmin(feature: string) {
    return this.isFeatureAdmin(`contest://${feature}`);
  }

  public isAcademyAdmin(feature: string) {
    return this.isFeatureAdmin(`academy://${feature}`);
  }

  @computed
  get isAdminOrAmbassador() {
    return (
      this.user &&
      (this.user.user_type === 'moderator' ||
        this.user.user_type === 'ambassador')
    );
  }

  @computed
  get isExternal() {
    return this.user && this.user.user_type.startsWith('external');
  }

  public async loadMyProjects() {
    if (this.user.user_type !== 'external') return;
    this.myProjects = await OpenInnoProjectsApi.getMyProjects();
    if (
      (window.location.pathname === homePath() ||
        window.location.pathname.startsWith(chatPath)) &&
      this.myProjects.length !== 0
    ) {
      history.push(openInnoProjectsDetailsPath(this.myProjects[0].slug));
    }
  }

  public setNext = () => {
    const { next } = qs.parse(window.location.search);
    if (next && next.length && !(next as string).startsWith('/me')) {
      this.next = `${next as string}${window.location.hash}`;
    }
  };

  @action.bound
  public async login(email: string, password: string) {
    try {
      const { user, token, file_token } = await AuthApi.login(email, password);
      this.updateToken(token);
      if (user.user_type === 'admin' || user.user_type === 'moderator') {
        this.rootStore.organizationStore.loadOrganization();
      }

      runInAction(() => {
        this.setNext();
        setCookie(file_token);
        this.authenticated = true;
        this.user = user;
        storage.set('login_date', DateTime.local().toISO());
      });
      await this.loadMyProjects();

      subscribe();
    } catch (error) {
      runInAction(() => {
        this.authenticated = false;
        delCookie();
      });
      throw error;
    }
  }

  @action.bound
  public async register(
    fullname: string,
    password: string,
    code: string,
    groups: string,
  ) {
    try {
      const { user, token, file_token } = await AuthApi.register(
        fullname,
        password,
        code,
        groups,
      );
      this.updateToken(token);

      runInAction(() => {
        setCookie(file_token);
        this.authenticated = true;
        this.user = user;
      });

      await this.loadMyProjects();
    } catch (error) {
      runInAction(() => {
        this.authenticated = false;
        delCookie();
      });
      throw error;
    }
  }

  @action.bound
  public async logout() {
    const ssoConfig = this.rootStore.organizationStore.ssoConfig;
    const sso_logout = ssoConfig ? ssoConfig.sso_logout : false;
    const slug = this.rootStore.organizationStore.organization.slug;
    const ssoLogoutUrl = getSsoLogoutUrl(
      slug,
      this.rootStore.organizationStore.organization.auth_backend,
    );
    try {
      await AuthApi.logout();
      this.internalLogout();
    } catch (_) {}

    if (sso_logout) {
      window.location.href = ssoLogoutUrl;
      return;
    }
  }

  @action.bound
  public async resetPassword(email: string) {
    await AuthApi.resetPassword(email);
  }

  @action.bound
  public async startRegistration(email: string) {
    await AuthApi.startRegistration(email);
  }

  @action.bound
  public async resetPasswordConfirm(
    uid: string,
    token: string,
    password: string,
    close: boolean = false,
  ) {
    await AuthApi.resetPasswordConfirm(uid, token, password, close);
  }

  @action.bound
  public internalLogout() {
    delCookie();
    unsubscribe();
    this.updateToken(null);
    this.loading = true;
    this.authenticated = false;
    this.rootStore.reset();
  }

  @action.bound
  public async updateAuthStatus() {
    try {
      const user = await AuthApi.getCurrentUser();
      runInAction(() => {
        this.authenticated = true;
        this.user = user;
      });
      await this.loadMyProjects();
    } catch (error) {
      this.updateToken(null);
      runInAction(() => {
        this.authenticated = false;
      });
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  @action.bound
  public async updateCurrentUser(
    user: Partial<CurrentUser>,
    avatar?: ImageImportResult | null,
  ) {
    const newUser = await AuthApi.updateCurrentUser(user, avatar);
    runInAction(() => {
      this.user = newUser;
    });
    return newUser;
  }

  public addXp(xpId: string) {
    const xp = this.rootStore.organizationStore.organization.config[xpId];
    this.addRawXp(xp);
  }

  public addRawXp(xp?: number | null) {
    if (!this.rootStore.organizationStore.organization.config.xp_enabled) {
      return;
    }
    if (!xp) return;
    this.rootStore.uiStore.addNotification(
      i18n.formatMessage('notifications.add_xp', { xp }),
    );
    runInAction(() => {
      this.user = { ...this.user, xp: this.user.xp + xp };
    });
  }

  public changePassword(old: string, newP: string) {
    return AuthApi.changePassword(old, newP);
  }

  public hasRole(role: string) {
    return (
      this.user.user_type === role ||
      this.user.roles.indexOf(role) !== -1 ||
      this.user.user_type === 'admin'
    );
  }

  @computed
  get language() {
    // if user is in the process of joining as external user, use default language
    const language =
      this.user && this.user.user_type !== 'external_tmp'
        ? this.user.language
        : this.defaultLanguage ||
          (window.navigator.language || '').split('-')[0];
    if (language === 'fr') return 'fr';
    if (language === 'en') return 'en';
    return this.rootStore.organizationStore.organization.language;
  }

  @computed
  get isModerator() {
    return this.user && this.user.roles.some((r) => r.endsWith(':admin'));
  }

  public isFullyRestricted(building: string, feature: string = '') {
    if (
      !this.user.restricted_contents ||
      Object.keys(this.user.restricted_contents).length === 0
    )
      return false;
    const contentIds = this.user.restricted_contents[building] || [];
    if (contentIds.includes(`${building}${feature ? `://${feature}` : ''}:*`)) {
      return false;
    }
    if (contentIds.filter((c: any) => c === +c).length === 0) return true;
    return false;
  }
}

export default AuthStore;
