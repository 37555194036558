import React, { ComponentType, useContext } from 'react';
import { RootStore, StoreContext } from '~/stores';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface WithStoreProps {
  store: RootStore;
}

export default function withStore<Props extends WithStoreProps>(
  Component: ComponentType<Props>,
): ComponentType<Omit<Props, keyof WithStoreProps>> {
  return (props: Props) => {
    const store = useContext(StoreContext);
    return <Component {...props} store={store} />;
  };
}
