import api from '~/api/api';
import { Comment, CommentFile } from '~/models/comment';

async function getComments(type: string, id: number): Promise<Comment[]> {
  try {
    const { data } = await api.get('/comments/by_type', {
      params: { object_id: id, content_type: type },
    });
    return data.map((c: any) => new Comment(c));
  } catch (e) {
    return [];
  }
}

async function postComment(
  type: string,
  id: number,
  content: string,
  file: CommentFile,
  parent?: number,
): Promise<Comment> {
  const formData = new FormData();
  formData.append('content', content);
  if (parent) formData.append('parent', parent.toString());
  formData.append('content_type', type);
  formData.append('object_id', id.toString());
  if (file) formData.append('file', file.blob!, file.name);

  const { data } = await api.post('/comments/post', formData);
  return new Comment(data);
}

async function deleteComment(id: number): Promise<void> {
  await api.delete('/comments/delete_comment', {
    params: { comment_id: id },
  });
}

async function getHistory(type: string, id: number) {
  const { data } = await api.get(`/comments/history`, {
    responseType: 'blob',
    params: { object_id: id, content_type: type },
  });
  return data;
}

export default {
  getComments,
  postComment,
  deleteComment,
  getHistory,
};
