import { DateTime } from 'luxon';
import React from 'react';
import { MiniUser } from '~/models/user';
import stores from '~/stores';
import { Upload } from '~/stores/progress';
import lazyRetry from '~/utils/lazyRetry';

const ChooseDate = lazyRetry(() => import('./ChooseDate'));

export const chooseDate = (d?: DateTime | null) =>
  new Promise<DateTime | null>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = (date: DateTime) => {
      resolve(date);
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <ChooseDate defaultDate={d} onSubmit={onClose} />,
    );
  });

const GetUser = lazyRetry(() => import('./GetUser'));

export const getUser = (
  title: string,
  exclude: MiniUser[],
  includeMe?: boolean,
) =>
  new Promise<MiniUser | null>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = (user: MiniUser | null) => {
      resolve(user);
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <GetUser
        title={title}
        onClose={onClose}
        exclude={exclude}
        includeMe={includeMe}
      />,
    );
  });

const GetUsers = lazyRetry(() => import('./GetUsers'));

export const getUsers = (
  title: string,
  description: string,
  exclude: MiniUser[],
) =>
  new Promise<MiniUser[]>((resolve, reject) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = (users: MiniUser[], exit?: boolean) => {
      if (exit) {
        reject();
        stores.uiStore.closeDialog(index);
      } else {
        resolve(users);
        stores.uiStore.closeDialog(index);
      }
    };
    stores.uiStore.openDialog(
      <GetUsers
        title={title}
        onClose={onClose}
        description={description}
        exclude={exclude}
      />,
    );
  });

const BuildingInfos = lazyRetry(() => import('./BuildingInfos'));

export const buildingInfos = (building: string, title?: string) =>
  new Promise<void>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = (force: boolean = false) => {
      resolve();
      if (force) {
        stores.authStore.storage.addUnique('forced_building_infos', building);
      }
      stores.authStore.storage.addUnique('building_infos', building);
      stores.uiStore.closeDialog(index, 'building_infos');
    };
    stores.uiStore.openDialog(
      <BuildingInfos onClose={onClose} building={building} title={title} />,
      'building_infos',
    );
  });

export const showBuildingInfos = (building: string, title?: string) => {
  const setting =
    stores.organizationStore.organization.config.info_settings[building] ||
    'info';

  const seen = stores.authStore.storage.contains('building_infos', building);
  const forced = stores.authStore.storage.contains(
    'forced_building_infos',
    building,
  );

  if (setting !== 'info' && ((setting === 'force' && !forced) || !seen)) {
    buildingInfos(building, title);
  }
};

const Likers = lazyRetry(() => import('./Likers'));

export const likers = (type: string, id: number) =>
  new Promise<void>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = () => {
      resolve();
      stores.uiStore.closeDialog(index, 'likers');
    };
    stores.uiStore.openDialog(
      <Likers onClose={onClose} type={type} id={id} />,
      'likers',
    );
  });

const Hits = lazyRetry(() => import('./Hits'));

export const hits = (type: string, id: number) =>
  new Promise<void>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = () => {
      resolve();
      stores.uiStore.closeDialog(index, 'hits');
    };
    stores.uiStore.openDialog(
      <Hits onClose={onClose} type={type} id={id} />,
      'hits',
    );
  });

const ExternalFormations = lazyRetry(() => import('./ExternalFormations'));

export const updateExternalFormations = () =>
  new Promise<void>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = () => {
      resolve();
      stores.uiStore.closeDialog(index, 'likers');
    };
    stores.uiStore.openDialog(<ExternalFormations onClose={onClose} />);
  });

const Progress = lazyRetry(() => import('./Progress'));

export const showProgress = (upload: Upload) =>
  new Promise<void>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = () => {
      resolve();
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(<Progress onClose={onClose} upload={upload} />);
  });

const Loading = lazyRetry(() => import('./Loading'));

export const startLoading = (text: string) => {
  const index = stores.uiStore.dialogStack.length;
  stores.uiStore.openDialog(<Loading text={text} />);
  return () => {
    stores.uiStore.closeDialog(index);
  };
};

const ShareDialog = lazyRetry(() => import('./ShareUrl'));

export const shareUrl = (url: string) =>
  new Promise<void>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = () => {
      resolve();
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(<ShareDialog url={url} onClose={onClose} />);
  });
