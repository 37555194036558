import { DateTime } from 'luxon';
import { runInAction } from 'mobx';
import api from '~/api/api';
import { ImageImportResult } from '~/components/ImageImport';
import {
  LimitOffsetParams,
  OrderParams,
  PaginatedResult,
  TagsParams,
} from '~/models/api';
import {
  Availability,
  Challenge,
  ChallengeContribution,
  ChallengeType,
  MiniChallenge,
  Rating,
} from '~/models/challenges';
import { Answer, PollResults } from '~/models/polls';
import { MiniUser } from '~/models/user';
import { FileImportResult } from '~/models/utils';
import getFileName from '~/utils/getFileName';
import toImageName from '~/utils/toImageName';

export interface GetChallengesParams
  extends LimitOffsetParams,
    TagsParams,
    OrderParams {}

async function getChallenges(
  url: string | null,
  params: any,
): Promise<PaginatedResult<Challenge>> {
  try {
    const { data } = await api.get(url || '/challenges/search', {
      params: { ...params },
    });
    return {
      ...data,
      results: data.results.map((i: any) => new Challenge(i)),
    };
  } catch (e) {
    return { next: null, previous: null, results: [] };
  }
}

async function getNotPublished(): Promise<Challenge[]> {
  try {
    const { data } = await api.get('/challenges/not_published');
    return data.map((i: any) => new Challenge(i));
  } catch (e) {
    return [];
  }
}

async function getContributions(
  challenge: Challenge,
  params?: any,
): Promise<ChallengeContribution[]> {
  try {
    const { data } = await api.get(
      `/challenges/${challenge.slug}/contributions`,
      {
        params,
      },
    );
    return data.map((i: any) => new ChallengeContribution(i));
  } catch (e) {
    return [];
  }
}

async function getContributors(challenge: Challenge): Promise<MiniUser[]> {
  try {
    const { data } = await api.get(
      `/challenges/${challenge.slug}/contributors`,
    );
    return data;
  } catch (e) {
    return [];
  }
}

async function getDescription(challenge: Challenge): Promise<any> {
  try {
    const { data } = await api.get(
      `/challenges/${challenge.slug}/descriptions`,
    );
    return data;
  } catch (e) {
    return '';
  }
}

async function getRewards(challenge: Challenge): Promise<any> {
  try {
    const { data } = await api.get(`/challenges/${challenge.slug}/rewards`);
    return data;
  } catch (e) {
    return '';
  }
}

async function getPopular(time: string): Promise<MiniChallenge[]> {
  try {
    const { data } = await api.get('/challenges/popular', { params: { time } });
    return data.map((i: any) => new Challenge(i));
  } catch (e) {
    return [];
  }
}

async function getChallenge(slug: string): Promise<Challenge | null> {
  try {
    const { data } = await api.get(`/challenges/${slug}`);
    return new Challenge(data);
  } catch (e) {
    return null;
  }
}

async function createChallenge(
  title: string,
  type: ChallengeType,
  options: any,
  sections: string[],
  tags: string[],
  juryMembers: MiniUser[],
  targetGroups: string[],
  date_end: DateTime | null,
  date_final_file: DateTime | null,
): Promise<Challenge> {
  const { data } = await api.post('/challenges', {
    title,
    options,
    sections,
    tags,
    type,
    date_end,
    date_final_file,
    new_jury_members: juryMembers.map((j) => j.id),
    target_groups: targetGroups,
  });
  return new Challenge(data);
}

async function updateChallenge(
  newData: Partial<Challenge>,
  challenge: Challenge,
): Promise<Challenge> {
  const { data } = await api.patch(`/challenges/${challenge.slug}`, {
    ...newData,
    new_jury_members: newData.jury_members
      ? newData.jury_members.map((j) => j.id)
      : undefined,
  });
  challenge.update(data);
  return challenge;
}

async function createContribution(
  challenge: Challenge,
  title: string,
  content: string,
  images: ImageImportResult[],
  files: FileImportResult[],
  pollData?: Answer[],
): Promise<ChallengeContribution | null> {
  const formData = new FormData();
  formData.append('content', content);
  formData.append('title', title);

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  images.forEach((image) => {
    formData.append('new_images', image.blob!, toImageName(image.name));
  });

  if (pollData) {
    formData.append('data', JSON.stringify(pollData));
  }

  try {
    const { data } = await api.post(
      `/challenges/${challenge.slug}/create_contribution`,
      formData,
    );
    return new ChallengeContribution(data);
  } catch (e) {
    return null;
  }
}

async function selectContribution(
  challenge: Challenge,
  contribution: ChallengeContribution,
  selected: boolean | null,
): Promise<ChallengeContribution> {
  const formData = new FormData();
  formData.append('selected', selected ? 'True' : 'False');
  formData.append('contribution', contribution.id.toString());
  const { data } = await api.post(
    `/challenges/${challenge.slug}/select_contribution`,
    formData,
  );
  return new ChallengeContribution(data);
}

async function publishResult(
  challenge: Challenge,
  message: string,
  publish: boolean,
  files: FileImportResult[],
): Promise<void> {
  const formData = new FormData();
  formData.append('end_message', message);
  formData.append('show_contributions', publish ? 'True' : 'False');

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  const { data } = await api.post(
    `/challenges/${challenge.slug}/publish_result`,
    formData,
  );
  challenge.update(data);
}

async function askChanges(
  challenge: Challenge,
  message: string,
): Promise<number> {
  const formData = new FormData();
  formData.append('message', message);
  const { data } = await api.post(
    `/challenges/${challenge.slug}/ask_changes`,
    formData,
  );
  return data.conv_id;
}

async function deleteChallenge(challenge: Challenge) {
  await api.delete(`/challenges/${challenge.slug}`);
}

async function updateChallengeSection(
  challenge: Challenge,
  section: string,
  content: string,
  newFiles: FileImportResult[],
  toDeleteFiles: number[],
): Promise<Challenge> {
  const formData = new FormData();

  formData.append(section, content);

  newFiles.forEach((f) => {
    formData.append(
      'new_files',
      f.blob!,
      getFileName(`${section}__section__${f.name}`),
    );
  });

  toDeleteFiles.forEach((f) => {
    formData.append('to_delete_files', f.toString());
  });

  const { data } = await api.patch(`/challenges/${challenge.slug}`, formData);
  challenge.update(data);
  return challenge;
}

async function updateChallengeBanner(
  banner: ImageImportResult,
  challenge: Challenge,
): Promise<Challenge> {
  const formData = new FormData();
  formData.append('banner', banner.blob!, toImageName(banner.name));

  const { data } = await api.patch(`/challenges/${challenge.slug}`, formData);

  challenge.update(data);
  return challenge;
}

async function getChallengeResults(challenge: Challenge): Promise<PollResults> {
  try {
    const { data } = await api.get(`/challenges/${challenge.slug}/results`);
    return data;
  } catch (e) {
    return [];
  }
}

async function rateContribution(
  challenge: Challenge,
  contribution: ChallengeContribution,
  rating: number,
  comment: string,
  files: FileImportResult[],
) {
  const formData = new FormData();
  formData.append('rating', rating.toString());
  formData.append('comment', comment);
  formData.append('contribution', contribution.id.toString());

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  const { data } = await api.post(
    `/challenges/${challenge.slug}/rate_contribution`,
    formData,
  );
  runInAction(() => {
    contribution.ratings.push(data as Rating);
  });
}

async function getCSVResult(challenge: Challenge) {
  const { data } = await api.get(`/challenges/${challenge.slug}/csv_results`);
  return data.csv as string;
}

async function updateContribution(
  challenge: Challenge,
  contribution: ChallengeContribution,
  data: Partial<ChallengeContribution>,
  finalFile?: FileImportResult | null,
  images: ImageImportResult[] = [],
  files: FileImportResult[] = [],
  toDeleteImages: number[] = [],
  toDeleteFiles: number[] = [],
) {
  const formData = new FormData();

  if (finalFile) {
    formData.append('final_file', finalFile.blob!, finalFile.name);
  }

  if (finalFile === null) {
    formData.append('final_file', 'None');
  }

  Object.keys(data).forEach((key) => {
    formData.append(key, data[key]);
  });

  toDeleteFiles.forEach((f) => {
    formData.append('to_delete_files', f.toString());
  });

  toDeleteImages.forEach((f) => {
    formData.append('to_delete_images', f.toString());
  });

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  images.forEach((image) => {
    formData.append('new_images', image.blob!, toImageName(image.name));
  });

  const resp = await api.post(
    `/challenges/${challenge.slug}/edit_contribution`,
    formData,
    {
      params: {
        id: contribution.id,
      },
    },
  );

  runInAction(() => {
    contribution.update(resp.data);
  });
}

async function setFinalFile(
  challenge: Challenge,
  finalFile: FileImportResult | null,
) {
  const formData = new FormData();

  if (finalFile) {
    formData.append('final_file', finalFile.blob!, finalFile.name);
  } else {
    formData.append('remove_final_file', 'true');
  }

  const { data } = await api.patch(`/challenges/${challenge.slug}`, formData);
  challenge.update(data);
}

async function deleteContribution(
  challenge: Challenge,
  contribution: ChallengeContribution,
) {
  await api.post(`/challenges/${challenge.slug}/delete_contribution`, null, {
    params: {
      id: contribution.id,
    },
  });
  runInAction(() => {
    challenge.contribution_count -= 1;
  });
}

async function setAvailability(
  challenge: Challenge,
  content: string,
): Promise<Availability> {
  const { data } = await api.post(
    `/challenges/${challenge.slug}/set_availability`,
    {
      content,
    },
  );
  runInAction(() => {
    challenge.availability_count += 1;
  });
  return new Availability(data);
}

async function removeAvailability(challenge: Challenge) {
  await api.post(`/challenges/${challenge.slug}/remove_availability`);
  runInAction(() => {
    challenge.availability_count -= 1;
  });
}

async function getAvailabilities(
  challenge: Challenge,
): Promise<Availability[]> {
  const { data } = await api.get(
    `/challenges/${challenge.slug}/availabilities`,
  );
  return data.map((a: any) => new Availability(a));
}

export default {
  getChallenge,
  getChallenges,
  getNotPublished,
  createChallenge,
  updateChallenge,
  deleteChallenge,
  getContributions,
  getContributors,
  getPopular,
  getDescription,
  getRewards,
  createContribution,
  selectContribution,
  publishResult,
  askChanges,
  updateChallengeBanner,
  updateChallengeSection,
  getChallengeResults,
  rateContribution,
  getCSVResult,
  updateContribution,
  setFinalFile,
  deleteContribution,
  setAvailability,
  removeAvailability,
  getAvailabilities,
};
