import api from '~/api/api';
import { MiniUser } from '~/models/user';

async function like(type: string, id: number): Promise<void> {
  const { data } = await api.post('/likes/like', {
    content_type: type,
    object_id: id,
  });
  return data;
}

async function unlike(type: string, id: number): Promise<void> {
  const { data } = await api.post('/likes/unlike', {
    content_type: type,
    object_id: id,
  });
  return data;
}

async function getLikers(type: string, id: number): Promise<MiniUser[]> {
  const { data } = await api.get('/likes/likers', {
    params: {
      content_type: type,
      object_id: id,
    },
  });
  return data;
}

export default {
  like,
  unlike,
  getLikers,
};
