import { action, computed, observable, runInAction } from 'mobx';

import ProjectsApi from '~/api/projects';
import { ImageImportResult } from '~/components/ImageImport';
import { Project } from '~/models/projects';
import { FileImportResult } from '~/models/utils';
import { RootStore } from '~/stores';

class ProjectsStore {
  public projects = observable.map<string, Project>();

  @observable
  public results: string[] = [];

  @observable
  public search: string = '';

  @observable
  public tags: string[] = [];

  @observable
  public hackathon: boolean = false;

  @observable
  public challengeSlug?: string;

  @observable
  public sort: string = '-date_created';

  @observable
  public status: string[] = [];

  @observable
  public next: string | null = null;
  public previous: string | null = null;
  public currentUrl: string | null;

  @observable
  public deletedPosts: number[] = [];

  @observable
  public deletedSteps: number[] = [];

  @observable
  public fileStatus: any = {};

  constructor(private rootStore: RootStore) {}

  public init() {
    this.fileStatus = this.rootStore.authStore.storage.get(
      'projects_files_status',
      {},
    );
  }

  @action
  public updateFileStatus(project: Project) {
    this.fileStatus[project.id] = project.file_count;
    this.rootStore.authStore.storage.set(
      'projects_files_status',
      this.fileStatus,
    );
  }

  public async loadProjects(force?: boolean) {
    if (force) this.resetUrl();

    if (!force && this.resultItems.length) return this.resultItems;
    const newProjects = await ProjectsApi.getProjects(null, this.queryParams);
    runInAction(() => {
      for (const project of newProjects.results) {
        this.projects.set(project.slug, project);
      }
      this.results = newProjects.results.map((project) => project.slug);
      this.next = newProjects.next;
      this.previous = newProjects.previous;
    });

    return this.resultItems;
  }

  public async loadNotPublished() {
    const notPublished = await ProjectsApi.getNotPublished();
    runInAction(() => {
      for (const project of notPublished) {
        this.projects.set(project.slug, project);
      }
    });

    return notPublished;
  }

  public async loadPopular(time: string) {
    const newIdeaboxItems = await ProjectsApi.getPopular(time);
    return newIdeaboxItems;
  }

  public async loadProject(slug: string, force: boolean = false) {
    if (force || !this.projects.has(slug)) {
      const item = await ProjectsApi.getProject(slug);
      if (item) {
        this.projects.set(item.slug, item);
      }
      return item;
    }
    return this.projects.get(slug);
  }

  public async createProject(
    name: string,
    description: string,
    tags: string[],
    ideabox_item: { name: string; slug: string } | null,
    images: ImageImportResult[],
    files: FileImportResult[],
    saveOnly: boolean,
  ) {
    const project = await ProjectsApi.createProject(
      name,
      description,
      tags,
      ideabox_item,
      images,
      files,
      saveOnly,
    );
    if (project) {
      this.projects.set(project.slug, project);
      if (project.published) {
        this.rootStore.authStore.addXp('projects_create_xp');
      }
    }
    return project;
  }

  public async updateProject(
    project: Project,
    name: string,
    description: string,
    tags: string[],
    ideabox_item: { name: string; slug: string } | null,
    images: ImageImportResult[],
    files: FileImportResult[],
    imagesToDelete: number[],
    filesToDelete: number[],
    saveOnly: boolean,
  ) {
    const oldPublished = project.published;
    const updatedProject = await ProjectsApi.updateProject(
      project,
      name,
      description,
      tags,
      ideabox_item,
      images,
      files,
      imagesToDelete,
      filesToDelete,
      saveOnly,
    );
    if (updatedProject) {
      this.projects.set(updatedProject.slug, updatedProject);
      if (
        !oldPublished &&
        updatedProject.published &&
        updatedProject.owner.id === this.rootStore.authStore.user.id
      ) {
        this.rootStore.authStore.addXp('projects_create_xp');
      }
    }
    return updatedProject;
  }

  @action
  public async deleteProject(project: Project) {
    this.results = this.results.filter((c) => c !== project.slug);
    await ProjectsApi.deleteProject(project);
  }

  public async loadMore() {
    const newProjects = await ProjectsApi.getProjects(
      this.currentUrl,
      this.queryParams,
    );
    runInAction(() => {
      for (const item of newProjects.results) {
        this.projects.set(item.slug, item);
      }
      this.results.push(...newProjects.results.map((item) => item.slug));
      this.next = newProjects.next;
      this.previous = newProjects.previous;
    });

    return this.resultItems;
  }

  @action.bound
  public async loadNextProjects() {
    this.currentUrl = this.next;
    return this.loadMore();
  }

  @computed
  get queryParams() {
    return {
      tags__name: this.tags.join(','),
      q: this.search,
      hit: this.sort === 'not_hit' ? false : undefined,
      ordering: this.sort !== 'not_hit' ? this.sort : undefined,
      status: this.status.filter((c) => c !== 'hackathon'),
      hackathon: this.hackathon || undefined,
      challenge_slug: this.challengeSlug,
    };
  }

  @computed
  get resultItems() {
    return this.results
      .map((slug) => this.projects.get(slug)!)
      .filter((item) => !!item);
  }

  @action
  public setSearch = (search: string) => {
    this.search = search;
    this.loadProjects(true);
  };

  @action
  public setChallengeSlug = (slug: string) => {
    this.challengeSlug = slug;
    this.loadProjects(true);
  };

  @action
  public setTags = (tags: string[]) => {
    this.tags = tags;
    this.loadProjects(true);
  };

  @action
  public setStatus = (status: string[]) => {
    this.hackathon = status.indexOf('hackathon') !== -1;
    this.status = status;

    this.loadProjects(true);
  };

  @action
  public setSort = (sort: string) => {
    this.sort = sort;
    this.loadProjects(true);
  };

  @computed
  get hasNext() {
    return this.next && this.next.length !== 0;
  }

  @action.bound
  public resetUrl() {
    this.next = null;
    this.previous = null;
    this.currentUrl = null;
  }

  @action.bound
  public reset() {
    this.tags = [];
    this.sort = '-date_created';
    this.search = '';
    this.results = [];
    this.challengeSlug = undefined;
    this.loadProjects(true);
  }
}

export default ProjectsStore;
