import { InputAdornment, InputBase } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import SearchIcon from '@material-ui/icons/Search';
import { action, observable, runInAction } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import i18n from '~/locales/i18n';
import styled from '~/styles';

const Input = styled(InputBase)`
  font-size: 17px;
  color: ${(p) => p.theme.palette.grey[800]};
  vertical-align: middle;

  input {
    transition: width 0.3s;
    width: 120px;
  }

  input:focus {
    width: 180px;
  }

  svg {
    font-size: 25px;
  }
`;

interface Props {
  onSubmit?: (value: string) => void;
  keepValue?: boolean;
  width?: number;
  enableReset?: boolean;
  defaultValue?: string;
  onClick?: () => void;
  autoFocus?: boolean;
}

@observer
class SearchInput extends React.Component<Props> {
  @observable
  public value: string;

  constructor(props: Props) {
    super(props);
    this.value = props.defaultValue || '';
  }

  @action.bound
  public setValue(value: string) {
    this.value = value;
  }

  @action
  public onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (this.props.onSubmit) {
      this.props.onSubmit(this.value);
    }
    if (!this.props.keepValue) {
      this.value = '';
    }
    return false;
  };

  @action.bound
  public handleValueChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.value = e.target.value;
  }

  public render() {
    return (
      <form onSubmit={this.onSubmit}>
        <Input
          autoFocus={this.props.autoFocus}
          onChange={this.handleValueChange}
          placeholder={i18n.formatMessage('search.placeholder')}
          value={this.value}
          onFocus={this.props.onClick}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          endAdornment={
            this.props.enableReset && this.value.length > 0 ? (
              <InputAdornment position="end">
                <Close
                  style={{ cursor: 'pointer' }}
                  onClick={() =>
                    runInAction(() => {
                      this.value = '';
                      if (this.props.onSubmit) {
                        this.props.onSubmit(this.value);
                      }
                    })
                  }
                />
              </InputAdornment>
            ) : (
              undefined
            )
          }
        />
      </form>
    );
  }
}

export default SearchInput;
