import { computed, observable, runInAction } from 'mobx';
import ContestApi from './api';
import { Contest } from './models';

class ContestStore {
  public contestsBySlug = observable.map<string, Contest>();

  public async loadContest(slug: string) {
    const p = this.contestsBySlug.get(slug);
    if (p) return p;

    const data = await ContestApi.getContest(slug);
    const contest = new Contest(data);
    runInAction(() => {
      this.contestsBySlug.set(contest.slug, contest);
    });
    return contest;
  }

  public async loadContests() {
    const data = await ContestApi.getContests(null, {
      ps: 10000,
    });
    data.results.forEach((d: any) => {
      runInAction(() => {
        const p = this.contestsBySlug.get(d.slug);
        if (p) {
          p._update(d);
        } else {
          const contest = new Contest(d);
          this.contestsBySlug.set(contest.slug, contest);
        }
      });
    });
  }
  public async createContest({ jury, ...contestData }: Partial<Contest>) {
    const data = await ContestApi.createContest({
      new_jury: jury?.map((c) => c.id),
      ...contestData,
    });
    const contest = new Contest(data);
    runInAction(() => {
      this.contestsBySlug.set(contest.slug, contest);
    });
    return contest;
  }

  public async deleteContest(contest: Contest) {
    await ContestApi.deleteContest(contest);
    runInAction(() => {
      this.contestsBySlug.delete(contest.slug);
    });
  }

  @computed
  get contests() {
    return Array.from(this.contestsBySlug.values());
  }
}

export default ContestStore;
