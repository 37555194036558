import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosTransformer,
} from 'axios';
import { DateTime } from 'luxon';
import qs from 'query-string';

import info from '~/components/dialogs/Info';
import store from '~/stores';
import env from '~/utils/env';
import getOrganizationSlug from '~/utils/getOrganizationSlug';
import progress from '~/utils/progress';

const percent = (x: number, of: number) => Math.floor(x * 1.0) / of;

const organizationSlug = getOrganizationSlug();

const api = axios.create({
  baseURL: env('API_URL').replace('{slug}', organizationSlug),
  paramsSerializer: (params: any) => {
    return qs.stringify(params, { arrayFormat: 'none' });
  },
});

export function setToken(token: string | null) {
  if (token !== null) {
    api.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    api.defaults.headers.common.Authorization = null;
  }
}

api.interceptors.request.use((config: AxiosRequestConfig) => {
  progress.start();
  return config;
});

api.interceptors.request.use((config: AxiosRequestConfig) => {
  const splitted = config.url!.split('?');
  let url = splitted[0];
  const params = splitted[1];
  if (url[url.length - 1] !== '/') {
    url += '/';
  }
  config.url = url + (params ? `?${params}` : '');
  return config;
});

const update = (e: ProgressEvent) =>
  progress.update(percent(e.loaded, e.total));

api.defaults.onDownloadProgress = update;
api.defaults.onUploadProgress = update;

api.interceptors.response.use(
  (response: AxiosResponse) => {
    if (
      process.env.PRINT_QUERY_COUNT === 'true' &&
      response.config.method === 'GET'
    ) {
      const queryCount = +(response.headers['x-djangoquerycount-count'] || 0);
      // tslint:disable-next-line: no-console
      console.log(`QUERY_COUNT (${response.config.url}): ${queryCount}`);
      if (queryCount > 12) {
        info(`Too much query ${response.config.url}: ${queryCount}`);
      }
    }
    progress.done();
    return response;
  },
  (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      store.authStore.internalLogout();
      location.reload();
      progress.done();
      return Promise.resolve();
    }

    progress.done();
    return Promise.reject(error);
  },
);

const originTransform = api.defaults.transformResponse as AxiosTransformer[];
const transform = (data: any) => {
  if (Array.isArray(data)) {
    return data.map((c) => transformObject(c));
  }

  return transformObject(data);
};

const transformObject = (data: any) => {
  if (data === null) {
    return null;
  }
  Object.keys(data).forEach((key: string) => {
    if (data[key] && typeof data[key] === 'object') {
      data[key] = transform(data[key]);
    } else if (
      (key.startsWith('date') || key.endsWith('_date')) &&
      data[key] !== null
    ) {
      data[key] = DateTime.fromISO(data[key]);
    }
  });
  return data;
};

api.defaults.transformResponse = originTransform.concat((data) =>
  transform(data),
);

export default api;
