function isEmptyHTML(html?: string | null) {
  if (!html) return true;
  const x = document.createElement('div');
  x.innerHTML = html;
  const hasImage = !!x.querySelector('img');
  const hasVideo = !!(x.querySelector('video') || x.querySelector('iframe'));
  return x.innerText.trim().length === 0 && !hasImage && !hasVideo;
}

export default isEmptyHTML;
