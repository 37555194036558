import React from 'react';
import stores from '~/stores';
import lazyRetry from '~/utils/lazyRetry';
import {
  Contest,
  ContestChallenge,
  ContestContribution,
  ContestRating,
  JuryForm,
} from '../models';
import { ResultsConfigData } from './ResultsConfig';

const ContestEditor = lazyRetry(() => import('./ContestEditor'));

export const createContest = (feature: string) =>
  new Promise<Contest | null>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = (c?: Contest | null) => {
      resolve(c || null);
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <ContestEditor feature={feature} onClose={onClose} />,
    );
  });

export const editContest = (contest: Contest) =>
  new Promise<Contest | null>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = (c?: Contest | null) => {
      resolve(c || null);
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <ContestEditor
        feature={contest.feature}
        contest={contest}
        onClose={onClose}
      />,
    );
  });

const ChallengeEditor = lazyRetry(() => import('./ChallengeEditor'));

export const createChallenge = () =>
  new Promise<Partial<ContestChallenge> | null>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = (challenge?: Partial<ContestChallenge> | null) => {
      resolve(challenge || null);
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(<ChallengeEditor onClose={onClose} />);
  });

export const editChallenge = (
  challenge: Partial<ContestChallenge>,
  readOnly = false,
) =>
  new Promise<Partial<ContestChallenge> | null>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = (c?: Partial<ContestChallenge> | null) => {
      resolve(c || null);
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <ChallengeEditor
        challenge={challenge}
        onClose={onClose}
        readOnly={readOnly}
      />,
    );
  });

const DisplayConfigEditor = lazyRetry(() => import('./DisplayConfigEditor'));

export const editDisplayConfig = (challenge: Partial<ContestChallenge>) =>
  new Promise<Partial<ContestChallenge> | null>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = (c?: Partial<ContestChallenge> | null) => {
      resolve(c || null);
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <DisplayConfigEditor challenge={challenge} onClose={onClose} />,
    );
  });

const JuryFormEditor = lazyRetry(() => import('./JuryFormEditor'));

export const editJuryForm = (juryForm: JuryForm, readOnly = false) =>
  new Promise<JuryForm | null>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = (c?: JuryForm) => {
      resolve(c || null);
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <JuryFormEditor value={juryForm} onClose={onClose} readOnly={readOnly} />,
    );
  });

const TabsEditor = lazyRetry(() => import('./TabsEditor'));

export const editTabs = (contest: Contest) =>
  new Promise<void>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = () => {
      resolve();
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <TabsEditor contest={contest} onClose={onClose} />,
    );
  });

const ChallengeSelector = lazyRetry(() => import('./ChallengeSelector'));

export const selectChallenge = (contest: Contest, withAdmin?: boolean) =>
  new Promise<ContestChallenge | null>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = (challenge?: ContestChallenge | null) => {
      resolve(challenge || null);
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <ChallengeSelector
        contest={contest}
        onClose={onClose}
        withAdmin={withAdmin}
      />,
    );
  });

const ContributionEditor = lazyRetry(() => import('./ContributionEditor'));

export const createContribution = (
  contest: Contest,
  challenge: ContestChallenge,
) =>
  new Promise<ContestContribution | null>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = (contribution?: ContestContribution | null) => {
      resolve(contribution || null);
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <ContributionEditor
        contest={contest}
        challenge={challenge}
        onClose={onClose}
      />,
    );
  });

export const editContribution = (
  contest: Contest,
  challenge: ContestChallenge,
  contribution: ContestContribution,
) =>
  new Promise<ContestContribution | null>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = (c?: ContestContribution | null) => {
      resolve(c || null);
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <ContributionEditor
        contest={contest}
        contribution={contribution}
        challenge={challenge}
        onClose={onClose}
      />,
    );
  });

const RatingEditor = lazyRetry(() => import('./RatingEditor'));

export const createRating = (
  contest: Contest,
  challenge: ContestChallenge,
  contribution: ContestContribution,
) =>
  new Promise<void>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = () => {
      resolve();
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <RatingEditor
        contest={contest}
        challenge={challenge}
        contribution={contribution}
        onClose={onClose}
      />,
    );
  });

export const editRating = (
  rating: ContestRating,
  contest: Contest,
  challenge: ContestChallenge,
  contribution: ContestContribution,
) =>
  new Promise<void>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = () => {
      resolve();
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <RatingEditor
        rating={rating}
        contest={contest}
        challenge={challenge}
        contribution={contribution}
        onClose={onClose}
      />,
    );
  });

const RatingViewer = lazyRetry(() => import('./RatingViewer'));

export const viewRating = (contest: Contest, rating: ContestRating) =>
  new Promise<void>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = () => {
      resolve();
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <RatingViewer contest={contest} rating={rating} onClose={onClose} />,
    );
  });

const RatingResults = lazyRetry(() => import('./RatingResults'));

export const viewRatingResults = (
  contest: Contest,
  contribution: ContestContribution,
) =>
  new Promise<void>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = () => {
      resolve();
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <RatingResults
        contest={contest}
        contribution={contribution}
        onClose={onClose}
      />,
    );
  });

const ResultsEditor = lazyRetry(() => import('./ResultsEditor'));

export const publishResults = (contest: Contest) =>
  new Promise<void>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = () => {
      resolve();
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <ResultsEditor contest={contest} onClose={onClose} />,
    );
  });

const ResultsConfig = lazyRetry(() => import('./ResultsConfig'));

export const getResultsConfig = (challenge: ContestChallenge) =>
  new Promise<ResultsConfigData | null>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = (data: ResultsConfigData | null) => {
      resolve(data);
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <ResultsConfig challenge={challenge} onClose={onClose} />,
    );
  });
