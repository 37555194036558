import { runInAction } from 'mobx';
import api from '~/api/api';
import { ImageImportResult } from '~/components/ImageImport';
import {
  LimitOffsetParams,
  OrderParams,
  PaginatedResult,
  TagsParams,
} from '~/models/api';
import { Answer, PollResults } from '~/models/polls';
import {
  MiniProject,
  Project,
  ProjectContribution,
  ProjectPost,
  ProjectStatus,
  ProjectStep,
  ProjectUploadedFile,
  StepType,
} from '~/models/projects';
import { MiniUser } from '~/models/user';
import { FileImportResult } from '~/models/utils';
import stores from '~/stores';
import toImageName from '~/utils/toImageName';

export interface GetProjectsParams
  extends LimitOffsetParams,
    TagsParams,
    OrderParams {}

async function getProjects(
  url: string | null,
  params: GetProjectsParams,
): Promise<PaginatedResult<Project>> {
  try {
    const { data } = await api.get(url || '/projects/search', { params });
    return {
      ...data,
      results: data.results.map((i: any) => new Project(i)),
    };
  } catch (e) {
    return { next: null, previous: null, results: [] };
  }
}

async function getNotPublished(): Promise<Project[]> {
  try {
    const { data } = await api.get('/projects/not_published');
    return data.map((i: any) => new Project(i));
  } catch (e) {
    return [];
  }
}

async function getContributions(
  project: Project,
  step?: ProjectStep,
  params?: any,
): Promise<ProjectContribution[]> {
  try {
    const { data } = await api.get(`/projects/${project.slug}/contributions`, {
      params: step
        ? {
          step: step.id,
          ...params,
        }
        : params,
    });
    return data.map((i: any) => new ProjectContribution(i));
  } catch (e) {
    return [];
  }
}

async function getResults(
  project: Project,
  step: ProjectStep,
): Promise<PollResults> {
  try {
    const { data } = await api.get(`/projects/${project.slug}/results`, {
      params: { step: step.id },
    });
    return data;
  } catch (e) {
    return [];
  }
}

async function deleteInvitation(
  project: Project,
  user: MiniUser,
): Promise<void> {
  const formData = new FormData();
  formData.append('user', user.id.toString());
  await api.post(`/projects/${project.slug}/delete_invitation`, formData);
}

async function resendInvitation(
  project: Project,
  user: MiniUser,
): Promise<void> {
  const formData = new FormData();
  formData.append('user', user.id.toString());
  await api.post(`/projects/${project.slug}/resend_invitation`, formData);
}

async function getContributors(
  project: Project,
  step?: ProjectStep,
): Promise<MiniUser[]> {
  try {
    const { data } = await api.get(`/projects/${project.slug}/contributors`, {
      params: step
        ? {
          step: step.id,
        }
        : null,
    });
    return data;
  } catch (e) {
    return [];
  }
}

async function getSteps(project: Project): Promise<ProjectStep[]> {
  try {
    const { data } = await api.get(`/projects/${project.slug}/steps`);
    return data.map((s: any) => new ProjectStep(s));
  } catch (e) {
    return [];
  }
}

async function getPosts(project: Project): Promise<ProjectPost[]> {
  try {
    const { data } = await api.get(`/projects/${project.slug}/posts`);
    return data.map((s: any) => new ProjectPost(s));
  } catch (e) {
    return [];
  }
}

async function getPopular(time: string): Promise<MiniProject[]> {
  try {
    const { data } = await api.get('/projects/popular', { params: { time } });
    return data.map((i: any) => new Project(i));
  } catch (e) {
    return [];
  }
}

async function getProject(slug: string): Promise<Project | null> {
  try {
    const { data } = await api.get(`/projects/${slug}`);
    return new Project(data);
  } catch (e) {
    return null;
  }
}

async function createProject(
  name: string,
  description: string,
  tags: string[],
  ideabox_item: { name: string; slug: string } | null,
  images: ImageImportResult[],
  files: FileImportResult[],
  saveOnly: boolean,
): Promise<Project | null> {
  const formData = new FormData();
  formData.append('name', name);
  formData.append('description', description);
  formData.append('tags', JSON.stringify(tags));
  formData.append('save_only', saveOnly ? 'True' : 'False');
  if (ideabox_item) {
    formData.append('new_ideabox_item', ideabox_item.slug);
  } else {
    formData.append('new_ideabox_item', 'null');
  }

  images.forEach((image) => {
    formData.append('new_images', image.blob!, toImageName(image.name));
  });

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  try {
    const { data } = await api.post('/projects/', formData);
    return new Project(data);
  } catch (e) {
    return null;
  }
}

async function updateProject(
  project: Project,
  name: string,
  description: string,
  tags: string[],
  ideabox_item: { name: string; slug: string } | null,
  images: ImageImportResult[],
  files: FileImportResult[],
  imagesToDelete: number[],
  filesToDelete: number[],
  saveOnly: boolean,
): Promise<Project | null> {
  const formData = new FormData();
  if (project.name !== name) formData.append('name', name);
  formData.append('description', description);
  formData.append('save_only', saveOnly ? 'True' : 'False');
  if (ideabox_item) {
    formData.append('new_ideabox_item', ideabox_item.slug);
  } else {
    formData.append('new_ideabox_item', 'null');
  }

  if (JSON.stringify(project.tags) !== JSON.stringify(tags)) {
    formData.append('tags', JSON.stringify(tags));
  }

  imagesToDelete.forEach((image) => {
    formData.append('to_delete_images', image.toString());
  });

  filesToDelete.forEach((file) => {
    formData.append('to_delete_files', file.toString());
  });

  images.forEach((image) => {
    formData.append('new_images', image.blob!, toImageName(image.name));
  });

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  try {
    const { data } = await api.patch(`/projects/${project.slug}`, formData);
    return new Project(data);
  } catch (e) {
    return null;
  }
}

async function simpleUpdateProject(
  project: Project,
  formData: Partial<Project>,
) {
  try {
    const { data } = await api.patch(`/projects/${project.slug}`, formData);
    project.update(data);
  } catch (e) {}
}

async function createContribution(
  project: Project,
  step: ProjectStep,
  content: string,
  images: ImageImportResult[],
  files: FileImportResult[],
  pollData?: Answer[],
): Promise<ProjectContribution | null> {
  const formData = new FormData();
  formData.append('content', content);
  formData.append('step', step.id.toString());

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  images.forEach((image) => {
    formData.append('new_images', image.blob!, toImageName(image.name));
  });

  if (pollData) {
    formData.append('data', JSON.stringify(pollData));
  }

  try {
    const { data } = await api.post(
      `/projects/${project.slug}/create_contribution`,
      formData,
    );
    return new ProjectContribution(data);
  } catch (e) {
    return null;
  }
}

async function createStep(
  project: Project,
  title: string,
  content: string,
  pollData: any,
  type: StepType,
  anonymous: boolean,
  multiple: boolean,
  alwaysOnResults: boolean,
  images: ImageImportResult[],
  files: FileImportResult[],
  targetGroups: string[] = [],
): Promise<ProjectStep | null> {
  const formData = new FormData();
  formData.append('description', content);
  formData.append('title', title);
  formData.append('type', type);
  formData.append('data', JSON.stringify(pollData));
  formData.append('anonymous', anonymous ? 'True' : 'False');
  formData.append('multiple', multiple ? 'True' : 'False');
  formData.append('always_on_results', alwaysOnResults ? 'True' : 'False');

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  images.forEach((image) => {
    formData.append('new_images', image.blob!, toImageName(image.name));
  });

  targetGroups.forEach((group) => {
    formData.append('target_groups', group);
  });

  try {
    const { data } = await api.post(
      `/projects/${project.slug}/create_step`,
      formData,
    );
    return new ProjectStep(data);
  } catch (e) {
    return null;
  }
}

async function updateStep(
  project: Project,
  step: ProjectStep,
  title: string,
  content: string,
  anonymous: boolean,
  multiple: boolean,
  alwaysOnResults: boolean,
  files: FileImportResult[],
  filesToDelete: number[],
  targetGroups: string[] = [],
): Promise<ProjectStep> {
  const formData = new FormData();
  formData.append('description', content);
  formData.append('title', title);
  formData.append('anonymous', anonymous ? 'True' : 'False');
  formData.append('multiple', multiple ? 'True' : 'False');
  formData.append('always_on_results', alwaysOnResults ? 'True' : 'False');
  formData.append('step', step.id.toString());
  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  filesToDelete.forEach((file) => {
    formData.append('to_delete_files', file.toString());
  });

  targetGroups.forEach((group) => {
    formData.append('target_groups', group);
  });

  if (targetGroups.length === 0) {
    formData.append('target_groups', 'None');
  }
  try {
    const { data } = await api.post(
      `/projects/${project.slug}/update_step`,
      formData,
    );
    step.update(data);
    return step;
  } catch (e) {
    return step;
  }
}

async function endStep(
  project: Project,
  step: ProjectStep,
  message: string,
  published: boolean,
  images: ImageImportResult[],
  files: FileImportResult[],
): Promise<ProjectStep | null> {
  const formData = new FormData();
  formData.append('step', step.id.toString());
  formData.append('result_message', message);
  formData.append('publish', published ? 'True' : 'False');

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  images.forEach((image) => {
    formData.append('new_images', image.blob!, toImageName(image.name));
  });

  try {
    const { data } = await api.post(
      `/projects/${project.slug}/end_step`,
      formData,
    );
    return data;
  } catch (e) {
    return null;
  }
}

async function createPost(
  project: Project,
  title: string,
  content: string,
  images: ImageImportResult[],
  files: FileImportResult[],
): Promise<ProjectPost | null> {
  const formData = new FormData();
  formData.append('title', title);
  formData.append('content', content);

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  images.forEach((image) => {
    formData.append('new_images', image.blob!, toImageName(image.name));
  });

  try {
    const { data } = await api.post(
      `/projects/${project.slug}/create_post`,
      formData,
    );
    return new ProjectPost(data);
  } catch (e) {
    return null;
  }
}

async function updatePost(
  project: Project,
  post: ProjectPost,
  title: string,
  content: string,
  images: ImageImportResult[],
  files: FileImportResult[],
  imagesToDelete: number[],
  filesToDelete: number[],
): Promise<ProjectPost | null> {
  const formData = new FormData();
  formData.append('title', title);
  formData.append('content', content);

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  images.forEach((image) => {
    formData.append('new_images', image.blob!, toImageName(image.name));
  });

  filesToDelete.forEach((file) => {
    formData.append('to_delete_files', file.toString());
  });

  imagesToDelete.forEach((image) => {
    formData.append('to_delete_images', image.toString());
  });

  try {
    const { data } = await api.post(
      `/projects/${project.slug}/update_post`,
      formData,
      {
        params: {
          post_id: post.id,
        },
      },
    );
    post.update(data);
    return data;
  } catch (e) {
    return null;
  }
}

async function deletePost(project: Project, post: ProjectPost) {
  runInAction(() => {
    stores.projectsStore.deletedPosts.push(post.id);
  });
  await api.post(`/projects/${project.slug}/delete_post`, null, {
    params: {
      post_id: post.id,
    },
  });
}

async function deleteStep(project: Project, step: ProjectStep) {
  runInAction(() => {
    stores.projectsStore.deletedSteps.push(step.id);
  });
  await api.post(`/projects/${project.slug}/delete_step`, null, {
    params: {
      step_id: step.id,
    },
  });
}

async function selectContribution(
  project: Project,
  contribution: ProjectContribution,
  selected: boolean | null,
): Promise<ProjectContribution> {
  const formData = new FormData();
  formData.append('selected', selected ? 'True' : 'False');
  formData.append('contribution', contribution.id.toString());
  const { data } = await api.post(
    `/projects/${project.slug}/select_contribution`,
    formData,
  );
  return new ProjectContribution(data);
}

async function askChanges(project: Project, message: string): Promise<number> {
  const formData = new FormData();
  formData.append('message', message);
  const { data } = await api.post(
    `/projects/${project.slug}/ask_changes`,
    formData,
  );
  return data.conv_id;
}

async function deleteProject(project: Project) {
  await api.delete(`/projects/${project.slug}`);
}

async function getInvitations(project: Project): Promise<MiniUser[]> {
  try {
    const { data } = await api.get(`/projects/${project.slug}/invitations`);
    return data.map((c: any) => c.user);
  } catch (e) {
    return [];
  }
}

async function getRequests(project: Project): Promise<MiniUser[]> {
  try {
    const { data } = await api.get(
      `/projects/${project.slug}/invitation_requests`,
    );
    return data.map((c: any) => c.user);
  } catch (e) {
    return [];
  }
}

async function isInvited(project: Project): Promise<boolean> {
  try {
    const { data } = await api.get(`/projects/${project.slug}/invited`);
    return data;
  } catch (e) {
    return false;
  }
}

async function hasRequested(project: Project): Promise<boolean> {
  try {
    const { data } = await api.get(`/projects/${project.slug}/requested`);
    return data;
  } catch (e) {
    return false;
  }
}

async function invite(
  project: Project,
  user: MiniUser,
  message?: string,
): Promise<void> {
  const formData = new FormData();
  formData.append('user', user.id.toString());
  if (message && message.length) {
    formData.append('message', message);
  }
  await api.post(`/projects/${project.slug}/invite`, formData);
}

async function removeMember(project: Project, user: MiniUser): Promise<void> {
  await api.post(`/projects/${project.slug}/remove_member`, {
    user: user.id,
  });
  runInAction(() => {
    project.members = project.members.filter((c) => c.id !== user.id);
    project.member_count -= 1;
  });
}

async function deleteRequest(project: Project, user: MiniUser): Promise<void> {
  const formData = new FormData();
  formData.append('user', user.id.toString());
  await api.post(`/projects/${project.slug}/delete_request`, formData);
}

async function acceptRequest(project: Project, user: MiniUser): Promise<void> {
  const formData = new FormData();
  formData.append('user', user.id.toString());
  await api.post(`/projects/${project.slug}/accept_request`, formData);
}

async function requestInvite(
  project: Project,
  message?: string,
): Promise<void> {
  const formData = new FormData();
  if (message && message.length) {
    formData.append('message', message);
  }
  await api.post(`/projects/${project.slug}/request_invitation`, formData);
}

async function join(project: Project): Promise<void> {
  await api.post(`/projects/${project.slug}/join`);
  runInAction(() => {
    project.member_count += 1;
    project.members.push(stores.authStore.user);
    stores.chatStore.loadConversation(project.conversation);
  });
  project.checkOnBoarding();
}

async function getFiles(project: Project): Promise<ProjectUploadedFile[]> {
  try {
    const { data } = await api.get(`/projects/${project.slug}/files`);
    return data;
  } catch (e) {
    return [];
  }
}

async function uploadFile(
  project: Project,
  file: FileImportResult,
): Promise<ProjectUploadedFile | null> {
  try {
    const formData = new FormData();
    formData.append('file', file.blob!, file.name);
    const { data } = await api.post(
      `/projects/${project.slug}/upload_file`,
      formData,
    );
    return data;
  } catch (e) {
    return null;
  }
}

async function deleteFile(project: Project, file: number): Promise<void> {
  const formData = new FormData();
  formData.append('file', file.toString());
  await api.post(`/projects/${project.slug}/delete_file`, formData);
}

async function publish(project: Project): Promise<void> {
  const { data } = await api.patch(`/projects/${project.slug}`);
  project.update(data);
}

async function setStatus(
  item: Project,
  status: ProjectStatus | null,
  message: string,
  moderationMessage?: string,
  file?: FileImportResult,
) {
  const formData = new FormData();
  formData.append('status', status ? status : '');
  formData.append('status_moderation_message', moderationMessage || '');
  formData.append('status_message', message);
  if (file) {
    formData.append('status_file', file.blob!, file.name);
  }
  const { data } = await api.post(
    `/projects/${item.slug}/set_status`,
    formData,
  );
  item.update(data);
}

async function reject(item: Project, message: string) {
  const { data } = await api.post(`/projects/${item.slug}/reject`, {
    message,
  });
  return data.conv_id as number;
}

async function getDefaultFiles() {
  const { data } = await api.get('/projects/default_files');
  return data;
}

async function updateOwner(project: Project, newOwner: MiniUser) {
  try {
    await api.post(`/projects/${project.slug}/update_owner`, {
      owner: newOwner.id,
    });
    runInAction(() => {
      project.owner = newOwner;
    });
  } catch (e) {}
}

async function togglePostPin(project: Project, post: ProjectPost) {
  const { data } = await api.post(`/projects/${project.slug}/toggle_post_pin`, {
    id: post.id,
  });
  runInAction(() => {
    post.pinned = data.pinned;
  });
}

export default {
  togglePostPin,
  getProject,
  getProjects,
  getNotPublished,
  createProject,
  updateProject,
  deleteProject,
  getContributions,
  getContributors,
  getPopular,
  getSteps,
  getPosts,
  createContribution,
  selectContribution,
  askChanges,
  createStep,
  createPost,
  endStep,
  getInvitations,
  getRequests,
  deleteRequest,
  requestInvite,
  acceptRequest,
  invite,
  isInvited,
  join,
  getFiles,
  uploadFile,
  deleteFile,
  updateStep,
  updatePost,
  getResults,
  hasRequested,
  publish,
  setStatus,
  reject,
  simpleUpdateProject,
  getDefaultFiles,
  deletePost,
  deleteStep,
  updateOwner,
  removeMember,
  deleteInvitation,
  resendInvitation,
};
