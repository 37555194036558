export const homePath = (subSection: string = '') => `/${subSection}`;

export const loginPath = '/login';
export const startRegistrationPath = '/start_registration';
export const resetPasswordPath = '/reset';
export const resetPasswordConfirmPath = '/reset_confirm';
export const joinPath = '/join';
export const registerPath = '/register';

export const myProfilePath = '/me';
export const accountPath = '/account';
export const accountSettingsPath = '/account/settings';
export const accountNewsPath = '/account/news';
export const accountCreationsPath = '/account/creations';
export const usersPath = '/users';
export const profilePath = (id: number | string = ':id') => `/profile/${id}`;
export const detailsRedirectPath = '/details_redirect';

export const chatPath = '/chat';
export const conversationPath = (conv: string | number = ':conv') =>
  `/chat/${conv}`;
export const startConversationPath = (userId: string | number = ':userId') =>
  `/chat/new/${userId}`;

export const ideaboxPath = '/ideabox';
export const ideaboxDetailsPath = (slug: string = ':slug') =>
  `/ideabox/${slug}`;

export const academyPath = (feature: string = 'academy') => `/${feature}`;
export const academyDetailsPath = (
  slug: string = ':slug',
  feature: string = 'academy',
) => `/${feature}/${slug}`;

export const challengesPath = '/challenges';
export const challengesDetailsPath = (slug: string = ':slug') =>
  `/challenges/${slug}`;
export const challengesAdminPath = (slug: string = ':slug') =>
  `/challenges_admin/${slug}`;

export const projectsPath = '/projects';
export const projectsDetailsPath = (
  slug: string = ':slug',
  subPage: string = 'dashboard',
) => `/projects/${slug}/${subPage}`;

export const intraPath = '/intra';
export const intraDetailsPath = (
  slug: string = ':slug',
  folderId: string = 'general',
  subFolder: string = '',
) =>
  subFolder.length !== 0
    ? `/intra/${slug}/${folderId}/${subFolder}`
    : `/intra/${slug}/${folderId}`;

export const openInnoPath = '/openinno';
export const openInnoJoinPath = (slug: string = ':slug') =>
  `/openinno/partnerships/${slug}/join`;
export const openInnoProjectJoinPath = (slug: string = ':slug') =>
  `/openinno/projects/${slug}/join`;
export const openInnoChallengePath = (slug: string = ':slug') =>
  `/innovation/challenges/${slug}`;
export const partnershipsPath = '/openinno/ext_partnerships';
export const partnershipPath = (slug: string = ':slug') =>
  `/openinno/partnerships/${slug}`;
export const openInnoChallengeAdminPath = (slug: string = ':slug') =>
  `/openinno/challenges/${slug}`;
export const openInnoChallengesPath = '/openinno/challenges';
export const openInnoPartnershipsPath = '/openinno/partnerships';
export const openInnoPublicPagePath = (slug: string = ':slug') =>
  `/innovation/${slug}`;
export const openInnoPublicPageAdminPath = (slug: string = ':slug') =>
  `/openinno/public_pages/${slug}`;

export const openInnoPartnerDetailsPath = (slug: string = ':slug') =>
  `/openinno/partners/${slug}`;
export const openInnoPartnerRedirectPath = (id: string | number = ':id') =>
  `/openinno/partners_id/${id}`;

export const openInnoProjectsDetailsPath = (
  slug: string = ':slug',
  subPage: string = 'dashboard',
) => `/openinno/projects/${slug}/${subPage}`;

export const settingsPath = (
  subPage: string = ':section',
  subSection?: string,
) => `/settings/${subSection || subPage}`;
export const dashboardPath = (subPage: string, feature?: string) =>
  `/dashboard/${subPage}${feature ? `/${feature}` : ''}`;

export const searchPath = (subPage: string = 'contents', reset?: boolean) =>
  `/search/${subPage}${reset ? '?reset=1' : ''}`;

export const communitiesPath = '/communities';
export const communitiesDetailsPath = (
  slug: string = ':slug',
  subPage?: string,
) => `/communities/${slug}${subPage ? `#${subPage}` : ''}`;
export const changeLogPath = '/changelog';

// modules
export const labProjectDetailsPath = (
  slug: string = ':slug',
  feature: string = 'lab',
) => `/${feature}/${slug}`;
export const labPath = (feature: string = 'lab') => `/${feature}`;

export const contestDetailsPath = (slug: string = ':slug', feature: string) =>
  `/${feature}/${slug}`;
export const contestPath = (feature: string) => `/${feature}`;

export const oiContestDetailsPath = (slug: string = ':slug') =>
  `/oi_contest/${slug}`;
export const oiContestAdminDetailsPath = (slug: string = ':slug') =>
  `/oi_contest/${slug}/admin`;
export const oiContestPath = '/oi_contest';
