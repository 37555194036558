import { DateTime } from 'luxon';
import { action, observable } from 'mobx';
import LikeApi from '~/api/like';
import ShareApi from '~/api/share';
export interface UploadedImage {
  id: number;
  image: string;
  date_created: DateTime;
}

export interface UploadedFile {
  id: number;
  file: string;
  date_created: DateTime;
}

export interface FileImportResult {
  name: string;
  blob?: Blob;
  id?: number;
}

export class Likable {
  public like_count: number;
  public liked: boolean;
}

export class Sharable {
  public share_count: number;
  public shared: boolean;
}

export class Commentable {
  @observable
  public comment_count: number;

  @observable
  public commented: boolean;

  constructor(data: any) {
    Object.assign(this, data);
  }

  @action.bound
  public addComment() {
    this.comment_count += 1;
    this.commented = true;
  }
}

export class Socialable {
  public object_type: string;
  public id: number;

  @observable
  public comment_count: number;

  @observable
  public commented: boolean;

  @observable
  public share_count: number;

  @observable
  public shared: boolean;

  @observable
  public like_count: number;

  @observable
  public liked: boolean;

  public date_created: DateTime;

  constructor(object_type: string, data: any) {
    this.object_type = object_type;
    this.comment_count = data.comment_count;
    this.commented = data.commented;
    this.like_count = data.like_count;
    this.liked = data.liked;
    this.share_count = data.share_count;
    this.shared = data.shared;
    this.date_created = data.date_created;
    this.id = data.id;
  }

  @action.bound
  public toggleLike() {
    if (this.liked) {
      this.like_count -= 1;
      LikeApi.unlike(this.object_type, this.id);
    } else {
      this.like_count += 1;
      LikeApi.like(this.object_type, this.id);
    }
    this.liked = !this.liked;
  }

  @action.bound
  public share() {
    if (!this.shared) {
      this.shared = true;
      this.share_count += 1;
      ShareApi.share(this.object_type, this.id);
    }
  }

  @action.bound
  public addComment() {
    this.comment_count += 1;
    this.commented = true;
  }
}
