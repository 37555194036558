function canvasHasTransparency(canvas: HTMLCanvasElement) {
  const context = canvas.getContext('2d');
  if (!context) return true;

  const data = context.getImageData(0, 0, canvas.width, canvas.height).data;
  if (!data) return true;

  for (let i = 3, n = data.length; i < n; i += 4) {
    if (data[i] < 255) {
      return true;
    }
  }

  return false;
}

export default canvasHasTransparency;
