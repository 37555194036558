export const yellow = '#FFCC33';
export const red = '#FF6666';
export const green = '#66CC99';
export const aqua = '#66bbfe';
export const pink = '#ff0066';
export const blue = '#003366';
export const sand = '#eccc68';
export const SECONDARY_COLORS = {
  '#F44336': '#36e7f4',
  '#FF1744': '#17b8ff',
  '#E91E63': '#1ee9a4',
  '#9C27B0': '#27b058',
  '#673AB7': '#4bb73a',
  '#3F51B5': '#51b53f',
  '#2196F3': '#f37e21',
  '#03A9F4': '#f44e03',
  '#00BCD4': '#d40052',
  '#009688': '#c90013',
  '#4CAF50': '#af4cab',
  '#8BC34A': '#824ac3',
  '#FF9800': '#0067ff',
  '#FF5722': '#22caff',
  '#795548': '#07b6f5',
  '#F8C12C': '#7CBB67',
  '#121426': '#008ed2',
  '#002ddc': '#b3f3fd',
};
export const PRIMARY_COLORS = [...Object.keys(SECONDARY_COLORS)];
export const orange = '#ff9800';

export const ideaColor = yellow;
export const problemColor = red;
export const watchColor = green;
export const communitiesColor = '#03a9f4';
export const openInnoColor = '#FF3333';

export const ideaboxTypeColors = {
  idea: ideaColor,
  problem: problemColor,
  watch: watchColor,
};

export const backgroundColor = '#f7f7f7';
export function randomColor() {
  return Object.values(SECONDARY_COLORS)[
    Math.floor(Math.random() * Object.keys(SECONDARY_COLORS).length)
  ] as string;
}
