import { runInAction } from 'mobx';
import { ImageImportResult } from '~/components/ImageImport';
import { PaginatedResult } from '~/models/api';
import { Rating } from '~/models/challenges';
import {
  Challenge,
  ChallengeContribution,
  Comment,
  MiniPartner,
  Partner,
  Partnership,
  PartnershipUploadedFile,
  PartnershipUser,
  PublicPage,
} from '~/models/openinno';
import { Answer, PollResults } from '~/models/polls';
import { MiniUser } from '~/models/user';
import { FileImportResult } from '~/models/utils';
import stores from '~/stores';
import getFileName from '~/utils/getFileName';
import storage from '~/utils/storage';
import toImageName from '~/utils/toImageName';
import api from './api';

let sessionKey: string | null = null;
let captchaToken: string | null = null;

function setCaptchaToken(token: string) {
  captchaToken = token;
}

async function getSessionKey(): Promise<string> {
  if (sessionKey) return sessionKey;

  const key = storage.get('sessionkey');
  if (key) {
    sessionKey = key;
    return key;
  }

  const { data } = await api.get('/openinno/create_session');
  storage.set('sessionkey', data.key);
  sessionKey = data.key;
  return data.key;
}

const getOpenInnoHeaders = async () => {
  return {
    'X-CAMPUS-SESSION': await getSessionKey(),
    'x-CAPTCHA-TOKEN': captchaToken,
  };
};

async function getMyPartnerships(): Promise<Partnership[]> {
  const { data } = await api.get('/openinno/partnerships/my_partnerships', {
    headers: await getOpenInnoHeaders(),
  });
  return data.map((c: any) => new Partnership(c));
}

async function getToken(
  join_code: string,
): Promise<{ token: string; file_token: string }> {
  const { data } = await api.get('/openinno/get_token', {
    params: { join_code },
  });
  return data;
}

async function getChallenges(
  url: string | null,
  params?: any,
): Promise<PaginatedResult<Challenge>> {
  const { data } = await api.get(url || '/openinno/challenges/search', {
    headers: await getOpenInnoHeaders(),
    params: {
      ...params,
    },
  });
  return {
    ...data,
    results: data.results.map((r: any) => new Challenge(r)),
  };
}

async function getNotPublishedChallenges(): Promise<Challenge[]> {
  const { data } = await api.get('/openinno/challenges/not_published', {
    headers: await getOpenInnoHeaders(),
  });
  return data.map((r: any) => new Challenge(r));
}

async function getChallenge(slug: string): Promise<Challenge> {
  const { data } = await api.get(`/openinno/challenges/${slug}`, {
    headers: await getOpenInnoHeaders(),
  });
  return new Challenge(data);
}

async function like(type: string, id: number): Promise<Comment> {
  const { data } = await api.post(
    '/openinno/likes/like',
    {
      content_type: type,
      object_id: id,
    },
    {
      headers: await getOpenInnoHeaders(),
    },
  );
  return data;
}

async function unlike(type: string, id: number): Promise<Comment> {
  const { data } = await api.post(
    '/openinno/likes/unlike',
    {
      content_type: type,
      object_id: id,
    },
    {
      headers: await getOpenInnoHeaders(),
    },
  );
  return data;
}

async function share(type: string, id: number): Promise<Comment> {
  const { data } = await api.post(
    '/openinno/shares/share',
    {
      content_type: type,
      object_id: id,
    },
    {
      headers: await getOpenInnoHeaders(),
    },
  );
  return data;
}

async function getComments(type: string, id: number): Promise<Comment[]> {
  try {
    const { data } = await api.get('/openinno/comments/by_type', {
      params: { object_id: id, content_type: type },
      headers: await getOpenInnoHeaders(),
    });
    return data.map((c: any) => new Comment(c));
  } catch (e) {
    return [];
  }
}

async function deleteComment(id: number): Promise<void> {
  await api.delete('/openinno/comments/delete_comment', {
    params: { id },
    headers: await getOpenInnoHeaders(),
  });
}

async function postComment(
  type: string,
  id: number,
  username: string,
  content: string,
  parent?: number,
): Promise<Comment> {
  const { data } = await api.post(
    '/openinno/comments/post',
    {
      content,
      username,
      parent,
      content_type: type,
      object_id: id,
    },
    {
      headers: await getOpenInnoHeaders(),
    },
  );
  return new Comment(data);
}

async function getPartnerships(): Promise<PaginatedResult<Partnership>> {
  const { data } = await api.get('/openinno/partnerships');
  return {
    ...data,
    results: data.results.map((c: any) => new Partnership(c)),
  };
}

async function getPartnership(slug: string): Promise<Partnership | null> {
  try {
    const { data } = await api.get(`/openinno/partnerships/${slug}`);
    return new Partnership(data);
  } catch (_) {
    return null;
  }
}

async function createPartnership(
  name: string,
  email: string,
  fullname: string,
  partnerName: string,
): Promise<Partnership> {
  const { data } = await api.post('/openinno/partnerships', {
    name,
    partner_name: partnerName,
    partner_email: email,
    partner_fullname: fullname,
  });
  return new Partnership(data);
}

async function getFiles(
  partnership: Partnership,
): Promise<PartnershipUploadedFile[]> {
  try {
    const { data } = await api.get(
      `/openinno/partnerships/${partnership.slug}/files`,
    );
    return data;
  } catch (e) {
    return [];
  }
}

async function invitePartnershipMember(
  user: MiniUser,
  partnership: Partnership,
): Promise<PartnershipUser> {
  const { data } = await api.post(
    `/openinno/partnerships/${partnership.slug}/invite`,
    {
      user: user.id,
    },
  );
  return data;
}

async function resendInvitation(
  user: PartnershipUser,
  partnership: Partnership,
): Promise<PartnershipUser> {
  const { data } = await api.post(
    `/openinno/partnerships/${partnership.slug}/resend_invitation`,
    {
      user: user.id,
    },
  );
  return data;
}

async function uninvite(
  user: PartnershipUser,
  partnership: Partnership,
): Promise<PartnershipUser> {
  const { data } = await api.post(
    `/openinno/partnerships/${partnership.slug}/uninvite`,
    {
      user: user.id,
    },
  );
  runInAction(() => {
    partnership.members = partnership.members.filter((c) => c.id !== user.id);
  });
  return data;
}

async function invitePartnershipPartner(
  name: string,
  email: string,
  partnership: Partnership,
): Promise<PartnershipUser> {
  const { data } = await api.post(
    `/openinno/partnerships/${partnership.slug}/invite_partner`,
    {
      partner_email: email,
      partner_fullname: name,
    },
  );
  return data;
}

async function getChallengeResults(challenge: Challenge): Promise<PollResults> {
  try {
    const { data } = await api.get(
      `/openinno/challenges/${challenge.slug}/results`,
    );
    return data;
  } catch (e) {
    return [];
  }
}

async function uploadFile(
  partnership: Partnership,
  file: FileImportResult,
): Promise<PartnershipUploadedFile | null> {
  try {
    const formData = new FormData();
    formData.append('file', file.blob!, file.name);
    const { data } = await api.post(
      `/openinno/partnerships/${partnership.slug}/upload_file`,
      formData,
    );
    return data;
  } catch (e) {
    return null;
  }
}

async function deleteFile(
  partnership: Partnership,
  file: number,
): Promise<void> {
  const formData = new FormData();
  formData.append('file', file.toString());
  await api.post(
    `/openinno/partnerships/${partnership.slug}/delete_file`,
    formData,
  );
}

async function createChallenge(
  title: string,
  isPoll: boolean,
  options: any,
  personalData: string[],
  sections: string[],
  language: string,
  juryMembers: MiniUser[],
  publicPage: number | null,
): Promise<Challenge> {
  const { data } = await api.post(
    '/openinno/challenges',
    {
      title,
      options,
      language,
      sections,
      personal_data: personalData,
      type: isPoll ? 'poll' : 'files',
      new_jury_members: juryMembers.map((j) => j.id),
      public_page: publicPage,
    },
    { headers: await getOpenInnoHeaders() },
  );
  return new Challenge(data);
}

async function updateChallenge(
  newData: Partial<Challenge>,
  challenge: Challenge,
): Promise<Challenge> {
  const { data } = await api.patch(
    `/openinno/challenges/${challenge.slug}`,
    {
      ...newData,
      new_jury_members: newData.jury_members
        ? newData.jury_members.map((j) => j.id)
        : undefined,
    },
    { headers: await getOpenInnoHeaders() },
  );
  challenge.update(data);
  return challenge;
}

async function updateChallengeSection(
  challenge: Challenge,
  section: string,
  content: string,
  newFiles: FileImportResult[],
  toDeleteFiles: number[],
): Promise<Challenge> {
  const formData = new FormData();

  formData.append(section, content);

  newFiles.forEach((f) => {
    formData.append(
      'new_files',
      f.blob!,
      getFileName(`${section}__section__${f.name}`),
    );
  });

  toDeleteFiles.forEach((f) => {
    formData.append('to_delete_files', f.toString());
  });

  const { data } = await api.patch(
    `/openinno/challenges/${challenge.slug}`,
    formData,
    { headers: await getOpenInnoHeaders() },
  );
  challenge.update(data);
  return challenge;
}

async function updatePartnership(
  newData: Partial<Partnership>,
  partnership: Partnership,
): Promise<Partnership> {
  const { data } = await api.patch(
    `/openinno/partnerships/${partnership.slug}`,
    newData,
    { headers: await getOpenInnoHeaders() },
  );
  partnership.update(data);
  return partnership;
}

async function changePartner(
  partner: PartnershipUser,
  partnership: Partnership,
): Promise<void> {
  await api.post(
    `/openinno/partnerships/${partnership.slug}/update_partner`,
    {
      partner: partner.id,
    },
    { headers: await getOpenInnoHeaders() },
  );
}

async function verifyEmail(email: string, force?: boolean): Promise<boolean> {
  const { data } = await api.get('/openinno/challenges/verify_email', {
    params: {
      email,
      force: force ? 'True' : undefined,
    },
    headers: await getOpenInnoHeaders(),
  });
  return data.valid;
}

async function updatePartnerPartnership(
  fullname: string,
  logo: ImageImportResult,
  partnership_slug: string,
  partnership?: Partnership,
): Promise<void> {
  const formData = new FormData();
  formData.append('partner_name', fullname);
  formData.append('partner_logo', logo.blob!, toImageName(logo.name));
  const { data } = await api.patch(
    `/openinno/partnerships/${partnership_slug}`,
    formData,
  );

  if (partnership) {
    partnership.update(data);
  }
}

async function updateChallengeBanner(
  banner: ImageImportResult,
  challenge: Challenge,
): Promise<Challenge> {
  const formData = new FormData();
  formData.append('banner', banner.blob!, toImageName(banner.name));

  const { data } = await api.patch(
    `/openinno/challenges/${challenge.slug}`,
    formData,
    { headers: await getOpenInnoHeaders() },
  );

  challenge.update(data);
  return challenge;
}

async function createContribution(
  challenge: Challenge,
  content: string,
  images: ImageImportResult[],
  files: FileImportResult[],
  personalData: any,
  code: string | null,
  pollData?: Answer[],
): Promise<ChallengeContribution | null> {
  const formData = new FormData();
  formData.append('content', content);

  if (code) {
    formData.append('code', code);
  }

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  images.forEach((image) => {
    formData.append('new_images', image.blob!, toImageName(image.name));
  });

  if (pollData) {
    formData.append('data', JSON.stringify(pollData));
  }

  formData.append('personal_data', JSON.stringify(personalData));

  try {
    const {
      data,
    } = await api.post(
      `/openinno/challenges/${challenge.slug}/create_contribution`,
      formData,
      { headers: await getOpenInnoHeaders() },
    );
    runInAction(() => {
      challenge.contributed = true;
      challenge.contribution_count += 1;
    });
    return new ChallengeContribution(data);
  } catch (e) {
    return null;
  }
}

async function getContributions(
  challenge: Challenge,
  params?: any,
): Promise<ChallengeContribution[]> {
  try {
    const { data } = await api.get(
      `/openinno/challenges/${challenge.slug}/contributions`,
      {
        params,
        headers: await getOpenInnoHeaders(),
      },
    );
    return data.map((i: any) => new ChallengeContribution(i));
  } catch (e) {
    return [];
  }
}

async function selectContribution(
  challenge: Challenge,
  contribution: ChallengeContribution,
  selected: boolean | null,
): Promise<ChallengeContribution> {
  const formData = new FormData();
  formData.append('selected', selected ? 'True' : 'False');
  formData.append('contribution', contribution.id.toString());
  const {
    data,
  } = await api.post(
    `/openinno/challenges/${challenge.slug}/select_contribution`,
    formData,
    { headers: await getOpenInnoHeaders() },
  );
  return new ChallengeContribution(data);
}

async function deleteContribution(
  challenge: Challenge,
  contribution: ChallengeContribution,
) {
  await api.post(
    `/openinno/challenges/${challenge.slug}/delete_contribution`,
    { contribution: contribution.id },
    { headers: await getOpenInnoHeaders() },
  );
  runInAction(() => {
    challenge.contribution_count -= 1;
  });
}

async function initUser(
  email: string,
  fullname: string,
  password: string,
  phone: string,
  language: string,
): Promise<any> {
  const { data } = await api.post('/openinno/init_user', {
    email,
    fullname,
    password,
    phone,
    language,
  });
  return data;
}

async function publishResult(
  challenge: Challenge,
  message: string,
  publish: boolean,
  files: FileImportResult[],
): Promise<void> {
  const formData = new FormData();
  formData.append('end_message', message);
  formData.append('show_contributions', publish ? 'True' : 'False');

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  const {
    data,
  } = await api.post(
    `/openinno/challenges/${challenge.slug}/publish_result`,
    formData,
    { headers: await getOpenInnoHeaders() },
  );
  challenge.update(data);
}

async function getPersonalDataCSV(challenge: Challenge): Promise<string> {
  const { data } = await api.get(
    `/openinno/challenges/${challenge.slug}/personal_data_csv`,
  );
  return data;
}

async function deletePartnership(partnership: Partnership): Promise<void> {
  await api.delete(`/openinno/partnerships/${partnership.slug}`);
}

async function deleteChallenge(challenge: Challenge): Promise<void> {
  await api.delete(`/openinno/challenges/${challenge.slug}`);
}

async function rateContribution(
  challenge: Challenge,
  contribution: ChallengeContribution,
  rating: number,
  comment: string,
  files: FileImportResult[],
) {
  const formData = new FormData();
  formData.append('rating', rating.toString());
  formData.append('comment', comment);
  formData.append('contribution', contribution.id.toString());

  files.forEach((file) => {
    formData.append('new_files', file.blob!, file.name);
  });

  const { data } = await api.post(
    `/openinno/challenges/${challenge.slug}/rate_contribution`,
    formData,
  );
  runInAction(() => {
    contribution.ratings.push(data as Rating);
  });
}

async function getPartner(slug: string): Promise<Partner | null> {
  try {
    const { data } = await api.get(`/openinno/partners/${slug}`);
    return new Partner(data);
  } catch (_) {
    return null;
  }
}

async function getPartnerById(id: number): Promise<Partner | null> {
  try {
    const { data } = await api.get(`/openinno/partners/by_id?id=${id}`);
    return new Partner(data);
  } catch (_) {
    return null;
  }
}

async function createPartner(
  name: string,
  note: string,
  data_: any,
  description: string,
  logo: ImageImportResult | null,
) {
  const formData = new FormData();

  formData.append('name', name);
  formData.append('note', note);
  formData.append('description', description);
  formData.append('data', JSON.stringify(data_));

  if (logo) {
    formData.append('logo', logo.blob!, logo.name);
  }

  const { data } = await api.post('/openinno/partners', formData);
  return new Partner(data);
}

async function editPartner(
  partner: Partner,
  name: string,
  note: string,
  data_: any,
  description: string,
  logo: ImageImportResult | null,
) {
  const formData = new FormData();

  formData.append('name', name);
  formData.append('note', note);
  formData.append('description', description);
  formData.append('data', JSON.stringify(data_));

  if (logo) {
    formData.append('logo', logo.blob!, logo.name);
  }

  const { data } = await api.patch(
    `/openinno/partners/${partner.slug}`,
    formData,
  );
  return partner.update(data);
}

async function deletePartner(partner: Partner) {
  await api.delete(`/openinno/partners/${partner.slug}`);
  runInAction(() => {
    stores.openInnoStore.partners.delete(partner.slug);
  });
}

async function getPartners(): Promise<PaginatedResult<Partner>> {
  const { data } = await api.get('/openinno/partners', {
    params: { ps: 10000 },
  });
  return {
    ...data,
    results: data.results.map((c: any) => new Partner(c)),
  };
}

async function inviteExternalUser(
  partner: Partner,
  partnerEmail: string,
  partnerFullname: string,
  partnerPhone: string,
) {
  const { data } = await api.post(
    `/openinno/partners/${partner.slug}/add_external`,
    {
      partner_email: partnerEmail,
      partner_fullname: partnerFullname,
      partner_phone: partnerPhone,
    },
  );

  return data as MiniUser;
}

async function getMembers(partner: MiniPartner): Promise<MiniUser[]> {
  const { data } = await api.get(`/openinno/partners/${partner.slug}/members`);
  return data;
}

async function getPublicPages(): Promise<PublicPage[]> {
  const { data } = await api.get('/openinno/public_pages');
  return data.map((c: any) => new PublicPage(c));
}

async function getPublicPage(slug: string): Promise<PublicPage> {
  const { data } = await api.get(`/openinno/public_pages/${slug}`);
  return new PublicPage(data);
}

async function updatePublicPage(
  page: PublicPage,
  pageData: Partial<PublicPage>,
): Promise<PublicPage> {
  const { data } = await api.patch(
    `/openinno/public_pages/${page.slug}`,
    pageData,
  );
  page.update(data);
  return page;
}

async function updatePublicPageBanner(
  banner: ImageImportResult,
  page: PublicPage,
): Promise<PublicPage> {
  const formData = new FormData();
  formData.append('banner', banner.blob!, toImageName(banner.name));
  const { data } = await api.patch(
    `/openinno/public_pages/${page.slug}`,
    formData,
    {
      headers: await getOpenInnoHeaders(),
    },
  );
  page.update(data);
  return page;
}

async function deletePublicPage(page: PublicPage) {
  await api.delete(`/openinno/public_pages/${page.slug}`);
}

async function createPublicPage(title: string): Promise<PublicPage> {
  const { data } = await api.post('/openinno/public_pages', {
    title,
  });
  return new PublicPage(data);
}

async function updateExternal(
  partner: MiniPartner,
  user: MiniUser,
): Promise<MiniUser> {
  const { data } = await api.post(
    `/openinno/partners/${partner.slug}/update_external`,
    user,
  );
  return data;
}

async function deleteExternal(partner: MiniPartner, user: MiniUser) {
  await api.delete(`/openinno/partners/${partner.slug}/delete_external`, {
    params: {
      user: user.id,
    },
  });
}

async function getCSVResult(challenge: Challenge) {
  const { data } = await api.get(
    `/openinno/challenges/${challenge.slug}/csv_results`,
  );
  return data.csv as string;
}

export default {
  createPublicPage,
  updatePublicPage,
  getPublicPage,
  getPublicPages,
  updatePublicPageBanner,
  deletePublicPage,
  deletePartnership,
  getChallenges,
  getChallenge,
  deleteComment,
  getContributions,
  updatePartnership,
  selectContribution,
  publishResult,
  getPartnership,
  getChallengeResults,
  getPartnerships,
  createPartnership,
  getFiles,
  uploadFile,
  deleteFile,
  like,
  unlike,
  share,
  verifyEmail,
  setCaptchaToken,
  getComments,
  initUser,
  postComment,
  getToken,
  updatePartnerPartnership,
  createChallenge,
  updateChallenge,
  updateChallengeBanner,
  createContribution,
  invitePartnershipMember,
  invitePartnershipPartner,
  resendInvitation,
  uninvite,
  getPersonalDataCSV,
  deleteChallenge,
  getMyPartnerships,
  changePartner,
  updateChallengeSection,
  rateContribution,
  deleteContribution,
  getPartner,
  editPartner,
  deletePartner,
  getCSVResult,
  getPartners,
  createPartner,
  getMembers,
  inviteExternalUser,
  deleteExternal,
  updateExternal,
  getPartnerById,
  getNotPublishedChallenges,
};
