import MiniSearch from 'minisearch';
import { action, computed, observable, runInAction } from 'mobx';
import { MiniUser } from '~/models/user';
import { Socialable } from '~/models/utils';
import stores from '~/stores';
import downloadFile from '~/utils/downloadFile';
import extractText from '~/utils/extractText';
import sanitize from '~/utils/sanitize';
import { Poll, PollData } from '../polls/models';
import FeedApi from './api';

export type DisplayTo = 'campus_team' | 'team' | null;

export class FeedItem extends Socialable {
  public id: number;

  @observable
  public content: string;

  @observable
  public pinned: boolean;

  @observable
  public display_to: DisplayTo;

  @observable
  public poll: Poll;

  @observable
  public tags: string[];

  public owner: MiniUser;

  constructor({ poll, ...data }: any) {
    super('feeditem', data);
    Object.assign(this, data);
    this.content = sanitize(this.content);
    if (poll) {
      this.poll = new Poll(poll);
    }
  }

  public update({ poll, ...data }: any) {
    Object.assign(this, data);
    if (poll) {
      this.poll = new Poll(poll);
    }
  }

  @computed
  get isOwner() {
    return this.owner.id === stores.authStore.user.id;
  }

  public getIndexableContent() {
    return { content: extractText(this.content), id: this.id };
  }
}

export class Feed {
  public id: number;

  @observable
  public items: FeedItem[] = [];

  @observable
  public data: any;

  public searchIndex: MiniSearch;

  constructor(data: any) {
    Object.assign(this, data);
  }

  public async loadItems() {
    const items = await FeedApi.getFeedItems(this);
    runInAction(() => {
      this.items = items.map((i: any) => new FeedItem(i));
    });
    if (!this.searchIndex) {
      this.initSearchIndex();
    }
    this.searchIndex.addAll(this.items.map((c) => c.getIndexableContent()));
  }

  public initSearchIndex() {
    this.searchIndex = new MiniSearch({
      fields: ['content'],
      storeFields: ['id'],
      searchOptions: {
        fuzzy: 0.2,
        prefix: true,
      },
    });
  }

  @action
  public toggleItemPin(item: FeedItem) {
    FeedApi.toggleItemPin(this, item);
    item.pinned = !item.pinned;
  }

  @action
  public deleteItem(item: FeedItem) {
    FeedApi.deleteItem(this, item);
    runInAction(() => {
      this.items = this.items.filter((i) => i.id !== item.id);
    });
    if (this.searchIndex) {
      this.searchIndex.remove(item.getIndexableContent());
    }
  }

  @action
  public async createItem(
    content: string,
    poll?: PollData | null,
    noActivity?: boolean,
    tags?: string[],
    displayTo?: DisplayTo,
    owner?: MiniUser,
  ) {
    const item = await FeedApi.createItem(
      this,
      content,
      poll || undefined,
      noActivity,
      tags,
      displayTo,
      owner,
    );
    const newItem = new FeedItem(item);
    runInAction(() => {
      this.items.unshift(newItem);
    });
    if (!this.searchIndex) this.initSearchIndex();
    this.searchIndex.add(newItem.getIndexableContent());
  }

  @action
  public async updateItem(
    item: FeedItem,
    content: string,
    poll?: PollData | null,
    tags?: string[],
    displayTo?: DisplayTo,
  ) {
    const newData = await FeedApi.updateItem(
      this,
      item,
      content,
      poll || undefined,
      tags,
      displayTo,
    );
    item.update(newData);

    if (!this.searchIndex) this.initSearchIndex();
    this.searchIndex.remove(item.getIndexableContent());
    this.searchIndex.add(item.getIndexableContent());
  }

  @computed
  get sortedItems() {
    return this.items.sort((i1, i2) => {
      return Number(i2.pinned) - Number(i1.pinned);
    });
  }

  public searchItems(items: FeedItem[], search: string) {
    if (!items || items.length === 0) return [];
    const validIds = items.map((c) => c.id);
    const res = this.searchIndex
      .search(search)
      .filter((c) => validIds.indexOf(c.id) !== -1);
    return res.map((c) => items.find((i) => i.id === c.id)!);
  }

  public downloadHistory = async () => {
    const data = await FeedApi.getHistory(this);
    downloadFile(
      URL.createObjectURL(
        new Blob([data], {
          type:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
      ),
      `feed_${this.data.name || this.id}.xlsx`,
    );
  };
}
