import { Button, Typography } from '@material-ui/core';
import { Forum, HelpOutline, KeyboardArrowLeft } from '@material-ui/icons';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { withRouter } from 'react-router';
import CommunitiesIcon from '~/assets/images/icons/communities.svg';
import { collapsedSidebarWidth, sidebarWidth } from '~/components/Sidebar';
import styled from '~/styles';
import SidebarItem from './SidebarItem';

import {
  academyPath,
  accountPath,
  challengesPath,
  changeLogPath,
  chatPath,
  communitiesPath,
  contestPath,
  conversationPath,
  dashboardPath,
  homePath,
  ideaboxPath,
  intraPath,
  labPath,
  oiContestPath,
  openInnoPartnershipsPath,
  openInnoProjectsDetailsPath,
  projectsPath,
  settingsPath,
} from '~/constants/routes';

import AdminIcon from '~/assets/images/icons/admin_icon.svg';
import AnalyticsIcon from '~/assets/images/icons/analytics.svg';
import GiftIcon from '~/assets/images/icons/gift.svg';

import ChallengeIcon from '~/assets/images/icons/challenge.svg';
import IdeaboxIcon from '~/assets/images/icons/ideabox.svg';
import IntraIcon from '~/assets/images/icons/intra.svg';
import ProjectsIcon from '~/assets/images/icons/projets.svg';

import { FormattedMessage } from 'react-intl';
import MediaQuery from 'react-responsive';
import Avatar from '~/components/Avatar';
import BuildingIcon from '~/components/BuildingIcon';
import history from '~/history';
import i18n from '~/locales/i18n';
import { useStore } from '~/stores';
import theme from '~/styles/theme';
import toMediaQuery from '~/utils/toMediaQuery';

const Wrapper = styled.div<{ open?: boolean }>`
  background-color: ${(p) => p.theme.palette.primary.main};
  height: 100vh;
  width: ${(p) => (p.open ? sidebarWidth : collapsedSidebarWidth)}px;
  min-width: ${(p) => (p.open ? sidebarWidth : collapsedSidebarWidth)}px;
  position: sticky;
  top: 0;
  overflow: hidden;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  transition: width 0.3s, min-width 0.3s;
  padding-top: 5px;
  box-sizing: border-box;

  ${(p) => p.theme.breakpoints.down('sm')} {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: calc(70vw);
    min-width: calc(70vw);
    z-index: 1410;
    box-shadow: 0 0px 3px rgba(0, 0, 0, 0.16), 0 0px 3px rgba(0, 0, 0, 0.23) !important;

    transition: transform 0.3s;
    transform: ${(p) => (p.open ? 'none' : 'translateX(calc(-100% - 5px))')};
  }

  ${(p) => p.theme.breakpoints.down('xs')} {
    width: calc(100vw - 120px);
    min-width: calc(100vw - 120px);
  }
`;

const Items = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding-bottom: 10px;
`;

const Separator = styled.div`
  height: 2px;
  min-height: 1px;
  margin: 10px 15px;
  background-color: rgba(255, 255, 255, 0.2);
`;

const Title = styled(Typography)`
  margin: 5px 15px;
  margin-bottom: 5px;
  margin-top: 3px;
  font-size: 14px;
  text-transform: uppercase;
  color: white;
  white-space: nowrap;
`;

const ToggleButton = styled.div`
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  cursor: pointer;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const Notif = styled(Typography)`
  height: 10px;
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border-radius: 50%;
  color: white;
  background-color: ${(p) => p.theme.palette.secondary.main};
  position: fixed;
  top: 25px;
  left: 40px;
  z-index: 100;
`;

function Sidebar() {
  const {
    activityStore,
    uiStore,
    organizationStore,
    chatStore,
    authStore,
    projectsStore,
    intraStore,
    challengesStore,
  } = useStore();

  const items = {
    ideabox: organizationStore.hasFeature('ideabox') &&
      !authStore.isFullyRestricted('ideabox') && (
        <SidebarItem
          // tslint:disable-next-line: campus-use-i18n
          label={<FormattedMessage id="buildings.ideabox" />}
          icon={<IdeaboxIcon height="22" width="22" />}
          to={ideaboxPath}
        />
      ),
    projects: organizationStore.hasFeature('projects') && (
      <SidebarItem
        // tslint:disable-next-line: campus-use-i18n
        label={<FormattedMessage id="buildings.projects" />}
        icon={<ProjectsIcon height="22" width="22" />}
        to={projectsPath}
        onClick={projectsStore.reset}
      />
    ),

    intra: organizationStore.hasFeature('intra') && (
      <SidebarItem
        // tslint:disable-next-line: campus-use-i18n
        label={<FormattedMessage id="buildings.intra" />}
        icon={<IntraIcon height="22" width="22" />}
        to={intraPath}
        onClick={intraStore.reset}
      />
    ),
    challenges: organizationStore.hasFeature('challenges') && (
      <SidebarItem
        // tslint:disable-next-line: campus-use-i18n
        label={<FormattedMessage id="buildings.challenges" />}
        icon={<ChallengeIcon height="22" width="22" />}
        to={challengesPath}
        onClick={challengesStore.reset}
      />
    ),
    oi_contest: organizationStore.hasFeature('oi_contest') &&
      !authStore.isFullyRestricted('oi_contest') && (
        <SidebarItem
          // tslint:disable-next-line: campus-use-i18n
          label={<FormattedMessage id="buildings.oi_contest" />}
          icon={
            <BuildingIcon building="oi_contest" size={22} color="inherit" />
          }
          to={oiContestPath}
        />
      ),
    'openinno.partnerships': organizationStore.hasFeature('openinno') &&
      !authStore.isFullyRestricted('openinno.partnerships') &&
      !organizationStore.isHidden('openinno.partnerships') && (
        <SidebarItem
          // tslint:disable-next-line: campus-use-i18n
          label={<FormattedMessage id="buildings.openinno.partnerships" />}
          icon={<BuildingIcon building="openinno" size={22} color="inherit" />}
          to={openInnoPartnershipsPath}
          matchPath={['/openinno/pa', '/openinno/projects']}
        />
      ),
    openinno: organizationStore.hasFeature('openinno') &&
      !authStore.isFullyRestricted('openinno.partnerships') &&
      !organizationStore.isHidden('openinno.partnerships') && (
        <SidebarItem
          // tslint:disable-next-line: campus-use-i18n
          label={<FormattedMessage id="buildings.openinno.partnerships" />}
          icon={<BuildingIcon building="openinno" size={22} color="inherit" />}
          to={openInnoPartnershipsPath}
          matchPath={['/openinno/pa', '/openinno/projects']}
        />
      ),
    communities: organizationStore.hasFeature('communities') &&
      !authStore.isFullyRestricted('communities') &&
      !authStore.isExternal && (
        <SidebarItem
          // tslint:disable-next-line: campus-use-i18n
          label={<FormattedMessage id="buildings.communities" />}
          icon={<CommunitiesIcon height="22" width="22" />}
          matchPath={communitiesPath}
          to={communitiesPath}
        />
      ),
  };

  if (organizationStore.hasFeature('lab')) {
    organizationStore.labFeatures
      .filter(
        (feature) =>
          !organizationStore.isHidden(feature) &&
          !authStore.isFullyRestricted('lab', feature),
      )
      .forEach(
        (c) =>
          (items[c] = (
            <SidebarItem
              // tslint:disable-next-line: campus-use-i18n
              label={organizationStore.getLabConfig(c).name}
              icon={
                <BuildingIcon
                  size={22}
                  color="white"
                  building={organizationStore.getLabConfig(c).icon || 'intra'}
                />
              }
              to={labPath(c)}
            />
          )),
      );
  }

  if (organizationStore.hasFeature('contest')) {
    organizationStore.contestFeatures
      .filter(
        (feature) =>
          !organizationStore.isHidden(feature) &&
          !authStore.isFullyRestricted('contest', feature),
      )
      .forEach(
        (c) =>
          (items[c] = (
            <SidebarItem
              // tslint:disable-next-line: campus-use-i18n
              label={organizationStore.getContestConfig(c).name}
              icon={
                <BuildingIcon
                  size={22}
                  color="white"
                  building={
                    organizationStore.getContestConfig(c).icon || 'challenges'
                  }
                />
              }
              to={contestPath(c)}
            />
          )),
      );
  }

  if (organizationStore.hasFeature('academy')) {
    organizationStore.academyFeatures
      .filter(
        (feature) =>
          !organizationStore.isHidden(feature) &&
          !authStore.isFullyRestricted('academy', feature),
      )
      .forEach(
        (c) =>
          (items[c] = (
            <SidebarItem
              // tslint:disable-next-line: campus-use-i18n
              label={organizationStore.getAcademyConfig(c).name}
              icon={
                <BuildingIcon
                  size={22}
                  color="white"
                  building={
                    organizationStore.getAcademyConfig(c).icon || 'academy'
                  }
                />
              }
              to={academyPath(c)}
            />
          )),
      );
  }

  return (
    <Wrapper open={!uiStore.sidebarCollapsed}>
      {!authStore.isExternal && (
        <>
          {activityStore.newActivityCount[0] > 0 && <Notif />}
          <SidebarItem
            icon={<BuildingIcon building="home" size={22} />}
            label={
              organizationStore.organization.config.campus_name ||
              i18n.formatMessage('titles.home')
            }
            exact
            to={homePath()}
            matchPath={homePath()}
          />
        </>
      )}
      <Items>
        {authStore.isExternal && (
          <>
            {!uiStore.sidebarCollapsed && (
              <>
                <Title>
                  <FormattedMessage id="openinno.projects.my_projects" />
                </Title>
              </>
            )}
            {authStore.myProjects.map((project) => (
              <SidebarItem
                icon={
                  <BuildingIcon
                    building="openinno.projects"
                    size={22}
                    color="inherit"
                  />
                }
                label={project.name}
                to={openInnoProjectsDetailsPath(project.slug)}
                matchPath={openInnoProjectsDetailsPath(project.slug, '')}
              />
            ))}
          </>
        )}
        {!authStore.isExternal &&
          organizationStore.organization.config.sidebar_config.map(
            (item, index) => {
              if (item.startsWith('separator://')) {
                if (
                  organizationStore.organization.config.hidden_separators.indexOf(
                    item === 'separator://' ? `separator://${index}` : item,
                  ) !== -1 &&
                  !authStore.isAdminOrAmbassador
                ) {
                  return null;
                }
                const parts = item.split('separator://');
                if (parts.length > 1 && parts[1].length > 0) {
                  return (
                    <>
                      <Separator />
                      {!uiStore.sidebarCollapsed && <Title>{parts[1]}</Title>}
                    </>
                  );
                }
                return <Separator />;
              }

              if (
                item === 'openinno.partnerships' &&
                organizationStore.organization.config.sidebar_config.indexOf(
                  'openinno',
                ) !== -1
              ) {
                return null;
              }

              const sidebarItem = items[item];
              return sidebarItem || null;
            },
          )}
        {!authStore.isExternal && (
          <MediaQuery
            query={toMediaQuery(theme.current.breakpoints.down('sm'))}
          >
            <Separator />
            <SidebarItem
              // tslint:disable-next-line: campus-use-i18n
              label={i18n.formatMessage('buildings.chat')}
              icon={<Forum height="22" width="22" />}
              to={chatPath}
              matchPath={chatPath}
            />
            {!authStore.isExternal && (
              <>
                {/* <SidebarItem
                  // tslint:disable-next-line: campus-use-i18n
                  label={i18n.formatMessage('dashboard.changelog')}
                  icon={<GiftIcon height="22" width="22" />}
                  to={changeLogPath}
                  matchPath={changeLogPath}
                /> */}

                {authStore.isAdmin && (
                  <SidebarItem
                    // tslint:disable-next-line: campus-use-i18n
                    label={i18n.formatMessage('buildings.admin')}
                    icon={<AdminIcon height="22" width="22" />}
                    to={settingsPath('general')}
                    matchPath={settingsPath('')}
                  />
                )}

                {authStore.isModerator && (
                  <SidebarItem
                    // tslint:disable-next-line: campus-use-i18n
                    label={i18n.formatMessage('buildings.dashboard')}
                    icon={<AnalyticsIcon height="22" width="22" />}
                    to={dashboardPath('general')}
                    matchPath={dashboardPath('')}
                  />
                )}
              </>
            )}
          </MediaQuery>
        )}
      </Items>
      {authStore.isExternal && (
        <>
          <div style={{ flexGrow: 1 }} />
          <SidebarItem
            // tslint:disable-next-line: campus-use-i18n
            label={i18n.formatMessage('buildings.chat')}
            icon={<Forum height="22" width="22" />}
            to={chatPath}
            matchPath={chatPath}
          />
          <SidebarItem
            // tslint:disable-next-line: campus-use-i18n
            label={authStore.user.fullname}
            icon={<Avatar user={authStore.user} size={22} />}
            to={accountPath}
            matchPath={accountPath}
          />
        </>
      )}
      {!authStore.isExternal && <div style={{ flexGrow: 1 }} />}{' '}
      {organizationStore.organization.bloomup_conversation &&
        authStore.isAdmin && (
          <div
            style={{
              padding: '10px',
              boxSizing: 'border-box',
            }}
          >
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              style={{
                whiteSpace: 'nowrap',
                minWidth: '0',
                overflow: 'hidden',
              }}
              onClick={() => {
                chatStore.loadConversation(
                  organizationStore.organization.bloomup_conversation!,
                );
                history.push(
                  conversationPath(
                    organizationStore.organization.bloomup_conversation,
                  ),
                );
              }}
            >
              <HelpOutline
                style={{
                  marginRight: !uiStore.sidebarCollapsed ? '7px' : undefined,
                }}
              />
              {!uiStore.sidebarCollapsed && (
                <FormattedMessage id="commons.need_help" />
              )}
            </Button>
          </div>
        )}
      <ToggleButton
        onClick={uiStore.toggleSidebar}
        style={{ marginTop: authStore.isExternal ? '20px' : undefined }}
      >
        <KeyboardArrowLeft
          style={{
            transform: uiStore.sidebarCollapsed ? 'rotateZ(180deg)' : 'none',
            transition: 'transform .3s',
          }}
        />
      </ToggleButton>
    </Wrapper>
  );
}

export default withRouter(observer(Sidebar));
