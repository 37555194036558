import { DateTime } from 'luxon';
import { action, computed, observable, runInAction } from 'mobx';
import { MiniUser } from '~/models/user';
import { Commentable } from '~/models/utils';
import stores from '~/stores';
import sanitize from '~/utils/sanitize';
import WorkFormsApi from './api';

export interface WorkFormItemConfig {
  title: string;
  description: string;
  type:
    | 'text'
    | 'choices'
    | 'number'
    | 'multiple-choices'
    | 'file'
    | 'rating'
    | 'tags';
  values: any;
}

export class WorkFormItem extends Commentable {
  public id: number;

  @observable
  public confirmed: boolean;

  @observable
  public data: any;

  @observable
  public config: WorkFormItemConfig;

  @observable
  public date_updated: DateTime;

  @observable
  public last_update_author: MiniUser | null = null;

  @observable
  public order: number;

  @observable
  public workform: number;

  @observable
  public locked_by: number | null = null;

  constructor(data: any) {
    super(data);
    Object.assign(this, data);
    if (this.config.type === 'text' && typeof this.data === 'string') {
      this.data = sanitize(this.data);
    }
  }

  public update(data: any) {
    Object.assign(this, data);
  }

  @computed
  get locked() {
    return !!this.locked_by && this.locked_by !== stores.authStore.user.id;
  }
}

export class WorkForm {
  public id: number;

  @observable
  public progress: number;

  @observable
  public items: WorkFormItem[] = [];

  constructor(data: any) {
    Object.assign(this, data);
    this.listen();
  }

  public async loadItems() {
    const items = await WorkFormsApi.getItems(this);
    runInAction(() => {
      this.items = items.map((d: any) => new WorkFormItem(d));
    });
  }

  public async updateItem(item: WorkFormItem, data: any) {
    const newData = await WorkFormsApi.updateItem(this, item, data);
    item.update(newData);
    this.updateProgress();
  }

  public async updateItemConfig(item: WorkFormItem, data: any) {
    const newData = await WorkFormsApi.updateItemConfig(this, item, data);
    runInAction(() => {
      item.update(newData);
    });
    this.updateProgress();
  }

  public async createItem(data: any) {
    const newData = await WorkFormsApi.createItem(this, data);
    runInAction(() => {
      this.items.push(new WorkFormItem(newData));
    });
    this.updateProgress();
  }

  public async deleteItem(item: WorkFormItem) {
    await WorkFormsApi.deleteItem(this, item);
    runInAction(() => {
      this.items = this.items.filter((c) => c !== item);
    });
    this.updateProgress();
  }

  @action
  public updateProgress() {
    let x = 0;
    this.items.forEach((item) => {
      if (item.confirmed) x += 1;
    });
    this.progress = x / this.items.length;
  }

  @action
  public lockItem(item: WorkFormItem) {
    item.locked_by = stores.authStore.user.id;
    stores.io.emit('lock_workformitem', {
      id: item.id,
      workform: this.id,
      user: stores.authStore.user.id,
    });
  }

  @action
  public unlockItem(item: WorkFormItem) {
    item.locked_by = null;
    stores.io.emit('unlock_workformitem', { id: item.id, workform: this.id });
  }

  @action
  private onItemLock = ({ id, user, workform }: any) => {
    if (workform !== this.id) return;
    const found = this.items.find((n) => n.id === id);
    if (found) {
      found.locked_by = user;
    }
  };

  @action
  private onItemUnlock = ({ id, workform }: any) => {
    if (workform !== this.id) return;
    const found = this.items.find((n) => n.id === id);
    if (found) {
      found.locked_by = null;
    }
  };

  private listen() {
    stores.io.on('workformitem', this.onItemUpdate);
    stores.io.on('lock_workformitem', this.onItemLock);
    stores.io.on('unlock_workformitem', this.onItemUnlock);
    stores.io.on('workformitems_status', this.onItemsStatus);

    stores.io.emit('workformitems_status', { workform: this.id });
  }

  private onItemUpdate = (data: WorkFormItem) => {
    const item = this.items.find((c) => c.id === data.id);
    if (item) {
      item.update({
        ...data,
        date_updated: DateTime.fromISO((data.date_updated as any) as string),
      });
      this.updateProgress();
    } else if (data.workform === this.id) {
      this.items.push(new WorkFormItem(data));
      this.updateProgress();
    }
  };

  private onItemsStatus = ({ locked_workformitems }: any) => {
    locked_workformitems.forEach((item_id: string) => {
      const found = this.items.find((item) => item.id === +item_id);
      if (found) {
        runInAction(() => {
          found.locked_by = -1;
        });
      }
    });
  };
}
