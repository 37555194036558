import { Button, withMobileDialog } from '@material-ui/core';
import { InjectedProps } from '@material-ui/core/withMobileDialog';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Dialog from '~/components/dialogs/Dialog';
import DialogActions from '~/components/dialogs/DialogActions';
import DialogContent from '~/components/dialogs/DialogContent';
import DialogTitle from '~/components/dialogs/DialogTitle';
import i18n from '~/locales/i18n';
import stores from '~/stores';

interface Props extends InjectedProps {
  open?: boolean;
  onClose: (value: boolean) => void;
  index: number;
  title: string;
  description: string;
  yes?: string;
  no?: string;
}

class Confirm extends React.Component<Props> {
  public closeFalse = () => {
    this.props.onClose(false);
  }

  public closeTrue = () => {
    this.props.onClose(true);
  }

  public render() {
    const { index, title, description } = this.props;
    return (
      <Dialog
        small
        index={index}
        fullScreen={false}
        disableEscapeKeyDown
        open
        onClose={this.closeFalse}
      >
        <DialogTitle onClose={this.closeFalse}>
          {description.length !== 0 ? (
            title
          ) : (
            <FormattedMessage id="commons.confirm" />
          )}
        </DialogTitle>
        <DialogContent>
          {description.length !== 0 ? description : title}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.closeFalse} color="primary">
            {this.props.no || <FormattedMessage id="commons.no" />}
          </Button>
          <Button onClick={this.closeTrue} variant="contained" color="primary">
            {this.props.yes || <FormattedMessage id="commons.yes" />}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const ConfirmDialog = withMobileDialog<Props>()(Confirm);

const confirm = (
  title: string = i18n.formatMessage('commons.sure'),
  description: string = '',
  yes?: string,
  no?: string,
) =>
  new Promise<boolean>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = (value: boolean) => {
      resolve(value);
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <ConfirmDialog
        index={index}
        onClose={onClose}
        title={title}
        description={description}
        yes={yes}
        no={no}
      />,
    );
  });

export default confirm;
