import { computed, observable, runInAction } from 'mobx';
import { Answer, Question } from '~/models/polls';
import { forceDownload } from '~/utils/downloadFile';
import PollsApi from './api';

export interface PollConfig {
  anonymous?: boolean;
  allowMultipleContribution?: boolean;
  resultsBeforeEnd?: boolean;
  ended?: boolean;
}

export interface PollData {
  data: Question[];
  options: PollConfig;
}

export class Poll implements PollData {
  public id: number;

  @observable
  public data: Question[];

  @observable
  public options: PollConfig;

  @observable
  public contributed: boolean;

  @observable
  public contribution_count: number;

  @observable
  public results: any = null;

  constructor(data: any) {
    Object.assign(this, data);
  }

  public async contribute(answer: Answer[]) {
    await PollsApi.contribute(this, answer);
    runInAction(() => {
      this.contributed = true;
      this.contribution_count += 1;
    });
  }

  public async loadResults() {
    const results = await PollsApi.getResults(this);
    runInAction(() => {
      this.results = results;
    });
  }

  public downloadResults = async () => {
    const csv = await PollsApi.getCSVResult(this);
    const content = `data:text/csv;charset=utf-8,\uFEFF${csv}`;

    const data = encodeURI(content);

    forceDownload(data, 'resultats.csv');
  };

  @computed
  get ended() {
    return this.options && this.options.ended;
  }
}
