import { action, computed, observable, runInAction } from 'mobx';
import ActivityApi from '~/api/activity';
import { Activity } from '~/models/activity';
import { RootStore } from '~/stores';

class ActivityStore {
  @observable
  public activities: Activity[] = [];

  @observable
  public pinnedActivities: Activity[] = [];

  @observable
  public tab: number = 0;

  @observable
  public next: string | null = null;
  public previous: string | null = null;
  public currentUrl: string | null;

  @observable
  public loading: boolean = true;

  @observable
  public newActivityCount: [number, number] = [-1, -1];

  constructor(
    private rootStore: RootStore,
    private io: SocketIOClient.Socket,
  ) {}

  public async init() {
    this.io.on('activity', (data: any) => {
      if (
        data.organization === this.rootStore.organizationStore.organization.id
      ) {
        runInAction(() => {
          this.newActivityCount[data.tab] += 1;
        });
      }
    });
  }

  @action
  public async loadActivities() {
    if (this.activities.length) {
      return this.activities;
    }
    this.loading = true;
    const activities = await ActivityApi.getActivities(
      this.currentUrl,
      this.tab === 1,
    );
    runInAction(() => {
      this.loading = false;
      this.activities = activities.results.filter((c) => !c.pinned);
      this.next = activities.next;
      this.previous = activities.previous;
      this.newActivityCount[this.tab] = 0;

      if (this.tab === 0) {
        this.pinnedActivities = activities.results.filter((c) => c.pinned);
      }
    });

    // check other tab for notif
    if (this.tab === 0 && this.newActivityCount[1] === -1) {
      const newActivities = await ActivityApi.getActivities(
        this.currentUrl,
        true,
      );
      if (newActivities.results.length) {
        const lastActivity = newActivities.results[0];
        if (
          lastActivity.date_extra >
          this.rootStore.authStore.user.date_last_seen_my_feed
        ) {
          runInAction(() => {
            this.newActivityCount[1] = 1;
          });
        }
      }
    }

    return activities.results;
  }

  @action
  public async loadMore() {
    this.loading = true;
    const activities = await ActivityApi.getActivities(this.currentUrl);
    runInAction(() => {
      this.activities.push(...activities.results);
      this.next = activities.next;
      this.previous = activities.previous;
      this.loading = false;
    });

    return activities.results;
  }

  @action.bound
  public async loadNextActivities() {
    this.currentUrl = this.next;
    return this.loadMore();
  }

  @action.bound
  public async reset(withoutTab: boolean = false) {
    this.next = null;
    this.previous = null;
    this.currentUrl = null;
    this.activities = [];
    if (!withoutTab) {
      this.tab = 0;
      this.newActivityCount[0] = 0;
    }
    this.loadActivities();
  }

  @computed
  get hasNext() {
    return this.next && this.next.length !== 0;
  }

  public setTab = (tab: number) => () =>
    runInAction(() => {
      this.tab = tab;
      this.reset(true);
    });

  @computed
  get newsCount() {
    return this.newActivityCount[this.tab];
  }

  @action.bound
  public async togglePin(activity: Activity) {
    const pinned = await ActivityApi.togglePin(activity);
    if (pinned) {
      this.activities = this.activities.filter((c) => c.id !== activity.id);
      this.pinnedActivities.push({ ...activity, pinned });
    } else {
      this.pinnedActivities = this.pinnedActivities.filter(
        (c) => c.id !== activity.id,
      );
      this.activities.push({ ...activity, pinned });
    }
  }

  @computed
  get notPinnedActivities() {
    return this.activities.sort((a1, a2) =>
      a1.date_extra < a2.date_extra ? 1 : -1,
    );
  }
}

export default ActivityStore;
