import api from '~/api/api';
import { MiniUser } from '~/models/user';
import { Team } from './models';

class TeamsApi {
  public async addMembers(team: Team, users: MiniUser[]) {
    const { data } = await api.post(`/teams/${team.id}/add_members`, {
      users: users.map((u) => u.id),
    });
    return data;
  }

  public async removeMembers(team: Team, users: MiniUser[]) {
    const { data } = await api.post(`/teams/${team.id}/remove_members`, {
      users: users.map((u) => u.id),
    });
    return data;
  }

  public async addOwners(team: Team, users: MiniUser[]) {
    const { data } = await api.post(`/teams/${team.id}/add_owners`, {
      users: users.map((u) => u.id),
    });
    return data;
  }

  public async removeOwners(team: Team, users: MiniUser[]) {
    const { data } = await api.post(`/teams/${team.id}/remove_owners`, {
      users: users.map((u) => u.id),
    });
    return data;
  }

  public async updateOwner(team: Team, user: MiniUser) {
    const { data } = await api.post(`/teams/${team.id}/update_owner`, {
      user: user.id,
    });
    return data;
  }

  public async request(team: Team) {
    await api.post(`/teams/${team.id}/request`);
  }

  public async requested(team: Team) {
    const { data } = await api.get(`/teams/${team.id}/requested`);
    return data.requested as boolean;
  }

  public async getJoinRequests(team: Team) {
    const { data } = await api.get(`/teams/${team.id}/requests`);
    return data as MiniUser[];
  }

  public async deleteRequest(team: Team, user: MiniUser) {
    await api.delete(`/teams/${team.id}/delete_request`, {
      params: {
        user: user.id,
      },
    });
  }
}

export default new TeamsApi();
