import { BusinessCenterRounded } from '@material-ui/icons';
import { observer } from 'mobx-react-lite';
import React from 'react';
import CommunitiesIcon from '~/assets/images/icons/communities.svg';
import IntraIcon from '~/assets/images/icons/intra.svg';
import PartnershipIcon from '~/assets/images/icons/partnership.svg';
import ProjectIcon from '~/assets/images/icons/projets.svg';
import TeamIcon from '~/assets/images/icons/team.svg';
import { communitiesColor, green, pink, sand } from '~/constants/colors';
import { Conversation } from '~/models/chat';
import { useStore } from '~/stores';
import styled from '~/styles';
import theme from '~/styles/theme';
import BuildingIcon from '../BuildingIcon';

const Wrapper = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: currentColor;
  svg {
    margin-top: 2px;
    color: white;
  }
`;

interface ChatAvatarProps {
  conversation: Conversation;
  size?: number;
}

function ChatAvatar(props: ChatAvatarProps) {
  const conversation = props.conversation;
  const { organizationStore } = useStore();

  if (conversation.isGroup) {
    return (
      <Wrapper
        style={{
          color: theme.current.palette.primary.main,
          width: `${props.size || 40}px`,
          height: `${props.size || 40}px`,
          minWidth: `${props.size || 40}px`,
          minHeight: `${props.size || 40}px`,
        }}
      >
        <TeamIcon
          style={{
            minWidth: '25px',
            minHeight: '25px',
          }}
          width="25"
          height="25"
        />
      </Wrapper>
    );
  }

  if (conversation.data && conversation.data.type === 'intra_folder') {
    return (
      <Wrapper
        style={{
          color: pink,
          width: `${props.size || 40}px`,
          height: `${props.size || 40}px`,
          minWidth: `${props.size || 40}px`,
          minHeight: `${props.size || 40}px`,
        }}
      >
        <IntraIcon
          style={{
            minWidth: '25px',
            minHeight: '25px',
          }}
          width="25"
          height="25"
        />
      </Wrapper>
    );
  }

  if (conversation.data && conversation.data.type === 'project') {
    return (
      <Wrapper
        style={{
          color: green,
          width: `${props.size || 40}px`,
          height: `${props.size || 40}px`,
          minWidth: `${props.size || 40}px`,
          minHeight: `${props.size || 40}px`,
        }}
      >
        <ProjectIcon
          style={{
            minWidth: '25px',
            minHeight: '25px',
          }}
          height="25"
          width="25"
        />
      </Wrapper>
    );
  }

  if (conversation.data && conversation.data.type === 'partnership') {
    return (
      <Wrapper
        style={{
          color: theme.current.palette.primary.main,
          width: `${props.size || 40}px`,
          height: `${props.size || 40}px`,
          minWidth: `${props.size || 40}px`,
          minHeight: `${props.size || 40}px`,
        }}
      >
        <PartnershipIcon
          style={{
            minWidth: '25px',
            minHeight: '25px',
          }}
          height="25"
          width="25"
        />
      </Wrapper>
    );
  }

  if (conversation.data && conversation.data.type === 'partnership') {
    return (
      <Wrapper
        style={{
          color: green,
          width: `${props.size || 40}px`,
          height: `${props.size || 40}px`,
          minWidth: `${props.size || 40}px`,
          minHeight: `${props.size || 40}px`,
        }}
      >
        <ProjectIcon
          style={{
            minWidth: '25px',
            minHeight: '25px',
          }}
          height="25"
          width="25"
        />
      </Wrapper>
    );
  }

  if (conversation.data && conversation.data.type === 'openinno_project') {
    return (
      <Wrapper
        style={{
          color: sand,
          width: `${props.size || 40}px`,
          height: `${props.size || 40}px`,
          minWidth: `${props.size || 40}px`,
          minHeight: `${props.size || 40}px`,
        }}
      >
        <BusinessCenterRounded
          style={{
            minWidth: '20px',
            minHeight: '20px',
          }}
          height="20"
          width="20"
        />
      </Wrapper>
    );
  }

  if (conversation.data && conversation.data.type === 'community') {
    return (
      <Wrapper
        style={{
          color: communitiesColor,
          width: `${props.size || 40}px`,
          height: `${props.size || 40}px`,
          minWidth: `${props.size || 40}px`,
          minHeight: `${props.size || 40}px`,
        }}
      >
        <CommunitiesIcon
          style={{
            minWidth: '25px',
            minHeight: '25px',
          }}
          height="25"
          width="25"
        />
      </Wrapper>
    );
  }

  if (conversation.data && conversation.data.type === 'labprojectopic') {
    const conf = organizationStore.getLabConfig(
      conversation.data.project_feature,
    );
    return (
      <Wrapper
        style={{
          color: communitiesColor,
          width: `${props.size || 40}px`,
          height: `${props.size || 40}px`,
          minWidth: `${props.size || 40}px`,
          minHeight: `${props.size || 40}px`,
        }}
      >
        <BuildingIcon building={conf ? conf.icon : 'lab'} color="white" />
      </Wrapper>
    );
  }

  return null;
}

export default observer(ChatAvatar);
