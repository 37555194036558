import { action, computed, observable, runInAction } from 'mobx';

import IntraApi from '~/api/intra';
import { ImageImportResult } from '~/components/ImageImport';
import { Folder, IntraItem } from '~/models/intra';
import { FileImportResult } from '~/models/utils';
import { RootStore } from '~/stores';

class IntraStore {
  public items = observable.map<string, IntraItem>();
  public folders = observable.map<string, Folder[]>();

  @observable
  public results: string[] = [];

  @observable
  public search: string = '';

  @observable
  public tags: string[] = [];

  @observable
  public sort: string = '-date_created';

  @observable
  public status: string[] = [];

  @observable
  public next: string | null = null;
  public previous: string | null = null;
  public currentUrl: string | null;

  @observable
  public fileStatus: any = {};

  @observable
  public deletedPosts: number[] = [];

  constructor(private rootStore: RootStore) {}

  public init() {
    this.fileStatus = this.rootStore.authStore.storage.get(
      'intra_files_status',
      {},
    );
  }

  @action
  public updateFileStatus(folder: Folder) {
    this.fileStatus[folder.id] = folder.file_count;
    this.rootStore.authStore.storage.set('intra_files_status', this.fileStatus);
  }

  public async loadIntraItems(force?: boolean) {
    if (force) this.resetUrl();

    if (!force && this.resultItems.length) return this.resultItems;
    const newIntraItems = await IntraApi.getItems(null, this.queryParams);
    runInAction(() => {
      for (const item of newIntraItems.results) {
        this.items.set(item.slug, item);
      }
      this.results = newIntraItems.results.map((item) => item.slug);
      this.next = newIntraItems.next;
      this.previous = newIntraItems.previous;
    });

    return this.resultItems;
  }

  public async loadNotPublished() {
    const notPublished = await IntraApi.getNotPublished();
    runInAction(() => {
      for (const item of notPublished) {
        this.items.set(item.slug, item);
      }
    });

    return notPublished;
  }

  public async loadPopular(time: string) {
    const newIdeaboxItems = await IntraApi.getPopular(time);
    return newIdeaboxItems;
  }

  public async loadIntraItem(slug: string, force: boolean = false) {
    if (force || !this.items.has(slug)) {
      const item = await IntraApi.getIntraItem(slug);
      if (item) {
        this.items.set(item.slug, item);
      }
      return item;
    }
    return this.items.get(slug);
  }

  public async createIntraItem(
    name: string,
    description: string,
    tags: string[],
    project: { name: string; slug: string } | null,
    images: ImageImportResult[],
    files: FileImportResult[],
    saveOnly: boolean,
  ) {
    const item = await IntraApi.createIntraItem(
      name,
      description,
      tags,
      project,
      images,
      files,
      saveOnly,
    );
    if (item) {
      this.items.set(item.slug, item);
      if (item.published) {
        this.rootStore.authStore.addXp('intra_create_xp');
      }
    }
    return item;
  }

  public async updateIntraItem(
    item: IntraItem,
    name: string,
    description: string,
    tags: string[],
    project: { name: string; slug: string } | null,
    images: ImageImportResult[],
    files: FileImportResult[],
    imagesToDelete: number[],
    filesToDelete: number[],
    saveOnly: boolean,
  ) {
    const oldPublished = item.published;
    const updatedIntraItem = await IntraApi.updateIntraItem(
      item,
      name,
      description,
      tags,
      project,
      images,
      files,
      imagesToDelete,
      filesToDelete,
      saveOnly,
    );
    if (updatedIntraItem) {
      this.items.set(updatedIntraItem.slug, updatedIntraItem);
      if (
        !oldPublished &&
        updatedIntraItem.published &&
        updatedIntraItem.owner.id === this.rootStore.authStore.user.id
      ) {
        this.rootStore.authStore.addXp('intra_create_xp');
      }
    }
    return updatedIntraItem;
  }

  @action
  public async deleteIntraItem(item: IntraItem) {
    this.results = this.results.filter((c) => c !== item.slug);
    await IntraApi.deleteIntraItem(item);
  }

  public async loadMore() {
    const newIntraItems = await IntraApi.getItems(
      this.currentUrl,
      this.queryParams,
    );
    runInAction(() => {
      for (const item of newIntraItems.results) {
        this.items.set(item.slug, item);
      }
      this.results.push(...newIntraItems.results.map((item) => item.slug));
      this.next = newIntraItems.next;
      this.previous = newIntraItems.previous;
    });

    return this.resultItems;
  }

  @action.bound
  public async loadNextIntraItems() {
    this.currentUrl = this.next;
    return this.loadMore();
  }

  @computed
  get queryParams() {
    return {
      tags__name: this.tags.join(','),
      q: this.search,
      status: this.status,
      ordering: this.sort,
    };
  }

  @computed
  get resultItems() {
    return this.results
      .map((slug) => this.items.get(slug)!)
      .filter((item) => !!item);
  }

  @action
  public setSearch = (search: string) => {
    this.search = search;
    this.loadIntraItems(true);
  }

  @action
  public setTags = (tags: string[]) => {
    this.tags = tags;
    this.loadIntraItems(true);
  }

  @action
  public setStatus = (status: string[]) => {
    this.status = status;
    this.loadIntraItems(true);
  }

  @action
  public setSort = (sort: string) => {
    this.sort = sort;
    this.loadIntraItems(true);
  }

  @computed
  get hasNext() {
    return this.next && this.next.length !== 0;
  }

  @action.bound
  public resetUrl() {
    this.next = null;
    this.previous = null;
    this.currentUrl = null;
  }

  @action.bound
  public reset() {
    this.tags = [];
    this.sort = '-date_created';
    this.search = '';
    this.results = [];
    this.loadIntraItems(true);
  }
}

export default IntraStore;
