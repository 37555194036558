import emotionStyled, { CreateStyled, injectGlobal } from 'react-emotion';

import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { backgroundColor } from '~/constants/colors';
import theme from '~/styles/theme';

// tslint:disable-next-line:no-unused-expression
injectGlobal`
  html {
    box-sizing: border-box;
  }

  body {
    padding: 0!important;
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0;
    background-color: ${backgroundColor};

    :not(.has-dialog) {
      padding: 0!important;
      overflow-y: scroll!important;
      overflow-x: hidden;
      margin: 0!important;
    }
  }

  div::-webkit-scrollbar, ul::-webkit-scrollbar, textarea::-webkit-scrollbar,
  embed::-webkit-scrollbar {
    width: 12px;
    height: 0px;
  }

  div::-webkit-scrollbar-track, ul::-webkit-scrollbar-track, textarea::-webkit-scrollbar-track,
  embed::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 12px;
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  div::-webkit-scrollbar-thumb, ul::-webkit-scrollbar-thumb, textarea::-webkit-scrollbar-thumb,
  embed::-webkit-scrollbar-thumb {
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.4);
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  div:hover::-webkit-scrollbar-track,
  ul:hover::-webkit-scrollbar-track,
  textarea:hover::-webkit-scrollbar-track,
  embed:hover::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  .horizontal-scroll:hover::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  .horizontal-scroll::-webkit-scrollbar {
    width: 0px;
    height: 12px;
  }

  .horizontal-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0,0,0,0.2); 
    border-radius: 12px;
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  .horizontal-scroll::-webkit-scrollbar-thumb {
    border-radius: 12px;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.2); 
    background-clip: padding-box;
    border: 3px solid transparent;
  }

  ${theme.current.breakpoints.down('sm')} {
    .desktop-only {
      display: none;
    }
  }

  .mobile-only {
    display: none!important;
  }
  ${theme.current.breakpoints.down('sm')} {
    .mobile-only {
      display: block!important;
    }
  }

  .mobile-only-flex {
    display: none!important;
  }
  ${theme.current.breakpoints.down('sm')} {
    .mobile-only-flex {
      display: flex!important;
    }
  }

  .dont-break-out {
    /* These are technically the same, but use both */
    overflow-wrap: break-word;
    word-wrap: break-word;

    /* Instead use this non-standard one: */
    word-break: break-word;
  }

  .ellipses {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .image-gallery {
    cursor: pointer;
  }

  .grecaptcha-badge {
    display: none;
  }

  ${theme.current.breakpoints.down('sm')} {
    #nprogress .bar {
      display: none;
    }
  }

  .medium-zoom-overlay {
    z-index: 1000;
  }

  .medium-zoom-image--opened {
    z-index: 10000;
  }
`;

export { css } from 'react-emotion';

const styled = emotionStyled as CreateStyled<Theme>;
export default styled;
