import { DateTime } from 'luxon';
import { computed, observable, runInAction } from 'mobx';
import IdeaboxApi from '~/api/ideabox';
import { randomColor } from '~/constants/colors';
import { MiniUser } from '~/models/user';
import { Socialable, UploadedImage } from '~/models/utils';
import stores from '~/stores';
import sanitize from '~/utils/sanitize';

export type IdeaboxType = string;

export interface MinIdeaboxItem {
  owner: MiniUser;
  name: string;
  type: IdeaboxType;
  slug: string;
  anonymous?: boolean;
  comment_count?: number;
  like_count?: number;
}

export type IdeaboxStatus = string;

export class IdeaboxItem extends Socialable {
  public name: string;
  public slug: string;
  public type: IdeaboxType;
  public description: string;
  public images: UploadedImage[];
  public tags: string[];
  public owner: MiniUser;
  public team: MiniUser[];
  public data: any;
  public anonymous: boolean;
  public hit_count: number;

  @observable
  public hit: boolean;

  @observable
  public status?: IdeaboxStatus;

  @observable
  public status_message?: string;

  @observable
  public status_users: MiniUser[];

  @observable
  public status_user: MiniUser | null;

  @observable
  public status_date: DateTime | null;

  @observable
  public project: { name: string; slug: string; published: boolean } | null;

  @observable
  public time_like_count?: number;

  @observable
  public color: string;

  @observable
  public custom_likes: number[];

  constructor(data: any) {
    super('ideaboxitem', data);
    Object.assign(this, data);
    this.color = randomColor();
    this.description = sanitize(this.description);
  }

  public update(data: any) {
    Object.assign(this, data);
  }

  @computed
  get thumbnail() {
    const image = this.images.length === 0 ? '' : this.images[0].image;
    return image;
  }

  @computed
  get isOwner() {
    return stores.authStore.user.id === this.owner.id;
  }

  @computed
  get isOwnerOrMember() {
    return (
      this.isOwner || this.team.some((c) => c.id === stores.authStore.user.id)
    );
  }

  @computed
  get statusData() {
    const data =
      stores.organizationStore.organization.config.ideabox_status[this.type];
    if (!data || !data.status || data.disabled) return null;
    return data.status.find((c) => c.name === this.status);
  }

  @computed
  get statusDelay() {
    return this.statusConfig ? this.statusConfig.delay || 0 : 0;
  }

  @computed
  get statusConfig() {
    const data =
      stores.organizationStore.organization.config.ideabox_status[this.type];
    if (!data || !data.status || data.status.length === 0 || data.disabled) {
      return null;
    }
    return data;
  }

  @computed
  get customLiked() {
    return this.custom_likes.indexOf(stores.authStore.user.id) !== -1;
  }

  @computed
  get customLikeCount() {
    return this.custom_likes.length;
  }

  @computed
  get customLikesConfig() {
    return stores.organizationStore.organization.config.custom_likes.ideabox;
  }

  @computed
  get toggleCustomLike() {
    if (!this.customLikesConfig) return;
    if (
      this.customLikesConfig.user_types.indexOf(
        stores.authStore.user.user_type,
      ) === -1 &&
      this.customLikesConfig.user_types.indexOf('*') === -1
    ) {
      return undefined;
    }

    return () =>
      runInAction(() => {
        IdeaboxApi.toggleCustomLike(this);
        if (this.customLiked) {
          this.custom_likes = this.custom_likes.filter(
            (c) => c !== stores.authStore.user.id,
          );
        } else {
          this.custom_likes = [...this.custom_likes, stores.authStore.user.id];
        }
      });
  }
}
