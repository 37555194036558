import React from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from '~/styles';

import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';

import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import withStore, { WithStoreProps } from '~/utils/withStore';

const SidebarListItem = styled(ListItem)`
  margin: 7px 0;
  will-change: background-color;
  border-radius: 8px;
  overflow: hidden;
  transition: background-color 0.1s;
  color: white;
  padding-top: 8px;
  padding-bottom: 8px;

  h6 {
    font-size: 15px;
  }

  &.selected {
    color: white;
    background-color: rgba(255, 255, 255, 0.2);

    h6 {
      font-weight: 500;
    }
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    &.selected {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  ${(p) => p.theme.breakpoints.down('xs')} {
    height: 40px;
  }
`;

const Wrapper = styled.div`
  padding: 0 9px;
`;

interface Props extends WithStoreProps {
  label: string | React.ReactElement;
  icon?: any;
  selected?: boolean;
  to?: string;
  feature?: string;
  onClick?: () => void;
  exact?: boolean;
  matchPath?: string | string[];
  disableLabelTranslation?: boolean;
  forceColor?: boolean;
}

@observer
class SidebarItem extends React.Component<RouteComponentProps<any> & Props> {
  public renderLink = (props: any) =>
    this.props.to ? <Link to={this.props.to} {...props} /> : null;

  public render() {
    const {
      label,
      icon,
      selected,
      to,
      location,
      onClick,
      exact,
      matchPath,
      store,
      disableLabelTranslation,
      forceColor,
    } = this.props;
    const finalSelected =
      selected ||
      (to
        ? exact
          ? location.pathname === to
          : Array.isArray(matchPath)
          ? matchPath.some((p) => location.pathname.startsWith(p))
          : location.pathname.startsWith(matchPath || to)
        : false);

    return (
      <Wrapper>
        <SidebarListItem
          disableRipple
          className={`${finalSelected ? 'selected' : ''} ${
            store.uiStore.sidebarCollapsed ? 'collapsed' : ''
          }`}
          button
          onClick={onClick}
          component={to ? this.renderLink : undefined}
        >
          <ListItemIcon
            style={{
              marginRight: '0px',
              color: forceColor ? 'white' : 'inherit',
            }}
          >
            {icon}
          </ListItemIcon>
          <ListItemText disableTypography>
            <Typography
              variant="subtitle1"
              color="inherit"
              style={{ overflow: 'hidden' }}
            >
              {disableLabelTranslation
                ? label
                : typeof label === 'string' &&
                  label.length !== 0 && (
                    <FormattedMessage id={label as string} />
                  )}
            </Typography>
          </ListItemText>
        </SidebarListItem>
      </Wrapper>
    );
  }
}

export default withStore(withRouter(SidebarItem));
