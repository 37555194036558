import { DateTime } from 'luxon';
import { action, observable } from 'mobx';

import LikeApi from '~/api/like';
import { ImageImportResult } from '~/components/ImageImport';
import { MiniUser } from '~/models/user';
import { FileImportResult } from '~/models/utils';

export type CommentFile = FileImportResult | ImageImportResult | null;
export class Comment {
  public id: number;
  public content: string;
  public user: MiniUser;
  public date_created: DateTime;
  public parent?: number;
  public file?: string;

  @observable
  public liked: boolean;

  @observable
  public like_count: number;

  constructor(data: any) {
    Object.assign(this, data);
  }

  @action.bound
  public toggleLike() {
    if (this.liked) {
      this.like_count -= 1;
      LikeApi.unlike('comment', this.id);
    } else {
      this.like_count += 1;
      LikeApi.like('comment', this.id);
    }
    this.liked = !this.liked;
  }
}
