import api from '~/api/api';
import { ImageImportResult } from '~/components/ImageImport';
import {
  LimitOffsetParams,
  OrderParams,
  PaginatedResult,
  TagsParams,
} from '~/models/api';
import {
  IdeaboxItem,
  IdeaboxStatus,
  IdeaboxType,
  MinIdeaboxItem,
} from '~/models/ideabox';
import { MiniUser } from '~/models/user';
import toImageName from '~/utils/toImageName';

export interface GetItemsParams
  extends LimitOffsetParams,
    TagsParams,
    OrderParams {
  type?: string[];
}

async function getItems(
  url: string | null,
  params: any,
): Promise<PaginatedResult<IdeaboxItem>> {
  try {
    const { data } = await api.get(url || '/ideabox/search', { params });
    return {
      ...data,
      results: data.results.map((i: any) => new IdeaboxItem(i)),
    };
  } catch (e) {
    return { next: null, previous: null, results: [] };
  }
}

async function getItem(slug: string): Promise<IdeaboxItem | null> {
  try {
    const { data } = await api.get(`/ideabox/${slug}`);
    return new IdeaboxItem(data);
  } catch (e) {
    return null;
  }
}

async function createItem(
  type: IdeaboxType,
  name: string,
  description: string,
  tags: string[],
  url: string,
  anonymous: boolean,
  team: MiniUser[],
): Promise<IdeaboxItem | null> {
  const formData = new FormData();
  formData.append('type', type);
  formData.append('name', name);
  formData.append('description', description);
  formData.append('data', JSON.stringify({ url }));
  formData.append('tags', JSON.stringify(tags));
  formData.append('anonymous', anonymous ? 'True' : 'False');

  team.forEach((member) => {
    formData.append('new_team', member.id.toString());
  });

  try {
    const { data } = await api.post('/ideabox/', formData);
    return new IdeaboxItem(data);
  } catch (e) {
    return null;
  }
}

async function updateItem(
  item: IdeaboxItem,
  type: IdeaboxType,
  name: string,
  description: string,
  tags: string[],
  url: string,
  anonymous: boolean,
  thumbnail: ImageImportResult | null,
  team: MiniUser[],
): Promise<IdeaboxItem | null> {
  const formData = new FormData();
  if (item.type !== type) formData.append('type', type);
  if (item.name !== name) formData.append('name', name);
  if (item.anonymous !== anonymous) {
    formData.append('anonymous', anonymous ? 'True' : 'False');
  }
  if (item.description !== description) {
    formData.append('description', description);
  }
  if (JSON.stringify(item.data) !== JSON.stringify({ url })) {
    formData.append('data', JSON.stringify({ url }));
  }

  if (JSON.stringify(item.tags) !== JSON.stringify(tags)) {
    formData.append('tags', JSON.stringify(tags));
  }

  if (thumbnail) {
    item.images
      .filter((c) => !!c.id)
      .forEach((file) => {
        formData.append('to_delete_images', file.id.toString());
      });
    formData.append('new_images', thumbnail.blob!, toImageName(thumbnail.name));
  }

  team.forEach((member) => {
    formData.append('new_team', member.id.toString());
  });

  try {
    const { data } = await api.patch(`/ideabox/${item.slug}`, formData);
    return new IdeaboxItem(data);
  } catch (e) {
    return null;
  }
}

async function getPopular(
  time: string,
  type?: string,
): Promise<MinIdeaboxItem[]> {
  try {
    const { data } = await api.get('/ideabox/popular', {
      params: { time, type },
    });
    return data.map((i: any) => new IdeaboxItem(i));
  } catch (e) {
    return [];
  }
}

async function deleteItem(item: IdeaboxItem) {
  await api.delete(`/ideabox/${item.slug}`);
}

async function setStatus(
  item: IdeaboxItem,
  status: IdeaboxStatus | null,
  message: string,
  members: MiniUser[],
  moderationMessage?: string,
) {
  const { data } = await api.post(`/ideabox/${item.slug}/set_status`, {
    status,
    status_moderation_message: moderationMessage,
    status_message: message,
    status_users: members.map((u) => u.id),
  });
  item.update(data);
}

async function getSimilar(tags: string[]): Promise<IdeaboxItem[]> {
  try {
    const { data } = await api.get('/ideabox/similar', { params: { tags } });
    return data.map((d: any) => new IdeaboxItem(d));
  } catch (e) {
    return [];
  }
}

async function toggleCustomLike(item: IdeaboxItem): Promise<number[]> {
  const { data } = await api.post(`/ideabox/${item.slug}/toggle_custom_like`);
  return data;
}

export default {
  getItem,
  getItems,
  createItem,
  updateItem,
  getPopular,
  deleteItem,
  setStatus,
  getSimilar,
  toggleCustomLike,
};
