import React from 'react';
import { Conversation } from '~/models/chat';
import stores from '~/stores';
import lazyRetry from '~/utils/lazyRetry';

const CreateChatDialog = lazyRetry(() => import('./CreateChatDialog'));

export const createChat = () =>
  new Promise<Conversation | null>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = (conv: Conversation | null) => {
      resolve(conv);
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(<CreateChatDialog onClose={onClose} />);
  });

const EditChatDialog = lazyRetry(() => import('./EditChatDialog'));

export const editChat = (conversation: Conversation) =>
  new Promise<Conversation>((resolve) => {
    const index = stores.uiStore.dialogStack.length;
    const onClose = (conv: Conversation) => {
      resolve(conv);
      stores.uiStore.closeDialog(index);
    };
    stores.uiStore.openDialog(
      <EditChatDialog onClose={onClose} conversation={conversation} />,
    );
  });
