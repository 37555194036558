import axios from 'axios';
import localForage from 'localforage';
import { Settings } from 'luxon';
import { autorun, computed, observable } from 'mobx';
import { addLocaleData, IntlProvider } from 'react-intl';
import en from 'react-intl/locale-data/en';
import fr from 'react-intl/locale-data/fr';
import enMessages from '~/locales/en.json';
import frMessages from '~/locales/fr.json';
import { RootStore } from '~/stores';
import storage from '~/utils/storage';

export type TranslationId = string;

export const messages = {
  en: enMessages,
  fr: frMessages,
};

const useGoogle = storage.get('GOOGLE_TRAD');

class I18N {
  public googleMessages: any = null;

  public store: RootStore | null = null;

  @observable
  public locale: 'fr' | 'en' = 'fr';

  public init(store: RootStore) {
    this.store = store;
    if (useGoogle) {
      this.loadGoogleMessages();
    }
    addLocaleData([...en, ...fr]);
    autorun(() => {
      localForage.setItem('locale', this.locale);
      Settings.defaultLocale = this.locale;
      this.locale = store.authStore.language;
    });
  }

  @computed
  get intl() {
    const intlProvider = new IntlProvider({
      locale: this.locale,
      messages: this.messages,
    });
    const { intl } = intlProvider.getChildContext();
    return intl;
  }

  public loadGoogleMessages = async () => {
    if (this.googleMessages) return;
    const { data } = await axios.get(
      // tslint:disable-next-line: max-line-length
      'https://docs.google.com/spreadsheets/d/e/2PACX-1vRJos_WSYpI93T_cdwVlivAl5zWpxAR9vzvCPy47LDYWXrtgg7y_z74zcyfyajNpftYJhzlqm4jKkgE/pub?output=csv',
    );

    const results = {
      fr: {},
      en: {},
    };

    const Papa = await import('papaparse');
    const result = Papa.parse(data);
    const rows = result.data.slice(1).map((c) => c.map((i: any) => i.trim()));

    rows.forEach((r) => {
      results.en[r[0]] = r[1];
      results.fr[r[0]] = r[2];
    });

    this.googleMessages = results;
  };

  @computed
  get messages() {
    const buildingNames =
      this.store && this.store.organizationStore.organization
        ? this.store.organizationStore.organization.config.building_names
        : {};

    const msgs = useGoogle
      ? this.googleMessages[this.locale]
      : messages[this.locale];

    const translations = Object.assign({}, msgs);

    const originalBuildingNames = {};

    [
      'ideabox',
      'academy',
      'projects',
      'intra',
      'challenges',
      'openinno',
      'openinno.partnerships',
      'communities',
      'lab',
      'oi_contest',
    ].forEach((building) => {
      const key = `buildings.${building}`;
      const fullKey = `buildings.${building}.full`;
      originalBuildingNames[building] = [
        translations[key],
        translations[fullKey],
      ];
      translations[key] = `{${key}}`;
      translations[fullKey] = `{${fullKey}}`;
    });

    [
      'ideabox',
      'academy',
      'projects',
      'intra',
      'challenges',
      'openinno',
      'openinno.partnerships',
      'communities',
      'lab',
      'oi_contest',
    ].forEach((building) => {
      const names = buildingNames[building];
      let [name, fullName] = originalBuildingNames[building];
      if (names && names.length === 2) {
        if (names[0].length > 0) name = names[0];
        if (names[1].length > 0) fullName = names[1];
      }

      const nameRegex = new RegExp(`{buildings.${building}}`, 'g');
      const fullNameRegex = new RegExp(`{buildings.${building}.full}`, 'g');
      Object.keys(translations).forEach((key) => {
        translations[key] = translations[key].replace(nameRegex, name);
        translations[key] = translations[key].replace(fullNameRegex, fullName);
      });
    });

    return translations;
  }

  public formatMessage(id: string, values?: any) {
    const value = this.intl.formatMessage({ id }, values);
    if (value === id && values) {
      return `${id} {value_keys=${JSON.stringify([...Object.keys(values)])}}`;
    }
    return value;
  }
}

export default new I18N();
