import React from 'react';
import progress from '~/utils/progress';

class Progress extends React.Component {
  public componentDidMount() {
    progress.start();
  }

  public componentWillUnmount() {
    progress.done();
  }

  public render() {
    return '';
  }
}

export default Progress;
