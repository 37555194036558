import { DateTime } from 'luxon';
import { action, observable, runInAction } from 'mobx';
import GlobalApi from '~/api/global';
import { Banner } from '~/models/global';
import storage from '~/utils/storage';
import { RootStore } from '.';

class GlobalStore {
  @observable
  public banner: Banner;

  @observable
  public canBeInstalled: boolean = false;

  @observable
  public displayInstallPromp: boolean = false;

  @observable
  public boardStatus: any = {};

  public prompt: any = null;

  constructor(private rootStore: RootStore) {
    const previousDate = storage.get('installPromptDate');

    this.displayInstallPromp =
      !previousDate ||
      (previousDate !== 'never' &&
        DateTime.fromISO(previousDate)
          .diffNow()
          .as('milliseconds') < 0);

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.prompt = e;
      runInAction(() => {
        this.canBeInstalled = true;
      });
    });
  }

  @action
  public updateBoardStatus(id: number, count: number) {
    this.boardStatus[id] = count;
    this.rootStore.authStore.storage.set('board_status', this.boardStatus);
  }

  public async loadBanner() {
    if (this.banner) return;
    const banner = await GlobalApi.getBanner();
    runInAction(() => {
      this.banner = banner;
    });
  }

  @action
  public openPrompt = () => {
    if (this.prompt && this.prompt.prompt) {
      this.prompt.prompt();
    }
    this.canBeInstalled = false;
  }

  @action
  public closeInstallPrompt = (again?: boolean) => {
    this.displayInstallPromp = false;
    storage.set(
      'installPromptDate',
      again
        ? DateTime.local()
            .plus({ hours: 48 })
            .toISO()
        : 'never',
    );
  }

  public init() {
    this.boardStatus = this.rootStore.authStore.storage.get('board_status', {});
  }
}

export default GlobalStore;
