import { css } from 'emotion';

export const VideoWrapper = css`
  max-width: 700px;
  margin: 0 auto;

  div {
    position: relative;
    display: block;
    width: 100%;
    padding-top: calc(9 / 16 * 100%);
    margin: 20px auto;
  }

  iframe {
    border: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const PdfWrapper = css`
  max-width: 100%;
  margin: 0 auto;

  div {
    position: relative;
    display: block;
    width: 100%;
    padding-top: calc(210 / 297 * 100%);
    margin: 20px auto;
  }

  embed {
    border: none;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    overflow: hidden;
  }
`;

export default (content: string) =>
  (content || '')
    .replace(
      /(<iframe [^>]*><\/iframe>)/g,
      `<div class="${VideoWrapper}"><div>$1</div></div>`,
    )
    .replace(
      /(<embed [^>]*>)/g,
      `<div class="${PdfWrapper}"><div>$1</div></div>`,
    );
