import styled from '~/styles';

import { Paper } from '@material-ui/core';
import { css } from 'emotion';
import { setLightness } from 'polished';

export function getContentStyle(p: any) {
  return css`
    font-family: Roboto, sans-serif;
    font-size: 15px;
    color: #333;

    .ql-emojiblot {
      vertical-align: middle;
      margin-top: 2px;
    }

    .mention {
      background-color: rgba(0, 0, 0, 0.05);
      padding: 2px;
      border-radius: 5px;
      font-weight: bold;

      .ql-mention-denotation-char {
        color: ${p.theme.palette.primary.main};
        margin-right: 1px;
      }

      a {
        text-decoration: none;
        color: ${p.theme.palette.primary.main};
      }
    }

    p {
      margin: 0;
      &.ql-align-center {
        text-align: center;
      }

      &.ql-align-right {
        text-align: right;
      }
    }

    img {
      display: inline-block;
      max-width: 100%;
      border-radius: 5px;
      margin-top: 5px;
      cursor: default !important;

      &.ql-align-center {
        margin: 0 auto;
      }

      &.ql-align-right {
        margin-right: auto;
      }
    }

    a {
      color: ${p.theme.palette.primary.main};

      img {
        cursor: pointer !important;
      }
    }

    h3 {
      margin: 0;
      font-size: 1.5em;
      color: ${p.theme.palette.primary.main};
    }

    h2 {
      margin: 0;
      font-size: 2em;
      color: ${p.theme.palette.primary.main};
    }

    h4 {
      margin: 0;
      margin-bottom: 5px;
      font-weight: 500;
      font-size: 1.25rem;
      color: ${p.theme.palette.primary.main};
    }

    video {
      cursor: pointer;
      display: block;
      border-radius: 5px;
      max-width: 700px;
      width: 100%;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
        0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);

      &.ql-align-center {
        margin: 0 auto;
      }

      &.ql-align-right {
        margin-right: auto;
      }
    }

    iframe {
      cursor: pointer;
      display: block;
      border-radius: 5px;
      max-width: 700px !important;
      min-height: 400px !important;
      width: 100%;
      border: none;
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
        0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);

      &.ql-align-center {
        margin: 0 auto;
      }

      &.ql-align-right {
        margin-right: auto;
      }
    }

    embed {
      border: none;
      width: 100%;
      max-width: 700px;
    }

    blockquote {
      width: calc(100% - 40px);
      padding: 10px 20px;
      color: ${p.theme.palette.grey[700]};
      border-left: 5px solid ${p.theme.palette.grey[600]};
      margin: 0;
    }

    .file {
      display: inline-flex;
      background-color: white;
      border-radius: 7px;
      padding: 10px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
        0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
      align-items: center;
      font-size: 15px;
      cursor: pointer;
      margin-bottom: 5px;
      margin-top: 5px;
      margin-right: 10px;

      transition: 0.3s transform;

      color: ${p.theme.palette.grey[800]};
      text-decoration: none;
      word-break: break-all;

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
          0px 0px 0px 0px rgba(0, 0, 0, 0.14),
          0px 0px 0px 0px rgba(0, 0, 0, 0.12);
        background-color: ${setLightness(0.98, p.theme.palette.primary.main)};
      }

      padding-left: 40px;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM17 13l-5 5-5-5h3V9h4v4h3z'%3E%3C/path%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: 22px;
      background-position-y: center;
      background-position-x: 10px;
    }
  `;
}

export const TextEditorWrapper = styled(Paper)`
  border-radius: 10px;
  padding: 5px;
  padding-left: 2px;

  &&& {
    .ql-container {
      border: none;
    }

    .ql-toolbar {
      border: none;

      button,
      svg {
        color: #989898;
      }

      .ql-stroke {
        stroke: #989898;
      }

      .ql-fill {
        fill: #989898;
      }

      > span:hover,
      > button:hover,
      .extras > span:hover,
      .extras > button:hover,
      .ql-active {
        color: ${(p) => p.theme.palette.primary.main};

        .ql-stroke {
          stroke: ${(p) => p.theme.palette.primary.main};
        }

        .ql-fill {
          fill: ${(p) => p.theme.palette.primary.main};
        }

        button,
        svg {
          color: ${(p) => p.theme.palette.primary.main};
        }
      }
    }

    .ql-picker-options {
      transform: translateY(calc(-100% - 23px)) !important;
    }

    .extras {
      max-width: 0px;
      white-space: nowrap;
      display: inline-flex;
      float: left;
      align-items: center;
      transition: max-width 0.3s;

      > * {
        display: none;
      }

      &.open {
        > * {
          display: unset;
        }
        max-width: 500px;
      }
    }

    .open-actions {
      float: left;

      cursor: pointer;
      &:hover {
        color: ${(p) => p.theme.palette.primary.main};
      }

      ${(p) => p.theme.breakpoints.down('sm')} {
        display: none;
      }
    }

    .ql-separator {
      height: 20px;
      width: 1px;
      vertical-align: middle;
      background-color: #989898;
      margin: 0 5px;
      margin-top: 2px;
      float: left;
    }

    .ql-snow .ql-editor {
      ${(p) => getContentStyle(p)}
    }

    .ql-editor.ql-blank::before {
      color: rgba(0, 0, 0, 0.4);
      content: attr(data-placeholder);
      font-style: normal;
      pointer-events: none;
      position: absolute;
    }

    #tab-panel {
      padding-top: 10px;
      ${(p) => getContentStyle(p)}
      .bem {
        margin: 4px;
      }
    }
  }
`;

export const TagFacesString =
  '<svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path fill="currentColor" d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"></path></svg>';
